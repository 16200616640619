import { TreeIterator } from '@/util';
import datasource from '@/visualEditor/libs/datasource';

export function convertModalToControl(data) {
  let pList = [];
  let cList = [];

  data.forEach((item) => {
    let $props = {};
    try {
      $props = JSON.parse(item.componentContext);
      $props.businessId = item.businessId;
    } catch (e) {}
    let curr = datasource.find((x) => x.$component == item.componentType);

    let xItem = {
      $id: item.componentCode,
      $name: curr?.$name || '',
      isOnly: curr?.isOnly,
      $component: item.componentType,
      componentUuid: item.componentUuid,
      parentId: item.parentId,
      $props,
    };

    if (item.parentId) {
      cList.push(xItem);
    } else {
      pList.push(xItem);
    }
  });

  cList.forEach((child) => {
    let parent = pList.find((item) => item.componentUuid == child.parentId);
    if (parent) {
      parent.$props.elements = parent.$props.elements || [];
      parent.$props.elements.push(child);
    }
  });

  return pList;
}

export function convertControlToModal(comList, excuteType, activityId) {
  let ret = [];
  TreeIterator.each(
    comList,
    (item) => {
      let cc = { ...item.$props };
      if (item.$component == 'pageTitle') {
        cc.excuteType = excuteType;
      } else if (item.$component == 'zone') {
        cc.elements = undefined;
        delete cc.elements;
      }
      ret.push({
        activityId,
        componentType: item.$component,
        componentCode: item.$id,
        componentUuid: item.componentUuid,
        parentId: item.parentId,
        componentContext: JSON.stringify(cc),
      });
    },
    '$props.elements',
  );

  return ret;
}
