import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import EditContext from '@/util/EditContext';

class EditTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  disabledDate = (current) => {
    let { comList } = this.context;
    const { startTime, endTime } = comList[0].$props;
    if (current && startTime && current <= moment(startTime).startOf('day')) return true;
    if (current && endTime && current >= moment(endTime).endOf('day')) return true;
  };

  handleChange = (e) => {
    if (e) {
      this.props.onChange?.(e.format('YYYY-MM-DD HH:mm:ss'));
    } else {
      this.props.onChange?.(e);
    }
  };

  render() {
    const { value } = this.props;
    return (
      <div>
        <DatePicker
          showTime
          placeholder="请选择时间"
          value={value ? moment(value) : undefined}
          disabledDate={this.disabledDate}
          onChange={this.handleChange}
          style={{ width: '100%', marginBottom: 6 }}
        />
        {/* <span style={{ color: ' rgba(0,0,0,0.45)' }}>
          该时间需在专题启用时间范围内；专题永久有效时，倒计时组件会被隐藏
        </span> */}
      </div>
    );
  }
}

EditTime.contextType = EditContext;

export default EditTime;
