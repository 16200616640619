import React from 'react';
import { Upload, Icon, message, Modal } from 'antd';
import _ from 'lodash';
import FileSize from './FileSize';
import OSS from 'ali-oss';
import { v4 as uuidv4 } from 'uuid';
import ReactPlayer from 'react-player';
import videoService from '@/service/video';
import globalService from '@/service/global';

import './index.less';

export default class UploadEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.uploader = null;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value && props.value !== undefined) {
      state.value = props.value;
      return state;
    }

    return null;
  }

  componentDidMount() {
    this.initUploader();
  }

  initUploader = async () => {
    this.uploader = new window.AliyunUpload.Vod({
      //userID，必填，只需有值即可。
      userId: '122',
      //分片大小默认1 MB，不能小于100 KB
      partSize: 1048576,
      //并行上传分片个数，默认5
      parallel: 5,
      //网络原因失败时，重新上传次数，默认为3
      retryCount: 3,
      //网络原因失败时，重新上传间隔时间，默认为2秒
      retryDuration: 2,
      //是否上报上传日志到视频点播，默认为true
      enableUploadProgress: true,
      //开始上传
      onUploadstarted: (uploadInfo) => {
        this.setState({ loading: true });
        //上传方式1，需要根据uploadInfo.videoId是否有值，调用视频点播的不同接口获取uploadauth和uploadAddress，如果videoId有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
        this.onGetUploadAddress(uploadInfo);
      },
      //文件上传成功
      onUploadSucceed: (uploadInfo) => {
        let { videoId } = uploadInfo;
        this.onGetVideoInfo(videoId);
      },
      //文件上传失败
      onUploadFailed: (uploadInfo, code) => {
        this.setState({ loading: false });
      },
      //文件上传进度，单位：字节
      //   onUploadProgress: (uploadInfo, totalSize, loadedPercent) => {},
      //上传凭证超时
      onUploadTokenExpired: (uploadInfo) => {
        //实现时，根据uploadInfo.videoId调用刷新视频上传凭证接口重新获取UploadAuth
        //从点播服务刷新的uploadAuth，设置到SDK里
        this.onGetUploadAddress(uploadInfo);

        this.uploader.resumeUploadWithAuth(window.uploadAuth);
      },
      //全部文件上传结束
      onUploadEnd: (uploadInfo) => {
        // console.log('onUploadEnd: uploaded all the files');
      },
    });
  };

  onGetUploadAddress = (uploadInfo) => {
    videoService
      .getUploadAddress({
        title: uploadInfo.file.name,
        fileName: uploadInfo.file.name,
      })
      .then((res) => {
        if (res?.code == 0) {
          this.uploader.setUploadAuthAndAddress(
            uploadInfo,
            res.data.UploadAuth,
            res.data.UploadAddress,
            res.data.VideoId,
          );
        }
      });
  };

  onGetVideoInfo = (videoId) => {
    const { onChange } = this.props;
    videoService
      .getVideoInfo({
        videoId,
      })
      .then((res) => {
        if (res?.code == 0) {
          if (res?.data?.status != 'Normal') {
            setTimeout(() => {
              this.onGetVideoInfo(videoId);
            }, 1000);

            return;
          }
          this.setState(
            {
              loading: false,
            },
            () => {
              let { coverMediaUrl } = res.data;
              let { mediaUrl } = res.data;
              let info = {
                coverMediaUrl,
                mediaUrl,
                videoId,
              };
              onChange(info?.mediaUrl);
              this.setState({
                value: info,
              });
            },
          );
        }
      });
  };

  getSTS = async () => {
    let accessKeyId = window.localStorage.getItem('sts_access_id');
    let accessKeySecret = window.localStorage.getItem('sts_access_secret');
    let securityToken = window.localStorage.getItem('sts_access_token');
    let stsTokenTime = window.localStorage.getItem('sts_token_time');
    let now = Date.now();

    if (accessKeyId && accessKeySecret && securityToken && stsTokenTime && now - stsTokenTime < 1000 * 60 * 30) {
      return {
        accessKeyId,
        accessKeySecret,
        securityToken,
      };
    }

    let res = await globalService.getOssTempAccount()

    if (res?.code == 0 && res?.data?.accessKeyId) {
      let obj = {
        accessKeyId: res.data.accessKeyId,
        accessKeySecret: res.data.accessKeySecret,
        securityToken: res.data.securityToken,
      };

      window.localStorage.setItem('sts_access_id', obj.accessKeyId);
      window.localStorage.setItem('sts_access_secret', obj.accessKeySecret);
      window.localStorage.setItem('sts_access_token', obj.securityToken);
      window.localStorage.setItem('sts_token_time', now);
      return obj;
    }

    return null;
  };

  handleChange = (info) => {
    const { onChange, multiple, needTransfer } = this.props;
    // 视频需要转码
    if (needTransfer && info.file.type.indexOf('mp4') !== -1) {
      this.uploader.addFile(info.file.originFileObj, null, null, null, null);
      this.uploader.startUpload();
      return;
    }
    if (multiple) {
      let xfileList = info.fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response;
        }
        return file;
      });
      this.setState({
        fileList: xfileList,
      });
    }

    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }

    if (multiple) {
      let x = info.fileList.find((item) => item.status != 'done');
      if (!x) {
        this.setState(
          {
            loading: false,
          },
          () => {
            let file = info.fileList.map((item) => {
              return item.response;
            });

            if (_.isFunction(onChange)) {
              onChange(file);
            } else {
              this.setState({
                value: file,
              });
            }
          },
        );
      }
    } else if (info.file.status === 'done' && info.file.response) {
      let vData = info.file.response;
      this.setState(
        {
          loading: false,
        },
        () => {
          let file = vData;
          if (_.isFunction(onChange)) {
            onChange(file);
          } else {
            this.setState({
              value: file,
            });
          }
        },
      );
    }
  };

  handlePreview = (xVal) => {
    const { isVideo } = this.props;

    if (isVideo) {
      Modal.confirm({
        className: 'preview-modal',
        // title: '视频预览',
        okCancel: false,
        content: <ReactPlayer controls url={xVal.split('?')[0]} />,
      });
      return;
    }
    Modal.confirm({
      className: 'preview-modal',
      // title: '图片预览',
      okCancel: false,
      content: <img src={xVal} alt="" style={{ maxWidth: '600px', maxHeight: '400px' }} />,
    });
  };

  renderImage = () => {
    const { multiple, maxCount = 8, isVideo, onChange } = this.props;
    const { value } = this.state;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    let xVal = value;
    if (xVal && isVideo) {
      xVal = xVal.split('?')[0] + '?x-oss-process=video/snapshot,t_7000,f_jpg,w_800,h_600,m_fast';
    }

    return value ? (
      <div className="bnq-upload-box">
        <img src={xVal} alt="avatar" style={{ width: '100%' }} />
        <div className="bnq-upload-mask">
          <Icon
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              this.handlePreview(xVal);
            }}
            type="eye"
            style={{ color: 'white', fontSize: 16 }}
          />
          <Icon
            type="delete"
            style={{ color: 'white', fontSize: 16 }}
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              onChange && onChange('');
              this.setState({
                value: undefined,
              });
            }}
          />
        </div>
      </div>
    ) : (
      uploadButton
    );
  };

  handleBefore = (file) => {
    const { maxSize, multiple, accept } = this.props;
    if (multiple) {
      this.setState({
        fileList: [],
      });
    }

    if (!file) {
      return false;
    }

    if (maxSize) {
      let xSize = new FileSize(maxSize);
      if (file.size > xSize.toNumber()) {
        message.warning(`文件不能超过${maxSize}`);

        return false;
      }
    }

    let type = file.type.toLocaleLowerCase();

    if (accept) {
      let xList = accept.split(',');
      if (!xList.find((item) => type.indexOf(item.slice(1).toLocaleLowerCase()) >= 0)) {
        message.warning(`文件格式不正确`);
        return false;
      }
    }

    return true;
  };

  handleCustomRequest = async ({ file, filename, onError, onProgress, onSuccess }) => {
    let xres = await this.getSTS();

    if (!xres) {
      return onError(new Error('获取临时认证失败'));
    }

    let params = {
      accessKeyId: xres.accessKeyId,
      accessKeySecret: xres.accessKeySecret,
      stsToken: xres.securityToken,
      // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
      region: 'oss-cn-hangzhou',
      bucket: 'decorationhome',
    };

    // 本地开发开启代理
    if (
      window.location.hostname.indexOf('localhost') >= 0 ||
      window.location.hostname.indexOf('192.168') >= 0 ||
      window.location.hostname.indexOf('127.0.0.1') >= 0 ||
      window.location.hostname.indexOf('0.0.0.0') >= 0
    ) {
      params = {
        ...params,
        cname: true,
        endpoint: `http://${window.location.host}/`,
      };
    }

    let client = new OSS(params);

    let xName = file.name;
    if (xName.indexOf('.') > 0) {
      let ext = xName.split('.').pop();
      xName = `${uuidv4()}.${ext}`;
    } else {
      xName = uuidv4();
    }

    client
      .put(`/oss/${xName}`, file)
      .then((result) => {
        if (result?.res?.status == 200) {
          onSuccess(`https://dhstatic.bthome.com/${result.name}`);
        } else {
          throw new Error('上传失败');
        }
      })
      .catch((err) => {
        window.localStorage.setItem('sts_access_id', '');
        window.localStorage.setItem('sts_access_secret', '');
        window.localStorage.setItem('sts_access_token', '');
        window.localStorage.setItem('sts_token_time', '');
        onError(err);
      });
  };

  render() {
    const { value: val, onChange, children, ...restProps } = this.props;
    const { value } = this.state;
    let mulProps = {};

    if (restProps.multiple) {
      mulProps = {
        fileList: this.state.fileList,
      };
    }

    return (
      <Upload
        name="file"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        {...mulProps}
        action="https://up.qiniup.com"
        data={{
          token: this.state.qiniuToken,
        }}
        beforeUpload={this.handleBefore}
        onChange={this.handleChange}
        customRequest={this.handleCustomRequest}
        {...restProps}
      >
        {children || this.renderImage()}
      </Upload>
    );
  }
}
