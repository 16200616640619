import React, { Component } from 'react';
import cn from 'classnames';
import './index.less';

class VisualMaskCom extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDragStart = (e) => {
    const { id, selectedId } = this.props;
    let isActive = id && selectedId === id;
    console.log('handleDragStart', isActive, selectedId);
    // e.dataTransfer.setData('TEXT_foo', 'barx222');

    if (!isActive) {
      return false;
    }

    return true;
  };

  render() {
    const { onSelect, id, selectedId, isOutLayer } = this.props;
    let isActive = id && selectedId === id;
    return (
      <div
        className={cn('visualMaskCom ve-dragnode', {
          actived: isActive,
          outLayer: isOutLayer
        })}
        id={id}
        onDragStart={this.handleDragStart}
        onDragOver={this.handleOver}
        onMouseMove={this.handleMove}
        // draggable={isActive}
        onClick={() => {
          if (onSelect) {
            onSelect(id);
          }
        }}
      >
        {this.props.children}
        <div className="visualMaskCom-mask"></div>
        <div className="visualMaskCom-border"></div>
      </div>
    );
  }
}

export default VisualMaskCom;
