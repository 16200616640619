import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import './index.scss';

const SelectRadio = (props) => {
  const [optionsData, setOptions] = useState([]);
  const [values, setValues] = useState([]);

  const { value, onChange, options, title } = props;

  const handleSure = () => {
    if (onChange) {
      onChange(values);
    }
  };

  useEffect(() => {
    setValues(value || []);
  }, [value]);

  useEffect(() => {
    setOptions(options || []);
  }, [options]);

  const handleClickOption = (val, selected) => {
    const vals = values.concat([]);
    if (selected === false) {
      vals.push(val);
      setValues(vals);
      return;
    }
    // 选中状态，取消选择
    for (let i in vals) {
      if (vals[i] === val) {
        vals.splice(i, 1);
        setValues(vals);
        break;
      }
    }
  };
  return (
    <div className="select-address-wrap">
      <div className="tab-head">
        <div className="tab-head-content">
          <div className="tab-head-item">{title}</div>
        </div>
        <div className="close-btn" onClick={handleSure}>
          确定
        </div>
      </div>
      <div className="tab-content">
        {optionsData.map((item) => {
          let selected = false;
          for (let i in values) {
            if (values[i] === item) {
              selected = true;
              break;
            }
          }
          return (
            <div
              className="option-item"
              key={item}
              data-value={item}
              data-selected={selected}
              onClick={() => handleClickOption(item, selected)}
            >
              {item}
              <Checkbox
                checked={selected}
              >
              </Checkbox>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectRadio;
