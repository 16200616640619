import React, { Component } from 'react';
import _ from 'lodash';
import VisualMask from '@/visualEditor/VisualMask';
import factory from '../../libs/factory';

import './index.scss';

/**
 * 1.选择子组件问题
 * 2.数据结构由数组变成树 以前通过id查找组件的 方式需要修改 （通过添加parentId可以解决该问题）
 */
class Zone extends Component {
  constructor(props) {
    super(props);
    let isEditor = true;
    if (window.location.pathname.indexOf('/activity/detail') === -1) {
      isEditor = false;
    }
    this.state = {
      isEditor,
    };
  }

  componentDidMount() {}

  render() {
    const { isEditor } = this.state;
    const {
      componentUuid,
      comList,
      elements = [],
      selectedId,
      onReqLogin,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      height,
      backgroundColor,
      backgroundSize,
      backgroundImage,
      borderRadius,
    } = this.props;

    let styleMap = {
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      height,
      borderRadius,
    };
    let style = {};

    _.keys(styleMap).forEach((key) => {
      if (styleMap[key]) {
        style[key] = (styleMap[key] / 375) * 100 + 'vw';
      }
    });

    if (backgroundImage) {
      style.backgroundImage = `url(${backgroundImage})`;
      style.backgroundRepeat = 'no-repeat';
      style.backgroundPosition = 'top';
    }
    if (backgroundColor) {
      style.backgroundColor = backgroundColor;
    }
    if (backgroundSize) {
      style.backgroundSize = backgroundSize == 'fill' ? '100% 100%' : backgroundSize;
    }

    // 当设置了背景 但没设置高度时候  默认高度为48
    if (!style.height && _.isEmpty(elements)) {
      style.height = (48 / 375) * 100 + 'vw';
    }

    // console.log('el', elements);

    // 渲染h5页面，且容器未配置子组件、背景，则渲染内容为空
    const isBlank = _.isEmpty(elements) && !backgroundColor && !backgroundImage;
    if (isBlank && elements.length < 1 && !isEditor) return null;

    return (
      <div className="Zone-com" id={componentUuid} style={style}>
        {isBlank && <div className="emptyholder">请添加组件</div>}

        {!_.isEmpty(elements) &&
          elements.map((item) => {
            let xCom = factory(item.$component);
            if (!xCom) {
              return '';
            }
            if (onReqLogin) {
              return React.createElement(
                xCom,
                {
                  ...item.$props,
                  key: item.$id,
                  id: item.$id,
                  componentUuid: item.componentUuid,
                  componentType: item.$component,
                  zoneComponentUuid: componentUuid,
                  comList,
                },
                item.$name,
              );
            }

            return (
              <VisualMask
                key={item.$id}
                id={item.$id}
                selectedId={selectedId}
                onSelect={(id) => this.handleSelect(id, item.componentUuid, item.$component)}
              >
                {React.createElement(
                  xCom,
                  {
                    ...item.$props,
                    key: item.$id,
                    randomId: Math.random(), // 触发子组件的重新渲染
                    id: item.$id,
                    componentUuid: item.componentUuid,
                    componentType: item.$component,
                    zoneComponentUuid: componentUuid,
                    comList,
                  },
                  item.$name,
                )}
              </VisualMask>
            );
          })}
      </div>
    );
  }
}

Zone.getDefaultValue = () => {
  return {
    backgroundImage: '',
  };
};

Zone.getEditData = () => ({
  data: {
    backgroundColor: {
      label: '背景色',
      useCustomItem: true,
      control: 'colorPicker',
    },
    backgroundImage: {
      label: '背景图片',
      control: 'upload',
      controlProps: {
        accept: '.jpg,.jpeg,.png',
      },
      renderAfter: () => {
        return <span style={{ color: ' rgba(0,0,0,0.45)' }}>支持拓展名：.png .jpg .jpeg，建议宽度750px</span>;
      },
    },
    backgroundSize: {
      label: '图片填充方式',
      control: 'radioGroup',
      getInitialValue: () => 'contain',
      options: () => [
        { key: 'contain', value: 'contain', label: 'contain' },
        { key: 'cover', value: 'cover', label: 'cover' },
        { key: 'fill', value: 'fill', label: 'fill' },
      ],
    },
    // height: {
    //   control: 'number',
    //   useCustomItem: true,
    //   label: '容器高度',
    // },
    $label1: '内边距',
    paddingTop: {
      control: 'number',
      useCustomItem: true,
      label: '上',
    },
    paddingRight: {
      control: 'number',
      useCustomItem: true,
      label: '右',
    },
    paddingBottom: {
      control: 'number',
      useCustomItem: true,
      label: '下',
    },
    paddingLeft: {
      control: 'number',
      useCustomItem: true,
      label: '左',
    },
    $label2: '外边距',
    marginTop: {
      control: 'number',
      useCustomItem: true,
      label: '上',
    },
    marginRight: {
      control: 'number',
      useCustomItem: true,
      label: '右',
    },
    marginBottom: {
      control: 'number',
      useCustomItem: true,
      label: '下',
    },
    marginLeft: {
      control: 'number',
      useCustomItem: true,
      label: '左',
    },
    $label3: '边框',
    borderRadius: {
      control: 'number',
      useCustomItem: true,
      label: '圆角值',
    },
  },
});

Zone.validate = (props) => {};

export default Zone;
