import Config from '../../config/index';

const MD5 = require('js-md5');
const axios = require('axios');
const uuid = require('uuid').v4;

const confs = {
  development: {
    APPKEY: '400896F44B954A228630EB3492E5FC3A',
    SECRET: '7C9A0A684E404E288ED4476F33BF74FD',
    SOUL_API: 'https://soul-dev.bthome.com',
  },
  test: {
    APPKEY: '9F08AF4FEEFD496A96FEC184D7CB2CE0',
    SECRET: '34598AA1BE304A6DB1CEA7D3AADF71ED',
    SOUL_API: 'https://soul-test.bthome.com',
  },
  production: {
    APPKEY: 'FB1ABA33DC0343A6AE646B0C3A0E4E98',
    SECRET: '58A375F1A9354C39AE8AAF428CB46F75',
    SOUL_API: 'https://soul.bthome.com',
  },
};

const serialize = (obj) =>
  Object.entries(obj)
    .map(([key, val]) => `${key}=${val === undefined ? '' : val}`)
    .join('&');

const env = process.env.CURRENT_ENV || 'production';
const currentEnv = env === 'production' ? confs.production : confs.test; //做替换
const { APPKEY, SECRET, SOUL_API } = currentEnv;

const makeSecret = (body, appKey = APPKEY, secret = SECRET) => {
  let keys = {
    'APP-KEY': appKey,
    'REQUEST-ID': uuid(),
    TIMESTAMP: Date.now(),
  };
  let prefix = serialize({
    ...keys,
    ...body,
  });
  //sessionStorage.setItem('__sign_prefix__', prefix)
  let signature = MD5(MD5(prefix).toString() + secret).toString();
  let headers = {
    ...keys,
    'Content-Type': 'application/json;charset=UTF-8',
    'APP-SECRET': secret,
    'GATEWAY-SIGNATURE': signature,
  };
  return headers;
};

export default function (data) {
  let xenv = process.env.CURRENT_ENV || 'production';
  let host = Config[xenv].apiUrl;
  let header = makeSecret(data);
  const url = `${host}/api/client/activityComponent/appointment/appointmentSync.do`;
  return new Promise((resolve) => {
    axios
      .post(url, data, { responseType: 'json', headers: header })
      .then((res) => resolve(res.data))
      .catch((err) => resolve({}));
  });
}
