import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import update from 'immutability-helper';
import consultService from '@/service/consult';
import globalService from '@/service/global';
import Tools from '@/util/Tools'
import './index.scss';

const SelectAddress = (props) => {
  const [tabArr, setTabArr] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [options, setOptions] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const { selectedIdArr, type, cityCodes } = props;
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    props.onCancel?.();
  };

  const handleSubmit = (data) => {
    // console.log('已选完：', data);
    const nameArr = data.map((v) => v.label ?? v.title);
    const idArr = data.map((v) => v.value);
    props.onReceive?.({ nameArr, idArr });
  };

  const getAreaData = async () => {
    setLoading(true);
    let res = await globalService.fetchAreaData();
    let newRes = Tools.getTreeData(res.data.result.data.provinces);
    console.log(newRes);
    // 为0不过滤
    if (cityCodes !== '0') {
      const cityArr = cityCodes.split(',');
      newRes = newRes.filter((item) => {
        let children = item.children || [];
        children = children.filter((c) => {
          for (let j in cityArr) {
            if (c.value === cityArr[j]) {
              return true;
            }
          }
          return false;
        });
        item.children = children;
        if (children.length === 0) {
          return false;
        }
        return true;
      });
    }
    if (newRes.length > 0) {
      setTabArr([{ init: true }]);
      setSourceData(newRes);
      setOptions(newRes);
    }
    setLoading(false)
  };

  const getStoreData = async () => {
    let res = null;
    try {
      res = await consultService.getStoreData();
    } catch (error) { }
    if (res?.code == 0 && res?.success && Array.isArray(res?.data)) {
      setSourceData(res.data);
      if (res.data.length > 0) {
        setTabArr([{ init: true }]);
        setSourceData(res.data);
        setOptions(res.data);
      }
    }
  };

  const getTabData = (list = [], idArr = [], data = []) => {
    if (idArr.length < 1 || idArr.length < 1) return data;
    const index = list.findIndex((v) => v.value === idArr[0]);
    if (index == -1) return data;
    data.push(list[index]);
    if (idArr.length == 1) return data;
    return getTabData(list[index].children ?? [], idArr.slice(1), data);
  };

  const handleClickTitle = (item, key) => {
    setTabIndex(key);
  };

  const handleClickOption = (newItem, isParent) => {
    if (isParent) {
      let selectedData = update(tabArr, {
        $splice: [[tabIndex, tabArr.length, newItem, { init: true }]],
      });
      setTabArr(selectedData);
      setTabIndex(tabIndex + 1);
    } else {
      let selectedData = update(tabArr, {
        $splice: [[tabIndex, 1, newItem]],
      });
      setTabArr(selectedData);
      handleSubmit(selectedData);
    }
  };

  const showCurrentTab = () => {
    setTimeout(() => {
      const tabEle = document.querySelector(`.tab-head-item[data-value='${tabIndex}']`);
      tabEle?.scrollIntoView();
    }, 100);
  };

  useEffect(() => {
    if (type === 'city') {
      getAreaData();
    } else {
      getStoreData();
    }
  }, [type]);

  useEffect(() => {
    if (tabArr.length > tabIndex) {
      // 重置已选内容
      const list = tabArr.slice(0, tabIndex);
      if (list.length > 0) {
        setOptions(list[list.length - 1].children);
      } else {
        setOptions(sourceData);
      }
      showCurrentTab();
      return;
    }
    if (tabIndex === 0 || tabArr.length < 1) return;

    // 新增tabTitle
    setTabArr(
      update(tabArr, {
        $push: [{ init: true }],
      }),
    );
    let data = tabArr[tabIndex - 1].children;
    if (!Array.isArray(data)) {
      data = [];
    }
    setOptions(data);
    showCurrentTab();
  }, [tabIndex]);

  // 数据回显
  useEffect(() => {
    if (!selectedIdArr) return;
    // "内蒙古自治区"/"通辽市""科尔沁左翼后旗"
    // const data = getTabData(sourceData, ["150000", "150500", "150522"])
    const data = getTabData(sourceData, selectedIdArr);
    setTabArr(data);
  }, [sourceData]);

  console.log('options', options);

  return (
    <Spin tip="加载中..." spinning={loading}>
      <div className="select-address-wrap">
        <div className="tab-head">
          <div className="tab-head-content">
            {tabArr.map((item, key) => (
              <div
                className="tab-head-item"
                key={item.value || key}
                data-value={key}
                data-selected={tabIndex === key}
                onClick={() => handleClickTitle(item, key)}
              >
                {item.init ? '请选择' : item.label ?? item.title}
              </div>
            ))}
          </div>
          <div className="close-btn" onClick={handleCancel}>
            取消
          </div>
        </div>
        <div className="tab-content">
          {options.map((item) => {
            let isParent = false;
            if (Array.isArray(item.children) && item.children.length > 0) {
              isParent = true;
            }
            let selected = false;
            if (tabArr.length > 0 && tabArr.length > tabIndex) {
              selected = Boolean(item.value === tabArr[tabIndex].value);
            }
            return (
              <div
                className="option-item"
                key={item.value}
                data-value={item.value}
                data-parent={isParent}
                data-selected={selected}
                onClick={() => handleClickOption(item, isParent)}
              >
                {item.label ?? item.title}
              </div>
            );
          })}
        </div>
      </div>
    </Spin>
  );
};

export default SelectAddress;
