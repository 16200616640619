/****** create file by codeGen ******/
import BaseService, { postFn } from '../magicPackages/BaseService';
import Remote from '@/util/Remote';
import axios from 'axios'

class GlobalService extends BaseService {
  constructor() {
    super('', {
      fetchGlobalEnmu: postFn('/activityDic/getDicByTypeList.do'),
    });
  }

  queryEmployeeOptions (name) {
    return Remote.get(`/activityInfo/searchEmployee.do?name=${name}`);
  }

  getServerTime () {
    return Remote.get('/client/common/getServerTime.do');
  }

  getOssTempAccount (params) {
    return new Promise((resolve) => {
      Remote.get('/client/aliOss/getOssTempAccount.do', params)
        .then((data) => resolve(data))
        .catch((err) => resolve(null));
    });
  }

  fetchAreaData () {
    return axios.get('https://areas.bnq.com.cn/areas/area/allAreas');
  }

  fetchAreaLevelData (level) {
    return axios.get('https://areas.bnq.com.cn/areas/area/allAreasByLevel?level=' + level);
  }

  
}

export default new GlobalService();
