import React, { Component } from 'react';
import $ from 'jQuery';
import qs from 'qs';
import VConsole from 'vconsole';
import { message } from 'antd';
import _ from 'lodash';
import wx from 'weixin-js-sdk';
import { RNTool, rnToolV2 } from 'web-message-for-rn';
import systemEvent from '@/util/systemEvent';
import factory from '@/visualEditor/libs/factory';
import datasource from '@/visualEditor/libs/datasource';
import service from '@/service/activity';
import goodsService from '@/service/goods';
import FlowButton from '@/components/FlowButton';
import tryOpen from '@/util/tryOpen';

import {
  reqLogin,
  setAppHeaderTitle,
  appShare,
  getCityByApp,
  isMinProgram,
  getMiniPrograParmas,
  getPageContainer,
  isApp,
  navToApp,
  track,
  newTrack,
  openMini,
  reqDeviceInfo,
  getShopCity,
} from '@/util/h5Helper';
import { browserTypeEnum } from '@/util/systemEnum';
import gio from '@/util/newGio';
import { convertModalToControl } from '../Detail/libs/dataConvert';
import Tip from './modules/Tip';
import './index.scss';

function toHome () {
  if (tryOpen()) {
    return;
  }
  if (isMinProgram()) {
    wx.miniProgram.switchTab({ url: '/pages/home/home' });
  } else if (isApp()) {
    // RNTool.pushToRouter('PTHome');
    RNTool.postMessageToNative('postNotification', { msgName: 'backToHome' });
  }
}

const startTime = new Date().getTime();

export default class H5Page extends Component {
  constructor(props) {
    super(props);
    let query = qs.parse(location.search.slice(1));
    let browserType = getPageContainer();
    this.state = {
      query,
      activityId: query?.activityId || '',
      isPreview: query?.isPreview || false,
      channel: query?.channel || '',
      giochannel: query?.giochannel || '',
      browserType,

      comList: [],
      pageTitle: null,
      pageDetail: null,
      isShowCityDialog: false,
      isShowShopDialog: false,

      // 登录后获取的信息
      phone: '',
      apptoken: '',
      cityCode: '',
      cityName: '',
      shopCode: '',
      shopName: '',
    };
  }

  componentDidMount () {
    this.initPage();

    RNTool.addNativeMessageListener((data) => {
      if (data.key === 'shareData' || data.key === 'share') {
        track({ code: 'share' }, {}, false);
      }
    });
  }

  reqLoginPromise = () => {
    return new Promise((resolve) => {
      reqLogin((data) => {
        resolve(data);
      });
    });
  };

  //
  setShopCityChangeAble = async (shopCityChangeAble) => {
    let { browserType } = this.state;
    // app7.6.0之后的版本才支持切换门店和城市
    if (browserType == browserTypeEnum.APP_BNQ || browserType == browserTypeEnum.APP) {
      let res = await reqDeviceInfo();
      console.log('===***********=======', res);
      let appVersion = (res?.appVersion || '').split('.');
      let major = (appVersion[0] || 0) * 10000;
      let minor = (appVersion[1] || 0) * 100;
      let suffix = appVersion[2] || 0;

      this.setState({
        showShopCityChange: shopCityChangeAble != 0 && major + minor + suffix > 70600,
      });
    } else if (browserType == browserTypeEnum.MINI) {
      this.setState({
        showShopCityChange: shopCityChangeAble == 2,
      });
      // 微信不显示切换城市
    }
  };

  initPage = () => {
    let query = qs.parse(location.search.slice(1));
    $('head').prepend(
      '<meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover"/>',
    );

    if (window.location.hostname !== 'ams.bthome.com' || query.__test__) {
      new VConsole();
    }
    // if (process.env.BUILD_ENV !== 'prod') {
    //   new VConsole();
    // }
    this.reqLoginPromise().then((loginData) => {
      this.setState(loginData);
    });

    this.initDetail().then((detailRes) => {
      // 设置登录信息
      let comList = convertModalToControl(detailRes.data?.activityComponentDtos);
      if (_.isEmpty(comList)) {
        return;
      }

      let pageTitle = (comList || []).shift();
      console.log(pageTitle, 'pageTitle');
      const {
        $props: {
          shareTitle,
          shareContent,
          sharePic,
          shopCityChangeAble,
          cityJson,
          platformFlag,
          shopJson,
          titleBg,
        } = {},
      } = pageTitle || {};
      // 城市的判断
      // if (cityJson && platformFlag == 2) {
      //   this.getCrruentCityIsActivity(cityJson);
      // }
      // if (shopJson && platformFlag == 1) {
      //   this.getCrruentShopIsActivity(shopJson);
      // }

      const title = pageTitle?.$props?.activityName || '活动详情';
      document.title = title;
      /**
       * 设置appTitle
       */
      setAppHeaderTitle({
        title,
        titleBg,
      });
      this.setShopCityChangeAble(shopCityChangeAble);
      const { activityId, channel, giochannel } = this.state;
      const miniProgramPageUrl = `unpack/pages/web/index?pageId=${activityId || ''
        }&channel=${channel}&giochannel=${giochannel}`;
      appShare({
        title: shareTitle,
        content: shareContent,
        url: window.location.href,
        iconUrl: sharePic,
        miniProgramPageUrl,
        sharePic,
      });

      this.setState(
        {
          pageDetail: detailRes?.data || {},
          pageTitle,
          comList,
        },
        () => [this.checkShopCity(cityJson, shopJson, platformFlag)],
      );

      track({ code: 'load' }, {}, false);
    });
  };

  checkShopCity = async () => {
    if (!isApp() && !isMinProgram()) {
      return;
    }

    let { cityCode, shopCode } = this.state;
    if (!cityCode && !shopCode) {
      let xRes = await getShopCity();
      cityCode = xRes.cityCode;
      shopCode = xRes.shopCode;
    }
    const { cityJson, shopJson, platformFlag } = this.state.pageTitle?.$props || {};
    console.log('=================================>', shopJson, platformFlag);

    if (cityJson && platformFlag == 2) {
      this.getCrruentCityIsActivity(cityCode);
    } else if (shopJson && platformFlag == 1) {
      this.getCrruentShopIsActivity(shopCode);
    }
  };

  getCrruentCityIsActivity = (cityCode) => {
    const { cityJson } = this.state.pageTitle?.$props || {};
    const flag = (cityJson || []).find((item) => item.value == 0); // 全国
    const index = (cityJson || []).findIndex((item) => item.value === cityCode); // 没找到
    if (index === -1 && !flag) {
      this.setState({
        isShowCityDialog: true,
      });
    }
  };

  getCrruentShopIsActivity = (shopCode) => {
    const { shopJson, shopCityChangeAble } = this.state.pageTitle?.$props || {};
    const index = (shopJson || []).findIndex((item) => item.value === shopCode); // -1 未找到
    const flag = (shopJson || []).find((item) => item.value === '0'); // 全部城市
    // 在app中 如果需要切换城市 则不提示门店是否在指定的范围
    if (index === -1 && !flag) {
      this.setState({
        isShowShopDialog: isMinProgram() ? true : shopCityChangeAble != 1,
      });
    }
  };

  handleLogin = (isManualLogin) => {
    reqLogin(isManualLogin, (data) => {
      this.setState(data);
    });
  };

  initDetail = async () => {
    const { activityId, isPreview, query } = this.state;
    if (!activityId) {
      return;
    }
    let res = await service.$getDetail({ id: activityId, isPreview });
    if (res?.code != 0 || !res?.data) {
      return;
    };
    console.log(query);
    const info = {
      pageType_var: '营销活动',
      pageName_var: `${res.data?.activityCode}${res.data?.activityName}`,
      storeID_var: '-',
      constructionSiteID_var: '-',
      activityID_var: res.data?.id,
      ...query,
    };
    gio.sendBnqPageExposureTrack({ ...info });
    sessionStorage.setItem('__activityCode__', res.data?.activityCode);
    sessionStorage.setItem('__activityID__', res.data?.id);
    sessionStorage.setItem('__activityName__', res.data?.activityName);
    const param = {
      ck: res.data?.id ? `${res.data?.id}` : '',
      cknm: res.data?.activityName,
      ckn: 'ckn5',
      tg: 'campaign',
    };
    newTrack('page_view_consumption_voucher', '', param);
    return res;
  };

  closeCityModal = () => {
    this.setState({
      isShowCityDialog: false,
    });
  };

  closeShopModal = () => {
    this.setState({
      isShowShopDialog: false,
    });
  };

  // 7.6.0
  handleShopCityChange = () => {
    const { browserType, activityId, channel, giochannel, pageTitle } = this.state;
    const { shopCityChangeAble } = pageTitle?.$props || {};
    if (browserType == browserTypeEnum.WX_BROWSER) {
      openMini(`unpack/pages/web/index?pageId=${activityId}&channel=${channel}&giochannel=${giochannel}`);
    } else if (browserType == browserTypeEnum.BROWSER) {
      navToApp();
    } else if (browserType == browserTypeEnum.APP || browserType == browserTypeEnum.APP_BNQ) {
      let keyMap = {
        1: 'selectCity',
        2: 'selectShop',
      };

      rnToolV2.sendMsgToRN(keyMap[shopCityChangeAble], {}, (res) => {
        let xparams = {};
        if (shopCityChangeAble == 1) {
          xparams = {
            cityCode: res?.cityCode || '',
            cityName: res?.cityName || '',
          };
        } else if (shopCityChangeAble == 2) {
          xparams = {
            // cityCode: res?.shopCityCode || '',
            // cityName: res?.shopCityName || '',
            shopCode: res?.shopCode || '',
            shopName: res?.shopName || '',
          };
        }

        this.setState(xparams, () => {
          systemEvent.emit('bnq_shopcity_change');
          this.checkShopCity();
        });
      });
    } else if (browserType == browserTypeEnum.MINI) {
      wx.miniProgram.navigateTo({
        url: `/unpack/pages/chooseshop/index?origin=activity&pageId=${activityId}&channel=${channel}&giochannel=${giochannel}`,
      });
    }
  };

  renderShopCity = (shopCityChangeAble, ruleBtnStyle) => {
    const { cityName, shopName } = this.state;
    console.info('cityyyyyyyyyyy', cityName, shopName);
    if (shopCityChangeAble == 1) {
      return (
        <div className="top-right-button" style={ruleBtnStyle} onClick={this.handleShopCityChange}>
          <span>{cityName || '请选择城市'}</span>
        </div>
      );
    }

    return (
      <div className="top-right-button" style={ruleBtnStyle} onClick={this.handleShopCityChange}>
        <span>{shopName || '请选择门店'}</span>
      </div>
    );
  };

  showRule = async () => {
    const { ruleLinkType, ruleLinkInfo } = this.state?.pageTitle?.$props || {};

    if (ruleLinkType == '1') {
      // 链接
      window.location.href = ruleLinkInfo;
      return;
    }
    // 专题
    let res = await goodsService.queryActivityId(ruleLinkInfo);
    if (res?.code === 0 && res?.success && res?.data) {
      window.location.href = window.origin + '/activity/h5?activityId=' + res.data?.id;
    } else {
      message.error('查询跳转信息失败');
    }
  };

  render () {
    const {
      activityId,
      comList,
      pageTitle,
      phone,
      apptoken,
      channel,
      showShopCityChange,
      isShowCityDialog,
      isShowShopDialog,

      cityCode,
      cityName,
      shopCode,
      shopName,
      pageDetail,
    } = this.state;
    const {
      bgType,
      bgColor,
      bgImage,
      flowButtons,
      shopCityChangeAble,
      isRuleShow,
      buttonBg,
      buttonColor,
      ruleLinkType,
      ruleLinkInfo,
    } = pageTitle?.$props || {};

    // console.log('bgColor', bgColor, bgType);
    let xStyle = {};
    if (bgType == 1 && bgColor) {
      xStyle.backgroundColor = bgColor;
    } else if (bgType == 2 && bgImage) {
      xStyle.backgroundImage = `url(${bgImage})`;
    }

    let h5BoxClass = 'preview-container';

    let ruleBtnStyle = {
      color: buttonColor,
      backgroundColor: buttonBg,
    };

    return (
      <div className={h5BoxClass} style={{ ...xStyle }}>
        {(comList || []).map((item) => {
          let xCom = factory(item.$component);
          if (!xCom) {
            return '';
          }
          return React.createElement(
            xCom,
            {
              pageDetail,
              ...item.$props,
              key: item.$id,
              componentUuid: item.componentUuid,
              businessId: item.businessId ?? item.$props?.businessId,
              comList,
              id: item.$id,
              componentType: item.$component,
              activityStartTime: pageTitle?.$props?.startTime,
              activityEndTime: pageTitle?.$props?.endTime,
              onReqLogin: this.handleLogin,
              __extraInfo: {
                pageTitle,
                activityId,
                phone,
                apptoken,
                channel,
                cityCode,
                cityName,
                shopCode,
                shopName,
              },
            },
            item.$name,
          );
        })}
        {isShowCityDialog && (
          <Tip
            onOk={() => {
              this.closeCityModal();
              // toHome();
              this.handleShopCityChange();
            }}
            onCancel={() => this.closeCityModal()}
          />
        )}
        {isShowShopDialog && (
          <Tip
            title="切换城市门店"
            des="定位与活动城市门店不符，是否需要切换城市门店"
            okText="立即切换"
            onOk={() => {
              // toHome();
              this.closeShopModal();
              this.handleShopCityChange();
            }}
            onCancel={() => this.closeShopModal()}
          />
        )}
        <div className="page-top-container">
          {!!showShopCityChange && this.renderShopCity(shopCityChangeAble, ruleBtnStyle)}
          {isRuleShow == '1' && (
            <div className="top-right-button" onClick={this.showRule} style={ruleBtnStyle}>
              活动规则
            </div>
          )}
        </div>

        <FlowButton
          dataList={flowButtons}
          __extraInfo={{
            phone,
            apptoken,
            channel,
            cityCode,
            cityName,
            shopCode,
            shopName,
          }}
          style={{ position: 'fixed' }}
        />
      </div>
    );
  }
}
