/****** create file by codeGen ******/
import { action, observable, runInAction } from 'mobx';
import service from '@/service/consult';

class ConsultStore {
  // 渠道选项
  @observable channelOptions = [];

  // 列表数据
  @observable tableData = {
    list: [],
    total: 0,
    pageNum: 1,
    pageSize: 10,
  };

  @action queryList = async (params) => {
    let res = {};
    try {
      res = await service.queryList(params);
    } catch (error) {}
    let list = [];
    if (res.code === 0 && res.success && Array.isArray(res.data?.list)) {
      list = res.data.list;
    }
    runInAction(() => {
      this.tableData = {
        list,
        total: res.data?.total || 0,
        pageNum: res.data?.pageNum || 1,
        pageSize: res.data?.pageSize || 10,
      };
    });
    return res;
  };

  @action queryChannelOptions = async () => {
    let res = {};
    try {
      res = await service.queryChannelOptions();
    } catch (error) {}
    let data = [];
    if (res.code === 0 && res.success && Array.isArray(res.data?.appointmentChannel)) {
      data = res.data.appointmentChannel;
    }
    runInAction(() => {
      this.channelOptions = data;
    });
    return res;
  };

  @action saveItem = async (params) => {
    let res = {};
    try {
      res = await service.saveItem(params);
    } catch (error) {
      res = error;
    }
    return res;
  };

  @action updateItem = async (params) => {
    let res = {};
    try {
      res = await service.updateItem(params);
    } catch (error) {
      res = error;
    }
    return res;
  };

  @action deleteItem = async (params) => {
    let res = {};
    try {
      res = await service.deleteItem(params);
    } catch (error) {
      res = error;
    }
    return res;
  };

  @action queryById = async (params) => {
    let res = {};
    try {
      res = await service.queryById(params);
    } catch (error) {}
    return res;
  };
}

export default new ConsultStore();
