import React, { Component } from 'react';
import { Radio, Dropdown, Icon } from 'antd';
import { SketchPicker } from 'react-color';
import Upload from '@/components/Upload';
import './index.scss';

class SelectBg extends Component {
  constructor(props) {
    super(props);
  }

  handleTypeChange = (evt) => {
    const { value: preValue, onChange } = this.props;
    const { value } = evt.target;
    onChange?.({ ...preValue, type: value, data: value === 0 ? '' : null });
  };

  handleFileChange = (evt) => {
    const { value: preValue, onChange } = this.props;
    onChange?.({ ...preValue, type: 1, data: evt });
  };

  handleStyleChange = (evt) => {
    const { value, onChange } = this.props;
    onChange?.({ ...value, style: evt.target.value });
  };

  handleColorChange = (evt) => {
    const { value, onChange } = this.props;
    onChange?.({ ...value, type: 0, data: evt });
  };

  colorReverse = (oColor = '') => {
    let oldColor = '0x' + oColor.replace(/#/g, '');
    let str = '000000' + (0xffffff - oldColor).toString(16);
    return '#' + str.substring(str.length - 6, str.length);
  };

  render() {
    let { type = 0, data, style } = this.props.value || {};
    return (
      <div className="goods-type-edit">
        <div className="goods-type-edit-title">商品背景</div>
        <div className="goods-type-edit-radio" style={{ marginBottom: 24 }}>
          <Radio.Group
            options={[
              { label: '纯色', value: 0 },
              { label: '图片', value: 1 },
              { label: '透明', value: 2 },
            ]}
            onChange={this.handleTypeChange}
            value={type}
          />
        </div>
        {type === 0 ? (
          <>
            <div className="goods-type-edit-title">商品背景颜色</div>
            <div className="colorPickerCom">
              <Dropdown
                trigger={['click']}
                overlay={<SketchPicker color={data} onChangeComplete={(evt) => this.handleColorChange(evt.hex)} />}
              >
                <div className="colorPickerCom-content" style={{ background: data }}>
                  <span style={{ marginRight: 5, color: this.colorReverse(data) }}>{data || '请选择颜色'}</span>
                  {data ? (
                    <Icon
                      onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        this.handleColorChange('');
                      }}
                      type="close-circle"
                      theme="filled"
                      style={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.25)' }}
                    />
                  ) : (
                    <Icon type="down" style={{ color: '#999' }} />
                  )}
                </div>
              </Dropdown>
            </div>
          </>
        ) : null}
        {type === 1 ? (
          <>
            <div className="goods-type-edit-title">商品背景图片</div>
            <Upload accept=".jpg,.jpeg,.png" maxSize="1M" value={data} onChange={this.handleFileChange} />
            <div className="goods-type-edit-upload-tip" style={{ marginBottom: 24 }}>
              图片大小不超过 1M，支持格式：jpg、jpeg、png，建议宽度为750px
            </div>
            <div className="goods-type-edit-title">背景图片样式</div>
            <div className="goods-type-edit-radio">
              <Radio.Group
                options={[
                  { label: '平铺', value: 0 },
                  { label: '拉伸', value: 1 },
                  { label: '自适应', value: 2 },
                ]}
                onChange={this.handleStyleChange}
                value={style}
              />
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

export default SelectBg;
