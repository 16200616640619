/**
 * @Description: 业务组件公共方法
 * @author zhaokang
 * @date 2021/1/20
 */
export default class PageHelper {
    // 将数组填充到指定位数
    static padArr = (arr, l) => {
        const { length } = arr;
        for (let i = length; i < l; i++) {
            arr[i] = { id: `mockId${i}` };
        }
        return arr;
    };

    // 生成Form layout配置
    static genLayout = (label = 5, wrapper = 10) => {
        return {
            labelCol: {
                span: label,
            },
            wrapperCol: {
                span: wrapper,
            },
        };
    };

    // 根据id过滤数组指定项
    static filterItemById = (arr, id) => {
        const res = arr?.find((item) => {
            return item.id === id;
        });
        return res || {};
    };

    // Table pagination通用配置
    static genPagination = (page = {}, onChangeFn) => {
        const { pageSize, pageNum, total, TotalNode = null } = page;
        return {
            showQuickJumper: true,
            pageSize,
            current: pageNum,
            total,
            showTotal: () => {
                return TotalNode || `共${total}条`;
            },
            onChange: onChangeFn,
        };
    };
}
