import React, { Component } from 'react';
import service from '@/service/global';
import EditTime from './EditTime';
import './index.scss';

class Timer extends Component {
  constructor(props) {
    super(props);
    let isEditor = true;
    if (window.location.pathname.indexOf('/activity/detail') === -1) {
      isEditor = false;
    }
    this.state = {
      isEditor,
      dayStr: '00',
      hourStr: '00',
      minStr: '00',
      secStr: '00',
      status: -1,
      displayTxt: '',
    };
  }

  componentDidMount() {
    this.toggleZone();
    this.queryServerTime();
  }

  componentDidUpdate(preProps, preState) {
    const { type, time } = this.props;
    const { status } = this.state;
    if (preState.status !== status) {
      this.toggleZone();
    }
    if (preProps.type !== type || preProps.time !== time) {
      this.queryServerTime();
    }
  }

  componentWillUnmount() {
    this.reset();
  }

  toggleZone() {
    const { isEditor, status } = this.state;
    const { zoneComponentUuid } = this.props;
    let zoneEl = document.getElementById(zoneComponentUuid);
    if (!zoneEl) return;
    if (status !== 1 && !isEditor) {
      zoneEl.style.display = 'none';
    } else {
      zoneEl.style.display = 'block';
    }
  }

  queryServerTime() {
    const { bgImage } = this.props;
    if (!bgImage) return;
    service
      .getServerTime()
      .then((res) => {
        if (res?.code == 0 && res?.success && res?.data) {
          this.reset();
          let gap = Math.abs(Date.now() - res.data.timeStamp);
          if (gap > 1000 && gap < 3000) {
            this.resolveTimeField(Date.now());
          } else {
            this.resolveTimeField(res.data.timeStamp);
          }
        }
      })
      .catch((err) => {
        this.resolveTimeField(Date.now());
      });
  }

  reset() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    this.setState({
      day: '00',
      hour: '00',
      min: '00',
      sec: '00',
      status: false,
    });
  }

  resolveTimeField(current) {
    this.timer = setTimeout(() => this.resolveTimeField(current + 1000), 1000);
    const { startTxt, endTxt, comList, type, time, activityStartTime, activityEndTime } = this.props;
    const { startTime, endTime } = comList[0].$props;
    const moduleStartTime = time ? new Date(time.replaceAll('-', '/')).getTime() : 0; // 模块设置的活动开始时间
    const t1 = startTime || activityStartTime;
    const t2 = endTime || activityEndTime;
    const pageStartTime = t1 ? new Date(t1.replaceAll('-', '/')).getTime() : 0; // 页面开始时间
    const pageEndTime = t2 ? new Date(t2.replaceAll('-', '/')).getTime() : 0; // 页面结束时间
    let timeGap = 0;
    if (type == 0) {
      // 活动开始倒计时
      if (current > pageStartTime && current < moduleStartTime) {
        timeGap = moduleStartTime - current;
        this.setState({ status: 1, displayTxt: startTxt });
      } else {
        this.setState({ status: 0, displayTxt: '活动已开始' });
        this.reset();
        return;
      }
    } else if (type == 1) {
      // 活动结束倒计时
      if (current > moduleStartTime && current < pageEndTime) {
        timeGap = pageEndTime - current;
        this.setState({ status: 1, displayTxt: endTxt });
      } else {
        this.setState({ status: 0, displayTxt: '活动未开始 或 活动已结束' });
        this.reset();
        return;
      }
    } else {
      // 展示活动开始及结束倒计时
      // eslint-disable-next-line no-lonely-if
      if (current < moduleStartTime) {
        timeGap = moduleStartTime - current;
        this.setState({ status: 1, displayTxt: startTxt });
      } else if (current < pageEndTime) {
        timeGap = pageEndTime - current;
        this.setState({ status: 1, displayTxt: endTxt });
      } else {
        this.setState({ status: 0, displayTxt: '活动已结束' });
        this.reset();
        return;
      }
    }
    if (timeGap < 0) {
      this.reset();
      return;
    }
    const D_MS = 1000 * 60 * 60 * 24;
    let H_MS = 1000 * 60 * 60;
    let M_MS = 1000 * 60;
    let S_MS = 1000;
    let day = parseInt(timeGap / D_MS);
    let hour = parseInt((timeGap - day * D_MS) / H_MS);
    let min = parseInt((timeGap - day * D_MS - hour * H_MS) / M_MS);
    let sec = parseInt((timeGap - day * D_MS - hour * H_MS - min * M_MS) / S_MS);
    let dayStr = day < 10 ? `0${day}` : day;
    let hourStr = hour < 10 ? `0${hour}` : hour;
    let minStr = min < 10 ? `0${min}` : min;
    let secStr = sec < 10 ? `0${sec}` : sec;
    this.setState({ dayStr, hourStr, minStr, secStr });
  }

  render() {
    const { isEditor, dayStr, hourStr, minStr, secStr, displayTxt, status } = this.state;
    const { componentUuid, bgImage, position, txtColor } = this.props;
    // console.log('timer render props:', this.props);
    let style = { color: txtColor };
    if (position == 0) {
      style.justifyContent = 'flex-start';
    } else if (position == 2) {
      style.justifyContent = 'flex-end';
    }
    if (!isEditor && status !== 1) return null;
    return (
      <div className="timer-com" id={componentUuid}>
        {isEditor && !bgImage ? (
          <div className="timer-tip">请设置倒计时</div>
        ) : (
          <div className="timer-content">
            <img src={bgImage} />
            <div className="timer-inner" style={style}>
              <span>{displayTxt}</span>
              {status === 1 ? (
                <>
                  <span className="item">{dayStr}</span>天<span className="item">{hourStr}</span>时
                  <span className="item">{minStr}</span>分<span className="item">{secStr}</span>秒
                </>
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

Timer.getDefaultValue = () => {
  return {
    bgImage: '',
    type: 0,
    position: 1,
    time: undefined,
    startTxt: '',
    endTxt: '',
    txtColor: '',
  };
};

Timer.getEditData = () => ({
  data: {
    bgImage: {
      label: '背景图片',
      control: 'upload',
      controlProps: {
        accept: '.jpg,.jpeg,.png',
        maxSize: '1M',
      },
      renderAfter: () => {
        return <span style={{ color: ' rgba(0,0,0,0.45)' }}>支持拓展名：.png .jpg .jpeg，建议宽度750px(小于1M)</span>;
      },
    },
    type: {
      label: '倒计时类型',
      control: 'radioGroup',
      getInitialValue: 0,
      options: () => [
        { label: '活动开始倒计时', value: 0 },
        { label: '活动结束倒计时', value: 1 },
        { label: '活动开始及结束倒计时切换', value: 2 },
      ],
    },
    position: {
      label: '位置设置',
      control: 'radioGroup',
      getInitialValue: 1,
      options: () => [
        { label: '左侧', value: 0 },
        { label: '居中', value: 1 },
        { label: '右侧', value: 2 },
      ],
    },
    time: {
      label: '倒计时开始时间',
      control: EditTime,
    },
    startTxt: {
      label: '开始倒计时文案',
      controlProps: {
        maxLength: 20,
      },
      checkShowBy: {
        type: (val) => val == 0 || val == 2,
      },
    },
    endTxt: {
      label: '结束倒计时文案',
      controlProps: {
        maxLength: 20,
      },
      checkShowBy: {
        type: (val) => val == 1 || val == 2,
      },
    },
    txtColor: {
      label: '文字颜色',
      control: 'colorPicker',
    },
  },
});

Timer.validate = (props, detail, comList) => {
  // console.log('Timer validate', props);
  const { startTime, endTime } = comList[0].$props;
  // console.log('Timer validate', startTime, endTime, props);
  const { bgImage, type, position, time, startTxt, endTxt, txtColor } = props;
  if (!bgImage) return '请设置背景图片';
  if ([0, 1, 2].indexOf(type) === -1) return '请设置倒计时类型';
  if (!time) return '请设置倒计时开始时间';
  if (time < startTime || time > endTime) return '倒计时开始时间需在标题设置的启用时间范围内';
  if ([0, 1, 2].indexOf(position) === -1) return '请设置位置';
  if (type == 0 && !startTxt) return '请设置开始倒计时文案';
  if (type == 1 && !endTxt) return '请设置结束倒计时文案';
  if (type == 2) {
    if (!startTxt) return '请设置开始倒计时文案';
    if (!endTxt) return '请设置结束倒计时文案';
  }
  if (!txtColor) return '请设置文字颜色';
  return '';
};

export default Timer;
