import qs from 'qs';

export default (link = '', params) => {
  let query = qs.parse(window.location.search.slice(1));
  query = {
    ...query,
    ...params,
  };

  let rPath = link.replace(/\$\{\w+\}/gi, '');
  let reg = /\$\{\w+\}/gi;

  let list = (link.match(reg) || []).map((item) => (item || '').slice(2, -1));
  let nQuery = {};

  // 没有占位符
  if (!list?.length) {
    return rPath;
  }

  list.forEach((item) => {
    if (query[item] !== null && query[item] !== undefined) {
      nQuery[item] = query[item];
    }
  });

  console.log(rPath, list);
  let queryIndx = link.indexOf('?');
  let nSearch = qs.stringify(nQuery);

  return `${rPath}${queryIndx > 0 ? '&' : '?'}${nSearch}`;
};
