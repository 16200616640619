import { Remote } from '@/util';

class VideoService {
  getUploadAddress(params = {}) {
    return Remote.get('/video/getUploadAddress.do', params);
  }
  getVideoInfo(params = {}) {
    return Remote.get('/video/getVideoInfo.do', params);
  }
}

const Service = new VideoService();
export default Service;
