import React, { Component } from 'react';
import { Icon, Input } from 'antd';
import _ from 'lodash';
import systemEvent from '@/util/systemEvent';

import './index.scss';

class SearchCom extends Component {
  handleSearch = _.debounce((evt) => {
    systemEvent.emit('bnq_search_event', { keyword: (evt.target.value || '').trim() });
  }, 150);

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { bgColor, position, placeholder, componentUuid } = this.props;

    let xStyle = {};
    if (bgColor) {
      xStyle.background = bgColor;
    }

    if (position == 'sticky') {
      xStyle.position = 'sticky';
      xStyle.zIndex = 99;
      xStyle.top = 0;
    }

    return (
      <div className="searchCom" id={componentUuid} style={{ ...xStyle }}>
        <div className="searchCom-content">
          <Icon type="search" className="searchCom-icon" />
          <Input placeholder={placeholder || '请输入'} className="searchCom-input" onChange={this.handleSearch} />
        </div>
      </div>
    );
  }
}

SearchCom.getDefaultValue = () => {
  return {};
};

SearchCom.getEditData = () => ({
  data: {
    placeholder: '占位文字',
    bgColor: {
      label: '页面背景',
      control: 'colorPicker',
    },
    position: {
      label: '位置设置',
      control: 'radioGroup',
      getInitialValue: 'fixed',
      options: () => [
        { key: 'fixed', value: 'fixed', label: '固定' },
        { key: 'sticky', value: 'sticky', label: '保持页面顶端' },
      ],
    },
  },
  methods: {},
});

SearchCom.validate = (props) => {
  return '';
};

export default SearchCom;
