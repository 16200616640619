import CallApp from 'callapp-lib';

const options = {
  scheme: {
    protocol: 'bnqbnqpay',
    host: 'bnq.com.cn',
  },
  intent: {
    package: 'com.baianju.yonghu',
    scheme: 'bnqbnqpay://bnq.com.cn',
  },
  universal: {
    host: 'merry.bnq.com.cn/project/app_down.html',
    pathKey: 'pagename',
  },
  appstore: 'https://apps.apple.com/cn/app/id1131131973',
  yingyongbao: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.baianju.yonghu',
  fallback: 'https://merry.bnq.com.cn/project/app_down.html',
};

const callLib = new CallApp(options);

const nativeHandlePath = 'nativeHandlePath';
const callHelper = {
  // 唤起app，并进入指定界面
  open(param) {
    callLib.open({
      path: nativeHandlePath,
      param: {
        // screen: 'PTMembers', 传 rn screen name
        // needlogin: true, 是否需要登录
        // param : {}, 原生页面需要的参数
        ...param,
      },
    });
  },
};

export default callHelper;
