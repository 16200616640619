/**
 * @Description: 定义路由组件
 * @author 王发靖 Fajing.Wang@b-and-qchina.com
 * @date 2019/2/18
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Spin } from 'antd';
import Loadable from 'react-loadable';
import RouteConfig from './RouterConfig';
import { menus, openPaths, hasPermission } from '../../config/config';
import { TreeIterator } from '../util';
import Exception404 from '../app/Exception404';
import SesameLogin from '../app/SesameLogin';

export default class RouterGenerator {
  static genRouter() {
    const routers = RouterGenerator.getRouters(RouteConfig);
    return (
      <Switch>
        {routers.map((route) => {
          return <Route key={route.path} path={route.path} exact={route.exact} component={route.main} />;
        })}
        {/* <Redirect to="/welcome" /> */}
        <Route path="/sesameLogin" component={SesameLogin} />

        <Route path="*" component={Exception404} />
      </Switch>
    );
  }

  static routers = null;

  static openPaths = openPaths;

  /**
   *  校验权限
   * @param path
   * @returns {boolean}
   */
  static validatePermission(path) {
    // 可以访问的菜单
    const menu = menus;
    if (RouterGenerator.openPaths.includes(path)) {
      return true;
    }
    const targetMenu = TreeIterator.filter(menu, (item) => {
      return item.path === path;
    });
    return targetMenu.length > 0;
  }

  /**
   *  设置菜单白名单
   * @param path
   */
  static setOpenPaths(path) {
    if (!path) {
      return;
    }
    if (typeof path === 'string') {
      RouterGenerator.openPaths.push(path);
    } else if (path instanceof Array) {
      RouterGenerator.openPaths = RouterGenerator.openPaths.concat(path);
    }
  }

  /**
   *  获取路由
   * @param routerConf
   * @returns {boolean}
   */
  static getRouters(routerConf) {
    if (!RouterGenerator.routers) {
      RouterGenerator.routers = routerConf.map((router) => {
        return {
          path: router.path,
          exact: router.exact,
          main: Loadable({
            loader: () => {
              return router.page() || Spin;
            },
            // eslint-disable-next-line react/prop-types
            loading: ({ error }) => {
              if (error) {
                window.console.error(error);
              }
              return (
                <Spin
                  size="large"
                  style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                />
              );
            },
            render(loaded, props) {
              const Component = loaded.default;
              if (hasPermission) {
                const flag = RouterGenerator.validatePermission(router.path);
                return flag ? <Component {...props} /> : <Exception404 type={403} />;
              }
              return <Component {...props} />;
            },
          }),
        };
      });
    }
    return RouterGenerator.routers;
  }
}
