import React, { useState, forwardRef } from 'react';
import { Form, Button, Select, Input } from 'antd';
import Service from '@/service/goods';

const { Option } = Select;

const QueryBox = (props, ref) => {
  const [timer, setTimer] = useState(null);
  const [timer2, setTimer2] = useState(null);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [brandLoading, setBrandLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const {
    queryLoading = false,
    form,
    form: { getFieldDecorator },
    onSearch,
  } = props;

  const fetchBrand = async (value) => {
    setBrandLoading(true);
    const res = await Service.queryBrandOptions({ brandName: value ?? '' });
    setBrandLoading(false);
    if (res.code === 0 && Array.isArray(res.data)) {
      setBrandOptions(res.data);
    } else {
      setBrandOptions([]);
    }
  };

  const fetchCategory = async (value) => {
    setCategoryLoading(true);
    const res = await Service.queryCategoryOptions({ categoryName: value ?? '' });
    setCategoryLoading(false);
    if (res.code === 0 && Array.isArray(res.data)) {
      setCategoryOptions(res.data);
    } else {
      setCategoryOptions([]);
    }
  };

  const onSearchBrand = (value) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        fetchBrand(value);
      }, 500),
    );
  };

  const onSearchCategory = (value) => {
    if (timer) {
      clearTimeout(timer2);
      setTimer2(null);
    }
    setTimer2(
      setTimeout(() => {
        fetchCategory(value);
      }, 500),
    );
  };

  return (
    <Form layout="inline" ref={ref}>
      <Form.Item label="SAP商品编码">
        {getFieldDecorator('sapSkuNo')(<Input placeholder="输入SAP商品编码" allowClear style={{ width: 160 }} />)}
      </Form.Item>
      <Form.Item label="品牌">
        {getFieldDecorator('brandId', {
          initialValue: null,
        })(
          <Select
            allowClear
            placeholder="请选择"
            loading={brandLoading}
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            notFoundContent={null}
            onSearch={onSearchBrand}
            style={{ width: 160 }}
          >
            {brandOptions.map(({ id, brandName }) => (
              <Option value={id} key={id}>
                {brandName}
              </Option>
            ))}
          </Select>,
        )}
      </Form.Item>
      <Form.Item label="分类">
        {getFieldDecorator('mainCatId', {
          initialValue: null,
        })(
          <Select
            allowClear
            placeholder="请选择"
            loading={categoryLoading}
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            notFoundContent={null}
            onSearch={onSearchCategory}
            style={{ width: 160 }}
          >
            {categoryOptions.map(({ id, categoryName }) => (
              <Option value={id} key={id}>
                {categoryName}
              </Option>
            ))}
          </Select>,
        )}
      </Form.Item>
      <Form.Item>
        <Button loading={Boolean(queryLoading)} type="primary" onClick={() => onSearch?.(form.getFieldsValue())}>
          搜索
        </Button>
        <Button
          style={{ marginLeft: 8 }}
          onClick={() => {
            form.resetFields();
            onSearch(null, true);
          }}
        >
          重置
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create({ name: 'goodsTableQuery' })(forwardRef(QueryBox));
