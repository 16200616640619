import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Icon, Input } from 'antd';
import { NUM_OPTIONS } from '@/util/systemEnum';
import './index.scss';

class EditProtocol extends Component {
  constructor(props) {
    super(props);
  }

  handleChange = (item, itemIndex, key, evt) => {
    let { onChange, value: list } = this.props;
    item[key] = evt.target.value;
    if (Array.isArray(list) && list.length > 0) {
      list[itemIndex] = item;
    } else {
      list = [item];
    }
    onChange?.(list);
  };

  removeSchedule = (index) => {
    const { onChange, value: list } = this.props;
    list.splice(index, 1);
    onChange?.(list);
  };

  addSchedule = (list) => {
    const { onChange } = this.props;
    list.push({
      id: uuidv4(),
      title: '',
      color: '',
    });
    onChange?.(list);
  };

  render() {
    let { value } = this.props;
    let list = [];
    if (Array.isArray(value) && value.length > 0) {
      list = value;
    }
    if (list.length < 1) {
      list.push({
        id: uuidv4(),
        title: '',
        url: '',
      });
    }
    return (
      <div className="EditProtocolCom">
        {list.map((schedule, index) => (
          <div className="EditProtocolCom-block" key={schedule.id}>
            <div className="EditProtocolCom-block-head">
              协议{NUM_OPTIONS[index + 1]}
              {list.length > 1 ? (
                <div className="EditProtocolCom-block-head-delete" onClick={() => this.removeSchedule(index)}>
                  <Icon type="delete" />
                </div>
              ) : null}
            </div>
            <div className="EditProtocolCom-block-item">
              <div className="EditProtocolCom-block-item-label">协议标题</div>
              <div className="EditProtocolCom-block-item-control">
                <Input
                  placeholder="请输入协议标题, 不超过20个字"
                  value={schedule.title}
                  onChange={(evt) => this.handleChange(schedule, index, 'title', evt)}
                  max={20}
                />
              </div>
            </div>
            <div className="EditProtocolCom-block-item">
              <div className="EditProtocolCom-block-item-label">协议链接</div>
              <div className="EditProtocolCom-block-item-control">
                <Input
                  allowClear
                  placeholder="请输入http或https链接"
                  onChange={(evt) => this.handleChange(schedule, index, 'url', evt)}
                  value={schedule.url}
                />
              </div>
            </div>
          </div>
        ))}
        {list.length < 9 ? (
          <Button type="dashed" icon="plus" className="EditProtocolCom-add" onClick={() => this.addSchedule(list)}>
            新增协议项
          </Button>
        ) : null}
      </div>
    );
  }
}

export default EditProtocol;
