/****** create file by codeGen ******/
import { action, observable, runInAction } from 'mobx';
import service from '@/service/acitvityconfig';

class AcitvityConfigStore {
  // 列表数据
  @observable tableData = {
    list: [],
    total: 0,
    pageNum: 1,
    pageSize: 10,
  };
  @observable flowListData = {
    list: [],
    total: 0,
    pageNum: 1,
    pageSize: 10,
  };


  @observable shopList = [];

  @action queryList = async (params) => {
    let res = {};
    try {
      res = await service.queryList(params);
    } catch (error) { }
    let list = [];
    if (res.code === 0 && res.success && Array.isArray(res.data?.list)) {
      list = res.data.list;
    }
    runInAction(() => {
      this.tableData = {
        list,
        total: res.data?.total || 0,
        pageNum: res.data?.pageNum || 1,
        pageSize: res.data?.pageSize || 10,
      };
    });
    return res;
  };

  @action queryFlowList = async (params) => {
    let res = {};
    try {
      res = await service.queryFlowList(params);
    } catch (error) { }
    let list = [];
    if (res.code === 0 && res.success && Array.isArray(res.data?.list)) {
      list = res.data.list;
    }
    runInAction(() => {
      this.flowListData = {
        list,
        total: res.data?.total || 0,
        pageNum: res.data?.pageNum || 1,
        pageSize: res.data?.pageSize || 10,
      };
    });
    return res;
  };

  @action setParams = (params) => {
    runInAction(() => {
      this.tableData = {
        ...params
      };
    });

  }

  @action queryShopList = async (params) => {
    let res = {};
    try {
      res = await service.queryShopList(params);
    } catch (error) { }
    let list = [];
    if (res.code === 0 && res.success && Array.isArray(res.data)) {
      list = res.data;
    }
    runInAction(() => {
      this.shopList = list;
    });
    return res;
  };

  @action exportToExcelByCondition = async (params) => {
    return await service.exportToExcelByCondition(params);
  };
}

export default new AcitvityConfigStore();
