import axios from 'axios';
import { Remote } from '@/util';

class CouponOrderService {
  queryList(params = {}) {
    return Remote.post('/marketcuponbag/queryShareAndPay', params);
  }
  queryShopList(params = {}) {
    return Remote.get('/activityDic/allMcmShopList.do', params);
  }
  queryOrderStatusOptions(params = {}) {
    return Remote.get('/marketcuponbag/getOrderStatusList', params);
  }
  queryActivity(params = {}) {
    return Remote.get('/marketcuponbag/queryShardActivityList', params);
  }
  exportToExcelByCondition(data = {}) {
    const accessToken = localStorage.getItem('accessToken');
    const host = Remote.genDomainForEnv('default');
    const url = `${host}/marketcuponbag/exportShareAndPay`;
    return axios.post(url, data, {
      headers: {
        'content-type': 'application/json',
        token: accessToken,
      },
      responseType: 'blob',
    });
  }
}

export default new CouponOrderService();
