import React, { Component } from 'react';
import { Radio } from 'antd';
import img3 from '@/assets/img/goods/goods_3col.png';
import img2 from '@/assets/img/goods/goods_2col.png';
import img1 from '@/assets/img/goods/goods_1col.png';
import './index.scss';

class SelectType extends Component {
  constructor(props) {
    super(props);
  }

  handleChange = (evt) => {
    const { value } = evt.target;
    this.props.onChange?.(value);
  };

  render() {
    let { value } = this.props;
    return (
      <div className="goods-type-edit">
        <div className="goods-type-edit-title">展示样式</div>
        <Radio.Group
          options={[
            { label: '基本样式1（3列）', value: '3col' },
            { label: '基本样式2（2列）', value: '2col' },
            { label: '基本样式3（1列）', value: '1col' },
          ]}
          onChange={this.handleChange}
          value={value}
        />
        <div className="goods-type-edit-img-wrap">
          {value === '3col' ? <img src={img3} alt="" /> : null}
          {value === '2col' ? <img src={img2} alt="" /> : null}
          {value === '1col' ? <img src={img1} alt="" /> : null}
        </div>
      </div>
    );
  }
}

export default SelectType;
