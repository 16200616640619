import moment from 'moment';
export default class Tools {
    /**
     * 对下拉菜单的数据进行处理，以满足Select公共控件对于字段的要求
     * MakeSearch中select的字段类型为[name, id]
     * @param data 原始数据
     * @param maps 字段匹配配置 {key: [] || '' }
     *  key为数组时，按照数组中拿到的第一个有数据的字段来处理
     *  例如后端数据格式为{ name: xxx, id: 1, code: 'x111'}
     *  传入maps {id: ['code', id]} 则在后端数据code有值时使用code code无数据时使用id
     * @returns {{name: *, id: *}[]}
     */
    static mapSelect = (data = [], maps = {}) => {
        return data.map((item) => {
            let name = 'name';
            let id = 'id';
            if (Array.isArray(maps.name)) {
                name = maps.name.find((findItem) => {
                    return Boolean(item[findItem]);
                });
            } else if (typeof maps.name === 'string') {
                name = maps.name;
            }
            if (Array.isArray(maps.id)) {
                id = maps.id.find((findItem) => {
                    return Boolean(item[findItem]);
                });
            } else if (typeof maps.id === 'string') {
                id = maps.id;
            }
            return {
                name: item[name],
                id: item[id],
            };
        });
    };
    /**
     * 生成tabal的columns方法
     * @param options  [{
     *     name: string.isRequired
     * }]，需传递name dataindex
     * @returns {*}
     */
    static genTableOptions = (options) => {
        return options.map((item) => {
            return {
                ...item,
                title: item.name,
                dataIndex: item.dataindex,
                key: item.dataindex,
                width: item.width || 120,
                className: 'tableStyle',
                render: typeof item.render === 'function' && item.render,
            };
        });
    };

    static createUrl = (request) => {
        let { url } = request;
        const { param } = request;

        if (param) {
            url = !url.includes('?') && `${url}?`;
            for (const key of Object.keys(param)) {
                url = `${url + key}=${encodeURI(param[key])}&`;
            }
            if (url.endsWith('&')) {
                url = url.substring(0, url.length - 1);
            }
        }
        return url;
    };

    static getUrlArg = (name, isSearchFromCookies) => {
        let { search } = window.location;
        // IE9(通过window.history.replaceState来判断IE9和其他浏览器，不考虑IE8及以下浏览器)时，search的值从cookie中获取
        if (isSearchFromCookies && !window.history.replaceState) {
            search = unescape(getCookie('CURRENT_SEARCH'));
        }
        const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
        const arg = search.substr(1).match(reg);
        return arg ? arg[2] : '';
    };

    static getCookie = (cookieName) => {
        const cookieStr = decodeURI(document.cookie);
        const arr = cookieStr.split('; ');
        let cookieValue = '';
        for (let i = 0; i < arr.length; i++) {
            const temp = arr[i].split('=');
            if (temp[0] == cookieName) {
                cookieValue = temp[1];
                break;
            }
        }
        return decodeURI(cookieValue);
    };

    static setCookie = (name, value, exdays = 30) => {
        const exp = new Date();
        exp.setTime(exp.getTime() + exdays * 24 * 60 * 60 * 1000);
        document.cookie = `${name}=${escape(value)};expires=${exp.toGMTString()}`;
    };

    static delCookie = (name) => {
        Tools.setCookie(name, '', -1);
    };

    /**
     *  Upload组件的fileList格式转换方法
     *  将url字符串或数组转换成Upload组件的fileList
     *
     * @export
     * @param {*} urls url字符串或数组
     * @returns {array} 符合UploadImage组件的fileList格式
     */
    static formatFilelist = (urls) => {
        let res;

        if (urls && typeof urls === 'string') {
            res = [
                {
                    uid: -1,
                    name: 'image.png',
                    status: 'done',
                    url: urls,
                },
            ];
        } else if (Array.isArray(urls) && urls.length) {
            let uid = 0;
            res = urls.map((itme) => {
                uid -= 1;

                return {
                    uid,
                    name: 'image.png',
                    status: 'done',
                    url: itme,
                };
            });
        }

        // console.log('formatFilelist res: ', res);
        return res;
    };

    static downloadBlob = (res, name) => {
        const blob = new Blob([res.data]);
        const url = window.URL ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
        let fileName = res.headers['content-disposition'].split(';')[1].split('=')[1];
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.xls`;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    static batchUpdateByIndex = (lists, index, val) => {
        let res = [];
        lists.forEach((item) => {
            item[index] = Object.assign(item[index], val);
            res = [...res, ...item];
        });
        return res;
    };

    static disabledDate = (current) => {
        return current && current < moment().startOf('day');
    };
    static range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }
    static disabledDateTime = (dates, type) => {
        if (!dates || !Array.isArray(dates) || dates.length === 0) {
            return false;
        }
        const hours = new Date().getHours(); // 0~23
        const minutes = new Date().getMinutes(); // 0~59
        const seconds = new Date().getSeconds(); // 0~59
        if (moment(dates[0]).date() === moment().date() && type === 'start') {
            return {
                disabledHours: () => {
                    return Tools.range(0, 24).splice(0, hours);
                },
                disabledMinutes: () => {
                    return Tools.range(0, 60).splice(0, minutes + 1);
                },
                disabledSeconds: () => {
                    return Tools.range(0, 60).splice(0, seconds + 1);
                },
            };
        }
        return {
            // disabledHours: () => { return range(0, 24); },
            // disabledMinutes: () => { return range(0, 60); },
            // disabledSeconds: () => { return range(0, 60); },
        };
    }

    static formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    static getTreeData = (arr = [], initKey = "0") => {
        const valid = Array.isArray(arr) && arr.length > 0;

        if (valid) {
            return arr.map(({ name, code, cities = [], districts = [], ...rest }, i) => {
                console.log('cities', cities, 'districts', districts);
                let arr = cities.length !== 0 ? cities : districts;
                const inkey = [initKey, i].join("-");
                return {
                    ...rest,
                    title: name,
                    value: code,
                    key: code,
                    id: code,
                    disableCheckbox: true,
                    selectable: false,
                    cities,
                    districts,
                    children: Tools.getTreeData(arr, inkey),
                };
            });
        }

        return [];
    };

}
