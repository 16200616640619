// 可读 不可以写
/* eslint-disable */
function toEnum (obj) {
  // if (_.isEmpty(obj)) {
  //   return;
  // }
  let _data = JSON.parse(JSON.stringify(obj));

  let _obj = {
    _data,
    getLabelByKey: function (key) {
      return (_data[key] || {}).label;
    },
    getLabel: function (val) {
      let keys = Object.keys(_data);
      for (let i = 0; i < keys.length; i++) {
        let x = _data[keys[i]] || {};

        if (x.value == val) {
          return x.label;
        }
      }
      return undefined;
    },
    toArray: function () {
      let arr = [];
      Object.keys(_data).forEach((key) => {
        let xVal = _data[key] || {};
        arr.push({ ...xVal, key: xVal.value });
      });
      return arr;
    },
    render: function (text, record, index) {
      let ret = _obj.getLabel(text);
      if (ret === undefined) {
        ret = text;
      }

      return ret;
    },
  };

  Object.keys(obj).forEach((key) => {
    Object.defineProperty(_obj, key, {
      configrable: false,
      get: function () {
        return _data[key].value;
      },
      set: function (val) {
        // if (!_data[key]) {
        //   _data[key] = {};
        // }
        // _data[key].value = val;
      },
    });
  });

  return _obj;
}

// mini wx_browser app app_ju browser

export const browserTypeEnum = toEnum({
  MINI: {
    value: 'mini',
    label: '微信小程序',
  },
  WX_BROWSER: {
    value: 'wx_browser',
    label: '微信浏览器',
  },
  APP_BNQ: {
    value: 'app_bnq',
    label: '官网APP',
  },
  APP_JU: {
    value: 'app_ju',
    label: '小居APP',
  },
  APP: {
    value: 'app',
    label: 'APP',
  },
  BROWSER: {
    value: 'browser',
    label: '普通浏览器',
  },
  QW_BROWSER: {
    value: 'qw_browser',
    label: '企微',
  },
});



export const scheduleTypeEnum = toEnum({
  DAY: {
    value: 1,
    label: '日',
  },
  WEEK: {
    value: 2,
    label: '周',
  },
  MONTH: {
    value: 3,
    label: '月',
  },
  YEAR: {
    value: 4,
    label: '年',
  },
});

export const LINK_OPTIONS = [
  { label: '商品', value: 'goods', inputLable: '商品ID', placeholder: 'SAP商品编码' },
  { label: '专题', value: 'specialTopic', inputLable: '专题ID', placeholder: '专题ID' },
  { label: '设计师', value: 'designer', inputLable: '设计师', placeholder: '设计师序号' },
  // { label: '云样板间', value: 'demo', inputLable: '云样板间', placeholder: '样板间名称' },
  { label: '链接', value: 'link', inputLable: '链接', placeholder: 'http或https链接' },
  { label: '无跳转', value: 'none' },
];

export const NUM_OPTIONS = {
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  7: '七',
  8: '八',
  9: '九',
};
