import React, { useState, forwardRef, useEffect } from 'react';
import { Form, Button, Input, Icon, Modal, message, TreeSelect } from 'antd';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';
// import district from '@/util/district';
import Service from '@/service/goods';

const itemConfig = (props, ref) => {

  const [options, setOptions] = useState([]);
  const {
    list,
    item,
    index,
    form,
    form: { getFieldDecorator },
    onDelete,
    onChange,
    district
  } = props;
  // console.log('item', list, item);

  const handleChangeCity = (value) => {
    console.log('handleChangeCity', value);
    onChange?.(index, { cityCodeList: value });
  };

  const handleChangeSapCode = (e) => {
    onChange?.(index, { sapCode: e.target.value?.trim() || '' });
  };

  const getProvinceCode = (cityCode) => {
    let item = district.find((v) => {
      if (!v.children) return null;
      let check = v.children.some((e) => e.value == cityCode);
      if (check) return v;
      return null;
    });
    return item?.value;
  };

  /**
   * 返回当前省，其他配置项已选城市数量
   * @param {*} provinceCode
   * @param {*} list 配置内容
   * @param {*} item 当前配置项
   * @returns
   */
  const getSelectedNum = (provinceCode, list, item) => {
    let selfProvinceList = [];
    if (item && item.cityCodeList && item.cityCodeList.length > 0) {
      selfProvinceList = item.cityCodeList.map((e) => getProvinceCode(e));
    }
    let tmpList = list.map((v) => {
      if (v.cityCodeList && v.cityCodeList.length > 0) {
        return v.cityCodeList.map((e) => getProvinceCode(e));
      }
      return [];
    });
    tmpList = tmpList.flat(); // 所有配置项中，城市code 转换为 省code
    let filterList = tmpList.filter((v) => v == provinceCode);
    if (filterList.length < 1) return 0;
    if (selfProvinceList.indexOf(provinceCode) != -1) return filterList.length - 1;
    return filterList.length;
  };

  useEffect(() => {
    let tmpOptions = district.map((e) => {
      let item = { label: e.label, value: e.value };
      if (e.children && e.children instanceof Array) {
        item.children = e.children.map((c) => ({ label: c.label, value: c.value }));
      }
      return item;
    });
    setOptions(tmpOptions);
  }, []);

  useEffect(() => {
    console.log('update form:', item);
    form.setFieldsValue({ cityCodeList: [] });
    setTimeout(() => {
      form.setFieldsValue({
        cityCodeList: item?.cityCodeList || [],
        sapCode: item?.sapCode,
      });
    }, 50);
  }, [item.uuid, list.length]);

  useEffect(() => {
    let newOptions = district.map((province) => {
      let tmpProvince = { label: province.label, value: province.value };
      let num = getSelectedNum(province.value, list, item);
      // if (num > 0) {
      //   console.log('省', province.label, num);
      // }
      if (province.children) {
        if (num == province.children.length) tmpProvince.disabled = true;
        tmpProvince.children = province.children.map((city) => {
          // 城市被其他配置项选中，则禁用
          let checkCity = list.some(
            (e) =>
              e.cityCodeList &&
              e.cityCodeList.indexOf(city.value) != -1 &&
              (!item?.cityCodeList || item.cityCodeList.indexOf(city.value) == -1),
          );
          return { label: city.label, value: city.value, disabled: checkCity };
        });
      }
      return tmpProvince;
    });
    setOptions(newOptions);
  }, [list]);

  return (
    <Form ref={ref} layout="inline">
      <Form.Item label={`配置${index + 1}`} key={item.cityFieldName}>
        <Form.Item>
          {getFieldDecorator('cityCodeList', {
            rules: [{ required: true, message: '请选择省份' }],
          })(
            <TreeSelect
              allowClear
              multiple
              treeCheckable
              treeData={options}
              placeholder="请选择城市"
              style={{ width: 200 }}
              dropdownStyle={{ maxHeight: 280, overflow: 'auto' }}
              onChange={handleChangeCity}
              filterTreeNode={(inputVal, treeNode) => {
                // console.log('inputVal, treeNode', inputVal, treeNode)
                let { label, value } = treeNode.props
                let v = inputVal?.trim()
                if (label.indexOf(v) != -1 || value.indexOf(v) != -1) return true
                return false
              }}
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('sapCode', {
            rules: [{ required: true, message: '输入SAP商品编码' }],
          })(<Input allowClear placeholder="输入SAP商品编码" style={{ width: 180 }} onBlur={handleChangeSapCode} />)}
        </Form.Item>
        <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => onDelete(index)} />
      </Form.Item>
    </Form>
  );
};

const ItemConfigForm = Form.create({ name: 'itemConfig' })(forwardRef(itemConfig));

const EditCityConfig = (props, ref) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const { onOk, data, visible, onCancel, district } = props;
  console.log(district);

  const handleAddItem = () => {
    setList(
      update(list, {
        $splice: [[list.length, 1, { uuid: uuidv4(), ref: React.createRef() }]],
      }),
    );
  };

  const handleDeleteItem = (index) => {
    setList(
      update(list, {
        $splice: [[index, 1]],
      }),
    );
  };

  const handleChangeItem = (index, data) => {
    let item = list[index];
    let newItem = { ...item, ...data };
    setList(
      update(list, {
        $splice: [[index, 1, newItem]],
      }),
    );
  };

  /**
   * 校验并获取单个表单数据
   * @param {*} form
   * @returns
   */
  const getFormValues = async (form) => {
    let val = null;
    if (!form) return val;
    try {
      await form.validateFields((errors, values) => {
        if (!errors) {
          val = values;
        }
      });
    } catch (error) {}
    return val;
  };

  /**
   * 获取所有表单数据
   * @returns
   */
  const getAllData = async () => {
    let tmpList = [];
    let check = true;
    for (let i = 0; i < list.length; i++) {
      let values = await getFormValues(list[i].ref.current);
      if (values === null) {
        tmpList = [];
        check = false;
        break;
      }
      tmpList.push({ ...values, sapCode: values?.sapCode?.trim() || '' });
    }
    return { tmpList, check };
  };

  const handleOk = async () => {
    const { tmpList, check } = await getAllData();
    if (!check) return;
    if (list.length > 0 && tmpList.list < 1) return;
    const sapCodeList = tmpList.map((v) => v.sapCode);
    setLoading(true);
    const res = await Service.validateSapCode({ sapSkuNo: sapCodeList.join() });
    setLoading(false);
    if (res?.data) {
      message.error(`商品编码 ${res.data} 校验失败`);
      return;
    }
    onOk?.(tmpList);
  };

  useEffect(() => {
    if (visible) {
      let tmpList = [];
      if (data) {
        tmpList = JSON.parse(data?.cityProduct || '[]');
        tmpList = tmpList.map((v) => ({ ...v, ref: React.createRef(), uuid: uuidv4() }));
      }
      setList(tmpList.length > 0 ? tmpList : [{ ref: React.createRef(), uuid: uuidv4() }]);
    } else {
      setList([]);
    }
  }, [visible, data]);

  return (
    <Modal title="城市配置" width={700} visible={visible} onCancel={onCancel} onOk={handleOk} confirmLoading={loading}>
      <div style={{ marginBottom: 20, color: '#000000d9' }}>
        <p>商品名称：{data?.productName || '--'}</p>
        <p>商品SAP编码：{data?.sapCode || '--'}</p>
      </div>
      {list.map((item, index) => (
        <ItemConfigForm
          key={item.uuid}
          ref={item.ref}
          item={item}
          index={index}
          list={list}
          onDelete={handleDeleteItem}
          onChange={handleChangeItem}
          district={district}
        />
      ))}
      {list.length < district.length ? (
        <Button type="dashed" onClick={handleAddItem} style={{ width: '100%', marginTop: 20 }}>
          添加
        </Button>
      ) : null}
    </Modal>
  );
};

export default EditCityConfig;
