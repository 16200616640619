import axios from 'axios';
import { Remote } from '@/util';
import { areaJson } from '@/util/area.js';
console.log(areaJson);

class ConsultService {
  queryList (params = {}) {
    return Remote.post('/activityAppointment/queryPageList.do', params);
  }

  saveItem (params = {}) {
    return Remote.post('/activityAppointment/save.do', params);
  }

  updateItem (params = {}) {
    return Remote.post('/activityAppointment/updateById.do', params);
  }

  deleteItem (params = {}) {
    return Remote.post('/api/activityAppointment/delete.do', params);
  }

  queryById (id) {
    return Remote.get(`/activityAppointment/get/${id}.do`);
  }

  queryFieldById (id) {
    return Remote.get('/activityAppointment/detailByCode.do', { appointmentCode: id });
  }

  // client
  queryClientFieldById (id) {
    return Remote.get(`/client/activityComponent/appointment/detailByCode.do`, { appointmentCode: id });
  }

  queryChannelOptions () {
    return Remote.post('/activityDic/getDicByTypeList.do', ['appointmentChannel']);
  }

  getStoreData () {
    return Remote.get('/activityDic/mcmShopList.do');
  }

  getAreaData () {
    return areaJson.data;
  }
}

export default new ConsultService();
