import { getPageContainer } from './h5Helper';
import callHelper from './callHelper';
import { browserTypeEnum } from '@/util/systemEnum';

function openApp() {
  let url = window.location.href;
  if (url.indexOf('?newapp=1&') != -1) {
    // 包含
    url = window.location.href?.replace('?newapp=1&', '?');
  } else if (url.indexOf('?newapp=1') != -1) {
    // 参数只有newapp=1
    url = window.location.href?.replace('?newapp=1', '');
  } else if (url.indexOf('&newapp=1') != -1) {
    // newapp跟在其他参数后面
    url = window.location.href?.replace('&newapp=1', '');
  }

  let param = {
    url,
    option: {},
  };

  callHelper.open({
    screen: 'PTCustomWebPage',
    param: encodeURIComponent(JSON.stringify(param)),
  });
}

export default function tryOpen() {
  let browserType = getPageContainer(); // mini wx_browser app app_ju browser

  if (browserType == browserTypeEnum.BROWSER) {
    openApp();
    return browserTypeEnum.BROWSER;
  }

  return '';
}
