import React from 'react';
import { createPortal } from 'react-dom';
import './index.scss';

export default class SubmitBtn extends React.Component {
  constructor(props) {
    super(props);
    this.dom = document.querySelector('.preview-container');
  }
  render() {
    const { btnStyle, btnTxt, onSubmit, type, loading, isEditor } = this.props;
    let content = (
      <div className="quotation-btn-wrap" data-type={type} data-editor={isEditor}>
        <div className="quotation-btn" style={btnStyle} onClick={onSubmit}>
          {btnTxt}
        </div>
      </div>
    );
    if (this.dom && type == 'float') {
      return createPortal(content, this.dom);
    }
    return content;
  }
}
