import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { message } from 'antd';
import qs from 'query-string';
import appointmentAPI from '@/util/appointment';
import { track, newTrack, openMini, getPageContainer, toLogin, getTerminalToken } from '@/util/h5Helper';
import consultService from '@/service/consult';
import { browserTypeEnum } from '@/util/systemEnum';
import CusForm from './CusForm';
import SubmitBtn from './SubmitBtn';
import EditProtocol from './EditProtocol';
import gio from '@/util/newGio';
import './index.scss';

@inject((store) => {
  return {
    consultStore: store.consultStore,
  };
})
@observer
class Appointment extends Component {
  constructor(props) {
    super(props);
    let isEditor = true;
    const browserType = getPageContainer(false);
    if (window.location.pathname.indexOf('/activity/detail') === -1) {
      isEditor = false;
    }
    const params = new URL(window.location.href).searchParams;
    const id = params.get('id') || params.get('activityId') || '';
    const channel = params.get('channel') || '';
    const giochannel = params.get('giochannel') || '';
    this.formRef = React.createRef();
    this.state = {
      isEditor,
      appointmentFieldArray: [], // 1姓名，2电话，3城市，4门店，5小区 6.服务 7.短信验证码
      channelCode: '', // 运营计划编码
      entrancePage: '',
      submitLoading: false,
      isCoupon: 0, // 是否留资发券，发券需登录
      browserType,
      token: null,
      channel,
      giochannel,
      activityId: id,
      serviceItems: [], // 服务项目列表
      channelType: 1, // 渠道类型
      serviceProviders: [], // 服务提供方
      cityCodes: '0',
      environment: null
    };
  }

  componentDidMount () {
    const params = new URL(window.location.href).searchParams;
    if (params.get('environment')) {
      this.setState({
        environment: params.get('environment')
      });
    } else {
      const { browserType } = this.state;
      let ua = navigator.userAgent.toLowerCase();
      let isWx = ua.match(/MicroMessenger/i) == "micromessenger";
      let environment = browserType;
      if (isWx) {
        environment = 'wx_browser'
      }
      if (ua.match(/WxWork/i) == "wxwork") {
        environment = 'qw_browser'
      };
      this.setState({
        environment: environment
      });
    }


    this.queryFields();
    this.queryUserToken();
  }

  componentDidUpdate (prevProps) {
    const { appointmentId: oldId } = prevProps;
    const { appointmentId } = this.props;
    if (appointmentId && oldId !== appointmentId) {
      this.queryFields();
    }
  }

  async queryUserToken () {
    const { browserType } = this.state;
    const res = await getTerminalToken(browserType === browserTypeEnum.APP);
    this.setState({ token: res });
  }

  // eslint-disable-next-line react/sort-comp
  async queryFields () {
    const { isEditor } = this.state;
    const { appointmentId, pageDetail } = this.props;
    if (!appointmentId) return;
    let cityCodes = '0';
    // TODO 编辑状态下无法获取到pageDetail
    if (pageDetail) {
      cityCodes = pageDetail?.platformFlag == '2' ? pageDetail?.cityCodes : '0';
    }
    let res = {};
    if (isEditor) {
      res = await consultService.queryFieldById(appointmentId);
    } else {
      res = await consultService.queryClientFieldById(appointmentId);
    }
    if (res?.code === 0 && res?.success && Array.isArray(res?.data?.appointmentFieldArray)) {
      this.setState({
        appointmentFieldArray: res.data.appointmentFieldArray,
        channelCode: res.data.channelCode,
        entrancePage: res.data.entrancePage,
        isCoupon: res.data.isCoupon,
        serviceItems: res.data.serviceItems || [],
        channelType: res.data.channelType || 1,
        serviceProviders: res.data.serviceProviders || [],
        cityCodes,
      });
    } else {
      this.setState({
        appointmentFieldArray: [],
        channelCode: '',
        entrancePage: '',
        serviceItems: [],
        channelType: 1,
        serviceProviders: [],
        cityCodes: '0',
      });
    }
  }

  submit = async (btnTxt) => {
    const {
      appointmentFieldArray,
      channelCode,
      entrancePage,
      isCoupon,
      isEditor,
      browserType,
      token,
      activityId,
      channel,
      giochannel,
      channelType,
      serviceProviders,
      submitLoading,
      environment
    } = this.state;
    if (submitLoading) {
      return;
    }
    const { componentType, id, componentUuid, __extraInfo } = this.props;
    track({ code: 'click', componentType, componentCode: id });
    const box = document.querySelector('.appointment-form-wrap');
    const { name, mobile, address, cityName, cityValue, serviceIds, phoneMsg, referee: formReferee } = this.formRef.current?.values || {};
    const query = qs.parse(location.search.slice(1));
    const referee = formReferee || query?.shareEmployeeID_var || '';
    // TODO 发券隐藏登录逻辑
    // if (isCoupon) {
    //   // 提交后需发券
    //   if (isEditor) return message.warn('请在手机端操作');
    //   if (browserType == browserTypeEnum.APP || browserType === browserTypeEnum.MINI) {
    //     if (!token) {
    //       toLogin((res) => {
    //         this.setState({ token: res }, this.submit);
    //       });
    //       return null;
    //     }
    //   } else {
    //     openMini(`unpack/pages/web/index?pageId=${activityId}&channel=${channel}&giochannel=${giochannel}`);
    //     return null;
    //   }
    // }
    let serviceProviderArray = [];
    if (typeof serviceProviders === 'string') {
      serviceProviderArray = JSON.parse(serviceProviders);
    }
    let params = {
      activityId: query?.activityId || '',
      componentUuid,
      componentCode: id,
      customerName: name,
      mobile,
      channelTerminalCode: channelCode,
      secondChannelCode: entrancePage,
      serviceId: '4yoU44SK6qzMiH0LlQn01qFuuLE=',
      traceId: '99d3fa71-a6c3-4f24-b845-21498b63f146',
      token,
      shopCode: __extraInfo?.shopCode,
      shopName: __extraInfo?.shopName,
      channelType,
      serviceProviderArray,
      referee,
      browserType,
      terminal: environment ? environment : browserType,
      appointmentFieldArray
    };

    if (appointmentFieldArray.indexOf(1) !== -1) {
      if (!name) {
        box?.scrollIntoView();
        message.warn('请输入姓名');
        return;
      }
    }
    if (appointmentFieldArray.indexOf(2) !== -1) {
      if (!mobile) {
        box?.scrollIntoView();
        message.warn('请填写手机号');
        return;
      }
      if (!/^[1][0-9]{10}$/.test(mobile)) {
        box?.scrollIntoView();
        message.warn('填写的手机号格式不正确');
        return;
      }
    }
    if (appointmentFieldArray.indexOf(3) !== -1) {
      if (!cityValue || cityValue.length < 3) {
        box?.scrollIntoView();
        message.warn('请选择城市');
        return;
      }
      let newNameArr = cityName.split('-');
      params.provinceCode = cityValue[0];
      params.provinceName = newNameArr[0];
      params.cityCode = cityValue[1];
      params.cityName = newNameArr[1];
      params.countyCode = cityValue[2];
      params.countyName = newNameArr[2];
    }
    if (appointmentFieldArray.indexOf(5) !== -1) {
      if (!address) {
        box?.scrollIntoView();
        message.warn('请输入小区地址');
        return;
      }
      params.address = address;
    }
    if (appointmentFieldArray.indexOf(6) !== -1) {
      if (serviceIds.length === 0) {
        box?.scrollIntoView();
        message.warn('请您选择需要的服务项目');
        return;
      }
      params.serviceItemArray = serviceIds;
    }
    if (appointmentFieldArray.indexOf(7) !== -1) {
      if (!phoneMsg) {
        box?.scrollIntoView();
        message.warn('请输入您收到的短信验证码');
        return;
      }
      params.verifyCode = Number(phoneMsg);
    }
    if (appointmentFieldArray.indexOf(8) !== -1) {
      if (!formReferee) {
        box?.scrollIntoView();
        message.warn('请输入推荐人');
        return;
      }
      params.referee = Number(formReferee);
    }
    this.setState({ submitLoading: true });

    // 新老埋点
    const activityID = sessionStorage.getItem('__activityID__');
    const activityCode = sessionStorage.getItem('__activityCode__');
    const activityName = sessionStorage.getItem('__activityName__');
    const param = {
      ck: activityID,
      cknm: activityName,
      ccity: cityName,
    };
    newTrack('click_coupon', '', param);
    const info = {
      pageType_var: '营销活动',
      pageName_var: `${activityCode || ''}${param.cknm}`,
      storeID_var: '-',
      constructionSiteID_var: '-',
      activityID_var: params.activityId,
      pageClickName_var: btnTxt,
      ToRetentionPage_var: '是',
      // 流量位区域
      pageClickArea_var: '首页',
      // 流量位位置
      pageClickPosition_var: '首页',

    };
    gio.sendBnqPageClickTrack({ ...info });

    const res = await appointmentAPI(params);
    this.setState({ submitLoading: false });
    if (res?.code == 0) {
      message.success({
        icon: <div></div>,
        content: res?.data || '提交成功',
      });
      this.formRef.current?.reset();
      this.formRef.current?.resetSendMsg()
      return;
    }
    message.error(res?.message || '提交失败');
  };

  render () {
    const { appointmentFieldArray, serviceItems, isEditor, submitLoading, channelCode, serviceProviders, cityCodes } =
      this.state;

    const {
      componentUuid,
      btnTxt,
      btnColor,
      btnBg,
      bgImage,
      position,
      appointmentId,
      protocol,
      protocolColor,
      protocolTitleColor,
      protocolList,
    } = this.props;

    let btnStyle = {};
    let comStyle = { backgroundImage: `url(${bgImage})` };
    if (btnColor) {
      btnStyle.color = btnColor;
    }
    if (btnBg) {
      btnStyle.backgroundColor = btnBg;
    }
    if (bgImage && position == 'fixed') {
      comStyle.backgroundColor = 'transparent';
    }
    let items = [];
    if (typeof serviceItems === 'string') {
      items = JSON.parse(serviceItems);
    }
    // console.log('appointment render props:', this.props);
    // TODO 模拟数据
    return (
      <div className="appointment-com" id={componentUuid} style={comStyle} data-type={position}>
        {isEditor && !btnTxt ? (
          <div className="appointment-tip">
            <div>留资信息</div>
            <div>请上传图片</div>
          </div>
        ) : (
          <>
            {appointmentId && appointmentFieldArray.length > 0 ? (
              <CusForm
                ref={this.formRef}
                position={position}
                data={appointmentFieldArray}
                serviceItems={items}
                cityCodes={cityCodes}
              />
            ) : null}
            <SubmitBtn
              isEditor={isEditor}
              type={position}
              appointmentFieldArray={appointmentFieldArray}
              btnStyle={btnStyle}
              btnTxt={btnTxt}
              onSubmit={this.submit}
              loading={submitLoading}
              protocol={protocol}
              protocolColor={protocolColor}
              protocolTitleColor={protocolTitleColor}
              protocolList={protocolList}
            />
          </>
        )}
      </div>
    );
  }
}

Appointment.getDefaultValue = () => {
  return {
    appointmentId: '',
    btnTxt: '',
    btnColor: '',
    btnBg: '',
    position: 'float',
    protocol: 1,
    protocolLinkUrl: '',
  };
};

Appointment.getEditData = () => ({
  data: {
    appointmentId: {
      label: '留资活动ID',
    },
    bgImage: {
      label: '留资图片',
      control: 'upload',
      controlProps: {
        accept: '.jpg,.jpeg,.png',
        maxSize: '1M',
      },
      renderAfter: () => {
        return <span style={{ color: ' rgba(0,0,0,0.45)' }}>支持拓展名：.png .jpg .jpeg，建议宽度750px(小于1M)</span>;
      },
    },
    btnTxt: {
      label: '提交按钮文案',
      controlProps: {
        maxLength: 20,
      },
    },
    btnColor: {
      label: '按钮文字颜色',
      control: 'colorPicker',
    },
    btnBg: {
      label: '按钮颜色',
      control: 'colorPicker',
    },
    position: {
      label: '按钮位置',
      control: 'radioGroup',
      getInitialValue: 'float',
      options: () => [
        { label: '浮动', value: 'float' },
        { label: '固定', value: 'fixed' },
      ],
    },
    protocol: {
      label: '协议展示',
      control: 'radioGroup',
      getInitialValue: 1,
      options: () => [
        { label: '展示', value: 1 },
        { label: '不展示', value: 0 },
      ],
    },
    protocolColor: {
      label: '协议文字颜色',
      control: 'colorPicker',
      checkShow: ({ props }) => {
        let { form } = props || {};
        return form.getFieldValue('protocol') == 1;
      },
    },
    protocolTitleColor: {
      label: '协议标题颜色',
      control: 'colorPicker',
      checkShow: ({ props }) => {
        let { form } = props || {};
        return form.getFieldValue('protocol') == 1;
      },
    },
    protocolList: {
      label: '',
      control: EditProtocol,
      checkShow: ({ props }) => {
        let { form } = props || {};
        return form.getFieldValue('protocol') == 1;
      },
    },
  },
});

Appointment.validate = (props) => {
  // console.log('Appointment validate', props);
  const { appointmentId, bgImage, btnTxt, btnColor, btnBg, position, protocol, protocolLinkUrl } = props;
  if (!appointmentId) return '请输入留资活动ID';
  if (!bgImage) return '请设置留资图片';
  if (!btnTxt) return '请设置提交按钮文案';
  if (!btnColor) return '请设置按钮文字颜色';
  if (!btnBg) return '请设置按钮颜色';
  if (!position) return '请设置按钮位置';
  // if (protocol == 1) {
  //   if (!protocolLinkUrl) return '请输入协议链接';
  // }
  return '';
};

export default Appointment;
