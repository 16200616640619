import React, { useState, useEffect, useCallback } from 'react';
import update from 'immutability-helper';
import { Button, message, Modal } from 'antd';
import { useLocalStore } from 'mobx-react';
import { toJS } from 'mobx';
import DrawerWrap from '@/components/DrawerWrap';
import SortTable from './SortTable';
import SelectModal from './SelectModal';
import UploadModal from './UploadModal';
import EditCityConfigModal from './EditCityConfigModal';
import Service from '@/service/goods';
import globalService from '@/service/global';
import store from '@/stores';
import './index.less';

const SelectGoodsDrawer = (props) => {
  const [queryLoading, setQueryLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [list, setList] = useState([]);
  const [selectModalVisible, setSelectModalVisible] = useState(false);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [editConfigModalVisible, setEditConfigModalVisible] = useState(false);
  const [editItemIndex, setEditItemIndex] = useState(null);
  const [district, setDistrict] = useState([]);

  const activityStore = useLocalStore(() => store.activityStore);
  const { activityId, editComId, editComUuid, currentGoods } = activityStore;
  const { visible, onOk } = props;

  const getAreaData = async () => {
    let res = await globalService.fetchAreaData();
    let arr = res.data.result.data.provinces.map((item)=>{
      return {
        value: item.code,
        label: item.name,
        children: item.cities.map((city)=>{
          return {
            value: city.code,
            label: city.name,
            children: []
          }
        })
      }
    })
    setDistrict(arr ||[]);
  }

  const fetchList = async () => {
    setQueryLoading(true);
    const res = await activityStore.queryCurrentGoods();
    if (res.code !== 0 || !res.success) {
      message.error(res.message || '查询商品失败');
    }
    setQueryLoading(false);
  };

  const onSelectGoods = (selectedKeys, selectedRows) => {
    setSelectModalVisible(false);
    setList(selectedRows);
  };

  const onImportGoods = (importedList) => {
    let arr = [];
    // 过滤重复数据
    for (let i = 0; i < importedList.length; i++) {
      let check = arr.find((v) => v.sapCode === importedList[i].sapCode);
      if (check) continue;
      arr.push(importedList[i]);
    }
    for (let i = 0; i < list.length; i++) {
      let check = arr.find((v) => v.sapCode === list[i].sapCode);
      if (check) continue;
      arr.push(list[i]);
    }
    setList(arr);
    setUploadModalVisible(false);
  };

  const handleConfirm = async () => {
    if (list.length < 1) {
      message.warn('商品列表为空，请添加商品');
      return;
    }
    if (list.length > 100) {
      message.warn('单个商品组件设置的商品数量不能超过100个，请删除部分商品');
      return;
    }
    setConfirmLoading(true);
    const res = await Service.saveComponentGoods({
      activityId,
      componentCode: editComId,
      componentUuid: editComUuid,
      data: list,
    });
    setConfirmLoading(false);
    if (res && res.code === 0 && res.success) {
      message.success('保存成功');
      activityStore.setCurrentGoods(list);
    } else {
      message.error(res.message || '保存失败');
    }
    onOk?.(list.length);
  };

  const handleDelete = (index) => {
    setList(
      update(list, {
        $splice: [[index, 1]],
      }),
    );
  };

  const handleUpdateItem = (index, newItem) => {
    setList(
      update(list, {
        $splice: [[index, 1, newItem]],
      }),
    );
  };

  const handleCheckAll = (evt) => {
    console.log(list);
    // 将list中所有商品的recommendGoods字段设置为1
    const newList = list.map((item) => ({ ...item, recommendGoods: evt.target.checked ? 1 : 0 }));
    setList(newList)
  }

  const clearList = () => {
    Modal.confirm({
      title: '确定清空商品？',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        setList([]);
      },
    });
  };

  const batchImport = () => {
    setUploadModalVisible(true);
  };

  const handleMoveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = list[dragIndex];
      setList(
        update(list, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [list],
  );

  const handleConfigItem = (index) => {
    setEditItemIndex(index);
    setEditConfigModalVisible(true);
  };

  const onSaveItemCityConfig = (data) => {
    setEditConfigModalVisible(false);
    const item = list[editItemIndex];
    setList(
      update(list, {
        $splice: [[editItemIndex, 1, { ...item, cityProduct: JSON.stringify(data) }]],
      }),
    );
  };

  useEffect(() => {
    if (!visible) return;
    setList(toJS(currentGoods));
  }, [visible, currentGoods]);

  useEffect(() => {
    if (!visible) return;
    fetchList();
    getAreaData();
  }, [visible]);

  return (
    <DrawerWrap
      {...props}
      visible={visible}
      confirmLoading={confirmLoading}
      onOk={handleConfirm}
      title="添加活动商品"
      okTxt="保存"
      width={1200}
    >
      <div className="table-head">
        <div className="btn-group">
          <Button onClick={clearList}>清空商品</Button>
          <Button type="primary" ghost onClick={batchImport}>
            批量导入商品
          </Button>
          <Button type="primary" onClick={() => setSelectModalVisible(true)}>
            添加商品
          </Button>
        </div>
      </div>
      <SortTable
        loading={queryLoading}
        data={list}
        handleDelete={handleDelete}
        handleUpdateItem={handleUpdateItem}
        handleMoveRow={handleMoveRow}
        handleConfigItem={handleConfigItem}
        handleCheckAll={handleCheckAll}
      />
      <SelectModal
        visible={selectModalVisible}
        selectedData={list}
        onOk={onSelectGoods}
        onCancel={() => setSelectModalVisible(false)}
      />
      <UploadModal visible={uploadModalVisible} onOk={onImportGoods} onCancel={() => setUploadModalVisible(false)} />
      <EditCityConfigModal
        visible={editConfigModalVisible}
        data={editItemIndex == null ? null : list[editItemIndex]}
        onOk={onSaveItemCityConfig}
        onCancel={() => setEditConfigModalVisible(false)}
        district={district||[]}
      />
    </DrawerWrap>
  );
};

SelectGoodsDrawer.defaultProps = {
  visible: false,
};
export default SelectGoodsDrawer;
