import React, { useEffect, useState, useRef } from 'react';
import { Modal, message } from 'antd';
import QueryBox from './QueryBox';
import MultiSelectTable from './MultiSelectTable';
import Service from '@/service/goods';

const SelectModal = (props) => {
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const [queryParams, setQueryParams] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const ref = useRef(null)

  const { visible, selectedData, onOk, ...rest } = props;

  const fetchList = async (pages = {}) => {
    setTableLoading(true);
    let params = {
      ...pagination,
      ...queryParams,
      ...pages,
    };
    params.pageNum = params.current;
    delete params.total
    delete params.current
    const res = await Service.queryShopGoods(params);
    setTableLoading(false);
    setBtnLoading(false);
    if (res?.code === 0 && res?.success) {
      let data = res?.data ?? {};
      setList(data.list ?? []);
      setPagination({ current: data.pageNum || 1, pageSize: data.pageSize || 10, total: data.total || 0 });
      return;
    }
    message.error(res?.message || '请求失败');
  };

  /**
   * 搜索
   * @param {*} values 搜索参数
   * @param {boolean} reset 是否重置搜索
   */
  const onSearch = (values, reset = false) => {
    if (reset) {
      setQueryParams({ current: 1 });
      return;
    }
    setQueryParams({ ...values, current: 1 });
  };

  const handleConfirm = () => {
    if (selectedRowKeys.length < 1) {
      return message.warn('请勾选商品');
    }
    onOk?.(selectedRowKeys, selectedRows);
  };

  useEffect(() => {
    if (!visible) {
      // reset
      setList([]);
      setPagination({ current: 1, pageSize: 10, total: 0 });
      setQueryParams({});
      setSelectedRowKeys([]);
      setSelectedRows([]);
      ref.current?.resetFields()
      return;
    }
    fetchList()
  }, [visible]);

  useEffect(() => {
    if (!visible) return;
    if (selectedData.length > 0) {
      let keys = selectedData.map((v) => v.sapCode);
      setSelectedRowKeys(keys);
      setSelectedRows(selectedData);
    }
  }, [visible, selectedData]);

  useEffect(() => {
    if (!visible) return
    setBtnLoading(true);
    fetchList();
  }, [queryParams]);

  const tableProps = {
    loading: tableLoading,
    data: list,
    pagination,
    onChange: ({ current, pageSize }) => {
      fetchList({ current, pageSize });
    },
    rowSelection: {
      selectedRowKeys,
      onChange: (selectedRowKeys, curPageSelectedRows) => {
        const arr = [...selectedRows, ...curPageSelectedRows];
        const newArr = [];
        selectedRowKeys.forEach((v) => {
          const item = arr.find((record) => record.sapCode === v);
          if (item) newArr.push(item);
        });
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(newArr);
      },
    },
  };

  return (
    <Modal {...rest} maskClosable={false} title="添加商品" visible={visible} width={1000} onOk={handleConfirm}>
      <QueryBox ref={ref} onSearch={onSearch} queryLoading={btnLoading} />
      <MultiSelectTable {...tableProps} />
    </Modal>
  );
};

export default SelectModal;
