import React, { useEffect } from 'react'
import './index.scss'

const SelectModal = props => {

  const handleClick = e => {
    props.onClose?.()
  }

  const handleClick2 = e => {
    e.stopPropagation();
  }

  return (
    <div className='select-modal-wrap' onClick={handleClick}>
      <div className='select-modal' onClick={handleClick2}>
        {props.children}
      </div>
    </div>
  )

}

export default SelectModal;