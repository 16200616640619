import { configure } from 'mobx';
import globalStore from './global';
import activityStore from './activity';
import consultStore from './consult';
import couponStore from './coupon';
import couponOrderStore from './couponOrder';
import activityCommonDataStore from './activityCommonData';
import AcitvityConfigStore from './acitvityconfig';

configure({
  enforceActions: 'always', // 严格模式
});

const stores = {
  globalStore,
  activityStore,
  consultStore,
  couponStore,
  couponOrderStore,
  activityCommonDataStore,
  AcitvityConfigStore
};

export default stores;
