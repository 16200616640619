import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Icon, Input, Radio, Dropdown } from 'antd';
import { SketchPicker } from 'react-color';
import { LINK_OPTIONS, NUM_OPTIONS } from '@/util/systemEnum';
import Upload from '@/components/Upload';
import './index.scss';

class BannerList extends Component {
  constructor(props) {
    super(props);
  }

  getOption = (value) => {
    if (!value) return null;
    return LINK_OPTIONS.find((v) => v.value === value);
  };

  handleChange = (item, itemIndex, key, evt) => {
    let { onChange, value: list } = this.props;
    if (key === 'url' || key === 'ruleBtnColor' || key === 'ruleBtnBg') {
      item[key] = evt;
      if (evt?.lastIndexOf('.mp4') === -1) {
        item.isVideo = 0;
      } else {
        item.isVideo = 1;
      }
    } else {
      item[key] = evt.target.value;
      // 链接类型变更时，重置链接信息
      if (key === 'linkType') {
        item.linkInfo = null;
      } else if (key === 'showRule') {
        item.ruleLinkType = 0;
        item.ruleLinkInfo = null;
      } else if (key === 'ruleLinkType') {
        item.ruleLinkInfo = null;
      }
    }
    if (Array.isArray(list) && list.length > 0) {
      list[itemIndex] = item;
    } else {
      list = [item];
    }
    onChange?.(list);
  };

  removeSchedule = (index) => {
    const { onChange, value: list } = this.props;
    list.splice(index, 1);
    onChange?.(list);
  };

  addSchedule = (list) => {
    const { onChange } = this.props;
    list.push({
      id: uuidv4(),
      url: null,
      isVideo: 0,
      linkType: 'none',
      linkInfo: null,
      showRule: 0,
      ruleLinkType: 1,
      ruleLinkInfo: null,
      ruleBtnBg: '',
      ruleBtnColor: '',
    });
    onChange?.(list);
  };

  colorReverse = (oColor = '') => {
    let oldColor = '0x' + oColor.replace(/#/g, '');
    let str = '000000' + (0xffffff - oldColor).toString(16);
    return '#' + str.substring(str.length - 6, str.length);
  };

  render() {
    let { value } = this.props;
    let list = [];
    if (Array.isArray(value) && value.length > 0) {
      list = value;
    }
    if (list.length < 1) {
      list.push({
        id: uuidv4(),
        url: null,
        isVideo: 0,
        linkType: 'none',
        linkInfo: null,
        showRule: 0,
        ruleLinkType: 1,
        ruleLinkInfo: null,
      });
    }
    return (
      <div className="bannerListCom">
        {list.map((schedule, index) => (
          <div className="bannerListCom-block" key={schedule.id}>
            <div className="bannerListCom-block-head">
              第{NUM_OPTIONS[index + 1]}帧图片
              {list.length > 1 ? (
                <div className="bannerListCom-block-head-delete" onClick={() => this.removeSchedule(index)}>
                  <Icon type="delete" />
                </div>
              ) : null}
            </div>
            <div className="bannerListCom-block-item">
              <div className="bannerListCom-block-item-label">上传图片/视频</div>
              <div className="bannerListCom-block-item-control">
                <Upload
                  needTransfer
                  accept=".gif,.jpg,.jpeg,.png,.mp4"
                  maxSize="20M"
                  value={schedule.url}
                  isVideo={schedule.isVideo}
                  onChange={(evt) => this.handleChange(schedule, index, 'url', evt)}
                />
              </div>
              <div className="bannerListCom-block-item-tip">支持格式：gif、jpg、jpeg、png、mp4，建议宽度为750px</div>
            </div>
            <div className="bannerListCom-block-item">
              <div className="bannerListCom-block-item-label">跳转至</div>
              <div className="bannerListCom-block-item-control">
                <Radio.Group
                  options={LINK_OPTIONS}
                  onChange={(evt) => this.handleChange(schedule, index, 'linkType', evt)}
                  value={schedule.linkType}
                />
              </div>
            </div>
            {schedule?.linkType === 'none' ? null : (
              <div className="bannerListCom-block-item">
                <div className="bannerListCom-block-item-label">{this.getOption(schedule?.linkType)?.inputLable}</div>
                <div className="bannerListCom-block-item-control">
                  <Input
                    value={schedule.linkInfo}
                    placeholder={`请输入${this.getOption(schedule?.linkType)?.placeholder}`}
                    onChange={(evt) => this.handleChange(schedule, index, 'linkInfo', evt)}
                  />
                </div>
              </div>
            )}
            {index === 0 ? (
              <div className="bannerListCom-block-item">
                <div className="bannerListCom-block-item-label">是否显示规则</div>
                <div className="bannerListCom-block-item-control">
                  <Radio.Group
                    options={[
                      { label: '显示', value: 1 },
                      { label: '不显示', value: 0 },
                    ]}
                    onChange={(evt) => this.handleChange(schedule, index, 'showRule', evt)}
                    value={schedule.showRule}
                  />
                </div>
              </div>
            ) : null}
            {index === 0 && schedule.showRule ? (
              <>
                <div className="bannerListCom-block-item">
                  <div className="bannerListCom-block-item-label">规则按钮背景色</div>
                  <div className="bannerListCom-block-item-control">
                    <Dropdown
                      trigger={['click']}
                      overlay={
                        <SketchPicker
                          color={schedule?.ruleBtnBg}
                          onChangeComplete={(evt) => this.handleChange(schedule, index, 'ruleBtnBg', evt.hex)}
                        />
                      }
                    >
                      <div className="colorPickerCom-content" style={{ background: schedule?.ruleBtnBg }}>
                        <span
                          style={{
                            marginRight: 5,
                            color: (schedule?.ruleBtnBg && this.colorReverse(schedule?.ruleBtnBg)) || '#bfbfbf',
                          }}
                        >
                          {schedule?.ruleBtnBg || '请选择颜色'}
                        </span>
                        {schedule?.ruleBtnBg ? (
                          <Icon
                            onClick={(evt) => {
                              evt.preventDefault();
                              evt.stopPropagation();
                              this.handleChange(schedule, index, 'ruleBtnBg', '');
                            }}
                            type="close-circle"
                            theme="filled"
                            style={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.25)' }}
                          />
                        ) : (
                          <Icon type="down" style={{ color: '#999' }} />
                        )}
                      </div>
                    </Dropdown>
                  </div>
                </div>
                <div className="bannerListCom-block-item">
                  <div className="bannerListCom-block-item-label">规则按钮文字颜色</div>
                  <div className="bannerListCom-block-item-control">
                    <Dropdown
                      trigger={['click']}
                      overlay={
                        <SketchPicker
                          color={schedule?.ruleBtnColor}
                          onChangeComplete={(evt) => this.handleChange(schedule, index, 'ruleBtnColor', evt.hex)}
                        />
                      }
                    >
                      <div className="colorPickerCom-content" style={{ background: schedule?.ruleBtnColor }}>
                        <span
                          style={{
                            marginRight: 5,
                            color: (schedule?.ruleBtnColor && this.colorReverse(schedule?.ruleBtnColor)) || '#bfbfbf',
                          }}
                        >
                          {schedule?.ruleBtnColor || '请选择颜色'}
                        </span>
                        {schedule?.ruleBtnColor ? (
                          <Icon
                            onClick={(evt) => {
                              evt.preventDefault();
                              evt.stopPropagation();
                              this.handleChange(schedule, index, 'ruleBtnColor', '');
                            }}
                            type="close-circle"
                            theme="filled"
                            style={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.25)' }}
                          />
                        ) : (
                          <Icon type="down" style={{ color: '#999' }} />
                        )}
                      </div>
                    </Dropdown>
                  </div>
                </div>
                <div className="bannerListCom-block-item">
                  <div className="bannerListCom-block-item-label">规则跳转</div>
                  <div className="bannerListCom-block-item-control">
                    <Radio.Group
                      options={[
                        { label: '专题', value: 0 },
                        { label: '链接', value: 1 },
                      ]}
                      onChange={(evt) => this.handleChange(schedule, index, 'ruleLinkType', evt)}
                      value={schedule.ruleLinkType}
                    />
                  </div>
                </div>
                <div className="bannerListCom-block-item">
                  <div className="bannerListCom-block-item-label">{schedule?.ruleLinkType ? '链接' : '专题ID'}</div>
                  <div className="bannerListCom-block-item-control">
                    <Input
                      allowClear
                      placeholder={schedule?.ruleLinkType ? '请输入http或https链接' : '请输入专题ID'}
                      onChange={(evt) => this.handleChange(schedule, index, 'ruleLinkInfo', evt)}
                      value={schedule.ruleLinkInfo}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        ))}
        {list.length < 9 ? (
          <Button type="dashed" icon="plus" className="bannerListCom-add" onClick={() => this.addSchedule(list)}>
            新增图片帧数
          </Button>
        ) : null}
      </div>
    );
  }
}

export default BannerList;
