import { Remote } from '@/util';

class CommonService {
    static getQiniuToken() {
        return Remote.get('/upload/getQiniuTokenWithParams', '', {
            urlType: 'qiniu',
            key: null,
            isShowPermissionPage: false,
        });
    }

    static getAuthInfo() {
        return Remote.get('/login/zhiMaLogin.do', {});
    }

    static logout() {
        return Remote.get('/login/zhiMaLogout.do', {});
    }
}

export default CommonService;
