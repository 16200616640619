import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Button } from 'antd';
import './index.less';

const DrawerWrap = props => {
  const {
    visible,
    title,
    confirmLoading,
    cancelTxt,
    okTxt,
    hideCancelBtn,
    hideOkBtn,
    contentClassName,
    onCancel,
    onOk,
    ...rest
  } = props;
  return (
    <Drawer
      className="drawerLayout"
      visible={visible}
      title={title}
      width={600}
      destroyOnClose
      closable={false}
      {...rest}
    >
      <div className={`contentWrap ${contentClassName}`}>{props.children}</div>
      <div className="btnWrap">
        {hideCancelBtn ? null : <Button onClick={onCancel}>{cancelTxt}</Button>}
        {hideOkBtn ? null : (
          <Button onClick={onOk} type="primary" loading={confirmLoading}>
            {okTxt}
          </Button>
        )}
      </div>
    </Drawer>
  );
};

DrawerWrap.propTypes = {
  visible: PropTypes.bool,
  confirmLoading: PropTypes.bool,
  title: PropTypes.string,
  cancelTxt: PropTypes.string,
  okTxt: PropTypes.string,
  hideCancelBtn: PropTypes.bool,
  hideOkBtn: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
};

DrawerWrap.defaultProps = {
  visible: false,
  confirmLoading: false,
  title: '新增',
  cancelTxt: '取消',
  okTxt: '提交',
  hideCancelBtn: false,
  hideOkBtn: false,
  contentClassName: '',
  onCancel: () => {},
  onOk: () => {},
};

export default DrawerWrap;
