import gdp from 'gio-webjs-sdk-cdp/gdp-full';
import { RNTool } from 'web-message-for-rn';
import {
  isApp,
} from '@/util/h5Helper';

// 埋点新方法
const track = (eventId, params, item = {}) => {
  let eventData = {
    ...params
  };
  // console.log('trackparams1', params);
  if (isApp()) {
    RNTool.requestLoginInfo(true, (info) => {
      const {
        data: { loginInfo }
      } = info;

      eventData.shareOneID_var = loginInfo.oneId || '-';
      eventData.employeeID_var = loginInfo.employeeNumber || '-'
      gdp('track', eventId, eventData, item);
    });
    // console.log('isApp()============>', isApp(), eventData);
  } else {
    gdp('track', eventId, eventData, item);
  }

};

// 通用字段
const _params = {
  // 页面类型
  pageType_var: '-',
  // 页面名称
  pageName_var: '-',
  // 页面URL
  pageURL_var: window.location.href || '-',
  // 门店ID
  storeID_var: '-',
  // 工地ID
  constructionSiteID_var: '-',
  // 业务单号
  document_bodyID_var: '-',
  // 上一页面类型
  prePageType_var: '-',
  // 上一页面名称：
  prePageName_var: '-',
  // 上一个页面URL：
  prePageURLvar: '-',
  // 分享者oneID：
  shareOneID_var: '-',
  // 分享者员工ID：
  shareEmployeeID_var: '-',
  // 广告内容ID：
  adContent_var: '-',
  // 广告关键字：
  adTerm_var: '-',
  // 三级渠道ID：
  channelThirdID_var: '-',
  // 三级渠道名称：
  channelThirdName_var: '-',
  // 员工ID
  employeeID_var: '-',
  // 内容ID
  contentID_var: '-',
};

//页面曝光 -- 进入页面
const bnqPageExposureTrack = (eventId, params = {}, item = {}) => {
  const comData = {
    ..._params,
    // 上一页面类型
    prePageType_var: '-',
    // 上一页面名称
    prePageName_var: '-',
    // 上一个页面URL
    prePageURLvar: document.referrer || '-',
    // 分享者oneID
    shareOneID_var: '-'
  };
  const eventData = { ...comData, ...params };
  track(eventId, eventData, item);
};

//页面预览 -- 离开页面
const bnqPageViewTrack = (eventId, params = {}, item = {}) => {
  const comData = {
    ..._params,
    // 页面浏览时长
    eventDuration: '-'
  };
  if (params.eventDuration) {
    params.eventDuration = `${(new Date().getTime() - params.eventDuration) / 1000
      }秒`;
  }
  const eventData = { ...comData, ...params };
  track(eventId, eventData, item);
};

//流量位点击
const bnqPageClickTrack = (eventId, params = {}, item = {}) => {
  const comData = {
    // 分享者oneID
    shareOneID_var: '-',
    // 流量位区域
    pageClickArea_var: '-',
    // 流量位位置
    pageClickPosition_var: '-',
    // 流量位名称
    pageClickName_var: '-',
    ..._params,
  };
  const eventData = { ...comData, ...params };
  track(eventId, eventData, item);
};

// tarck事件
function gioTrack (eventId, params = {}, item = {}) {
  track(eventId, params, item);
}
// 页面曝光 -- 进入页面
function sendBnqPageExposureTrack (params = {}, item = {}) {
  bnqPageExposureTrack('PageExposure', params, item);
}
// 页面预览 -- 离开页面
function sendBnqPageViewTrack (params = {}, item = {}) {
  bnqPageViewTrack('PageView', params, item);
}
// 流量位点击
function sendBnqPageClickTrack (params = {}, item = {}) {
  bnqPageClickTrack('pageClick', params, item);
}

export default {
  gioTrack,
  sendBnqPageExposureTrack,
  sendBnqPageViewTrack,
  sendBnqPageClickTrack
};
