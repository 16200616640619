export default [
  {
    path: '/',
    exact: true,
    text: '专题活动列表',
    page: () => {
      return import('../app/Activity');
    },
  },
  {
    path: '/acitvityManage/specialMangage',
    text: '专题管理',
    breadParent: [{ name: '活动管理' }],
    page: () => {
      return import('../app/Activity');
    },
  },
  {
    path: '/acitvityManage/specialMangage/detail',
    text: '活动详情',
    page: () => {
      return import('../app/Activity/Detail');
    },
  },
  // {
  //   path: '/activity',
  //   exact: true,
  //   text: '活动管理',
  //   page: () => {
  //     return import('../app/Activity/Detail');
  //   },
  // },
  {
    path: '/activity/detail',
    exact: true,
    text: '活动详情',
    breadParent: [{ name: '活动管理' }, { name: '专题活动', url: '/acitvityManage/specialMangage' }],
    page: () => {
      return import('../app/Activity/Detail');
    },
  },
  {
    path: '/consult',
    exact: true,
    text: '留资活动',
    breadParent: [{ name: '活动管理' }],
    page: () => {
      return import('../app/Consult');
    },
  },
  {
    path: '/consult/add',
    text: '新增活动',
    page: () => {
      return import('../app/Consult/AddOrEdit');
    },
  },
  {
    path: '/consult/edit',
    text: '编辑活动',
    page: () => {
      return import('../app/Consult/AddOrEdit');
    },
  },
  {
    path: '/couponConfig',
    exact: true,
    text: '优惠券设定',
    breadParent: [{ name: '活动管理' }],
    page: () => {
      return import('../app/Coupon');
    },
  },
  {
    path: '/couponConfig/add',
    text: '新增优惠券设定',
    page: () => {
      return import('../app/Coupon/AddOrEdit');
    },
  },
  {
    path: '/couponConfig/edit',
    text: '编辑优惠券',
    page: () => {
      return import('../app/Coupon/AddOrEdit');
    },
  },
  {
    path: '/couponOrder',
    exact: true,
    text: '优惠券订单',
    breadParent: [{ name: '活动管理' }],
    page: () => {
      return import('../app/CouponOrder');
    },
  },
  {
    path: '/qrcodeTool',
    exact: true,
    text: '二维码生成工具',
    breadParent: [{ name: '活动管理' }],
    page: () => {
      return import('../app/QrcodeTool');
    },
  },
  {
    path: '/ossTool',
    exact: true,
    text: '视频链接工具',
    breadParent: [{ name: '活动管理' }],
    page: () => {
      return import('../app/OssTool');
    },
  },
  {
    path: '/probabilitySetting',
    exact: true,
    text: '抽奖概率设置',
    breadParent: [{ name: '活动管理' }],
    page: () => {
      return import('../app/ProbabilitySetting');
    },
  },
  {
    path: '/acitvityMap/list',
    text: '活动数据看板',
    breadParent: [{ name: '活动数据看板' }],
    page: () => {
      return import('../app/ActivityMap');
    },
  },
  {
    path: '/acitvityconfig',
    exact: true,
    text: '家装活动配置',
    breadParent: [{ name: '家装活动配置' }],
    page: () => {
      return import('../app/AcitvityConfig');
    },
  },
  {
    path: '/acitvityconfig/record',
    text: '参与记录',
    page: () => {
      return import('../app/AcitvityConfig/Record');
    },
  },
  {
    path: '/activityRecord',
    text: '家装活动参与记录',
    page: () => {
      return import('../app/ActivityRecord');
    },
  },
];
