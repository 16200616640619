import React, { useEffect, useState } from 'react';
import district from '@/util/district';
import './index.scss';

const initData = (data = {}) => ({
  selectedProvinceCode: '',
  selectedProvinceName: '',
  selectedCityCode: '',
  selectedCityName: '',
  districtCode: '',
  districtName: '',
  ...data,
});

const SelectCityModal = (props) => {
  const parentList = district;
  const [childList, setChildList] = useState([]);
  const [selectedData, setSelectedData] = useState(() => initData);
  const { visible, cityCode, onChange, onClose } = props;

  const init = () => {
    let item = null;
    let tempData = initData();
    for (let i = 0; i < district.length; i++) {
      item = district[i].children.find((v) => v.value == cityCode);
      tempData.selectedProvinceCode = district[i].value;
      tempData.selectedProvinceName = district[i].label;
      if (item) {
        setChildList(district[i].children);
        tempData.selectedCityCode = item.value;
        tempData.selectedCityName = item.label;
        if (item.children instanceof Array && item.children.length > 0) {
          tempData.districtCode = item.children[0].value;
          tempData.districtName = item.children[0].label;
        } else {
          setChildList([]);
        }
        break;
      }
    }
    setSelectedData(tempData);
    onChange?.({
      provinceCode: tempData.selectedProvinceCode,
      provinceName: tempData.selectedProvinceName,
      cityCode: tempData.selectedCityCode,
      cityName: tempData.selectedCityName,
      countyName: tempData.districtName,
      district: tempData.districtCode,
      districtName: tempData.districtName,
    });
  };

  const handleSelectCity = (data) => {
    let tempData = initData();
    if (selectedData.selectedProvinceCode == data.value) return;
    let item = parentList.find((v) => v.value == data.value);
    tempData.selectedProvinceCode = item.value;
    tempData.selectedProvinceName = item.label;
    let tempChildList = [];
    if (item) {
      tempChildList = item.children;
      setChildList(tempChildList);
    }
    if (tempChildList.length > 0) {
      tempData.selectedCityCode = tempChildList[0].value;
      tempData.selectedCityName = tempChildList[0].label;
      if (tempChildList.children instanceof Array && tempChildList.children > 0) {
        tempData.districtCode = tempChildList.children[0].value;
        tempData.districtName = tempChildList.children[0].label;
      }
    }
    setSelectedData(tempData);
  };

  const handleSelectDistrict = (item) => {
    let tempData = initData(selectedData);
    tempData.selectedCityCode = item.value;
    tempData.selectedCityName = item.label;
    if (item.children instanceof Array && item.children.length > 0) {
      tempData.districtCode = item.children[0].value;
      tempData.districtName = item.children[0].label;
    }
    setSelectedData(tempData);
    onChange?.({
      provinceCode: tempData.selectedProvinceCode,
      provinceName: tempData.selectedProvinceName,
      cityCode: tempData.selectedCityCode,
      cityName: tempData.selectedCityName,
      countyName: tempData.districtName,
      district: tempData.districtCode,
      districtName: tempData.districtName,
    });
    onClose?.();
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, [cityCode]);

  return (
    <div className="select-city-modal-wrap" onClick={() => onClose?.()} style={{ display: visible ? 'block' : 'none' }}>
      <div className="select-city-modal" onClick={(e) => e.stopPropagation()}>
        <div className="column">
          {parentList.map((province) => (
            <div
              className="item"
              key={province.value}
              data-selected={province.value == selectedData.selectedProvinceCode}
              onClick={() => handleSelectCity(province)}
            >
              {province.letter + ' ' + province.label}
            </div>
          ))}
        </div>
        <div className="column">
          {childList.map((item) => (
            <div
              className="item"
              key={item.value}
              data-selected={item.value == selectedData.selectedCityCode}
              onClick={() => handleSelectDistrict(item)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectCityModal;
