import './public-path';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as MobxProvider } from 'mobx-react';
import { ConfigProvider, message, notification } from 'antd';
import providerZhCN from 'antd/lib/locale-provider/zh_CN';
import gdp from 'gio-webjs-sdk-cdp/gdp-full';
import 'dayjs/locale/zh-cn';
import Home from './app/Home';
import moment from 'moment'

import './assets/css/reset.css';

import stores from './stores';

// message全局配置
message.config({
  top: '45%',
  duration: 3,
  maxCount: 1,
});

if (window.location.origin.includes('btadmin')) {
  let now = moment();
  let isAfter = moment('2024-6-3 21:00:00').isAfter(now);
  if (isAfter) {
    notification.warning({
      message: '温馨提示',
      duration: 0,
      description:
        '活动管理平台已迁移至促销系统，请从促销系统登录使用：http://promotion.bnq.com.cn/welcome，该模块将于2024/6/3 21:00下线',
    });
  }
}

function render (props) {
  const { container } = props;
  if (window.location.pathname !== '/activity/h5') {
    document.title = '通用管理后台';
  }

  console.log(process.env.CURRENT_ENV);

  if (process.env.CURRENT_ENV === 'production') {
    gdp('init', '84111d55c1fa0080', 'bd1e1c3d50c49288', {
      host: 'api.bnq.com.cn', // 固定host
      version: '1.0.0',
      scheme: 'https'
    });
  } else {
    // test环境
    // 开启本地页面采集
    window._gr_ignore_local_rule = true;
    gdp('init', 'bae811352a39405c', 'b4737d279d6d560b', {
      host: 'api-qa.bnq.com.cn', // 固定host
      version: '1.0.0',
      compress: false,
      debug: true,
      scheme: 'https'
    });
  }

  ReactDOM.render(
    <ConfigProvider locale={providerZhCN}>
      <MobxProvider {...stores}>
        <Home />
      </MobxProvider>
    </ConfigProvider>,
    container ? container.querySelector('#app') : document.querySelector('#app'),
  );
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}

export async function bootstrap () {
  console.log('[react16] react app bootstraped');
}

export async function mount (props) {
  console.log('[react16] props from main framework', props);
  render(props);
}

export async function unmount (props) {
  const { container } = props;
  ReactDOM.unmountComponentAtNode(container ? container.querySelector('#app') : document.querySelector('#app'));
}
