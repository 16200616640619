import _ from 'lodash';

const address = [
  {
    value: '0',
    title: '全国',
    key: '0',
  },
  {
    "value": "110000",
    "children": [
      {
        "value": "110100",
        "title": "北京市",
        "children": [
          {
            "value": "110101",
            "title": "东城区",
            "id": 1,
            "firstLetter": "D"
          },
          {
            "value": "110102",
            "title": "西城区",
            "id": 2,
            "firstLetter": "X"
          },
          {
            "value": "110105",
            "title": "朝阳区",
            "id": 3,
            "firstLetter": "C"
          },
          {
            "value": "110106",
            "title": "丰台区",
            "id": 4,
            "firstLetter": "F"
          },
          {
            "value": "110107",
            "title": "石景山区",
            "id": 5,
            "firstLetter": "S"
          },
          {
            "value": "110108",
            "title": "海淀区",
            "id": 6,
            "firstLetter": "H"
          },
          {
            "value": "110109",
            "title": "门头沟区",
            "id": 7,
            "firstLetter": "M"
          },
          {
            "value": "110111",
            "title": "房山区",
            "id": 8,
            "firstLetter": "F"
          },
          {
            "value": "110112",
            "title": "通州区",
            "id": 9,
            "firstLetter": "T"
          },
          {
            "value": "110113",
            "title": "顺义区",
            "id": 10,
            "firstLetter": "S"
          },
          {
            "value": "110114",
            "title": "昌平区",
            "id": 11,
            "firstLetter": "C"
          },
          {
            "value": "110115",
            "title": "大兴区",
            "id": 12,
            "firstLetter": "D"
          },
          {
            "value": "110116",
            "title": "怀柔区",
            "id": 13,
            "firstLetter": "H"
          },
          {
            "value": "110117",
            "title": "平谷区",
            "id": 14,
            "firstLetter": "P"
          },
          {
            "value": "110118",
            "title": "密云区",
            "id": 15,
            "firstLetter": "M"
          },
          {
            "value": "110119",
            "title": "延庆区",
            "id": 16,
            "firstLetter": "Y"
          }
        ],
        "id": 1,
        "provinceID": 1,
        "firstLetter": "B"
      }
    ],
    "title": "北京市",
    "id": 1,
    "firstLetter": "B"
  },
  {
    "value": "120000",
    "children": [
      {
        "value": "120100",
        "title": "天津市",
        "children": [
          {
            "value": "120101",
            "title": "和平区",
            "id": 17,
            "firstLetter": "H"
          },
          {
            "value": "120102",
            "title": "河东区",
            "id": 18,
            "firstLetter": "H"
          },
          {
            "value": "120103",
            "title": "河西区",
            "id": 19,
            "firstLetter": "H"
          },
          {
            "value": "120104",
            "title": "南开区",
            "id": 20,
            "firstLetter": "N"
          },
          {
            "value": "120105",
            "title": "河北区",
            "id": 21,
            "firstLetter": "H"
          },
          {
            "value": "120106",
            "title": "红桥区",
            "id": 22,
            "firstLetter": "H"
          },
          {
            "value": "120110",
            "title": "东丽区",
            "id": 23,
            "firstLetter": "D"
          },
          {
            "value": "120111",
            "title": "西青区",
            "id": 24,
            "firstLetter": "X"
          },
          {
            "value": "120112",
            "title": "津南区",
            "id": 25,
            "firstLetter": "J"
          },
          {
            "value": "120113",
            "title": "北辰区",
            "id": 26,
            "firstLetter": "B"
          },
          {
            "value": "120114",
            "title": "武清区",
            "id": 27,
            "firstLetter": "W"
          },
          {
            "value": "120115",
            "title": "宝坻区",
            "id": 28,
            "firstLetter": "B"
          },
          {
            "value": "120116",
            "title": "滨海新区",
            "id": 29,
            "firstLetter": "B"
          },
          {
            "value": "120117",
            "title": "宁河区",
            "id": 4106,
            "firstLetter": "N"
          },
          {
            "value": "120118",
            "title": "静海区",
            "id": 4107,
            "firstLetter": "J"
          },
          {
            "value": "120119",
            "title": "蓟州区",
            "id": 4108,
            "firstLetter": "J"
          }
        ],
        "id": 3,
        "provinceID": 2,
        "firstLetter": "T"
      }
    ],
    "title": "天津市",
    "id": 2,
    "firstLetter": "T"
  },
  {
    "value": "130000",
    "children": [
      {
        "value": "130100",
        "title": "石家庄市",
        "children": [
          {
            "value": "130102",
            "title": "长安区",
            "id": 34,
            "firstLetter": "Z"
          },
          {
            "value": "130104",
            "title": "桥西区",
            "id": 35,
            "firstLetter": "Q"
          },
          {
            "value": "130105",
            "title": "新华区",
            "id": 36,
            "firstLetter": "X"
          },
          {
            "value": "130107",
            "title": "井陉矿区",
            "id": 37,
            "firstLetter": "J"
          },
          {
            "value": "130108",
            "title": "裕华区",
            "id": 38,
            "firstLetter": "Y"
          },
          {
            "value": "130109",
            "title": "藁城区",
            "id": 39,
            "firstLetter": "G"
          },
          {
            "value": "130110",
            "title": "鹿泉区",
            "id": 40,
            "firstLetter": "L"
          },
          {
            "value": "130111",
            "title": "栾城区",
            "id": 41,
            "firstLetter": "L"
          },
          {
            "value": "130121",
            "title": "井陉县",
            "id": 42,
            "firstLetter": "J"
          },
          {
            "value": "130123",
            "title": "正定县",
            "id": 43,
            "firstLetter": "Z"
          },
          {
            "value": "130125",
            "title": "行唐县",
            "id": 44,
            "firstLetter": "X"
          },
          {
            "value": "130126",
            "title": "灵寿县",
            "id": 45,
            "firstLetter": "L"
          },
          {
            "value": "130127",
            "title": "高邑县",
            "id": 46,
            "firstLetter": "G"
          },
          {
            "value": "130128",
            "title": "深泽县",
            "id": 47,
            "firstLetter": "S"
          },
          {
            "value": "130129",
            "title": "赞皇县",
            "id": 48,
            "firstLetter": "Z"
          },
          {
            "value": "130130",
            "title": "无极县",
            "id": 49,
            "firstLetter": "W"
          },
          {
            "value": "130131",
            "title": "平山县",
            "id": 50,
            "firstLetter": "P"
          },
          {
            "value": "130132",
            "title": "元氏县",
            "id": 51,
            "firstLetter": "Y"
          },
          {
            "value": "130133",
            "title": "赵县",
            "id": 52,
            "firstLetter": "Z"
          },
          {
            "value": "130181",
            "title": "辛集市",
            "id": 53,
            "firstLetter": "X"
          },
          {
            "value": "130183",
            "title": "晋州市",
            "id": 54,
            "firstLetter": "J"
          },
          {
            "value": "130184",
            "title": "新乐市",
            "id": 55,
            "firstLetter": "X"
          }
        ],
        "id": 5,
        "provinceID": 3,
        "firstLetter": "S"
      },
      {
        "value": "130200",
        "title": "唐山市",
        "children": [
          {
            "value": "130202",
            "title": "路南区",
            "id": 57,
            "firstLetter": "L"
          },
          {
            "value": "130203",
            "title": "路北区",
            "id": 58,
            "firstLetter": "L"
          },
          {
            "value": "130204",
            "title": "古冶区",
            "id": 59,
            "firstLetter": "G"
          },
          {
            "value": "130205",
            "title": "开平区",
            "id": 60,
            "firstLetter": "K"
          },
          {
            "value": "130207",
            "title": "丰南区",
            "id": 61,
            "firstLetter": "F"
          },
          {
            "value": "130208",
            "title": "丰润区",
            "id": 62,
            "firstLetter": "F"
          },
          {
            "value": "130209",
            "title": "曹妃甸区",
            "id": 63,
            "firstLetter": "C"
          },
          {
            "value": "130224",
            "title": "滦南县",
            "id": 65,
            "firstLetter": "L"
          },
          {
            "value": "130225",
            "title": "乐亭县",
            "id": 66,
            "firstLetter": "L"
          },
          {
            "value": "130227",
            "title": "迁西县",
            "id": 67,
            "firstLetter": "Q"
          },
          {
            "value": "130229",
            "title": "玉田县",
            "id": 68,
            "firstLetter": "Y"
          },
          {
            "value": "130281",
            "title": "遵化市",
            "id": 69,
            "firstLetter": "Z"
          },
          {
            "value": "130283",
            "title": "迁安市",
            "id": 70,
            "firstLetter": "Q"
          },
          {
            "value": "130284",
            "title": "滦州市",
            "id": 4109,
            "firstLetter": "L"
          }
        ],
        "id": 6,
        "provinceID": 3,
        "firstLetter": "T"
      },
      {
        "value": "130300",
        "title": "秦皇岛市",
        "children": [
          {
            "value": "130302",
            "title": "海港区",
            "id": 72,
            "firstLetter": "H"
          },
          {
            "value": "130303",
            "title": "山海关区",
            "id": 73,
            "firstLetter": "S"
          },
          {
            "value": "130304",
            "title": "北戴河区",
            "id": 74,
            "firstLetter": "B"
          },
          {
            "value": "130321",
            "title": "青龙满族自治县",
            "id": 75,
            "firstLetter": "Q"
          },
          {
            "value": "130322",
            "title": "昌黎县",
            "id": 76,
            "firstLetter": "C"
          },
          {
            "value": "130324",
            "title": "卢龙县",
            "id": 78,
            "firstLetter": "L"
          },
          {
            "value": "130306",
            "title": "抚宁区",
            "id": 4110,
            "firstLetter": "F"
          }
        ],
        "id": 7,
        "provinceID": 3,
        "firstLetter": "Q"
      },
      {
        "value": "130400",
        "title": "邯郸市",
        "children": [
          {
            "value": "130402",
            "title": "邯山区",
            "id": 80,
            "firstLetter": "H"
          },
          {
            "value": "130403",
            "title": "丛台区",
            "id": 81,
            "firstLetter": "C"
          },
          {
            "value": "130404",
            "title": "复兴区",
            "id": 82,
            "firstLetter": "F"
          },
          {
            "value": "130406",
            "title": "峰峰矿区",
            "id": 83,
            "firstLetter": "F"
          },
          {
            "value": "130423",
            "title": "临漳县",
            "id": 85,
            "firstLetter": "L"
          },
          {
            "value": "130424",
            "title": "成安县",
            "id": 86,
            "firstLetter": "C"
          },
          {
            "value": "130425",
            "title": "大名县",
            "id": 87,
            "firstLetter": "D"
          },
          {
            "value": "130426",
            "title": "涉县",
            "id": 88,
            "firstLetter": "S"
          },
          {
            "value": "130427",
            "title": "磁县",
            "id": 89,
            "firstLetter": "C"
          },
          {
            "value": "130430",
            "title": "邱县",
            "id": 92,
            "firstLetter": "Q"
          },
          {
            "value": "130431",
            "title": "鸡泽县",
            "id": 93,
            "firstLetter": "J"
          },
          {
            "value": "130432",
            "title": "广平县",
            "id": 94,
            "firstLetter": "G"
          },
          {
            "value": "130433",
            "title": "馆陶县",
            "id": 95,
            "firstLetter": "G"
          },
          {
            "value": "130434",
            "title": "魏县",
            "id": 96,
            "firstLetter": "W"
          },
          {
            "value": "130435",
            "title": "曲周县",
            "id": 97,
            "firstLetter": "Q"
          },
          {
            "value": "130481",
            "title": "武安市",
            "id": 98,
            "firstLetter": "W"
          },
          {
            "value": "130407",
            "title": "肥乡区",
            "id": 4111,
            "firstLetter": "F"
          },
          {
            "value": "130408",
            "title": "永年区",
            "id": 4112,
            "firstLetter": "Y"
          }
        ],
        "id": 8,
        "provinceID": 3,
        "firstLetter": "H"
      },
      {
        "value": "130500",
        "title": "邢台市",
        "children": [
          {
            "value": "130502",
            "title": "桥东区",
            "id": 100,
            "firstLetter": "Q"
          },
          {
            "value": "130503",
            "title": "桥西区",
            "id": 101,
            "firstLetter": "Q"
          },
          {
            "value": "130521",
            "title": "邢台县",
            "id": 102,
            "firstLetter": "X"
          },
          {
            "value": "130522",
            "title": "临城县",
            "id": 103,
            "firstLetter": "L"
          },
          {
            "value": "130523",
            "title": "内丘县",
            "id": 104,
            "firstLetter": "N"
          },
          {
            "value": "130524",
            "title": "柏乡县",
            "id": 105,
            "firstLetter": "B"
          },
          {
            "value": "130525",
            "title": "隆尧县",
            "id": 106,
            "firstLetter": "L"
          },
          {
            "value": "130526",
            "title": "任县",
            "id": 107,
            "firstLetter": "R"
          },
          {
            "value": "130527",
            "title": "南和县",
            "id": 108,
            "firstLetter": "N"
          },
          {
            "value": "130528",
            "title": "宁晋县",
            "id": 109,
            "firstLetter": "N"
          },
          {
            "value": "130529",
            "title": "巨鹿县",
            "id": 110,
            "firstLetter": "J"
          },
          {
            "value": "130530",
            "title": "新河县",
            "id": 111,
            "firstLetter": "X"
          },
          {
            "value": "130531",
            "title": "广宗县",
            "id": 112,
            "firstLetter": "G"
          },
          {
            "value": "130532",
            "title": "平乡县",
            "id": 113,
            "firstLetter": "P"
          },
          {
            "value": "130533",
            "title": "威县",
            "id": 114,
            "firstLetter": "W"
          },
          {
            "value": "130534",
            "title": "清河县",
            "id": 115,
            "firstLetter": "Q"
          },
          {
            "value": "130535",
            "title": "临西县",
            "id": 116,
            "firstLetter": "L"
          },
          {
            "value": "130581",
            "title": "南宫市",
            "id": 117,
            "firstLetter": "N"
          },
          {
            "value": "130582",
            "title": "沙河市",
            "id": 118,
            "firstLetter": "S"
          }
        ],
        "id": 9,
        "provinceID": 3,
        "firstLetter": "X"
      },
      {
        "value": "130600",
        "title": "保定市",
        "children": [
          {
            "value": "130602",
            "title": "新市区",
            "id": 120,
            "firstLetter": "X"
          },
          {
            "value": "130623",
            "title": "涞水县",
            "id": 125,
            "firstLetter": "L"
          },
          {
            "value": "130624",
            "title": "阜平县",
            "id": 126,
            "firstLetter": "F"
          },
          {
            "value": "130626",
            "title": "定兴县",
            "id": 128,
            "firstLetter": "D"
          },
          {
            "value": "130627",
            "title": "唐县",
            "id": 129,
            "firstLetter": "T"
          },
          {
            "value": "130628",
            "title": "高阳县",
            "id": 130,
            "firstLetter": "G"
          },
          {
            "value": "130629",
            "title": "容城县",
            "id": 131,
            "firstLetter": "R"
          },
          {
            "value": "130630",
            "title": "涞源县",
            "id": 132,
            "firstLetter": "L"
          },
          {
            "value": "130631",
            "title": "望都县",
            "id": 133,
            "firstLetter": "W"
          },
          {
            "value": "130632",
            "title": "安新县",
            "id": 134,
            "firstLetter": "A"
          },
          {
            "value": "130633",
            "title": "易县",
            "id": 135,
            "firstLetter": "Y"
          },
          {
            "value": "130634",
            "title": "曲阳县",
            "id": 136,
            "firstLetter": "Q"
          },
          {
            "value": "130635",
            "title": "蠡县",
            "id": 137,
            "firstLetter": "L"
          },
          {
            "value": "130636",
            "title": "顺平县",
            "id": 138,
            "firstLetter": "S"
          },
          {
            "value": "130637",
            "title": "博野县",
            "id": 139,
            "firstLetter": "B"
          },
          {
            "value": "130638",
            "title": "雄县",
            "id": 140,
            "firstLetter": "X"
          },
          {
            "value": "130681",
            "title": "涿州市",
            "id": 141,
            "firstLetter": "Z"
          },
          {
            "value": "130682",
            "title": "定州市",
            "id": 142,
            "firstLetter": "D"
          },
          {
            "value": "130683",
            "title": "安国市",
            "id": 143,
            "firstLetter": "A"
          },
          {
            "value": "130684",
            "title": "高碑店市",
            "id": 144,
            "firstLetter": "G"
          },
          {
            "value": "130606",
            "title": "莲池区",
            "id": 4113,
            "firstLetter": "L"
          },
          {
            "value": "130607",
            "title": "满城区",
            "id": 4114,
            "firstLetter": "M"
          },
          {
            "value": "130608",
            "title": "清苑区",
            "id": 4115,
            "firstLetter": "Q"
          },
          {
            "value": "130609",
            "title": "徐水区",
            "id": 4116,
            "firstLetter": "X"
          }
        ],
        "id": 10,
        "provinceID": 3,
        "firstLetter": "B"
      },
      {
        "value": "130700",
        "title": "张家口市",
        "children": [
          {
            "value": "130702",
            "title": "桥东区",
            "id": 146,
            "firstLetter": "Q"
          },
          {
            "value": "130703",
            "title": "桥西区",
            "id": 147,
            "firstLetter": "Q"
          },
          {
            "value": "130705",
            "title": "宣化区",
            "id": 148,
            "firstLetter": "X"
          },
          {
            "value": "130706",
            "title": "下花园区",
            "id": 149,
            "firstLetter": "X"
          },
          {
            "value": "130722",
            "title": "张北县",
            "id": 151,
            "firstLetter": "Z"
          },
          {
            "value": "130723",
            "title": "康保县",
            "id": 152,
            "firstLetter": "K"
          },
          {
            "value": "130724",
            "title": "沽源县",
            "id": 153,
            "firstLetter": "G"
          },
          {
            "value": "130725",
            "title": "尚义县",
            "id": 154,
            "firstLetter": "S"
          },
          {
            "value": "130726",
            "title": "蔚县",
            "id": 155,
            "firstLetter": "Y"
          },
          {
            "value": "130727",
            "title": "阳原县",
            "id": 156,
            "firstLetter": "Y"
          },
          {
            "value": "130728",
            "title": "怀安县",
            "id": 157,
            "firstLetter": "H"
          },
          {
            "value": "130730",
            "title": "怀来县",
            "id": 159,
            "firstLetter": "H"
          },
          {
            "value": "130731",
            "title": "涿鹿县",
            "id": 160,
            "firstLetter": "Z"
          },
          {
            "value": "130732",
            "title": "赤城县",
            "id": 161,
            "firstLetter": "C"
          },
          {
            "value": "130708",
            "title": "万全区",
            "id": 4117,
            "firstLetter": "W"
          },
          {
            "value": "130709",
            "title": "崇礼区",
            "id": 4118,
            "firstLetter": "C"
          }
        ],
        "id": 11,
        "provinceID": 3,
        "firstLetter": "Z"
      },
      {
        "value": "130800",
        "title": "承德市",
        "children": [
          {
            "value": "130802",
            "title": "双桥区",
            "id": 164,
            "firstLetter": "S"
          },
          {
            "value": "130803",
            "title": "双滦区",
            "id": 165,
            "firstLetter": "S"
          },
          {
            "value": "130804",
            "title": "鹰手营子矿区",
            "id": 166,
            "firstLetter": "Y"
          },
          {
            "value": "130821",
            "title": "承德县",
            "id": 167,
            "firstLetter": "C"
          },
          {
            "value": "130822",
            "title": "兴隆县",
            "id": 168,
            "firstLetter": "X"
          },
          {
            "value": "130824",
            "title": "滦平县",
            "id": 170,
            "firstLetter": "L"
          },
          {
            "value": "130825",
            "title": "隆化县",
            "id": 171,
            "firstLetter": "L"
          },
          {
            "value": "130826",
            "title": "丰宁满族自治县",
            "id": 172,
            "firstLetter": "F"
          },
          {
            "value": "130827",
            "title": "宽城满族自治县",
            "id": 173,
            "firstLetter": "K"
          },
          {
            "value": "130828",
            "title": "围场满族蒙古族自治县",
            "id": 174,
            "firstLetter": "W"
          },
          {
            "value": "130881",
            "title": "平泉市",
            "id": 4119,
            "firstLetter": "P"
          }
        ],
        "id": 12,
        "provinceID": 3,
        "firstLetter": "C"
      },
      {
        "value": "130900",
        "title": "沧州市",
        "children": [
          {
            "value": "130902",
            "title": "新华区",
            "id": 176,
            "firstLetter": "X"
          },
          {
            "value": "130903",
            "title": "运河区",
            "id": 177,
            "firstLetter": "Y"
          },
          {
            "value": "130921",
            "title": "沧县",
            "id": 178,
            "firstLetter": "C"
          },
          {
            "value": "130922",
            "title": "青县",
            "id": 179,
            "firstLetter": "Q"
          },
          {
            "value": "130923",
            "title": "东光县",
            "id": 180,
            "firstLetter": "D"
          },
          {
            "value": "130924",
            "title": "海兴县",
            "id": 181,
            "firstLetter": "H"
          },
          {
            "value": "130925",
            "title": "盐山县",
            "id": 182,
            "firstLetter": "Y"
          },
          {
            "value": "130926",
            "title": "肃宁县",
            "id": 183,
            "firstLetter": "S"
          },
          {
            "value": "130927",
            "title": "南皮县",
            "id": 184,
            "firstLetter": "N"
          },
          {
            "value": "130928",
            "title": "吴桥县",
            "id": 185,
            "firstLetter": "W"
          },
          {
            "value": "130929",
            "title": "献县",
            "id": 186,
            "firstLetter": "X"
          },
          {
            "value": "130930",
            "title": "孟村回族自治县",
            "id": 187,
            "firstLetter": "M"
          },
          {
            "value": "130981",
            "title": "泊头市",
            "id": 188,
            "firstLetter": "B"
          },
          {
            "value": "130982",
            "title": "任丘市",
            "id": 189,
            "firstLetter": "R"
          },
          {
            "value": "130983",
            "title": "黄骅市",
            "id": 190,
            "firstLetter": "H"
          },
          {
            "value": "130984",
            "title": "河间市",
            "id": 191,
            "firstLetter": "H"
          }
        ],
        "id": 13,
        "provinceID": 3,
        "firstLetter": "C"
      },
      {
        "value": "131000",
        "title": "廊坊市",
        "children": [
          {
            "value": "131002",
            "title": "安次区",
            "id": 193,
            "firstLetter": "A"
          },
          {
            "value": "131003",
            "title": "广阳区",
            "id": 194,
            "firstLetter": "G"
          },
          {
            "value": "131022",
            "title": "固安县",
            "id": 195,
            "firstLetter": "G"
          },
          {
            "value": "131023",
            "title": "永清县",
            "id": 196,
            "firstLetter": "Y"
          },
          {
            "value": "131024",
            "title": "香河县",
            "id": 197,
            "firstLetter": "X"
          },
          {
            "value": "131025",
            "title": "大城县",
            "id": 198,
            "firstLetter": "D"
          },
          {
            "value": "131026",
            "title": "文安县",
            "id": 199,
            "firstLetter": "W"
          },
          {
            "value": "131028",
            "title": "大厂回族自治县",
            "id": 200,
            "firstLetter": "D"
          },
          {
            "value": "131081",
            "title": "霸州市",
            "id": 201,
            "firstLetter": "B"
          },
          {
            "value": "131082",
            "title": "三河市",
            "id": 202,
            "firstLetter": "S"
          }
        ],
        "id": 14,
        "provinceID": 3,
        "firstLetter": "L"
      },
      {
        "value": "131100",
        "title": "衡水市",
        "children": [
          {
            "value": "131102",
            "title": "桃城区",
            "id": 204,
            "firstLetter": "T"
          },
          {
            "value": "131121",
            "title": "枣强县",
            "id": 205,
            "firstLetter": "Z"
          },
          {
            "value": "131122",
            "title": "武邑县",
            "id": 206,
            "firstLetter": "W"
          },
          {
            "value": "131123",
            "title": "武强县",
            "id": 207,
            "firstLetter": "W"
          },
          {
            "value": "131124",
            "title": "饶阳县",
            "id": 208,
            "firstLetter": "R"
          },
          {
            "value": "131125",
            "title": "安平县",
            "id": 209,
            "firstLetter": "A"
          },
          {
            "value": "131126",
            "title": "故城县",
            "id": 210,
            "firstLetter": "G"
          },
          {
            "value": "131127",
            "title": "景县",
            "id": 211,
            "firstLetter": "J"
          },
          {
            "value": "131128",
            "title": "阜城县",
            "id": 212,
            "firstLetter": "F"
          },
          {
            "value": "131182",
            "title": "深州市",
            "id": 214,
            "firstLetter": "S"
          },
          {
            "value": "131103",
            "title": "冀州区",
            "id": 4120,
            "firstLetter": "J"
          }
        ],
        "id": 15,
        "provinceID": 3,
        "firstLetter": "H"
      }
    ],
    "title": "河北省",
    "id": 3,
    "firstLetter": "H"
  },
  {
    "value": "140000",
    "children": [
      {
        "value": "140100",
        "title": "太原市",
        "children": [
          {
            "value": "140105",
            "title": "小店区",
            "id": 216,
            "firstLetter": "X"
          },
          {
            "value": "140106",
            "title": "迎泽区",
            "id": 217,
            "firstLetter": "Y"
          },
          {
            "value": "140107",
            "title": "杏花岭区",
            "id": 218,
            "firstLetter": "X"
          },
          {
            "value": "140108",
            "title": "尖草坪区",
            "id": 219,
            "firstLetter": "J"
          },
          {
            "value": "140109",
            "title": "万柏林区",
            "id": 220,
            "firstLetter": "W"
          },
          {
            "value": "140110",
            "title": "晋源区",
            "id": 221,
            "firstLetter": "J"
          },
          {
            "value": "140121",
            "title": "清徐县",
            "id": 222,
            "firstLetter": "Q"
          },
          {
            "value": "140122",
            "title": "阳曲县",
            "id": 223,
            "firstLetter": "Y"
          },
          {
            "value": "140123",
            "title": "娄烦县",
            "id": 224,
            "firstLetter": "L"
          },
          {
            "value": "140181",
            "title": "古交市",
            "id": 225,
            "firstLetter": "G"
          }
        ],
        "id": 16,
        "provinceID": 4,
        "firstLetter": "T"
      },
      {
        "value": "140200",
        "title": "大同市",
        "children": [
          {
            "value": "140212",
            "title": "新荣区",
            "id": 230,
            "firstLetter": "X"
          },
          {
            "value": "140221",
            "title": "阳高县",
            "id": 231,
            "firstLetter": "Y"
          },
          {
            "value": "140222",
            "title": "天镇县",
            "id": 232,
            "firstLetter": "T"
          },
          {
            "value": "140223",
            "title": "广灵县",
            "id": 233,
            "firstLetter": "G"
          },
          {
            "value": "140224",
            "title": "灵丘县",
            "id": 234,
            "firstLetter": "L"
          },
          {
            "value": "140225",
            "title": "浑源县",
            "id": 235,
            "firstLetter": "H"
          },
          {
            "value": "140226",
            "title": "左云县",
            "id": 236,
            "firstLetter": "Z"
          },
          {
            "value": "140213",
            "title": "平城区",
            "id": 4121,
            "firstLetter": "P"
          },
          {
            "value": "140214",
            "title": "云冈区",
            "id": 4122,
            "firstLetter": "Y"
          },
          {
            "value": "140215",
            "title": "云州区",
            "id": 4123,
            "firstLetter": "Y"
          }
        ],
        "id": 17,
        "provinceID": 4,
        "firstLetter": "D"
      },
      {
        "value": "140300",
        "title": "阳泉市",
        "children": [
          {
            "value": "140302",
            "title": "城区",
            "id": 239,
            "firstLetter": "C"
          },
          {
            "value": "140303",
            "title": "矿区",
            "id": 240,
            "firstLetter": "K"
          },
          {
            "value": "140311",
            "title": "郊区",
            "id": 241,
            "firstLetter": "J"
          },
          {
            "value": "140321",
            "title": "平定县",
            "id": 242,
            "firstLetter": "P"
          },
          {
            "value": "140322",
            "title": "盂县",
            "id": 243,
            "firstLetter": "Y"
          }
        ],
        "id": 18,
        "provinceID": 4,
        "firstLetter": "Y"
      },
      {
        "value": "140400",
        "title": "长治市",
        "children": [
          {
            "value": "140423",
            "title": "襄垣县",
            "id": 248,
            "firstLetter": "X"
          },
          {
            "value": "140425",
            "title": "平顺县",
            "id": 250,
            "firstLetter": "P"
          },
          {
            "value": "140426",
            "title": "黎城县",
            "id": 251,
            "firstLetter": "L"
          },
          {
            "value": "140427",
            "title": "壶关县",
            "id": 252,
            "firstLetter": "H"
          },
          {
            "value": "140428",
            "title": "长子县",
            "id": 253,
            "firstLetter": "Z"
          },
          {
            "value": "140429",
            "title": "武乡县",
            "id": 254,
            "firstLetter": "W"
          },
          {
            "value": "140430",
            "title": "沁县",
            "id": 255,
            "firstLetter": "Q"
          },
          {
            "value": "140431",
            "title": "沁源县",
            "id": 256,
            "firstLetter": "Q"
          },
          {
            "value": "140412",
            "title": "潞州区",
            "id": 4124,
            "firstLetter": "L"
          },
          {
            "value": "140413",
            "title": "上党区",
            "id": 4125,
            "firstLetter": "S"
          },
          {
            "value": "140414",
            "title": "屯留区",
            "id": 4126,
            "firstLetter": "T"
          },
          {
            "value": "140415",
            "title": "潞城区",
            "id": 4127,
            "firstLetter": "L"
          }
        ],
        "id": 19,
        "provinceID": 4,
        "firstLetter": "Z"
      },
      {
        "value": "140500",
        "title": "晋城市",
        "children": [
          {
            "value": "140502",
            "title": "城区",
            "id": 259,
            "firstLetter": "C"
          },
          {
            "value": "140521",
            "title": "沁水县",
            "id": 260,
            "firstLetter": "Q"
          },
          {
            "value": "140522",
            "title": "阳城县",
            "id": 261,
            "firstLetter": "Y"
          },
          {
            "value": "140524",
            "title": "陵川县",
            "id": 262,
            "firstLetter": "L"
          },
          {
            "value": "140525",
            "title": "泽州县",
            "id": 263,
            "firstLetter": "Z"
          },
          {
            "value": "140581",
            "title": "高平市",
            "id": 264,
            "firstLetter": "G"
          }
        ],
        "id": 20,
        "provinceID": 4,
        "firstLetter": "J"
      },
      {
        "value": "140600",
        "title": "朔州市",
        "children": [
          {
            "value": "140602",
            "title": "朔城区",
            "id": 266,
            "firstLetter": "S"
          },
          {
            "value": "140603",
            "title": "平鲁区",
            "id": 267,
            "firstLetter": "P"
          },
          {
            "value": "140621",
            "title": "山阴县",
            "id": 268,
            "firstLetter": "S"
          },
          {
            "value": "140622",
            "title": "应县",
            "id": 269,
            "firstLetter": "Y"
          },
          {
            "value": "140623",
            "title": "右玉县",
            "id": 270,
            "firstLetter": "Y"
          },
          {
            "value": "140681",
            "title": "怀仁市",
            "id": 4128,
            "firstLetter": "H"
          }
        ],
        "id": 21,
        "provinceID": 4,
        "firstLetter": "S"
      },
      {
        "value": "140700",
        "title": "晋中市",
        "children": [
          {
            "value": "140702",
            "title": "榆次区",
            "id": 273,
            "firstLetter": "Y"
          },
          {
            "value": "140721",
            "title": "榆社县",
            "id": 274,
            "firstLetter": "Y"
          },
          {
            "value": "140722",
            "title": "左权县",
            "id": 275,
            "firstLetter": "Z"
          },
          {
            "value": "140723",
            "title": "和顺县",
            "id": 276,
            "firstLetter": "H"
          },
          {
            "value": "140724",
            "title": "昔阳县",
            "id": 277,
            "firstLetter": "X"
          },
          {
            "value": "140725",
            "title": "寿阳县",
            "id": 278,
            "firstLetter": "S"
          },
          {
            "value": "140726",
            "title": "太谷县",
            "id": 279,
            "firstLetter": "T"
          },
          {
            "value": "140727",
            "title": "祁县",
            "id": 280,
            "firstLetter": "Q"
          },
          {
            "value": "140728",
            "title": "平遥县",
            "id": 281,
            "firstLetter": "P"
          },
          {
            "value": "140729",
            "title": "灵石县",
            "id": 282,
            "firstLetter": "L"
          },
          {
            "value": "140781",
            "title": "介休市",
            "id": 283,
            "firstLetter": "J"
          }
        ],
        "id": 22,
        "provinceID": 4,
        "firstLetter": "J"
      },
      {
        "value": "140800",
        "title": "运城市",
        "children": [
          {
            "value": "140802",
            "title": "盐湖区",
            "id": 285,
            "firstLetter": "Y"
          },
          {
            "value": "140821",
            "title": "临猗县",
            "id": 286,
            "firstLetter": "L"
          },
          {
            "value": "140822",
            "title": "万荣县",
            "id": 287,
            "firstLetter": "W"
          },
          {
            "value": "140823",
            "title": "闻喜县",
            "id": 288,
            "firstLetter": "W"
          },
          {
            "value": "140824",
            "title": "稷山县",
            "id": 289,
            "firstLetter": "J"
          },
          {
            "value": "140825",
            "title": "新绛县",
            "id": 290,
            "firstLetter": "X"
          },
          {
            "value": "140826",
            "title": "绛县",
            "id": 291,
            "firstLetter": "J"
          },
          {
            "value": "140827",
            "title": "垣曲县",
            "id": 292,
            "firstLetter": "Y"
          },
          {
            "value": "140828",
            "title": "夏县",
            "id": 293,
            "firstLetter": "X"
          },
          {
            "value": "140829",
            "title": "平陆县",
            "id": 294,
            "firstLetter": "P"
          },
          {
            "value": "140830",
            "title": "芮城县",
            "id": 295,
            "firstLetter": "R"
          },
          {
            "value": "140881",
            "title": "永济市",
            "id": 296,
            "firstLetter": "Y"
          },
          {
            "value": "140882",
            "title": "河津市",
            "id": 297,
            "firstLetter": "H"
          }
        ],
        "id": 23,
        "provinceID": 4,
        "firstLetter": "Y"
      },
      {
        "value": "140900",
        "title": "忻州市",
        "children": [
          {
            "value": "140902",
            "title": "忻府区",
            "id": 299,
            "firstLetter": "X"
          },
          {
            "value": "140921",
            "title": "定襄县",
            "id": 300,
            "firstLetter": "D"
          },
          {
            "value": "140922",
            "title": "五台县",
            "id": 301,
            "firstLetter": "W"
          },
          {
            "value": "140923",
            "title": "代县",
            "id": 302,
            "firstLetter": "D"
          },
          {
            "value": "140924",
            "title": "繁峙县",
            "id": 303,
            "firstLetter": "F"
          },
          {
            "value": "140925",
            "title": "宁武县",
            "id": 304,
            "firstLetter": "N"
          },
          {
            "value": "140926",
            "title": "静乐县",
            "id": 305,
            "firstLetter": "J"
          },
          {
            "value": "140927",
            "title": "神池县",
            "id": 306,
            "firstLetter": "S"
          },
          {
            "value": "140928",
            "title": "五寨县",
            "id": 307,
            "firstLetter": "W"
          },
          {
            "value": "140929",
            "title": "岢岚县",
            "id": 308,
            "firstLetter": "K"
          },
          {
            "value": "140930",
            "title": "河曲县",
            "id": 309,
            "firstLetter": "H"
          },
          {
            "value": "140931",
            "title": "保德县",
            "id": 310,
            "firstLetter": "B"
          },
          {
            "value": "140932",
            "title": "偏关县",
            "id": 311,
            "firstLetter": "P"
          },
          {
            "value": "140981",
            "title": "原平市",
            "id": 312,
            "firstLetter": "Y"
          }
        ],
        "id": 24,
        "provinceID": 4,
        "firstLetter": "X"
      },
      {
        "value": "141000",
        "title": "临汾市",
        "children": [
          {
            "value": "141002",
            "title": "尧都区",
            "id": 314,
            "firstLetter": "Y"
          },
          {
            "value": "141021",
            "title": "曲沃县",
            "id": 315,
            "firstLetter": "Q"
          },
          {
            "value": "141022",
            "title": "翼城县",
            "id": 316,
            "firstLetter": "Y"
          },
          {
            "value": "141023",
            "title": "襄汾县",
            "id": 317,
            "firstLetter": "X"
          },
          {
            "value": "141024",
            "title": "洪洞县",
            "id": 318,
            "firstLetter": "H"
          },
          {
            "value": "141025",
            "title": "古县",
            "id": 319,
            "firstLetter": "G"
          },
          {
            "value": "141026",
            "title": "安泽县",
            "id": 320,
            "firstLetter": "A"
          },
          {
            "value": "141027",
            "title": "浮山县",
            "id": 321,
            "firstLetter": "F"
          },
          {
            "value": "141028",
            "title": "吉县",
            "id": 322,
            "firstLetter": "J"
          },
          {
            "value": "141029",
            "title": "乡宁县",
            "id": 323,
            "firstLetter": "X"
          },
          {
            "value": "141030",
            "title": "大宁县",
            "id": 324,
            "firstLetter": "D"
          },
          {
            "value": "141031",
            "title": "隰县",
            "id": 325,
            "firstLetter": "X"
          },
          {
            "value": "141032",
            "title": "永和县",
            "id": 326,
            "firstLetter": "Y"
          },
          {
            "value": "141033",
            "title": "蒲县",
            "id": 327,
            "firstLetter": "P"
          },
          {
            "value": "141034",
            "title": "汾西县",
            "id": 328,
            "firstLetter": "F"
          },
          {
            "value": "141081",
            "title": "侯马市",
            "id": 329,
            "firstLetter": "H"
          },
          {
            "value": "141082",
            "title": "霍州市",
            "id": 330,
            "firstLetter": "H"
          }
        ],
        "id": 25,
        "provinceID": 4,
        "firstLetter": "L"
      },
      {
        "value": "141100",
        "title": "吕梁市",
        "children": [
          {
            "value": "141102",
            "title": "离石区",
            "id": 332,
            "firstLetter": "L"
          },
          {
            "value": "141121",
            "title": "文水县",
            "id": 333,
            "firstLetter": "W"
          },
          {
            "value": "141122",
            "title": "交城县",
            "id": 334,
            "firstLetter": "J"
          },
          {
            "value": "141123",
            "title": "兴县",
            "id": 335,
            "firstLetter": "X"
          },
          {
            "value": "141124",
            "title": "临县",
            "id": 336,
            "firstLetter": "L"
          },
          {
            "value": "141125",
            "title": "柳林县",
            "id": 337,
            "firstLetter": "L"
          },
          {
            "value": "141126",
            "title": "石楼县",
            "id": 338,
            "firstLetter": "S"
          },
          {
            "value": "141127",
            "title": "岚县",
            "id": 339,
            "firstLetter": "L"
          },
          {
            "value": "141128",
            "title": "方山县",
            "id": 340,
            "firstLetter": "F"
          },
          {
            "value": "141129",
            "title": "中阳县",
            "id": 341,
            "firstLetter": "Z"
          },
          {
            "value": "141130",
            "title": "交口县",
            "id": 342,
            "firstLetter": "J"
          },
          {
            "value": "141181",
            "title": "孝义市",
            "id": 343,
            "firstLetter": "X"
          },
          {
            "value": "141182",
            "title": "汾阳市",
            "id": 344,
            "firstLetter": "F"
          }
        ],
        "id": 26,
        "provinceID": 4,
        "firstLetter": "L"
      }
    ],
    "title": "山西省",
    "id": 4,
    "firstLetter": "S"
  },
  {
    "value": "150000",
    "children": [
      {
        "value": "150100",
        "title": "呼和浩特市",
        "children": [
          {
            "value": "150102",
            "title": "新城区",
            "id": 346,
            "firstLetter": "X"
          },
          {
            "value": "150103",
            "title": "回民区",
            "id": 347,
            "firstLetter": "H"
          },
          {
            "value": "150104",
            "title": "玉泉区",
            "id": 348,
            "firstLetter": "Y"
          },
          {
            "value": "150105",
            "title": "赛罕区",
            "id": 349,
            "firstLetter": "S"
          },
          {
            "value": "150121",
            "title": "土默特左旗",
            "id": 350,
            "firstLetter": "T"
          },
          {
            "value": "150122",
            "title": "托克托县",
            "id": 351,
            "firstLetter": "T"
          },
          {
            "value": "150123",
            "title": "和林格尔县",
            "id": 352,
            "firstLetter": "H"
          },
          {
            "value": "150124",
            "title": "清水河县",
            "id": 353,
            "firstLetter": "Q"
          },
          {
            "value": "150125",
            "title": "武川县",
            "id": 354,
            "firstLetter": "W"
          }
        ],
        "id": 27,
        "provinceID": 5,
        "firstLetter": "H"
      },
      {
        "value": "150200",
        "title": "包头市",
        "children": [
          {
            "value": "150202",
            "title": "东河区",
            "id": 356,
            "firstLetter": "D"
          },
          {
            "value": "150203",
            "title": "昆都仑区",
            "id": 357,
            "firstLetter": "K"
          },
          {
            "value": "150204",
            "title": "青山区",
            "id": 358,
            "firstLetter": "Q"
          },
          {
            "value": "150205",
            "title": "石拐区",
            "id": 359,
            "firstLetter": "S"
          },
          {
            "value": "150206",
            "title": "白云鄂博矿区",
            "id": 360,
            "firstLetter": "B"
          },
          {
            "value": "150207",
            "title": "九原区",
            "id": 361,
            "firstLetter": "J"
          },
          {
            "value": "150221",
            "title": "土默特右旗",
            "id": 362,
            "firstLetter": "T"
          },
          {
            "value": "150222",
            "title": "固阳县",
            "id": 363,
            "firstLetter": "G"
          },
          {
            "value": "150223",
            "title": "达尔罕茂明安联合旗",
            "id": 364,
            "firstLetter": "D"
          }
        ],
        "id": 28,
        "provinceID": 5,
        "firstLetter": "B"
      },
      {
        "value": "150300",
        "title": "乌海市",
        "children": [
          {
            "value": "150302",
            "title": "海勃湾区",
            "id": 366,
            "firstLetter": "H"
          },
          {
            "value": "150303",
            "title": "海南区",
            "id": 367,
            "firstLetter": "H"
          },
          {
            "value": "150304",
            "title": "乌达区",
            "id": 368,
            "firstLetter": "W"
          }
        ],
        "id": 29,
        "provinceID": 5,
        "firstLetter": "W"
      },
      {
        "value": "150400",
        "title": "赤峰市",
        "children": [
          {
            "value": "150402",
            "title": "红山区",
            "id": 370,
            "firstLetter": "H"
          },
          {
            "value": "150403",
            "title": "元宝山区",
            "id": 371,
            "firstLetter": "Y"
          },
          {
            "value": "150404",
            "title": "松山区",
            "id": 372,
            "firstLetter": "S"
          },
          {
            "value": "150421",
            "title": "阿鲁科尔沁旗",
            "id": 373,
            "firstLetter": "A"
          },
          {
            "value": "150422",
            "title": "巴林左旗",
            "id": 374,
            "firstLetter": "B"
          },
          {
            "value": "150423",
            "title": "巴林右旗",
            "id": 375,
            "firstLetter": "B"
          },
          {
            "value": "150424",
            "title": "林西县",
            "id": 376,
            "firstLetter": "L"
          },
          {
            "value": "150425",
            "title": "克什克腾旗",
            "id": 377,
            "firstLetter": "K"
          },
          {
            "value": "150426",
            "title": "翁牛特旗",
            "id": 378,
            "firstLetter": "W"
          },
          {
            "value": "150428",
            "title": "喀喇沁旗",
            "id": 379,
            "firstLetter": "K"
          },
          {
            "value": "150429",
            "title": "宁城县",
            "id": 380,
            "firstLetter": "N"
          },
          {
            "value": "150430",
            "title": "敖汉旗",
            "id": 381,
            "firstLetter": "A"
          }
        ],
        "id": 30,
        "provinceID": 5,
        "firstLetter": "C"
      },
      {
        "value": "150500",
        "title": "通辽市",
        "children": [
          {
            "value": "150502",
            "title": "科尔沁区",
            "id": 383,
            "firstLetter": "K"
          },
          {
            "value": "150521",
            "title": "科尔沁左翼中旗",
            "id": 384,
            "firstLetter": "K"
          },
          {
            "value": "150522",
            "title": "科尔沁左翼后旗",
            "id": 385,
            "firstLetter": "K"
          },
          {
            "value": "150523",
            "title": "开鲁县",
            "id": 386,
            "firstLetter": "K"
          },
          {
            "value": "150524",
            "title": "库伦旗",
            "id": 387,
            "firstLetter": "K"
          },
          {
            "value": "150525",
            "title": "奈曼旗",
            "id": 388,
            "firstLetter": "N"
          },
          {
            "value": "150526",
            "title": "扎鲁特旗",
            "id": 389,
            "firstLetter": "Z"
          },
          {
            "value": "150581",
            "title": "霍林郭勒市",
            "id": 390,
            "firstLetter": "H"
          }
        ],
        "id": 31,
        "provinceID": 5,
        "firstLetter": "T"
      },
      {
        "value": "150600",
        "title": "鄂尔多斯市",
        "children": [
          {
            "value": "150602",
            "title": "东胜区",
            "id": 392,
            "firstLetter": "D"
          },
          {
            "value": "150621",
            "title": "达拉特旗",
            "id": 393,
            "firstLetter": "D"
          },
          {
            "value": "150622",
            "title": "准格尔旗",
            "id": 394,
            "firstLetter": "Z"
          },
          {
            "value": "150623",
            "title": "鄂托克前旗",
            "id": 395,
            "firstLetter": "E"
          },
          {
            "value": "150624",
            "title": "鄂托克旗",
            "id": 396,
            "firstLetter": "E"
          },
          {
            "value": "150625",
            "title": "杭锦旗",
            "id": 397,
            "firstLetter": "H"
          },
          {
            "value": "150626",
            "title": "乌审旗",
            "id": 398,
            "firstLetter": "W"
          },
          {
            "value": "150627",
            "title": "伊金霍洛旗",
            "id": 399,
            "firstLetter": "Y"
          },
          {
            "value": "150603",
            "title": "康巴什区",
            "id": 4129,
            "firstLetter": "K"
          }
        ],
        "id": 32,
        "provinceID": 5,
        "firstLetter": "E"
      },
      {
        "value": "150700",
        "title": "呼伦贝尔市",
        "children": [
          {
            "value": "150702",
            "title": "海拉尔区",
            "id": 401,
            "firstLetter": "H"
          },
          {
            "value": "150703",
            "title": "扎赉诺尔区",
            "id": 402,
            "firstLetter": "Z"
          },
          {
            "value": "150721",
            "title": "阿荣旗",
            "id": 403,
            "firstLetter": "A"
          },
          {
            "value": "150722",
            "title": "莫力达瓦达斡尔族自治旗",
            "id": 404,
            "firstLetter": "M"
          },
          {
            "value": "150723",
            "title": "鄂伦春自治旗",
            "id": 405,
            "firstLetter": "E"
          },
          {
            "value": "150724",
            "title": "鄂温克族自治旗",
            "id": 406,
            "firstLetter": "E"
          },
          {
            "value": "150725",
            "title": "陈巴尔虎旗",
            "id": 407,
            "firstLetter": "C"
          },
          {
            "value": "150726",
            "title": "新巴尔虎左旗",
            "id": 408,
            "firstLetter": "X"
          },
          {
            "value": "150727",
            "title": "新巴尔虎右旗",
            "id": 409,
            "firstLetter": "X"
          },
          {
            "value": "150781",
            "title": "满洲里市",
            "id": 410,
            "firstLetter": "M"
          },
          {
            "value": "150782",
            "title": "牙克石市",
            "id": 411,
            "firstLetter": "Y"
          },
          {
            "value": "150783",
            "title": "扎兰屯市",
            "id": 412,
            "firstLetter": "Z"
          },
          {
            "value": "150784",
            "title": "额尔古纳市",
            "id": 413,
            "firstLetter": "E"
          },
          {
            "value": "150785",
            "title": "根河市",
            "id": 414,
            "firstLetter": "G"
          }
        ],
        "id": 33,
        "provinceID": 5,
        "firstLetter": "H"
      },
      {
        "value": "150800",
        "title": "巴彦淖尔市",
        "children": [
          {
            "value": "150802",
            "title": "临河区",
            "id": 416,
            "firstLetter": "L"
          },
          {
            "value": "150821",
            "title": "五原县",
            "id": 417,
            "firstLetter": "W"
          },
          {
            "value": "150822",
            "title": "磴口县",
            "id": 418,
            "firstLetter": "D"
          },
          {
            "value": "150823",
            "title": "乌拉特前旗",
            "id": 419,
            "firstLetter": "W"
          },
          {
            "value": "150824",
            "title": "乌拉特中旗",
            "id": 420,
            "firstLetter": "W"
          },
          {
            "value": "150825",
            "title": "乌拉特后旗",
            "id": 421,
            "firstLetter": "W"
          },
          {
            "value": "150826",
            "title": "杭锦后旗",
            "id": 422,
            "firstLetter": "H"
          }
        ],
        "id": 34,
        "provinceID": 5,
        "firstLetter": "B"
      },
      {
        "value": "150900",
        "title": "乌兰察布市",
        "children": [
          {
            "value": "150902",
            "title": "集宁区",
            "id": 424,
            "firstLetter": "J"
          },
          {
            "value": "150921",
            "title": "卓资县",
            "id": 425,
            "firstLetter": "Z"
          },
          {
            "value": "150922",
            "title": "化德县",
            "id": 426,
            "firstLetter": "H"
          },
          {
            "value": "150923",
            "title": "商都县",
            "id": 427,
            "firstLetter": "S"
          },
          {
            "value": "150924",
            "title": "兴和县",
            "id": 428,
            "firstLetter": "X"
          },
          {
            "value": "150925",
            "title": "凉城县",
            "id": 429,
            "firstLetter": "L"
          },
          {
            "value": "150926",
            "title": "察哈尔右翼前旗",
            "id": 430,
            "firstLetter": "C"
          },
          {
            "value": "150927",
            "title": "察哈尔右翼中旗",
            "id": 431,
            "firstLetter": "C"
          },
          {
            "value": "150928",
            "title": "察哈尔右翼后旗",
            "id": 432,
            "firstLetter": "C"
          },
          {
            "value": "150929",
            "title": "四子王旗",
            "id": 433,
            "firstLetter": "S"
          },
          {
            "value": "150981",
            "title": "丰镇市",
            "id": 434,
            "firstLetter": "F"
          }
        ],
        "id": 35,
        "provinceID": 5,
        "firstLetter": "W"
      },
      {
        "value": "152200",
        "title": "兴安盟",
        "children": [
          {
            "value": "152201",
            "title": "乌兰浩特市",
            "id": 435,
            "firstLetter": "W"
          },
          {
            "value": "152202",
            "title": "阿尔山市",
            "id": 436,
            "firstLetter": "A"
          },
          {
            "value": "152221",
            "title": "科尔沁右翼前旗",
            "id": 437,
            "firstLetter": "K"
          },
          {
            "value": "152222",
            "title": "科尔沁右翼中旗",
            "id": 438,
            "firstLetter": "K"
          },
          {
            "value": "152223",
            "title": "扎赉特旗",
            "id": 439,
            "firstLetter": "Z"
          },
          {
            "value": "152224",
            "title": "突泉县",
            "id": 440,
            "firstLetter": "T"
          }
        ],
        "id": 36,
        "provinceID": 5,
        "firstLetter": "X"
      },
      {
        "value": "152500",
        "title": "锡林郭勒盟",
        "children": [
          {
            "value": "152501",
            "title": "二连浩特市",
            "id": 441,
            "firstLetter": "E"
          },
          {
            "value": "152502",
            "title": "锡林浩特市",
            "id": 442,
            "firstLetter": "X"
          },
          {
            "value": "152522",
            "title": "阿巴嘎旗",
            "id": 443,
            "firstLetter": "A"
          },
          {
            "value": "152523",
            "title": "苏尼特左旗",
            "id": 444,
            "firstLetter": "S"
          },
          {
            "value": "152524",
            "title": "苏尼特右旗",
            "id": 445,
            "firstLetter": "S"
          },
          {
            "value": "152525",
            "title": "东乌珠穆沁旗",
            "id": 446,
            "firstLetter": "D"
          },
          {
            "value": "152526",
            "title": "西乌珠穆沁旗",
            "id": 447,
            "firstLetter": "X"
          },
          {
            "value": "152527",
            "title": "太仆寺旗",
            "id": 448,
            "firstLetter": "T"
          },
          {
            "value": "152528",
            "title": "镶黄旗",
            "id": 449,
            "firstLetter": "X"
          },
          {
            "value": "152529",
            "title": "正镶白旗",
            "id": 450,
            "firstLetter": "Z"
          },
          {
            "value": "152530",
            "title": "正蓝旗",
            "id": 451,
            "firstLetter": "Z"
          },
          {
            "value": "152531",
            "title": "多伦县",
            "id": 452,
            "firstLetter": "D"
          }
        ],
        "id": 37,
        "provinceID": 5,
        "firstLetter": "X"
      },
      {
        "value": "152900",
        "title": "阿拉善盟",
        "children": [
          {
            "value": "152921",
            "title": "阿拉善左旗",
            "id": 453,
            "firstLetter": "A"
          },
          {
            "value": "152922",
            "title": "阿拉善右旗",
            "id": 454,
            "firstLetter": "A"
          },
          {
            "value": "152923",
            "title": "额济纳旗",
            "id": 455,
            "firstLetter": "E"
          }
        ],
        "id": 38,
        "provinceID": 5,
        "firstLetter": "A"
      }
    ],
    "title": "内蒙古自治区",
    "id": 5,
    "firstLetter": "N"
  },
  {
    "value": "210000",
    "children": [
      {
        "value": "210100",
        "title": "沈阳市",
        "children": [
          {
            "value": "210102",
            "title": "和平区",
            "id": 457,
            "firstLetter": "H"
          },
          {
            "value": "210103",
            "title": "沈河区",
            "id": 458,
            "firstLetter": "S"
          },
          {
            "value": "210104",
            "title": "大东区",
            "id": 459,
            "firstLetter": "D"
          },
          {
            "value": "210105",
            "title": "皇姑区",
            "id": 460,
            "firstLetter": "H"
          },
          {
            "value": "210106",
            "title": "铁西区",
            "id": 461,
            "firstLetter": "T"
          },
          {
            "value": "210111",
            "title": "苏家屯区",
            "id": 462,
            "firstLetter": "S"
          },
          {
            "value": "210112",
            "title": "浑南区",
            "id": 463,
            "firstLetter": "H"
          },
          {
            "value": "210113",
            "title": "沈北新区",
            "id": 464,
            "firstLetter": "S"
          },
          {
            "value": "210114",
            "title": "于洪区",
            "id": 465,
            "firstLetter": "Y"
          },
          {
            "value": "210123",
            "title": "康平县",
            "id": 467,
            "firstLetter": "K"
          },
          {
            "value": "210124",
            "title": "法库县",
            "id": 468,
            "firstLetter": "F"
          },
          {
            "value": "210181",
            "title": "新民市",
            "id": 469,
            "firstLetter": "X"
          },
          {
            "value": "210115",
            "title": "辽中区",
            "id": 4130,
            "firstLetter": "L"
          }
        ],
        "id": 39,
        "provinceID": 6,
        "firstLetter": "S"
      },
      {
        "value": "210200",
        "title": "大连市",
        "children": [
          {
            "value": "210202",
            "title": "中山区",
            "id": 471,
            "firstLetter": "Z"
          },
          {
            "value": "210203",
            "title": "西岗区",
            "id": 472,
            "firstLetter": "X"
          },
          {
            "value": "210204",
            "title": "沙河口区",
            "id": 473,
            "firstLetter": "S"
          },
          {
            "value": "210211",
            "title": "甘井子区",
            "id": 474,
            "firstLetter": "G"
          },
          {
            "value": "210212",
            "title": "旅顺口区",
            "id": 475,
            "firstLetter": "L"
          },
          {
            "value": "210213",
            "title": "金州区",
            "id": 476,
            "firstLetter": "J"
          },
          {
            "value": "210224",
            "title": "长海县",
            "id": 477,
            "firstLetter": "Z"
          },
          {
            "value": "210281",
            "title": "瓦房店市",
            "id": 478,
            "firstLetter": "W"
          },
          {
            "value": "210283",
            "title": "庄河市",
            "id": 480,
            "firstLetter": "Z"
          },
          {
            "value": "210214",
            "title": "普兰店区",
            "id": 4131,
            "firstLetter": "P"
          }
        ],
        "id": 40,
        "provinceID": 6,
        "firstLetter": "D"
      },
      {
        "value": "210300",
        "title": "鞍山市",
        "children": [
          {
            "value": "210302",
            "title": "铁东区",
            "id": 482,
            "firstLetter": "T"
          },
          {
            "value": "210303",
            "title": "铁西区",
            "id": 483,
            "firstLetter": "T"
          },
          {
            "value": "210304",
            "title": "立山区",
            "id": 484,
            "firstLetter": "L"
          },
          {
            "value": "210311",
            "title": "千山区",
            "id": 485,
            "firstLetter": "Q"
          },
          {
            "value": "210321",
            "title": "台安县",
            "id": 486,
            "firstLetter": "T"
          },
          {
            "value": "210323",
            "title": "岫岩满族自治县",
            "id": 487,
            "firstLetter": "X"
          },
          {
            "value": "210381",
            "title": "海城市",
            "id": 488,
            "firstLetter": "H"
          }
        ],
        "id": 41,
        "provinceID": 6,
        "firstLetter": "A"
      },
      {
        "value": "210400",
        "title": "抚顺市",
        "children": [
          {
            "value": "210402",
            "title": "新抚区",
            "id": 490,
            "firstLetter": "X"
          },
          {
            "value": "210403",
            "title": "东洲区",
            "id": 491,
            "firstLetter": "D"
          },
          {
            "value": "210404",
            "title": "望花区",
            "id": 492,
            "firstLetter": "W"
          },
          {
            "value": "210411",
            "title": "顺城区",
            "id": 493,
            "firstLetter": "S"
          },
          {
            "value": "210421",
            "title": "抚顺县",
            "id": 494,
            "firstLetter": "F"
          },
          {
            "value": "210422",
            "title": "新宾满族自治县",
            "id": 495,
            "firstLetter": "X"
          },
          {
            "value": "210423",
            "title": "清原满族自治县",
            "id": 496,
            "firstLetter": "Q"
          }
        ],
        "id": 42,
        "provinceID": 6,
        "firstLetter": "F"
      },
      {
        "value": "210500",
        "title": "本溪市",
        "children": [
          {
            "value": "210502",
            "title": "平山区",
            "id": 498,
            "firstLetter": "P"
          },
          {
            "value": "210503",
            "title": "溪湖区",
            "id": 499,
            "firstLetter": "X"
          },
          {
            "value": "210504",
            "title": "明山区",
            "id": 500,
            "firstLetter": "M"
          },
          {
            "value": "210505",
            "title": "南芬区",
            "id": 501,
            "firstLetter": "N"
          },
          {
            "value": "210521",
            "title": "本溪满族自治县",
            "id": 502,
            "firstLetter": "B"
          },
          {
            "value": "210522",
            "title": "桓仁满族自治县",
            "id": 503,
            "firstLetter": "H"
          }
        ],
        "id": 43,
        "provinceID": 6,
        "firstLetter": "B"
      },
      {
        "value": "210600",
        "title": "丹东市",
        "children": [
          {
            "value": "210602",
            "title": "元宝区",
            "id": 505,
            "firstLetter": "Y"
          },
          {
            "value": "210603",
            "title": "振兴区",
            "id": 506,
            "firstLetter": "Z"
          },
          {
            "value": "210604",
            "title": "振安区",
            "id": 507,
            "firstLetter": "Z"
          },
          {
            "value": "210624",
            "title": "宽甸满族自治县",
            "id": 508,
            "firstLetter": "K"
          },
          {
            "value": "210681",
            "title": "东港市",
            "id": 509,
            "firstLetter": "D"
          },
          {
            "value": "210682",
            "title": "凤城市",
            "id": 510,
            "firstLetter": "F"
          }
        ],
        "id": 44,
        "provinceID": 6,
        "firstLetter": "D"
      },
      {
        "value": "210700",
        "title": "锦州市",
        "children": [
          {
            "value": "210702",
            "title": "古塔区",
            "id": 512,
            "firstLetter": "G"
          },
          {
            "value": "210703",
            "title": "凌河区",
            "id": 513,
            "firstLetter": "L"
          },
          {
            "value": "210711",
            "title": "太和区",
            "id": 514,
            "firstLetter": "T"
          },
          {
            "value": "210726",
            "title": "黑山县",
            "id": 515,
            "firstLetter": "H"
          },
          {
            "value": "210727",
            "title": "义县",
            "id": 516,
            "firstLetter": "Y"
          },
          {
            "value": "210781",
            "title": "凌海市",
            "id": 517,
            "firstLetter": "L"
          },
          {
            "value": "210782",
            "title": "北镇市",
            "id": 518,
            "firstLetter": "B"
          }
        ],
        "id": 45,
        "provinceID": 6,
        "firstLetter": "J"
      },
      {
        "value": "210800",
        "title": "营口市",
        "children": [
          {
            "value": "210802",
            "title": "站前区",
            "id": 520,
            "firstLetter": "Z"
          },
          {
            "value": "210803",
            "title": "西市区",
            "id": 521,
            "firstLetter": "X"
          },
          {
            "value": "210804",
            "title": "鲅鱼圈区",
            "id": 522,
            "firstLetter": "B"
          },
          {
            "value": "210811",
            "title": "老边区",
            "id": 523,
            "firstLetter": "L"
          },
          {
            "value": "210881",
            "title": "盖州市",
            "id": 524,
            "firstLetter": "G"
          },
          {
            "value": "210882",
            "title": "大石桥市",
            "id": 525,
            "firstLetter": "D"
          }
        ],
        "id": 46,
        "provinceID": 6,
        "firstLetter": "Y"
      },
      {
        "value": "210900",
        "title": "阜新市",
        "children": [
          {
            "value": "210902",
            "title": "海州区",
            "id": 527,
            "firstLetter": "H"
          },
          {
            "value": "210903",
            "title": "新邱区",
            "id": 528,
            "firstLetter": "X"
          },
          {
            "value": "210904",
            "title": "太平区",
            "id": 529,
            "firstLetter": "T"
          },
          {
            "value": "210905",
            "title": "清河门区",
            "id": 530,
            "firstLetter": "Q"
          },
          {
            "value": "210911",
            "title": "细河区",
            "id": 531,
            "firstLetter": "X"
          },
          {
            "value": "210921",
            "title": "阜新蒙古族自治县",
            "id": 532,
            "firstLetter": "F"
          },
          {
            "value": "210922",
            "title": "彰武县",
            "id": 533,
            "firstLetter": "Z"
          }
        ],
        "id": 47,
        "provinceID": 6,
        "firstLetter": "F"
      },
      {
        "value": "211000",
        "title": "辽阳市",
        "children": [
          {
            "value": "211002",
            "title": "白塔区",
            "id": 535,
            "firstLetter": "B"
          },
          {
            "value": "211003",
            "title": "文圣区",
            "id": 536,
            "firstLetter": "W"
          },
          {
            "value": "211004",
            "title": "宏伟区",
            "id": 537,
            "firstLetter": "H"
          },
          {
            "value": "211005",
            "title": "弓长岭区",
            "id": 538,
            "firstLetter": "G"
          },
          {
            "value": "211011",
            "title": "太子河区",
            "id": 539,
            "firstLetter": "T"
          },
          {
            "value": "211021",
            "title": "辽阳县",
            "id": 540,
            "firstLetter": "L"
          },
          {
            "value": "211081",
            "title": "灯塔市",
            "id": 541,
            "firstLetter": "D"
          }
        ],
        "id": 48,
        "provinceID": 6,
        "firstLetter": "L"
      },
      {
        "value": "211100",
        "title": "盘锦市",
        "children": [
          {
            "value": "211102",
            "title": "双台子区",
            "id": 543,
            "firstLetter": "S"
          },
          {
            "value": "211103",
            "title": "兴隆台区",
            "id": 544,
            "firstLetter": "X"
          },
          {
            "value": "211122",
            "title": "盘山县",
            "id": 546,
            "firstLetter": "P"
          },
          {
            "value": "211104",
            "title": "大洼区",
            "id": 4132,
            "firstLetter": "D"
          }
        ],
        "id": 49,
        "provinceID": 6,
        "firstLetter": "P"
      },
      {
        "value": "211200",
        "title": "铁岭市",
        "children": [
          {
            "value": "211202",
            "title": "银州区",
            "id": 548,
            "firstLetter": "Y"
          },
          {
            "value": "211204",
            "title": "清河区",
            "id": 549,
            "firstLetter": "Q"
          },
          {
            "value": "211221",
            "title": "铁岭县",
            "id": 550,
            "firstLetter": "T"
          },
          {
            "value": "211223",
            "title": "西丰县",
            "id": 551,
            "firstLetter": "X"
          },
          {
            "value": "211224",
            "title": "昌图县",
            "id": 552,
            "firstLetter": "C"
          },
          {
            "value": "211281",
            "title": "调兵山市",
            "id": 553,
            "firstLetter": "D"
          },
          {
            "value": "211282",
            "title": "开原市",
            "id": 554,
            "firstLetter": "K"
          }
        ],
        "id": 50,
        "provinceID": 6,
        "firstLetter": "T"
      },
      {
        "value": "211300",
        "title": "朝阳市",
        "children": [
          {
            "value": "211302",
            "title": "双塔区",
            "id": 556,
            "firstLetter": "S"
          },
          {
            "value": "211303",
            "title": "龙城区",
            "id": 557,
            "firstLetter": "L"
          },
          {
            "value": "211321",
            "title": "朝阳县",
            "id": 558,
            "firstLetter": "C"
          },
          {
            "value": "211322",
            "title": "建平县",
            "id": 559,
            "firstLetter": "J"
          },
          {
            "value": "211324",
            "title": "喀喇沁左翼蒙古族自治县",
            "id": 560,
            "firstLetter": "K"
          },
          {
            "value": "211381",
            "title": "北票市",
            "id": 561,
            "firstLetter": "B"
          },
          {
            "value": "211382",
            "title": "凌源市",
            "id": 562,
            "firstLetter": "L"
          }
        ],
        "id": 51,
        "provinceID": 6,
        "firstLetter": "C"
      },
      {
        "value": "211400",
        "title": "葫芦岛市",
        "children": [
          {
            "value": "211402",
            "title": "连山区",
            "id": 564,
            "firstLetter": "L"
          },
          {
            "value": "211403",
            "title": "龙港区",
            "id": 565,
            "firstLetter": "L"
          },
          {
            "value": "211404",
            "title": "南票区",
            "id": 566,
            "firstLetter": "N"
          },
          {
            "value": "211421",
            "title": "绥中县",
            "id": 567,
            "firstLetter": "S"
          },
          {
            "value": "211422",
            "title": "建昌县",
            "id": 568,
            "firstLetter": "J"
          },
          {
            "value": "211481",
            "title": "兴城市",
            "id": 569,
            "firstLetter": "X"
          }
        ],
        "id": 52,
        "provinceID": 6,
        "firstLetter": "H"
      }
    ],
    "title": "辽宁省",
    "id": 6,
    "firstLetter": "L"
  },
  {
    "value": "220000",
    "children": [
      {
        "value": "220100",
        "title": "长春市",
        "children": [
          {
            "value": "220102",
            "title": "南关区",
            "id": 571,
            "firstLetter": "N"
          },
          {
            "value": "220103",
            "title": "宽城区",
            "id": 572,
            "firstLetter": "K"
          },
          {
            "value": "220104",
            "title": "朝阳区",
            "id": 573,
            "firstLetter": "C"
          },
          {
            "value": "220105",
            "title": "二道区",
            "id": 574,
            "firstLetter": "E"
          },
          {
            "value": "220106",
            "title": "绿园区",
            "id": 575,
            "firstLetter": "L"
          },
          {
            "value": "220112",
            "title": "双阳区",
            "id": 576,
            "firstLetter": "S"
          },
          {
            "value": "220113",
            "title": "九台区",
            "id": 577,
            "firstLetter": "J"
          },
          {
            "value": "220122",
            "title": "农安县",
            "id": 578,
            "firstLetter": "N"
          },
          {
            "value": "220182",
            "title": "榆树市",
            "id": 579,
            "firstLetter": "Y"
          },
          {
            "value": "220183",
            "title": "德惠市",
            "id": 580,
            "firstLetter": "D"
          }
        ],
        "id": 53,
        "provinceID": 7,
        "firstLetter": "Z"
      },
      {
        "value": "220200",
        "title": "吉林市",
        "children": [
          {
            "value": "220202",
            "title": "昌邑区",
            "id": 582,
            "firstLetter": "C"
          },
          {
            "value": "220203",
            "title": "龙潭区",
            "id": 583,
            "firstLetter": "L"
          },
          {
            "value": "220204",
            "title": "船营区",
            "id": 584,
            "firstLetter": "C"
          },
          {
            "value": "220211",
            "title": "丰满区",
            "id": 585,
            "firstLetter": "F"
          },
          {
            "value": "220221",
            "title": "永吉县",
            "id": 586,
            "firstLetter": "Y"
          },
          {
            "value": "220281",
            "title": "蛟河市",
            "id": 587,
            "firstLetter": "J"
          },
          {
            "value": "220282",
            "title": "桦甸市",
            "id": 588,
            "firstLetter": "H"
          },
          {
            "value": "220283",
            "title": "舒兰市",
            "id": 589,
            "firstLetter": "S"
          },
          {
            "value": "220284",
            "title": "磐石市",
            "id": 590,
            "firstLetter": "P"
          }
        ],
        "id": 54,
        "provinceID": 7,
        "firstLetter": "J"
      },
      {
        "value": "220300",
        "title": "四平市",
        "children": [
          {
            "value": "220302",
            "title": "铁西区",
            "id": 592,
            "firstLetter": "T"
          },
          {
            "value": "220303",
            "title": "铁东区",
            "id": 593,
            "firstLetter": "T"
          },
          {
            "value": "220322",
            "title": "梨树县",
            "id": 594,
            "firstLetter": "L"
          },
          {
            "value": "220323",
            "title": "伊通满族自治县",
            "id": 595,
            "firstLetter": "Y"
          },
          {
            "value": "220381",
            "title": "公主岭市",
            "id": 596,
            "firstLetter": "G"
          },
          {
            "value": "220382",
            "title": "双辽市",
            "id": 597,
            "firstLetter": "S"
          }
        ],
        "id": 55,
        "provinceID": 7,
        "firstLetter": "S"
      },
      {
        "value": "220400",
        "title": "辽源市",
        "children": [
          {
            "value": "220402",
            "title": "龙山区",
            "id": 599,
            "firstLetter": "L"
          },
          {
            "value": "220403",
            "title": "西安区",
            "id": 600,
            "firstLetter": "X"
          },
          {
            "value": "220421",
            "title": "东丰县",
            "id": 601,
            "firstLetter": "D"
          },
          {
            "value": "220422",
            "title": "东辽县",
            "id": 602,
            "firstLetter": "D"
          }
        ],
        "id": 56,
        "provinceID": 7,
        "firstLetter": "L"
      },
      {
        "value": "220500",
        "title": "通化市",
        "children": [
          {
            "value": "220502",
            "title": "东昌区",
            "id": 604,
            "firstLetter": "D"
          },
          {
            "value": "220503",
            "title": "二道江区",
            "id": 605,
            "firstLetter": "E"
          },
          {
            "value": "220521",
            "title": "通化县",
            "id": 606,
            "firstLetter": "T"
          },
          {
            "value": "220523",
            "title": "辉南县",
            "id": 607,
            "firstLetter": "H"
          },
          {
            "value": "220524",
            "title": "柳河县",
            "id": 608,
            "firstLetter": "L"
          },
          {
            "value": "220581",
            "title": "梅河口市",
            "id": 609,
            "firstLetter": "M"
          },
          {
            "value": "220582",
            "title": "集安市",
            "id": 610,
            "firstLetter": "J"
          }
        ],
        "id": 57,
        "provinceID": 7,
        "firstLetter": "T"
      },
      {
        "value": "220600",
        "title": "白山市",
        "children": [
          {
            "value": "220602",
            "title": "浑江区",
            "id": 612,
            "firstLetter": "H"
          },
          {
            "value": "220605",
            "title": "江源区",
            "id": 613,
            "firstLetter": "J"
          },
          {
            "value": "220621",
            "title": "抚松县",
            "id": 614,
            "firstLetter": "F"
          },
          {
            "value": "220622",
            "title": "靖宇县",
            "id": 615,
            "firstLetter": "J"
          },
          {
            "value": "220623",
            "title": "长白朝鲜族自治县",
            "id": 616,
            "firstLetter": "Z"
          },
          {
            "value": "220681",
            "title": "临江市",
            "id": 617,
            "firstLetter": "L"
          }
        ],
        "id": 58,
        "provinceID": 7,
        "firstLetter": "B"
      },
      {
        "value": "220700",
        "title": "松原市",
        "children": [
          {
            "value": "220702",
            "title": "宁江区",
            "id": 619,
            "firstLetter": "N"
          },
          {
            "value": "220721",
            "title": "前郭尔罗斯蒙古族自治县",
            "id": 620,
            "firstLetter": "Q"
          },
          {
            "value": "220722",
            "title": "长岭县",
            "id": 621,
            "firstLetter": "Z"
          },
          {
            "value": "220723",
            "title": "乾安县",
            "id": 622,
            "firstLetter": "Q"
          },
          {
            "value": "220781",
            "title": "扶余市",
            "id": 623,
            "firstLetter": "F"
          }
        ],
        "id": 59,
        "provinceID": 7,
        "firstLetter": "S"
      },
      {
        "value": "220800",
        "title": "白城市",
        "children": [
          {
            "value": "220802",
            "title": "洮北区",
            "id": 625,
            "firstLetter": "T"
          },
          {
            "value": "220821",
            "title": "镇赉县",
            "id": 626,
            "firstLetter": "Z"
          },
          {
            "value": "220822",
            "title": "通榆县",
            "id": 627,
            "firstLetter": "T"
          },
          {
            "value": "220881",
            "title": "洮南市",
            "id": 628,
            "firstLetter": "T"
          },
          {
            "value": "220882",
            "title": "大安市",
            "id": 629,
            "firstLetter": "D"
          }
        ],
        "id": 60,
        "provinceID": 7,
        "firstLetter": "B"
      },
      {
        "value": "222400",
        "title": "延边朝鲜族自治州",
        "children": [
          {
            "value": "222401",
            "title": "延吉市",
            "id": 630,
            "firstLetter": "Y"
          },
          {
            "value": "222402",
            "title": "图们市",
            "id": 631,
            "firstLetter": "T"
          },
          {
            "value": "222403",
            "title": "敦化市",
            "id": 632,
            "firstLetter": "D"
          },
          {
            "value": "222404",
            "title": "珲春市",
            "id": 633,
            "firstLetter": "H"
          },
          {
            "value": "222405",
            "title": "龙井市",
            "id": 634,
            "firstLetter": "L"
          },
          {
            "value": "222406",
            "title": "和龙市",
            "id": 635,
            "firstLetter": "H"
          },
          {
            "value": "222424",
            "title": "汪清县",
            "id": 636,
            "firstLetter": "W"
          },
          {
            "value": "222426",
            "title": "安图县",
            "id": 637,
            "firstLetter": "A"
          }
        ],
        "id": 61,
        "provinceID": 7,
        "firstLetter": "Y"
      }
    ],
    "title": "吉林省",
    "id": 7,
    "firstLetter": "J"
  },
  {
    "value": "230000",
    "children": [
      {
        "value": "230100",
        "title": "哈尔滨市",
        "children": [
          {
            "value": "230102",
            "title": "道里区",
            "id": 639,
            "firstLetter": "D"
          },
          {
            "value": "230103",
            "title": "南岗区",
            "id": 640,
            "firstLetter": "N"
          },
          {
            "value": "230104",
            "title": "道外区",
            "id": 641,
            "firstLetter": "D"
          },
          {
            "value": "230108",
            "title": "平房区",
            "id": 642,
            "firstLetter": "P"
          },
          {
            "value": "230109",
            "title": "松北区",
            "id": 643,
            "firstLetter": "S"
          },
          {
            "value": "230110",
            "title": "香坊区",
            "id": 644,
            "firstLetter": "X"
          },
          {
            "value": "230111",
            "title": "呼兰区",
            "id": 645,
            "firstLetter": "H"
          },
          {
            "value": "230112",
            "title": "阿城区",
            "id": 646,
            "firstLetter": "A"
          },
          {
            "value": "230123",
            "title": "依兰县",
            "id": 647,
            "firstLetter": "Y"
          },
          {
            "value": "230124",
            "title": "方正县",
            "id": 648,
            "firstLetter": "F"
          },
          {
            "value": "230125",
            "title": "宾县",
            "id": 649,
            "firstLetter": "B"
          },
          {
            "value": "230126",
            "title": "巴彦县",
            "id": 650,
            "firstLetter": "B"
          },
          {
            "value": "230127",
            "title": "木兰县",
            "id": 651,
            "firstLetter": "M"
          },
          {
            "value": "230128",
            "title": "通河县",
            "id": 652,
            "firstLetter": "T"
          },
          {
            "value": "230129",
            "title": "延寿县",
            "id": 653,
            "firstLetter": "Y"
          },
          {
            "value": "230183",
            "title": "尚志市",
            "id": 655,
            "firstLetter": "S"
          },
          {
            "value": "230184",
            "title": "五常市",
            "id": 656,
            "firstLetter": "W"
          },
          {
            "value": "230113",
            "title": "双城区",
            "id": 4133,
            "firstLetter": "S"
          }
        ],
        "id": 62,
        "provinceID": 8,
        "firstLetter": "H"
      },
      {
        "value": "230200",
        "title": "齐齐哈尔市",
        "children": [
          {
            "value": "230202",
            "title": "龙沙区",
            "id": 658,
            "firstLetter": "L"
          },
          {
            "value": "230203",
            "title": "建华区",
            "id": 659,
            "firstLetter": "J"
          },
          {
            "value": "230204",
            "title": "铁锋区",
            "id": 660,
            "firstLetter": "T"
          },
          {
            "value": "230205",
            "title": "昂昂溪区",
            "id": 661,
            "firstLetter": "A"
          },
          {
            "value": "230206",
            "title": "富拉尔基区",
            "id": 662,
            "firstLetter": "F"
          },
          {
            "value": "230207",
            "title": "碾子山区",
            "id": 663,
            "firstLetter": "N"
          },
          {
            "value": "230208",
            "title": "梅里斯达斡尔族区",
            "id": 664,
            "firstLetter": "M"
          },
          {
            "value": "230221",
            "title": "龙江县",
            "id": 665,
            "firstLetter": "L"
          },
          {
            "value": "230223",
            "title": "依安县",
            "id": 666,
            "firstLetter": "Y"
          },
          {
            "value": "230224",
            "title": "泰来县",
            "id": 667,
            "firstLetter": "T"
          },
          {
            "value": "230225",
            "title": "甘南县",
            "id": 668,
            "firstLetter": "G"
          },
          {
            "value": "230227",
            "title": "富裕县",
            "id": 669,
            "firstLetter": "F"
          },
          {
            "value": "230229",
            "title": "克山县",
            "id": 670,
            "firstLetter": "K"
          },
          {
            "value": "230230",
            "title": "克东县",
            "id": 671,
            "firstLetter": "K"
          },
          {
            "value": "230231",
            "title": "拜泉县",
            "id": 672,
            "firstLetter": "B"
          },
          {
            "value": "230281",
            "title": "讷河市",
            "id": 673,
            "firstLetter": "N"
          }
        ],
        "id": 63,
        "provinceID": 8,
        "firstLetter": "Q"
      },
      {
        "value": "230300",
        "title": "鸡西市",
        "children": [
          {
            "value": "230302",
            "title": "鸡冠区",
            "id": 675,
            "firstLetter": "J"
          },
          {
            "value": "230303",
            "title": "恒山区",
            "id": 676,
            "firstLetter": "H"
          },
          {
            "value": "230304",
            "title": "滴道区",
            "id": 677,
            "firstLetter": "D"
          },
          {
            "value": "230305",
            "title": "梨树区",
            "id": 678,
            "firstLetter": "L"
          },
          {
            "value": "230306",
            "title": "城子河区",
            "id": 679,
            "firstLetter": "C"
          },
          {
            "value": "230307",
            "title": "麻山区",
            "id": 680,
            "firstLetter": "M"
          },
          {
            "value": "230321",
            "title": "鸡东县",
            "id": 681,
            "firstLetter": "J"
          },
          {
            "value": "230381",
            "title": "虎林市",
            "id": 682,
            "firstLetter": "H"
          },
          {
            "value": "230382",
            "title": "密山市",
            "id": 683,
            "firstLetter": "M"
          }
        ],
        "id": 64,
        "provinceID": 8,
        "firstLetter": "J"
      },
      {
        "value": "230400",
        "title": "鹤岗市",
        "children": [
          {
            "value": "230402",
            "title": "向阳区",
            "id": 685,
            "firstLetter": "X"
          },
          {
            "value": "230403",
            "title": "工农区",
            "id": 686,
            "firstLetter": "G"
          },
          {
            "value": "230404",
            "title": "南山区",
            "id": 687,
            "firstLetter": "N"
          },
          {
            "value": "230405",
            "title": "兴安区",
            "id": 688,
            "firstLetter": "X"
          },
          {
            "value": "230406",
            "title": "东山区",
            "id": 689,
            "firstLetter": "D"
          },
          {
            "value": "230407",
            "title": "兴山区",
            "id": 690,
            "firstLetter": "X"
          },
          {
            "value": "230421",
            "title": "萝北县",
            "id": 691,
            "firstLetter": "L"
          },
          {
            "value": "230422",
            "title": "绥滨县",
            "id": 692,
            "firstLetter": "S"
          }
        ],
        "id": 65,
        "provinceID": 8,
        "firstLetter": "H"
      },
      {
        "value": "230500",
        "title": "双鸭山市",
        "children": [
          {
            "value": "230502",
            "title": "尖山区",
            "id": 694,
            "firstLetter": "J"
          },
          {
            "value": "230503",
            "title": "岭东区",
            "id": 695,
            "firstLetter": "L"
          },
          {
            "value": "230505",
            "title": "四方台区",
            "id": 696,
            "firstLetter": "S"
          },
          {
            "value": "230506",
            "title": "宝山区",
            "id": 697,
            "firstLetter": "B"
          },
          {
            "value": "230521",
            "title": "集贤县",
            "id": 698,
            "firstLetter": "J"
          },
          {
            "value": "230522",
            "title": "友谊县",
            "id": 699,
            "firstLetter": "Y"
          },
          {
            "value": "230523",
            "title": "宝清县",
            "id": 700,
            "firstLetter": "B"
          },
          {
            "value": "230524",
            "title": "饶河县",
            "id": 701,
            "firstLetter": "R"
          }
        ],
        "id": 66,
        "provinceID": 8,
        "firstLetter": "S"
      },
      {
        "value": "230600",
        "title": "大庆市",
        "children": [
          {
            "value": "230602",
            "title": "萨尔图区",
            "id": 703,
            "firstLetter": "S"
          },
          {
            "value": "230603",
            "title": "龙凤区",
            "id": 704,
            "firstLetter": "L"
          },
          {
            "value": "230604",
            "title": "让胡路区",
            "id": 705,
            "firstLetter": "R"
          },
          {
            "value": "230605",
            "title": "红岗区",
            "id": 706,
            "firstLetter": "H"
          },
          {
            "value": "230606",
            "title": "大同区",
            "id": 707,
            "firstLetter": "D"
          },
          {
            "value": "230621",
            "title": "肇州县",
            "id": 708,
            "firstLetter": "Z"
          },
          {
            "value": "230622",
            "title": "肇源县",
            "id": 709,
            "firstLetter": "Z"
          },
          {
            "value": "230623",
            "title": "林甸县",
            "id": 710,
            "firstLetter": "L"
          },
          {
            "value": "230624",
            "title": "杜尔伯特蒙古族自治县",
            "id": 711,
            "firstLetter": "D"
          }
        ],
        "id": 67,
        "provinceID": 8,
        "firstLetter": "D"
      },
      {
        "value": "230700",
        "title": "伊春市",
        "children": [
          {
            "value": "230702",
            "title": "伊春区",
            "id": 713,
            "firstLetter": "Y"
          },
          {
            "value": "230703",
            "title": "南岔区",
            "id": 714,
            "firstLetter": "N"
          },
          {
            "value": "230704",
            "title": "友好区",
            "id": 715,
            "firstLetter": "Y"
          },
          {
            "value": "230705",
            "title": "西林区",
            "id": 716,
            "firstLetter": "X"
          },
          {
            "value": "230706",
            "title": "翠峦区",
            "id": 717,
            "firstLetter": "C"
          },
          {
            "value": "230707",
            "title": "新青区",
            "id": 718,
            "firstLetter": "X"
          },
          {
            "value": "230708",
            "title": "美溪区",
            "id": 719,
            "firstLetter": "M"
          },
          {
            "value": "230709",
            "title": "金山屯区",
            "id": 720,
            "firstLetter": "J"
          },
          {
            "value": "230710",
            "title": "五营区",
            "id": 721,
            "firstLetter": "W"
          },
          {
            "value": "230711",
            "title": "乌马河区",
            "id": 722,
            "firstLetter": "W"
          },
          {
            "value": "230712",
            "title": "汤旺河区",
            "id": 723,
            "firstLetter": "T"
          },
          {
            "value": "230713",
            "title": "带岭区",
            "id": 724,
            "firstLetter": "D"
          },
          {
            "value": "230714",
            "title": "乌伊岭区",
            "id": 725,
            "firstLetter": "W"
          },
          {
            "value": "230715",
            "title": "红星区",
            "id": 726,
            "firstLetter": "H"
          },
          {
            "value": "230716",
            "title": "上甘岭区",
            "id": 727,
            "firstLetter": "S"
          },
          {
            "value": "230722",
            "title": "嘉荫县",
            "id": 728,
            "firstLetter": "J"
          },
          {
            "value": "230781",
            "title": "铁力市",
            "id": 729,
            "firstLetter": "T"
          }
        ],
        "id": 68,
        "provinceID": 8,
        "firstLetter": "Y"
      },
      {
        "value": "230800",
        "title": "佳木斯市",
        "children": [
          {
            "value": "230803",
            "title": "向阳区",
            "id": 731,
            "firstLetter": "X"
          },
          {
            "value": "230804",
            "title": "前进区",
            "id": 732,
            "firstLetter": "Q"
          },
          {
            "value": "230805",
            "title": "东风区",
            "id": 733,
            "firstLetter": "D"
          },
          {
            "value": "230811",
            "title": "郊区",
            "id": 734,
            "firstLetter": "J"
          },
          {
            "value": "230822",
            "title": "桦南县",
            "id": 735,
            "firstLetter": "H"
          },
          {
            "value": "230826",
            "title": "桦川县",
            "id": 736,
            "firstLetter": "H"
          },
          {
            "value": "230828",
            "title": "汤原县",
            "id": 737,
            "firstLetter": "T"
          },
          {
            "value": "230881",
            "title": "同江市",
            "id": 739,
            "firstLetter": "T"
          },
          {
            "value": "230882",
            "title": "富锦市",
            "id": 740,
            "firstLetter": "F"
          },
          {
            "value": "230883",
            "title": "抚远市",
            "id": 4134,
            "firstLetter": "F"
          }
        ],
        "id": 69,
        "provinceID": 8,
        "firstLetter": "J"
      },
      {
        "value": "230900",
        "title": "七台河市",
        "children": [
          {
            "value": "230902",
            "title": "新兴区",
            "id": 742,
            "firstLetter": "X"
          },
          {
            "value": "230903",
            "title": "桃山区",
            "id": 743,
            "firstLetter": "T"
          },
          {
            "value": "230904",
            "title": "茄子河区",
            "id": 744,
            "firstLetter": "Q"
          },
          {
            "value": "230921",
            "title": "勃利县",
            "id": 745,
            "firstLetter": "B"
          }
        ],
        "id": 70,
        "provinceID": 8,
        "firstLetter": "Q"
      },
      {
        "value": "231000",
        "title": "牡丹江市",
        "children": [
          {
            "value": "231002",
            "title": "东安区",
            "id": 747,
            "firstLetter": "D"
          },
          {
            "value": "231003",
            "title": "阳明区",
            "id": 748,
            "firstLetter": "Y"
          },
          {
            "value": "231004",
            "title": "爱民区",
            "id": 749,
            "firstLetter": "A"
          },
          {
            "value": "231005",
            "title": "西安区",
            "id": 750,
            "firstLetter": "X"
          },
          {
            "value": "231025",
            "title": "林口县",
            "id": 752,
            "firstLetter": "L"
          },
          {
            "value": "231081",
            "title": "绥芬河市",
            "id": 753,
            "firstLetter": "S"
          },
          {
            "value": "231083",
            "title": "海林市",
            "id": 754,
            "firstLetter": "H"
          },
          {
            "value": "231084",
            "title": "宁安市",
            "id": 755,
            "firstLetter": "N"
          },
          {
            "value": "231085",
            "title": "穆棱市",
            "id": 756,
            "firstLetter": "M"
          },
          {
            "value": "231086",
            "title": "东宁市",
            "id": 4135,
            "firstLetter": "D"
          }
        ],
        "id": 71,
        "provinceID": 8,
        "firstLetter": "M"
      },
      {
        "value": "231100",
        "title": "黑河市",
        "children": [
          {
            "value": "231102",
            "title": "爱辉区",
            "id": 758,
            "firstLetter": "A"
          },
          {
            "value": "231121",
            "title": "嫩江县",
            "id": 759,
            "firstLetter": "N"
          },
          {
            "value": "231123",
            "title": "逊克县",
            "id": 760,
            "firstLetter": "X"
          },
          {
            "value": "231124",
            "title": "孙吴县",
            "id": 761,
            "firstLetter": "S"
          },
          {
            "value": "231181",
            "title": "北安市",
            "id": 762,
            "firstLetter": "B"
          },
          {
            "value": "231182",
            "title": "五大连池市",
            "id": 763,
            "firstLetter": "W"
          }
        ],
        "id": 72,
        "provinceID": 8,
        "firstLetter": "H"
      },
      {
        "value": "231200",
        "title": "绥化市",
        "children": [
          {
            "value": "231202",
            "title": "北林区",
            "id": 765,
            "firstLetter": "B"
          },
          {
            "value": "231221",
            "title": "望奎县",
            "id": 766,
            "firstLetter": "W"
          },
          {
            "value": "231222",
            "title": "兰西县",
            "id": 767,
            "firstLetter": "L"
          },
          {
            "value": "231223",
            "title": "青冈县",
            "id": 768,
            "firstLetter": "Q"
          },
          {
            "value": "231224",
            "title": "庆安县",
            "id": 769,
            "firstLetter": "Q"
          },
          {
            "value": "231225",
            "title": "明水县",
            "id": 770,
            "firstLetter": "M"
          },
          {
            "value": "231226",
            "title": "绥棱县",
            "id": 771,
            "firstLetter": "S"
          },
          {
            "value": "231281",
            "title": "安达市",
            "id": 772,
            "firstLetter": "A"
          },
          {
            "value": "231282",
            "title": "肇东市",
            "id": 773,
            "firstLetter": "Z"
          },
          {
            "value": "231283",
            "title": "海伦市",
            "id": 774,
            "firstLetter": "H"
          }
        ],
        "id": 73,
        "provinceID": 8,
        "firstLetter": "S"
      },
      {
        "value": "232700",
        "title": "大兴安岭地区",
        "children": [
          {
            "value": "232721",
            "title": "呼玛县",
            "id": 775,
            "firstLetter": "H"
          },
          {
            "value": "232722",
            "title": "塔河县",
            "id": 776,
            "firstLetter": "T"
          },
          {
            "value": "232701",
            "title": "漠河市",
            "id": 4136,
            "firstLetter": "M"
          },
          {
            "value": "232761",
            "title": "加格达奇区",
            "id": 4137,
            "firstLetter": "J"
          },
          {
            "value": "232762",
            "title": "松岭区",
            "id": 4138,
            "firstLetter": "S"
          },
          {
            "value": "232763",
            "title": "新林区",
            "id": 4139,
            "firstLetter": "X"
          },
          {
            "value": "232764",
            "title": "呼中区",
            "id": 4140,
            "firstLetter": "H"
          }
        ],
        "id": 74,
        "provinceID": 8,
        "firstLetter": "D"
      }
    ],
    "title": "黑龙江省",
    "id": 8,
    "firstLetter": "H"
  },
  {
    "value": "310000",
    "children": [
      {
        "value": "310100",
        "title": "上海市",
        "children": [
          {
            "value": "310101",
            "title": "黄浦区",
            "id": 778,
            "firstLetter": "H"
          },
          {
            "value": "310104",
            "title": "徐汇区",
            "id": 779,
            "firstLetter": "X"
          },
          {
            "value": "310105",
            "title": "长宁区",
            "id": 780,
            "firstLetter": "Z"
          },
          {
            "value": "310106",
            "title": "静安区",
            "id": 781,
            "firstLetter": "J"
          },
          {
            "value": "310107",
            "title": "普陀区",
            "id": 782,
            "firstLetter": "P"
          },
          {
            "value": "310109",
            "title": "虹口区",
            "id": 784,
            "firstLetter": "H"
          },
          {
            "value": "310110",
            "title": "杨浦区",
            "id": 785,
            "firstLetter": "Y"
          },
          {
            "value": "310112",
            "title": "闵行区",
            "id": 786,
            "firstLetter": "M"
          },
          {
            "value": "310113",
            "title": "宝山区",
            "id": 787,
            "firstLetter": "B"
          },
          {
            "value": "310114",
            "title": "嘉定区",
            "id": 788,
            "firstLetter": "J"
          },
          {
            "value": "310115",
            "title": "浦东新区",
            "id": 789,
            "firstLetter": "P"
          },
          {
            "value": "310116",
            "title": "金山区",
            "id": 790,
            "firstLetter": "J"
          },
          {
            "value": "310117",
            "title": "松江区",
            "id": 791,
            "firstLetter": "S"
          },
          {
            "value": "310118",
            "title": "青浦区",
            "id": 792,
            "firstLetter": "Q"
          },
          {
            "value": "310120",
            "title": "奉贤区",
            "id": 793,
            "firstLetter": "F"
          },
          {
            "value": "310151",
            "title": "崇明区",
            "id": 4141,
            "firstLetter": "C"
          }
        ],
        "id": 75,
        "provinceID": 9,
        "firstLetter": "S"
      }
    ],
    "title": "上海市",
    "id": 9,
    "firstLetter": "S"
  },
  {
    "value": "320000",
    "children": [
      {
        "value": "320100",
        "title": "南京市",
        "children": [
          {
            "value": "320102",
            "title": "玄武区",
            "id": 796,
            "firstLetter": "X"
          },
          {
            "value": "320104",
            "title": "秦淮区",
            "id": 797,
            "firstLetter": "Q"
          },
          {
            "value": "320105",
            "title": "建邺区",
            "id": 798,
            "firstLetter": "J"
          },
          {
            "value": "320106",
            "title": "鼓楼区",
            "id": 799,
            "firstLetter": "G"
          },
          {
            "value": "320111",
            "title": "浦口区",
            "id": 800,
            "firstLetter": "P"
          },
          {
            "value": "320113",
            "title": "栖霞区",
            "id": 801,
            "firstLetter": "Q"
          },
          {
            "value": "320114",
            "title": "雨花台区",
            "id": 802,
            "firstLetter": "Y"
          },
          {
            "value": "320115",
            "title": "江宁区",
            "id": 803,
            "firstLetter": "J"
          },
          {
            "value": "320116",
            "title": "六合区",
            "id": 804,
            "firstLetter": "L"
          },
          {
            "value": "320117",
            "title": "溧水区",
            "id": 805,
            "firstLetter": "L"
          },
          {
            "value": "320118",
            "title": "高淳区",
            "id": 806,
            "firstLetter": "G"
          }
        ],
        "id": 77,
        "provinceID": 10,
        "firstLetter": "N"
      },
      {
        "value": "320200",
        "title": "无锡市",
        "children": [
          {
            "value": "320205",
            "title": "锡山区",
            "id": 811,
            "firstLetter": "X"
          },
          {
            "value": "320206",
            "title": "惠山区",
            "id": 812,
            "firstLetter": "H"
          },
          {
            "value": "320211",
            "title": "滨湖区",
            "id": 813,
            "firstLetter": "B"
          },
          {
            "value": "320281",
            "title": "江阴市",
            "id": 814,
            "firstLetter": "J"
          },
          {
            "value": "320282",
            "title": "宜兴市",
            "id": 815,
            "firstLetter": "Y"
          },
          {
            "value": "320213",
            "title": "梁溪区",
            "id": 4142,
            "firstLetter": "L"
          },
          {
            "value": "320214",
            "title": "新吴区",
            "id": 4143,
            "firstLetter": "X"
          }
        ],
        "id": 78,
        "provinceID": 10,
        "firstLetter": "W"
      },
      {
        "value": "320300",
        "title": "徐州市",
        "children": [
          {
            "value": "320302",
            "title": "鼓楼区",
            "id": 817,
            "firstLetter": "G"
          },
          {
            "value": "320303",
            "title": "云龙区",
            "id": 818,
            "firstLetter": "Y"
          },
          {
            "value": "320305",
            "title": "贾汪区",
            "id": 819,
            "firstLetter": "J"
          },
          {
            "value": "320311",
            "title": "泉山区",
            "id": 820,
            "firstLetter": "Q"
          },
          {
            "value": "320312",
            "title": "铜山区",
            "id": 821,
            "firstLetter": "T"
          },
          {
            "value": "320321",
            "title": "丰县",
            "id": 822,
            "firstLetter": "F"
          },
          {
            "value": "320322",
            "title": "沛县",
            "id": 823,
            "firstLetter": "P"
          },
          {
            "value": "320324",
            "title": "睢宁县",
            "id": 824,
            "firstLetter": "S"
          },
          {
            "value": "320381",
            "title": "新沂市",
            "id": 825,
            "firstLetter": "X"
          },
          {
            "value": "320382",
            "title": "邳州市",
            "id": 826,
            "firstLetter": "P"
          }
        ],
        "id": 79,
        "provinceID": 10,
        "firstLetter": "X"
      },
      {
        "value": "320400",
        "title": "常州市",
        "children": [
          {
            "value": "320402",
            "title": "天宁区",
            "id": 828,
            "firstLetter": "T"
          },
          {
            "value": "320404",
            "title": "钟楼区",
            "id": 829,
            "firstLetter": "Z"
          },
          {
            "value": "320411",
            "title": "新北区",
            "id": 831,
            "firstLetter": "X"
          },
          {
            "value": "320412",
            "title": "武进区",
            "id": 832,
            "firstLetter": "W"
          },
          {
            "value": "320481",
            "title": "溧阳市",
            "id": 833,
            "firstLetter": "L"
          },
          {
            "value": "320413",
            "title": "金坛区",
            "id": 4144,
            "firstLetter": "J"
          }
        ],
        "id": 80,
        "provinceID": 10,
        "firstLetter": "C"
      },
      {
        "value": "320500",
        "title": "苏州市",
        "children": [
          {
            "value": "320505",
            "title": "虎丘区",
            "id": 836,
            "firstLetter": "H"
          },
          {
            "value": "320506",
            "title": "吴中区",
            "id": 837,
            "firstLetter": "W"
          },
          {
            "value": "320507",
            "title": "相城区",
            "id": 838,
            "firstLetter": "X"
          },
          {
            "value": "320508",
            "title": "姑苏区",
            "id": 839,
            "firstLetter": "G"
          },
          {
            "value": "320509",
            "title": "吴江区",
            "id": 840,
            "firstLetter": "W"
          },
          {
            "value": "320581",
            "title": "常熟市",
            "id": 841,
            "firstLetter": "C"
          },
          {
            "value": "320582",
            "title": "张家港市",
            "id": 842,
            "firstLetter": "Z"
          },
          {
            "value": "320583",
            "title": "昆山市",
            "id": 843,
            "firstLetter": "K"
          },
          {
            "value": "320585",
            "title": "太仓市",
            "id": 844,
            "firstLetter": "T"
          },
          {
            "value": "320571",
            "title": "苏州工业园区",
            "id": 4289,
            "firstLetter": "S"
          }
        ],
        "id": 81,
        "provinceID": 10,
        "firstLetter": "S"
      },
      {
        "value": "320600",
        "title": "南通市",
        "children": [
          {
            "value": "320602",
            "title": "崇川区",
            "id": 846,
            "firstLetter": "C"
          },
          {
            "value": "320611",
            "title": "港闸区",
            "id": 847,
            "firstLetter": "G"
          },
          {
            "value": "320612",
            "title": "通州区",
            "id": 848,
            "firstLetter": "T"
          },
          {
            "value": "320623",
            "title": "如东县",
            "id": 850,
            "firstLetter": "R"
          },
          {
            "value": "320681",
            "title": "启东市",
            "id": 851,
            "firstLetter": "Q"
          },
          {
            "value": "320682",
            "title": "如皋市",
            "id": 852,
            "firstLetter": "R"
          },
          {
            "value": "320684",
            "title": "海门市",
            "id": 853,
            "firstLetter": "H"
          },
          {
            "value": "320685",
            "title": "海安市",
            "id": 4145,
            "firstLetter": "H"
          }
        ],
        "id": 82,
        "provinceID": 10,
        "firstLetter": "N"
      },
      {
        "value": "320700",
        "title": "连云港市",
        "children": [
          {
            "value": "320703",
            "title": "连云区",
            "id": 855,
            "firstLetter": "L"
          },
          {
            "value": "320706",
            "title": "海州区",
            "id": 856,
            "firstLetter": "H"
          },
          {
            "value": "320707",
            "title": "赣榆区",
            "id": 857,
            "firstLetter": "G"
          },
          {
            "value": "320722",
            "title": "东海县",
            "id": 858,
            "firstLetter": "D"
          },
          {
            "value": "320723",
            "title": "灌云县",
            "id": 859,
            "firstLetter": "G"
          },
          {
            "value": "320724",
            "title": "灌南县",
            "id": 860,
            "firstLetter": "G"
          }
        ],
        "id": 83,
        "provinceID": 10,
        "firstLetter": "L"
      },
      {
        "value": "320800",
        "title": "淮安市",
        "children": [
          {
            "value": "320803",
            "title": "淮安区",
            "id": 863,
            "firstLetter": "H"
          },
          {
            "value": "320804",
            "title": "淮阴区",
            "id": 864,
            "firstLetter": "H"
          },
          {
            "value": "320826",
            "title": "涟水县",
            "id": 866,
            "firstLetter": "L"
          },
          {
            "value": "320830",
            "title": "盱眙县",
            "id": 868,
            "firstLetter": "X"
          },
          {
            "value": "320831",
            "title": "金湖县",
            "id": 869,
            "firstLetter": "J"
          },
          {
            "value": "320812",
            "title": "清江浦区",
            "id": 4146,
            "firstLetter": "Q"
          },
          {
            "value": "320813",
            "title": "洪泽区",
            "id": 4147,
            "firstLetter": "H"
          }
        ],
        "id": 84,
        "provinceID": 10,
        "firstLetter": "H"
      },
      {
        "value": "320900",
        "title": "盐城市",
        "children": [
          {
            "value": "320902",
            "title": "亭湖区",
            "id": 871,
            "firstLetter": "T"
          },
          {
            "value": "320903",
            "title": "盐都区",
            "id": 872,
            "firstLetter": "Y"
          },
          {
            "value": "320921",
            "title": "响水县",
            "id": 873,
            "firstLetter": "X"
          },
          {
            "value": "320922",
            "title": "滨海县",
            "id": 874,
            "firstLetter": "B"
          },
          {
            "value": "320923",
            "title": "阜宁县",
            "id": 875,
            "firstLetter": "F"
          },
          {
            "value": "320924",
            "title": "射阳县",
            "id": 876,
            "firstLetter": "S"
          },
          {
            "value": "320925",
            "title": "建湖县",
            "id": 877,
            "firstLetter": "J"
          },
          {
            "value": "320981",
            "title": "东台市",
            "id": 878,
            "firstLetter": "D"
          },
          {
            "value": "320904",
            "title": "大丰区",
            "id": 4148,
            "firstLetter": "D"
          }
        ],
        "id": 85,
        "provinceID": 10,
        "firstLetter": "Y"
      },
      {
        "value": "321000",
        "title": "扬州市",
        "children": [
          {
            "value": "321002",
            "title": "广陵区",
            "id": 881,
            "firstLetter": "G"
          },
          {
            "value": "321003",
            "title": "邗江区",
            "id": 882,
            "firstLetter": "H"
          },
          {
            "value": "321012",
            "title": "江都区",
            "id": 883,
            "firstLetter": "J"
          },
          {
            "value": "321023",
            "title": "宝应县",
            "id": 884,
            "firstLetter": "B"
          },
          {
            "value": "321081",
            "title": "仪征市",
            "id": 885,
            "firstLetter": "Y"
          },
          {
            "value": "321084",
            "title": "高邮市",
            "id": 886,
            "firstLetter": "G"
          }
        ],
        "id": 86,
        "provinceID": 10,
        "firstLetter": "Y"
      },
      {
        "value": "321100",
        "title": "镇江市",
        "children": [
          {
            "value": "321102",
            "title": "京口区",
            "id": 888,
            "firstLetter": "J"
          },
          {
            "value": "321111",
            "title": "润州区",
            "id": 889,
            "firstLetter": "R"
          },
          {
            "value": "321112",
            "title": "丹徒区",
            "id": 890,
            "firstLetter": "D"
          },
          {
            "value": "321181",
            "title": "丹阳市",
            "id": 891,
            "firstLetter": "D"
          },
          {
            "value": "321182",
            "title": "扬中市",
            "id": 892,
            "firstLetter": "Y"
          },
          {
            "value": "321183",
            "title": "句容市",
            "id": 893,
            "firstLetter": "J"
          }
        ],
        "id": 87,
        "provinceID": 10,
        "firstLetter": "Z"
      },
      {
        "value": "321200",
        "title": "泰州市",
        "children": [
          {
            "value": "321202",
            "title": "海陵区",
            "id": 895,
            "firstLetter": "H"
          },
          {
            "value": "321203",
            "title": "高港区",
            "id": 896,
            "firstLetter": "G"
          },
          {
            "value": "321204",
            "title": "姜堰区",
            "id": 897,
            "firstLetter": "J"
          },
          {
            "value": "321281",
            "title": "兴化市",
            "id": 898,
            "firstLetter": "X"
          },
          {
            "value": "321282",
            "title": "靖江市",
            "id": 899,
            "firstLetter": "J"
          },
          {
            "value": "321283",
            "title": "泰兴市",
            "id": 900,
            "firstLetter": "T"
          }
        ],
        "id": 88,
        "provinceID": 10,
        "firstLetter": "T"
      },
      {
        "value": "321300",
        "title": "宿迁市",
        "children": [
          {
            "value": "321302",
            "title": "宿城区",
            "id": 902,
            "firstLetter": "S"
          },
          {
            "value": "321311",
            "title": "宿豫区",
            "id": 903,
            "firstLetter": "S"
          },
          {
            "value": "321322",
            "title": "沭阳县",
            "id": 904,
            "firstLetter": "S"
          },
          {
            "value": "321323",
            "title": "泗阳县",
            "id": 905,
            "firstLetter": "S"
          },
          {
            "value": "321324",
            "title": "泗洪县",
            "id": 906,
            "firstLetter": "S"
          }
        ],
        "id": 89,
        "provinceID": 10,
        "firstLetter": "S"
      }
    ],
    "title": "江苏省",
    "id": 10,
    "firstLetter": "J"
  },
  {
    "value": "330000",
    "children": [
      {
        "value": "330100",
        "title": "杭州市",
        "children": [
          {
            "value": "330102",
            "title": "上城区",
            "id": 908,
            "firstLetter": "S"
          },
          {
            "value": "330103",
            "title": "下城区",
            "id": 909,
            "firstLetter": "X"
          },
          {
            "value": "330104",
            "title": "江干区",
            "id": 910,
            "firstLetter": "J"
          },
          {
            "value": "330105",
            "title": "拱墅区",
            "id": 911,
            "firstLetter": "G"
          },
          {
            "value": "330106",
            "title": "西湖区",
            "id": 912,
            "firstLetter": "X"
          },
          {
            "value": "330108",
            "title": "滨江区",
            "id": 913,
            "firstLetter": "B"
          },
          {
            "value": "330109",
            "title": "萧山区",
            "id": 914,
            "firstLetter": "X"
          },
          {
            "value": "330110",
            "title": "余杭区",
            "id": 915,
            "firstLetter": "Y"
          },
          {
            "value": "330122",
            "title": "桐庐县",
            "id": 916,
            "firstLetter": "T"
          },
          {
            "value": "330127",
            "title": "淳安县",
            "id": 917,
            "firstLetter": "C"
          },
          {
            "value": "330182",
            "title": "建德市",
            "id": 918,
            "firstLetter": "J"
          },
          {
            "value": "330111",
            "title": "富阳区",
            "id": 4149,
            "firstLetter": "F"
          },
          {
            "value": "330112",
            "title": "临安区",
            "id": 4150,
            "firstLetter": "L"
          },
          {
            "value": "330114",
            "title": "钱塘区",
            "id": 4291,
            "firstLetter": "Q"
          }
        ],
        "id": 90,
        "provinceID": 11,
        "firstLetter": "H"
      },
      {
        "value": "330200",
        "title": "宁波市",
        "children": [
          {
            "value": "330203",
            "title": "海曙区",
            "id": 922,
            "firstLetter": "H"
          },
          {
            "value": "330205",
            "title": "江北区",
            "id": 924,
            "firstLetter": "J"
          },
          {
            "value": "330206",
            "title": "北仑区",
            "id": 925,
            "firstLetter": "B"
          },
          {
            "value": "330211",
            "title": "镇海区",
            "id": 926,
            "firstLetter": "Z"
          },
          {
            "value": "330212",
            "title": "鄞州区",
            "id": 927,
            "firstLetter": "Y"
          },
          {
            "value": "330225",
            "title": "象山县",
            "id": 928,
            "firstLetter": "X"
          },
          {
            "value": "330226",
            "title": "宁海县",
            "id": 929,
            "firstLetter": "N"
          },
          {
            "value": "330281",
            "title": "余姚市",
            "id": 930,
            "firstLetter": "Y"
          },
          {
            "value": "330282",
            "title": "慈溪市",
            "id": 931,
            "firstLetter": "C"
          },
          {
            "value": "330213",
            "title": "奉化区",
            "id": 4151,
            "firstLetter": "F"
          }
        ],
        "id": 91,
        "provinceID": 11,
        "firstLetter": "N"
      },
      {
        "value": "330300",
        "title": "温州市",
        "children": [
          {
            "value": "330302",
            "title": "鹿城区",
            "id": 934,
            "firstLetter": "L"
          },
          {
            "value": "330303",
            "title": "龙湾区",
            "id": 935,
            "firstLetter": "L"
          },
          {
            "value": "330304",
            "title": "瓯海区",
            "id": 936,
            "firstLetter": "O"
          },
          {
            "value": "330324",
            "title": "永嘉县",
            "id": 938,
            "firstLetter": "Y"
          },
          {
            "value": "330326",
            "title": "平阳县",
            "id": 939,
            "firstLetter": "P"
          },
          {
            "value": "330327",
            "title": "苍南县",
            "id": 940,
            "firstLetter": "C"
          },
          {
            "value": "330328",
            "title": "文成县",
            "id": 941,
            "firstLetter": "W"
          },
          {
            "value": "330329",
            "title": "泰顺县",
            "id": 942,
            "firstLetter": "T"
          },
          {
            "value": "330381",
            "title": "瑞安市",
            "id": 943,
            "firstLetter": "R"
          },
          {
            "value": "330382",
            "title": "乐清市",
            "id": 944,
            "firstLetter": "L"
          },
          {
            "value": "330305",
            "title": "洞头区",
            "id": 4152,
            "firstLetter": "D"
          }
        ],
        "id": 92,
        "provinceID": 11,
        "firstLetter": "W"
      },
      {
        "value": "330400",
        "title": "嘉兴市",
        "children": [
          {
            "value": "330402",
            "title": "南湖区",
            "id": 946,
            "firstLetter": "N"
          },
          {
            "value": "330411",
            "title": "秀洲区",
            "id": 947,
            "firstLetter": "X"
          },
          {
            "value": "330421",
            "title": "嘉善县",
            "id": 948,
            "firstLetter": "J"
          },
          {
            "value": "330424",
            "title": "海盐县",
            "id": 949,
            "firstLetter": "H"
          },
          {
            "value": "330481",
            "title": "海宁市",
            "id": 950,
            "firstLetter": "H"
          },
          {
            "value": "330482",
            "title": "平湖市",
            "id": 951,
            "firstLetter": "P"
          },
          {
            "value": "330483",
            "title": "桐乡市",
            "id": 952,
            "firstLetter": "T"
          }
        ],
        "id": 93,
        "provinceID": 11,
        "firstLetter": "J"
      },
      {
        "value": "330500",
        "title": "湖州市",
        "children": [
          {
            "value": "330502",
            "title": "吴兴区",
            "id": 954,
            "firstLetter": "W"
          },
          {
            "value": "330503",
            "title": "南浔区",
            "id": 955,
            "firstLetter": "N"
          },
          {
            "value": "330521",
            "title": "德清县",
            "id": 956,
            "firstLetter": "D"
          },
          {
            "value": "330522",
            "title": "长兴县",
            "id": 957,
            "firstLetter": "Z"
          },
          {
            "value": "330523",
            "title": "安吉县",
            "id": 958,
            "firstLetter": "A"
          }
        ],
        "id": 94,
        "provinceID": 11,
        "firstLetter": "H"
      },
      {
        "value": "330600",
        "title": "绍兴市",
        "children": [
          {
            "value": "330602",
            "title": "越城区",
            "id": 960,
            "firstLetter": "Y"
          },
          {
            "value": "330603",
            "title": "柯桥区",
            "id": 961,
            "firstLetter": "K"
          },
          {
            "value": "330604",
            "title": "上虞区",
            "id": 962,
            "firstLetter": "S"
          },
          {
            "value": "330624",
            "title": "新昌县",
            "id": 963,
            "firstLetter": "X"
          },
          {
            "value": "330681",
            "title": "诸暨市",
            "id": 964,
            "firstLetter": "Z"
          },
          {
            "value": "330683",
            "title": "嵊州市",
            "id": 965,
            "firstLetter": "S"
          }
        ],
        "id": 95,
        "provinceID": 11,
        "firstLetter": "S"
      },
      {
        "value": "330700",
        "title": "金华市",
        "children": [
          {
            "value": "330702",
            "title": "婺城区",
            "id": 967,
            "firstLetter": "W"
          },
          {
            "value": "330703",
            "title": "金东区",
            "id": 968,
            "firstLetter": "J"
          },
          {
            "value": "330723",
            "title": "武义县",
            "id": 969,
            "firstLetter": "W"
          },
          {
            "value": "330726",
            "title": "浦江县",
            "id": 970,
            "firstLetter": "P"
          },
          {
            "value": "330727",
            "title": "磐安县",
            "id": 971,
            "firstLetter": "P"
          },
          {
            "value": "330781",
            "title": "兰溪市",
            "id": 972,
            "firstLetter": "L"
          },
          {
            "value": "330782",
            "title": "义乌市",
            "id": 973,
            "firstLetter": "Y"
          },
          {
            "value": "330783",
            "title": "东阳市",
            "id": 974,
            "firstLetter": "D"
          },
          {
            "value": "330784",
            "title": "永康市",
            "id": 975,
            "firstLetter": "Y"
          }
        ],
        "id": 96,
        "provinceID": 11,
        "firstLetter": "J"
      },
      {
        "value": "330800",
        "title": "衢州市",
        "children": [
          {
            "value": "330802",
            "title": "柯城区",
            "id": 977,
            "firstLetter": "K"
          },
          {
            "value": "330803",
            "title": "衢江区",
            "id": 978,
            "firstLetter": "Q"
          },
          {
            "value": "330822",
            "title": "常山县",
            "id": 979,
            "firstLetter": "C"
          },
          {
            "value": "330824",
            "title": "开化县",
            "id": 980,
            "firstLetter": "K"
          },
          {
            "value": "330825",
            "title": "龙游县",
            "id": 981,
            "firstLetter": "L"
          },
          {
            "value": "330881",
            "title": "江山市",
            "id": 982,
            "firstLetter": "J"
          }
        ],
        "id": 97,
        "provinceID": 11,
        "firstLetter": "Q"
      },
      {
        "value": "330900",
        "title": "舟山市",
        "children": [
          {
            "value": "330902",
            "title": "定海区",
            "id": 984,
            "firstLetter": "D"
          },
          {
            "value": "330903",
            "title": "普陀区",
            "id": 985,
            "firstLetter": "P"
          },
          {
            "value": "330921",
            "title": "岱山县",
            "id": 986,
            "firstLetter": "D"
          },
          {
            "value": "330922",
            "title": "嵊泗县",
            "id": 987,
            "firstLetter": "S"
          }
        ],
        "id": 98,
        "provinceID": 11,
        "firstLetter": "Z"
      },
      {
        "value": "331000",
        "title": "台州市",
        "children": [
          {
            "value": "331002",
            "title": "椒江区",
            "id": 989,
            "firstLetter": "J"
          },
          {
            "value": "331003",
            "title": "黄岩区",
            "id": 990,
            "firstLetter": "H"
          },
          {
            "value": "331004",
            "title": "路桥区",
            "id": 991,
            "firstLetter": "L"
          },
          {
            "value": "331022",
            "title": "三门县",
            "id": 993,
            "firstLetter": "S"
          },
          {
            "value": "331023",
            "title": "天台县",
            "id": 994,
            "firstLetter": "T"
          },
          {
            "value": "331024",
            "title": "仙居县",
            "id": 995,
            "firstLetter": "X"
          },
          {
            "value": "331081",
            "title": "温岭市",
            "id": 996,
            "firstLetter": "W"
          },
          {
            "value": "331082",
            "title": "临海市",
            "id": 997,
            "firstLetter": "L"
          },
          {
            "value": "331083",
            "title": "玉环市",
            "id": 4153,
            "firstLetter": "Y"
          }
        ],
        "id": 99,
        "provinceID": 11,
        "firstLetter": "T"
      },
      {
        "value": "331100",
        "title": "丽水市",
        "children": [
          {
            "value": "331102",
            "title": "莲都区",
            "id": 999,
            "firstLetter": "L"
          },
          {
            "value": "331121",
            "title": "青田县",
            "id": 1000,
            "firstLetter": "Q"
          },
          {
            "value": "331122",
            "title": "缙云县",
            "id": 1001,
            "firstLetter": "J"
          },
          {
            "value": "331123",
            "title": "遂昌县",
            "id": 1002,
            "firstLetter": "S"
          },
          {
            "value": "331124",
            "title": "松阳县",
            "id": 1003,
            "firstLetter": "S"
          },
          {
            "value": "331125",
            "title": "云和县",
            "id": 1004,
            "firstLetter": "Y"
          },
          {
            "value": "331126",
            "title": "庆元县",
            "id": 1005,
            "firstLetter": "Q"
          },
          {
            "value": "331127",
            "title": "景宁畲族自治县",
            "id": 1006,
            "firstLetter": "J"
          },
          {
            "value": "331181",
            "title": "龙泉市",
            "id": 1007,
            "firstLetter": "L"
          }
        ],
        "id": 100,
        "provinceID": 11,
        "firstLetter": "L"
      }
    ],
    "title": "浙江省",
    "id": 11,
    "firstLetter": "Z"
  },
  {
    "value": "340000",
    "children": [
      {
        "value": "340100",
        "title": "合肥市",
        "children": [
          {
            "value": "340102",
            "title": "瑶海区",
            "id": 1009,
            "firstLetter": "Y"
          },
          {
            "value": "340103",
            "title": "庐阳区",
            "id": 1010,
            "firstLetter": "L"
          },
          {
            "value": "340104",
            "title": "蜀山区",
            "id": 1011,
            "firstLetter": "S"
          },
          {
            "value": "340111",
            "title": "包河区",
            "id": 1012,
            "firstLetter": "B"
          },
          {
            "value": "340121",
            "title": "长丰县",
            "id": 1013,
            "firstLetter": "Z"
          },
          {
            "value": "340122",
            "title": "肥东县",
            "id": 1014,
            "firstLetter": "F"
          },
          {
            "value": "340123",
            "title": "肥西县",
            "id": 1015,
            "firstLetter": "F"
          },
          {
            "value": "340124",
            "title": "庐江县",
            "id": 1016,
            "firstLetter": "L"
          },
          {
            "value": "340181",
            "title": "巢湖市",
            "id": 1017,
            "firstLetter": "C"
          }
        ],
        "id": 101,
        "provinceID": 12,
        "firstLetter": "H"
      },
      {
        "value": "340200",
        "title": "芜湖市",
        "children": [
          {
            "value": "340202",
            "title": "镜湖区",
            "id": 1019,
            "firstLetter": "J"
          },
          {
            "value": "340203",
            "title": "弋江区",
            "id": 1020,
            "firstLetter": "Y"
          },
          {
            "value": "340207",
            "title": "鸠江区",
            "id": 1021,
            "firstLetter": "J"
          },
          {
            "value": "340208",
            "title": "三山区",
            "id": 1022,
            "firstLetter": "S"
          },
          {
            "value": "340221",
            "title": "芜湖县",
            "id": 1023,
            "firstLetter": "W"
          },
          {
            "value": "340222",
            "title": "繁昌县",
            "id": 1024,
            "firstLetter": "F"
          },
          {
            "value": "340223",
            "title": "南陵县",
            "id": 1025,
            "firstLetter": "N"
          },
          {
            "value": "340225",
            "title": "无为县",
            "id": 1026,
            "firstLetter": "W"
          }
        ],
        "id": 102,
        "provinceID": 12,
        "firstLetter": "W"
      },
      {
        "value": "340300",
        "title": "蚌埠市",
        "children": [
          {
            "value": "340302",
            "title": "龙子湖区",
            "id": 1028,
            "firstLetter": "L"
          },
          {
            "value": "340303",
            "title": "蚌山区",
            "id": 1029,
            "firstLetter": "B"
          },
          {
            "value": "340304",
            "title": "禹会区",
            "id": 1030,
            "firstLetter": "Y"
          },
          {
            "value": "340311",
            "title": "淮上区",
            "id": 1031,
            "firstLetter": "H"
          },
          {
            "value": "340321",
            "title": "怀远县",
            "id": 1032,
            "firstLetter": "H"
          },
          {
            "value": "340322",
            "title": "五河县",
            "id": 1033,
            "firstLetter": "W"
          },
          {
            "value": "340323",
            "title": "固镇县",
            "id": 1034,
            "firstLetter": "G"
          }
        ],
        "id": 103,
        "provinceID": 12,
        "firstLetter": "B"
      },
      {
        "value": "340400",
        "title": "淮南市",
        "children": [
          {
            "value": "340402",
            "title": "大通区",
            "id": 1036,
            "firstLetter": "D"
          },
          {
            "value": "340403",
            "title": "田家庵区",
            "id": 1037,
            "firstLetter": "T"
          },
          {
            "value": "340404",
            "title": "谢家集区",
            "id": 1038,
            "firstLetter": "X"
          },
          {
            "value": "340405",
            "title": "八公山区",
            "id": 1039,
            "firstLetter": "B"
          },
          {
            "value": "340406",
            "title": "潘集区",
            "id": 1040,
            "firstLetter": "P"
          },
          {
            "value": "340421",
            "title": "凤台县",
            "id": 1041,
            "firstLetter": "F"
          },
          {
            "value": "340422",
            "title": "寿县",
            "id": 4154,
            "firstLetter": "S"
          }
        ],
        "id": 104,
        "provinceID": 12,
        "firstLetter": "H"
      },
      {
        "value": "340500",
        "title": "马鞍山市",
        "children": [
          {
            "value": "340503",
            "title": "花山区",
            "id": 1043,
            "firstLetter": "H"
          },
          {
            "value": "340504",
            "title": "雨山区",
            "id": 1044,
            "firstLetter": "Y"
          },
          {
            "value": "340506",
            "title": "博望区",
            "id": 1045,
            "firstLetter": "B"
          },
          {
            "value": "340521",
            "title": "当涂县",
            "id": 1046,
            "firstLetter": "D"
          },
          {
            "value": "340522",
            "title": "含山县",
            "id": 1047,
            "firstLetter": "H"
          },
          {
            "value": "340523",
            "title": "和县",
            "id": 1048,
            "firstLetter": "H"
          }
        ],
        "id": 105,
        "provinceID": 12,
        "firstLetter": "M"
      },
      {
        "value": "340600",
        "title": "淮北市",
        "children": [
          {
            "value": "340602",
            "title": "杜集区",
            "id": 1050,
            "firstLetter": "D"
          },
          {
            "value": "340603",
            "title": "相山区",
            "id": 1051,
            "firstLetter": "X"
          },
          {
            "value": "340604",
            "title": "烈山区",
            "id": 1052,
            "firstLetter": "L"
          },
          {
            "value": "340621",
            "title": "濉溪县",
            "id": 1053,
            "firstLetter": "S"
          }
        ],
        "id": 106,
        "provinceID": 12,
        "firstLetter": "H"
      },
      {
        "value": "340700",
        "title": "铜陵市",
        "children": [
          {
            "value": "340711",
            "title": "郊区",
            "id": 1057,
            "firstLetter": "J"
          },
          {
            "value": "340705",
            "title": "铜官区",
            "id": 4155,
            "firstLetter": "T"
          },
          {
            "value": "340706",
            "title": "义安区",
            "id": 4156,
            "firstLetter": "Y"
          },
          {
            "value": "340722",
            "title": "枞阳县",
            "id": 4157,
            "firstLetter": "Z"
          }
        ],
        "id": 107,
        "provinceID": 12,
        "firstLetter": "T"
      },
      {
        "value": "340800",
        "title": "安庆市",
        "children": [
          {
            "value": "340802",
            "title": "迎江区",
            "id": 1060,
            "firstLetter": "Y"
          },
          {
            "value": "340803",
            "title": "大观区",
            "id": 1061,
            "firstLetter": "D"
          },
          {
            "value": "340811",
            "title": "宜秀区",
            "id": 1062,
            "firstLetter": "Y"
          },
          {
            "value": "340822",
            "title": "怀宁县",
            "id": 1063,
            "firstLetter": "H"
          },
          {
            "value": "340825",
            "title": "太湖县",
            "id": 1066,
            "firstLetter": "T"
          },
          {
            "value": "340826",
            "title": "宿松县",
            "id": 1067,
            "firstLetter": "S"
          },
          {
            "value": "340827",
            "title": "望江县",
            "id": 1068,
            "firstLetter": "W"
          },
          {
            "value": "340828",
            "title": "岳西县",
            "id": 1069,
            "firstLetter": "Y"
          },
          {
            "value": "340881",
            "title": "桐城市",
            "id": 1070,
            "firstLetter": "T"
          },
          {
            "value": "340882",
            "title": "潜山市",
            "id": 4158,
            "firstLetter": "Q"
          }
        ],
        "id": 108,
        "provinceID": 12,
        "firstLetter": "A"
      },
      {
        "value": "341000",
        "title": "黄山市",
        "children": [
          {
            "value": "341002",
            "title": "屯溪区",
            "id": 1072,
            "firstLetter": "T"
          },
          {
            "value": "341003",
            "title": "黄山区",
            "id": 1073,
            "firstLetter": "H"
          },
          {
            "value": "341004",
            "title": "徽州区",
            "id": 1074,
            "firstLetter": "H"
          },
          {
            "value": "341021",
            "title": "歙县",
            "id": 1075,
            "firstLetter": "S"
          },
          {
            "value": "341022",
            "title": "休宁县",
            "id": 1076,
            "firstLetter": "X"
          },
          {
            "value": "341023",
            "title": "黟县",
            "id": 1077,
            "firstLetter": "Y"
          },
          {
            "value": "341024",
            "title": "祁门县",
            "id": 1078,
            "firstLetter": "Q"
          }
        ],
        "id": 109,
        "provinceID": 12,
        "firstLetter": "H"
      },
      {
        "value": "341100",
        "title": "滁州市",
        "children": [
          {
            "value": "341102",
            "title": "琅琊区",
            "id": 1080,
            "firstLetter": "L"
          },
          {
            "value": "341103",
            "title": "南谯区",
            "id": 1081,
            "firstLetter": "N"
          },
          {
            "value": "341122",
            "title": "来安县",
            "id": 1082,
            "firstLetter": "L"
          },
          {
            "value": "341124",
            "title": "全椒县",
            "id": 1083,
            "firstLetter": "Q"
          },
          {
            "value": "341125",
            "title": "定远县",
            "id": 1084,
            "firstLetter": "D"
          },
          {
            "value": "341126",
            "title": "凤阳县",
            "id": 1085,
            "firstLetter": "F"
          },
          {
            "value": "341181",
            "title": "天长市",
            "id": 1086,
            "firstLetter": "T"
          },
          {
            "value": "341182",
            "title": "明光市",
            "id": 1087,
            "firstLetter": "M"
          }
        ],
        "id": 110,
        "provinceID": 12,
        "firstLetter": "C"
      },
      {
        "value": "341200",
        "title": "阜阳市",
        "children": [
          {
            "value": "341202",
            "title": "颍州区",
            "id": 1089,
            "firstLetter": "Y"
          },
          {
            "value": "341203",
            "title": "颍东区",
            "id": 1090,
            "firstLetter": "Y"
          },
          {
            "value": "341204",
            "title": "颍泉区",
            "id": 1091,
            "firstLetter": "Y"
          },
          {
            "value": "341221",
            "title": "临泉县",
            "id": 1092,
            "firstLetter": "L"
          },
          {
            "value": "341222",
            "title": "太和县",
            "id": 1093,
            "firstLetter": "T"
          },
          {
            "value": "341225",
            "title": "阜南县",
            "id": 1094,
            "firstLetter": "F"
          },
          {
            "value": "341226",
            "title": "颍上县",
            "id": 1095,
            "firstLetter": "Y"
          },
          {
            "value": "341282",
            "title": "界首市",
            "id": 1096,
            "firstLetter": "J"
          }
        ],
        "id": 111,
        "provinceID": 12,
        "firstLetter": "F"
      },
      {
        "value": "341300",
        "title": "宿州市",
        "children": [
          {
            "value": "341302",
            "title": "埇桥区",
            "id": 1098,
            "firstLetter": "Y"
          },
          {
            "value": "341321",
            "title": "砀山县",
            "id": 1099,
            "firstLetter": "D"
          },
          {
            "value": "341322",
            "title": "萧县",
            "id": 1100,
            "firstLetter": "X"
          },
          {
            "value": "341323",
            "title": "灵璧县",
            "id": 1101,
            "firstLetter": "L"
          },
          {
            "value": "341324",
            "title": "泗县",
            "id": 1102,
            "firstLetter": "S"
          }
        ],
        "id": 112,
        "provinceID": 12,
        "firstLetter": "S"
      },
      {
        "value": "341500",
        "title": "六安市",
        "children": [
          {
            "value": "341502",
            "title": "金安区",
            "id": 1104,
            "firstLetter": "J"
          },
          {
            "value": "341503",
            "title": "裕安区",
            "id": 1105,
            "firstLetter": "Y"
          },
          {
            "value": "341522",
            "title": "霍邱县",
            "id": 1107,
            "firstLetter": "H"
          },
          {
            "value": "341523",
            "title": "舒城县",
            "id": 1108,
            "firstLetter": "S"
          },
          {
            "value": "341524",
            "title": "金寨县",
            "id": 1109,
            "firstLetter": "J"
          },
          {
            "value": "341525",
            "title": "霍山县",
            "id": 1110,
            "firstLetter": "H"
          },
          {
            "value": "341504",
            "title": "叶集区",
            "id": 4159,
            "firstLetter": "Y"
          }
        ],
        "id": 113,
        "provinceID": 12,
        "firstLetter": "L"
      },
      {
        "value": "341600",
        "title": "亳州市",
        "children": [
          {
            "value": "341602",
            "title": "谯城区",
            "id": 1112,
            "firstLetter": "Q"
          },
          {
            "value": "341621",
            "title": "涡阳县",
            "id": 1113,
            "firstLetter": "W"
          },
          {
            "value": "341622",
            "title": "蒙城县",
            "id": 1114,
            "firstLetter": "M"
          },
          {
            "value": "341623",
            "title": "利辛县",
            "id": 1115,
            "firstLetter": "L"
          }
        ],
        "id": 114,
        "provinceID": 12,
        "firstLetter": "B"
      },
      {
        "value": "341700",
        "title": "池州市",
        "children": [
          {
            "value": "341702",
            "title": "贵池区",
            "id": 1117,
            "firstLetter": "G"
          },
          {
            "value": "341721",
            "title": "东至县",
            "id": 1118,
            "firstLetter": "D"
          },
          {
            "value": "341722",
            "title": "石台县",
            "id": 1119,
            "firstLetter": "S"
          },
          {
            "value": "341723",
            "title": "青阳县",
            "id": 1120,
            "firstLetter": "Q"
          }
        ],
        "id": 115,
        "provinceID": 12,
        "firstLetter": "C"
      },
      {
        "value": "341800",
        "title": "宣城市",
        "children": [
          {
            "value": "341802",
            "title": "宣州区",
            "id": 1122,
            "firstLetter": "X"
          },
          {
            "value": "341821",
            "title": "郎溪县",
            "id": 1123,
            "firstLetter": "L"
          },
          {
            "value": "341822",
            "title": "广德县",
            "id": 1124,
            "firstLetter": "G"
          },
          {
            "value": "341823",
            "title": "泾县",
            "id": 1125,
            "firstLetter": "J"
          },
          {
            "value": "341824",
            "title": "绩溪县",
            "id": 1126,
            "firstLetter": "J"
          },
          {
            "value": "341825",
            "title": "旌德县",
            "id": 1127,
            "firstLetter": "J"
          },
          {
            "value": "341881",
            "title": "宁国市",
            "id": 1128,
            "firstLetter": "N"
          }
        ],
        "id": 116,
        "provinceID": 12,
        "firstLetter": "X"
      }
    ],
    "title": "安徽省",
    "id": 12,
    "firstLetter": "A"
  },
  {
    "value": "350000",
    "children": [
      {
        "value": "350100",
        "title": "福州市",
        "children": [
          {
            "value": "350102",
            "title": "鼓楼区",
            "id": 1130,
            "firstLetter": "G"
          },
          {
            "value": "350103",
            "title": "台江区",
            "id": 1131,
            "firstLetter": "T"
          },
          {
            "value": "350104",
            "title": "仓山区",
            "id": 1132,
            "firstLetter": "C"
          },
          {
            "value": "350105",
            "title": "马尾区",
            "id": 1133,
            "firstLetter": "M"
          },
          {
            "value": "350111",
            "title": "晋安区",
            "id": 1134,
            "firstLetter": "J"
          },
          {
            "value": "350121",
            "title": "闽侯县",
            "id": 1135,
            "firstLetter": "M"
          },
          {
            "value": "350122",
            "title": "连江县",
            "id": 1136,
            "firstLetter": "L"
          },
          {
            "value": "350123",
            "title": "罗源县",
            "id": 1137,
            "firstLetter": "L"
          },
          {
            "value": "350124",
            "title": "闽清县",
            "id": 1138,
            "firstLetter": "M"
          },
          {
            "value": "350125",
            "title": "永泰县",
            "id": 1139,
            "firstLetter": "Y"
          },
          {
            "value": "350128",
            "title": "平潭县",
            "id": 1140,
            "firstLetter": "P"
          },
          {
            "value": "350181",
            "title": "福清市",
            "id": 1141,
            "firstLetter": "F"
          },
          {
            "value": "350112",
            "title": "长乐区",
            "id": 4160,
            "firstLetter": "Z"
          }
        ],
        "id": 117,
        "provinceID": 13,
        "firstLetter": "F"
      },
      {
        "value": "350200",
        "title": "厦门市",
        "children": [
          {
            "value": "350203",
            "title": "思明区",
            "id": 1144,
            "firstLetter": "S"
          },
          {
            "value": "350205",
            "title": "海沧区",
            "id": 1145,
            "firstLetter": "H"
          },
          {
            "value": "350206",
            "title": "湖里区",
            "id": 1146,
            "firstLetter": "H"
          },
          {
            "value": "350211",
            "title": "集美区",
            "id": 1147,
            "firstLetter": "J"
          },
          {
            "value": "350212",
            "title": "同安区",
            "id": 1148,
            "firstLetter": "T"
          },
          {
            "value": "350213",
            "title": "翔安区",
            "id": 1149,
            "firstLetter": "X"
          }
        ],
        "id": 118,
        "provinceID": 13,
        "firstLetter": "S"
      },
      {
        "value": "350300",
        "title": "莆田市",
        "children": [
          {
            "value": "350302",
            "title": "城厢区",
            "id": 1151,
            "firstLetter": "C"
          },
          {
            "value": "350303",
            "title": "涵江区",
            "id": 1152,
            "firstLetter": "H"
          },
          {
            "value": "350304",
            "title": "荔城区",
            "id": 1153,
            "firstLetter": "L"
          },
          {
            "value": "350305",
            "title": "秀屿区",
            "id": 1154,
            "firstLetter": "X"
          },
          {
            "value": "350322",
            "title": "仙游县",
            "id": 1155,
            "firstLetter": "X"
          }
        ],
        "id": 119,
        "provinceID": 13,
        "firstLetter": "P"
      },
      {
        "value": "350400",
        "title": "三明市",
        "children": [
          {
            "value": "350402",
            "title": "梅列区",
            "id": 1157,
            "firstLetter": "M"
          },
          {
            "value": "350403",
            "title": "三元区",
            "id": 1158,
            "firstLetter": "S"
          },
          {
            "value": "350421",
            "title": "明溪县",
            "id": 1159,
            "firstLetter": "M"
          },
          {
            "value": "350423",
            "title": "清流县",
            "id": 1160,
            "firstLetter": "Q"
          },
          {
            "value": "350424",
            "title": "宁化县",
            "id": 1161,
            "firstLetter": "N"
          },
          {
            "value": "350425",
            "title": "大田县",
            "id": 1162,
            "firstLetter": "D"
          },
          {
            "value": "350426",
            "title": "尤溪县",
            "id": 1163,
            "firstLetter": "Y"
          },
          {
            "value": "350427",
            "title": "沙县",
            "id": 1164,
            "firstLetter": "S"
          },
          {
            "value": "350428",
            "title": "将乐县",
            "id": 1165,
            "firstLetter": "J"
          },
          {
            "value": "350429",
            "title": "泰宁县",
            "id": 1166,
            "firstLetter": "T"
          },
          {
            "value": "350430",
            "title": "建宁县",
            "id": 1167,
            "firstLetter": "J"
          },
          {
            "value": "350481",
            "title": "永安市",
            "id": 1168,
            "firstLetter": "Y"
          }
        ],
        "id": 120,
        "provinceID": 13,
        "firstLetter": "S"
      },
      {
        "value": "350500",
        "title": "泉州市",
        "children": [
          {
            "value": "350502",
            "title": "鲤城区",
            "id": 1170,
            "firstLetter": "L"
          },
          {
            "value": "350503",
            "title": "丰泽区",
            "id": 1171,
            "firstLetter": "F"
          },
          {
            "value": "350504",
            "title": "洛江区",
            "id": 1172,
            "firstLetter": "L"
          },
          {
            "value": "350505",
            "title": "泉港区",
            "id": 1173,
            "firstLetter": "Q"
          },
          {
            "value": "350521",
            "title": "惠安县",
            "id": 1174,
            "firstLetter": "H"
          },
          {
            "value": "350524",
            "title": "安溪县",
            "id": 1175,
            "firstLetter": "A"
          },
          {
            "value": "350525",
            "title": "永春县",
            "id": 1176,
            "firstLetter": "Y"
          },
          {
            "value": "350526",
            "title": "德化县",
            "id": 1177,
            "firstLetter": "D"
          },
          {
            "value": "350527",
            "title": "金门县",
            "id": 1178,
            "firstLetter": "J"
          },
          {
            "value": "350581",
            "title": "石狮市",
            "id": 1179,
            "firstLetter": "S"
          },
          {
            "value": "350582",
            "title": "晋江市",
            "id": 1180,
            "firstLetter": "J"
          },
          {
            "value": "350583",
            "title": "南安市",
            "id": 1181,
            "firstLetter": "N"
          }
        ],
        "id": 121,
        "provinceID": 13,
        "firstLetter": "Q"
      },
      {
        "value": "350600",
        "title": "漳州市",
        "children": [
          {
            "value": "350602",
            "title": "芗城区",
            "id": 1183,
            "firstLetter": "X"
          },
          {
            "value": "350603",
            "title": "龙文区",
            "id": 1184,
            "firstLetter": "L"
          },
          {
            "value": "350622",
            "title": "云霄县",
            "id": 1185,
            "firstLetter": "Y"
          },
          {
            "value": "350623",
            "title": "漳浦县",
            "id": 1186,
            "firstLetter": "Z"
          },
          {
            "value": "350624",
            "title": "诏安县",
            "id": 1187,
            "firstLetter": "Z"
          },
          {
            "value": "350625",
            "title": "长泰县",
            "id": 1188,
            "firstLetter": "Z"
          },
          {
            "value": "350626",
            "title": "东山县",
            "id": 1189,
            "firstLetter": "D"
          },
          {
            "value": "350627",
            "title": "南靖县",
            "id": 1190,
            "firstLetter": "N"
          },
          {
            "value": "350628",
            "title": "平和县",
            "id": 1191,
            "firstLetter": "P"
          },
          {
            "value": "350629",
            "title": "华安县",
            "id": 1192,
            "firstLetter": "H"
          },
          {
            "value": "350681",
            "title": "龙海市",
            "id": 1193,
            "firstLetter": "L"
          },
          {
            "value": "363107",
            "title": "龙海区",
            "id": 4290,
            "firstLetter": "L"
          }
        ],
        "id": 122,
        "provinceID": 13,
        "firstLetter": "Z"
      },
      {
        "value": "350700",
        "title": "南平市",
        "children": [
          {
            "value": "350702",
            "title": "延平区",
            "id": 1195,
            "firstLetter": "Y"
          },
          {
            "value": "350721",
            "title": "顺昌县",
            "id": 1196,
            "firstLetter": "S"
          },
          {
            "value": "350722",
            "title": "浦城县",
            "id": 1197,
            "firstLetter": "P"
          },
          {
            "value": "350723",
            "title": "光泽县",
            "id": 1198,
            "firstLetter": "G"
          },
          {
            "value": "350724",
            "title": "松溪县",
            "id": 1199,
            "firstLetter": "S"
          },
          {
            "value": "350725",
            "title": "政和县",
            "id": 1200,
            "firstLetter": "Z"
          },
          {
            "value": "350781",
            "title": "邵武市",
            "id": 1201,
            "firstLetter": "S"
          },
          {
            "value": "350782",
            "title": "武夷山市",
            "id": 1202,
            "firstLetter": "W"
          },
          {
            "value": "350783",
            "title": "建瓯市",
            "id": 1203,
            "firstLetter": "J"
          },
          {
            "value": "350703",
            "title": "建阳区",
            "id": 4161,
            "firstLetter": "J"
          }
        ],
        "id": 123,
        "provinceID": 13,
        "firstLetter": "N"
      },
      {
        "value": "350800",
        "title": "龙岩市",
        "children": [
          {
            "value": "350802",
            "title": "新罗区",
            "id": 1206,
            "firstLetter": "X"
          },
          {
            "value": "350821",
            "title": "长汀县",
            "id": 1207,
            "firstLetter": "Z"
          },
          {
            "value": "350823",
            "title": "上杭县",
            "id": 1209,
            "firstLetter": "S"
          },
          {
            "value": "350824",
            "title": "武平县",
            "id": 1210,
            "firstLetter": "W"
          },
          {
            "value": "350825",
            "title": "连城县",
            "id": 1211,
            "firstLetter": "L"
          },
          {
            "value": "350881",
            "title": "漳平市",
            "id": 1212,
            "firstLetter": "Z"
          },
          {
            "value": "350803",
            "title": "永定区",
            "id": 4162,
            "firstLetter": "Y"
          }
        ],
        "id": 124,
        "provinceID": 13,
        "firstLetter": "L"
      },
      {
        "value": "350900",
        "title": "宁德市",
        "children": [
          {
            "value": "350902",
            "title": "蕉城区",
            "id": 1214,
            "firstLetter": "J"
          },
          {
            "value": "350921",
            "title": "霞浦县",
            "id": 1215,
            "firstLetter": "X"
          },
          {
            "value": "350922",
            "title": "古田县",
            "id": 1216,
            "firstLetter": "G"
          },
          {
            "value": "350923",
            "title": "屏南县",
            "id": 1217,
            "firstLetter": "P"
          },
          {
            "value": "350924",
            "title": "寿宁县",
            "id": 1218,
            "firstLetter": "S"
          },
          {
            "value": "350925",
            "title": "周宁县",
            "id": 1219,
            "firstLetter": "Z"
          },
          {
            "value": "350926",
            "title": "柘荣县",
            "id": 1220,
            "firstLetter": "Z"
          },
          {
            "value": "350981",
            "title": "福安市",
            "id": 1221,
            "firstLetter": "F"
          },
          {
            "value": "350982",
            "title": "福鼎市",
            "id": 1222,
            "firstLetter": "F"
          }
        ],
        "id": 125,
        "provinceID": 13,
        "firstLetter": "N"
      }
    ],
    "title": "福建省",
    "id": 13,
    "firstLetter": "F"
  },
  {
    "value": "360000",
    "children": [
      {
        "value": "360100",
        "title": "南昌市",
        "children": [
          {
            "value": "360102",
            "title": "东湖区",
            "id": 1224,
            "firstLetter": "D"
          },
          {
            "value": "360103",
            "title": "西湖区",
            "id": 1225,
            "firstLetter": "X"
          },
          {
            "value": "360104",
            "title": "青云谱区",
            "id": 1226,
            "firstLetter": "Q"
          },
          {
            "value": "360105",
            "title": "湾里区",
            "id": 1227,
            "firstLetter": "W"
          },
          {
            "value": "360111",
            "title": "青山湖区",
            "id": 1228,
            "firstLetter": "Q"
          },
          {
            "value": "360121",
            "title": "南昌县",
            "id": 1229,
            "firstLetter": "N"
          },
          {
            "value": "360123",
            "title": "安义县",
            "id": 1231,
            "firstLetter": "A"
          },
          {
            "value": "360124",
            "title": "进贤县",
            "id": 1232,
            "firstLetter": "J"
          },
          {
            "value": "360112",
            "title": "新建区",
            "id": 4163,
            "firstLetter": "X"
          },
          {
            "value": "360113",
            "title": "红谷滩区",
            "id": 4292,
            "firstLetter": "H"
          }
        ],
        "id": 126,
        "provinceID": 14,
        "firstLetter": "N"
      },
      {
        "value": "360200",
        "title": "景德镇市",
        "children": [
          {
            "value": "360202",
            "title": "昌江区",
            "id": 1234,
            "firstLetter": "C"
          },
          {
            "value": "360203",
            "title": "珠山区",
            "id": 1235,
            "firstLetter": "Z"
          },
          {
            "value": "360222",
            "title": "浮梁县",
            "id": 1236,
            "firstLetter": "F"
          },
          {
            "value": "360281",
            "title": "乐平市",
            "id": 1237,
            "firstLetter": "L"
          }
        ],
        "id": 127,
        "provinceID": 14,
        "firstLetter": "J"
      },
      {
        "value": "360300",
        "title": "萍乡市",
        "children": [
          {
            "value": "360302",
            "title": "安源区",
            "id": 1239,
            "firstLetter": "A"
          },
          {
            "value": "360313",
            "title": "湘东区",
            "id": 1240,
            "firstLetter": "X"
          },
          {
            "value": "360321",
            "title": "莲花县",
            "id": 1241,
            "firstLetter": "L"
          },
          {
            "value": "360322",
            "title": "上栗县",
            "id": 1242,
            "firstLetter": "S"
          },
          {
            "value": "360323",
            "title": "芦溪县",
            "id": 1243,
            "firstLetter": "L"
          }
        ],
        "id": 128,
        "provinceID": 14,
        "firstLetter": "P"
      },
      {
        "value": "360400",
        "title": "九江市",
        "children": [
          {
            "value": "360402",
            "title": "庐山区",
            "id": 1245,
            "firstLetter": "L"
          },
          {
            "value": "360403",
            "title": "浔阳区",
            "id": 1246,
            "firstLetter": "X"
          },
          {
            "value": "360423",
            "title": "武宁县",
            "id": 1248,
            "firstLetter": "W"
          },
          {
            "value": "360424",
            "title": "修水县",
            "id": 1249,
            "firstLetter": "X"
          },
          {
            "value": "360425",
            "title": "永修县",
            "id": 1250,
            "firstLetter": "Y"
          },
          {
            "value": "360426",
            "title": "德安县",
            "id": 1251,
            "firstLetter": "D"
          },
          {
            "value": "360428",
            "title": "都昌县",
            "id": 1253,
            "firstLetter": "D"
          },
          {
            "value": "360429",
            "title": "湖口县",
            "id": 1254,
            "firstLetter": "H"
          },
          {
            "value": "360430",
            "title": "彭泽县",
            "id": 1255,
            "firstLetter": "P"
          },
          {
            "value": "360481",
            "title": "瑞昌市",
            "id": 1256,
            "firstLetter": "R"
          },
          {
            "value": "360482",
            "title": "共青城市",
            "id": 1257,
            "firstLetter": "G"
          },
          {
            "value": "360404",
            "title": "柴桑区",
            "id": 4164,
            "firstLetter": "C"
          },
          {
            "value": "360483",
            "title": "庐山市",
            "id": 4165,
            "firstLetter": "L"
          }
        ],
        "id": 129,
        "provinceID": 14,
        "firstLetter": "J"
      },
      {
        "value": "360500",
        "title": "新余市",
        "children": [
          {
            "value": "360502",
            "title": "渝水区",
            "id": 1259,
            "firstLetter": "Y"
          },
          {
            "value": "360521",
            "title": "分宜县",
            "id": 1260,
            "firstLetter": "F"
          }
        ],
        "id": 130,
        "provinceID": 14,
        "firstLetter": "X"
      },
      {
        "value": "360600",
        "title": "鹰潭市",
        "children": [
          {
            "value": "360602",
            "title": "月湖区",
            "id": 1262,
            "firstLetter": "Y"
          },
          {
            "value": "360681",
            "title": "贵溪市",
            "id": 1264,
            "firstLetter": "G"
          },
          {
            "value": "360603",
            "title": "余江区",
            "id": 4166,
            "firstLetter": "Y"
          }
        ],
        "id": 131,
        "provinceID": 14,
        "firstLetter": "Y"
      },
      {
        "value": "360700",
        "title": "赣州市",
        "children": [
          {
            "value": "360702",
            "title": "章贡区",
            "id": 1266,
            "firstLetter": "Z"
          },
          {
            "value": "360703",
            "title": "南康区",
            "id": 1267,
            "firstLetter": "N"
          },
          {
            "value": "360722",
            "title": "信丰县",
            "id": 1269,
            "firstLetter": "X"
          },
          {
            "value": "360723",
            "title": "大余县",
            "id": 1270,
            "firstLetter": "D"
          },
          {
            "value": "360724",
            "title": "上犹县",
            "id": 1271,
            "firstLetter": "S"
          },
          {
            "value": "360725",
            "title": "崇义县",
            "id": 1272,
            "firstLetter": "C"
          },
          {
            "value": "360726",
            "title": "安远县",
            "id": 1273,
            "firstLetter": "A"
          },
          {
            "value": "360727",
            "title": "龙南县",
            "id": 1274,
            "firstLetter": "L"
          },
          {
            "value": "360728",
            "title": "定南县",
            "id": 1275,
            "firstLetter": "D"
          },
          {
            "value": "360729",
            "title": "全南县",
            "id": 1276,
            "firstLetter": "Q"
          },
          {
            "value": "360730",
            "title": "宁都县",
            "id": 1277,
            "firstLetter": "N"
          },
          {
            "value": "360731",
            "title": "于都县",
            "id": 1278,
            "firstLetter": "Y"
          },
          {
            "value": "360732",
            "title": "兴国县",
            "id": 1279,
            "firstLetter": "X"
          },
          {
            "value": "360733",
            "title": "会昌县",
            "id": 1280,
            "firstLetter": "H"
          },
          {
            "value": "360734",
            "title": "寻乌县",
            "id": 1281,
            "firstLetter": "X"
          },
          {
            "value": "360735",
            "title": "石城县",
            "id": 1282,
            "firstLetter": "S"
          },
          {
            "value": "360781",
            "title": "瑞金市",
            "id": 1283,
            "firstLetter": "R"
          },
          {
            "value": "360704",
            "title": "赣县区",
            "id": 4167,
            "firstLetter": "G"
          }
        ],
        "id": 132,
        "provinceID": 14,
        "firstLetter": "G"
      },
      {
        "value": "360800",
        "title": "吉安市",
        "children": [
          {
            "value": "360802",
            "title": "吉州区",
            "id": 1285,
            "firstLetter": "J"
          },
          {
            "value": "360803",
            "title": "青原区",
            "id": 1286,
            "firstLetter": "Q"
          },
          {
            "value": "360821",
            "title": "吉安县",
            "id": 1287,
            "firstLetter": "J"
          },
          {
            "value": "360822",
            "title": "吉水县",
            "id": 1288,
            "firstLetter": "J"
          },
          {
            "value": "360823",
            "title": "峡江县",
            "id": 1289,
            "firstLetter": "X"
          },
          {
            "value": "360824",
            "title": "新干县",
            "id": 1290,
            "firstLetter": "X"
          },
          {
            "value": "360825",
            "title": "永丰县",
            "id": 1291,
            "firstLetter": "Y"
          },
          {
            "value": "360826",
            "title": "泰和县",
            "id": 1292,
            "firstLetter": "T"
          },
          {
            "value": "360827",
            "title": "遂川县",
            "id": 1293,
            "firstLetter": "S"
          },
          {
            "value": "360828",
            "title": "万安县",
            "id": 1294,
            "firstLetter": "W"
          },
          {
            "value": "360829",
            "title": "安福县",
            "id": 1295,
            "firstLetter": "A"
          },
          {
            "value": "360830",
            "title": "永新县",
            "id": 1296,
            "firstLetter": "Y"
          },
          {
            "value": "360881",
            "title": "井冈山市",
            "id": 1297,
            "firstLetter": "J"
          }
        ],
        "id": 133,
        "provinceID": 14,
        "firstLetter": "J"
      },
      {
        "value": "360900",
        "title": "宜春市",
        "children": [
          {
            "value": "360902",
            "title": "袁州区",
            "id": 1299,
            "firstLetter": "Y"
          },
          {
            "value": "360921",
            "title": "奉新县",
            "id": 1300,
            "firstLetter": "F"
          },
          {
            "value": "360922",
            "title": "万载县",
            "id": 1301,
            "firstLetter": "W"
          },
          {
            "value": "360923",
            "title": "上高县",
            "id": 1302,
            "firstLetter": "S"
          },
          {
            "value": "360924",
            "title": "宜丰县",
            "id": 1303,
            "firstLetter": "Y"
          },
          {
            "value": "360925",
            "title": "靖安县",
            "id": 1304,
            "firstLetter": "J"
          },
          {
            "value": "360926",
            "title": "铜鼓县",
            "id": 1305,
            "firstLetter": "T"
          },
          {
            "value": "360981",
            "title": "丰城市",
            "id": 1306,
            "firstLetter": "F"
          },
          {
            "value": "360982",
            "title": "樟树市",
            "id": 1307,
            "firstLetter": "Z"
          },
          {
            "value": "360983",
            "title": "高安市",
            "id": 1308,
            "firstLetter": "G"
          }
        ],
        "id": 134,
        "provinceID": 14,
        "firstLetter": "Y"
      },
      {
        "value": "361000",
        "title": "抚州市",
        "children": [
          {
            "value": "361002",
            "title": "临川区",
            "id": 1310,
            "firstLetter": "L"
          },
          {
            "value": "361021",
            "title": "南城县",
            "id": 1311,
            "firstLetter": "N"
          },
          {
            "value": "361022",
            "title": "黎川县",
            "id": 1312,
            "firstLetter": "L"
          },
          {
            "value": "361023",
            "title": "南丰县",
            "id": 1313,
            "firstLetter": "N"
          },
          {
            "value": "361024",
            "title": "崇仁县",
            "id": 1314,
            "firstLetter": "C"
          },
          {
            "value": "361025",
            "title": "乐安县",
            "id": 1315,
            "firstLetter": "L"
          },
          {
            "value": "361026",
            "title": "宜黄县",
            "id": 1316,
            "firstLetter": "Y"
          },
          {
            "value": "361027",
            "title": "金溪县",
            "id": 1317,
            "firstLetter": "J"
          },
          {
            "value": "361028",
            "title": "资溪县",
            "id": 1318,
            "firstLetter": "Z"
          },
          {
            "value": "361030",
            "title": "广昌县",
            "id": 1320,
            "firstLetter": "G"
          },
          {
            "value": "361003",
            "title": "东乡区",
            "id": 4168,
            "firstLetter": "D"
          }
        ],
        "id": 135,
        "provinceID": 14,
        "firstLetter": "F"
      },
      {
        "value": "361100",
        "title": "上饶市",
        "children": [
          {
            "value": "361102",
            "title": "信州区",
            "id": 1322,
            "firstLetter": "X"
          },
          {
            "value": "361121",
            "title": "上饶县",
            "id": 1323,
            "firstLetter": "S"
          },
          {
            "value": "361123",
            "title": "玉山县",
            "id": 1325,
            "firstLetter": "Y"
          },
          {
            "value": "361124",
            "title": "铅山县",
            "id": 1326,
            "firstLetter": "Q"
          },
          {
            "value": "361125",
            "title": "横峰县",
            "id": 1327,
            "firstLetter": "H"
          },
          {
            "value": "361126",
            "title": "弋阳县",
            "id": 1328,
            "firstLetter": "Y"
          },
          {
            "value": "361127",
            "title": "余干县",
            "id": 1329,
            "firstLetter": "Y"
          },
          {
            "value": "361128",
            "title": "鄱阳县",
            "id": 1330,
            "firstLetter": "P"
          },
          {
            "value": "361129",
            "title": "万年县",
            "id": 1331,
            "firstLetter": "W"
          },
          {
            "value": "361130",
            "title": "婺源县",
            "id": 1332,
            "firstLetter": "W"
          },
          {
            "value": "361181",
            "title": "德兴市",
            "id": 1333,
            "firstLetter": "D"
          },
          {
            "value": "361103",
            "title": "广丰区",
            "id": 4169,
            "firstLetter": "G"
          }
        ],
        "id": 136,
        "provinceID": 14,
        "firstLetter": "S"
      }
    ],
    "title": "江西省",
    "id": 14,
    "firstLetter": "J"
  },
  {
    "value": "370000",
    "children": [
      {
        "value": "370100",
        "title": "济南市",
        "children": [
          {
            "value": "370102",
            "title": "历下区",
            "id": 1335,
            "firstLetter": "L"
          },
          {
            "value": "370103",
            "title": "市中区",
            "id": 1336,
            "firstLetter": "S"
          },
          {
            "value": "370104",
            "title": "槐荫区",
            "id": 1337,
            "firstLetter": "H"
          },
          {
            "value": "370105",
            "title": "天桥区",
            "id": 1338,
            "firstLetter": "T"
          },
          {
            "value": "370112",
            "title": "历城区",
            "id": 1339,
            "firstLetter": "L"
          },
          {
            "value": "370113",
            "title": "长清区",
            "id": 1340,
            "firstLetter": "Z"
          },
          {
            "value": "370124",
            "title": "平阴县",
            "id": 1341,
            "firstLetter": "P"
          },
          {
            "value": "370126",
            "title": "商河县",
            "id": 1343,
            "firstLetter": "S"
          },
          {
            "value": "370114",
            "title": "章丘区",
            "id": 4170,
            "firstLetter": "Z"
          },
          {
            "value": "370115",
            "title": "济阳区",
            "id": 4171,
            "firstLetter": "J"
          }
        ],
        "id": 137,
        "provinceID": 15,
        "firstLetter": "J"
      },
      {
        "value": "370200",
        "title": "青岛市",
        "children": [
          {
            "value": "370202",
            "title": "市南区",
            "id": 1346,
            "firstLetter": "S"
          },
          {
            "value": "370203",
            "title": "市北区",
            "id": 1347,
            "firstLetter": "S"
          },
          {
            "value": "370211",
            "title": "黄岛区",
            "id": 1348,
            "firstLetter": "H"
          },
          {
            "value": "370212",
            "title": "崂山区",
            "id": 1349,
            "firstLetter": "L"
          },
          {
            "value": "370213",
            "title": "李沧区",
            "id": 1350,
            "firstLetter": "L"
          },
          {
            "value": "370214",
            "title": "城阳区",
            "id": 1351,
            "firstLetter": "C"
          },
          {
            "value": "370281",
            "title": "胶州市",
            "id": 1352,
            "firstLetter": "J"
          },
          {
            "value": "370283",
            "title": "平度市",
            "id": 1354,
            "firstLetter": "P"
          },
          {
            "value": "370285",
            "title": "莱西市",
            "id": 1355,
            "firstLetter": "L"
          },
          {
            "value": "370215",
            "title": "即墨区",
            "id": 4172,
            "firstLetter": "J"
          }
        ],
        "id": 138,
        "provinceID": 15,
        "firstLetter": "Q"
      },
      {
        "value": "370300",
        "title": "淄博市",
        "children": [
          {
            "value": "370302",
            "title": "淄川区",
            "id": 1357,
            "firstLetter": "Z"
          },
          {
            "value": "370303",
            "title": "张店区",
            "id": 1358,
            "firstLetter": "Z"
          },
          {
            "value": "370304",
            "title": "博山区",
            "id": 1359,
            "firstLetter": "B"
          },
          {
            "value": "370305",
            "title": "临淄区",
            "id": 1360,
            "firstLetter": "L"
          },
          {
            "value": "370306",
            "title": "周村区",
            "id": 1361,
            "firstLetter": "Z"
          },
          {
            "value": "370321",
            "title": "桓台县",
            "id": 1362,
            "firstLetter": "H"
          },
          {
            "value": "370322",
            "title": "高青县",
            "id": 1363,
            "firstLetter": "G"
          },
          {
            "value": "370323",
            "title": "沂源县",
            "id": 1364,
            "firstLetter": "Y"
          }
        ],
        "id": 139,
        "provinceID": 15,
        "firstLetter": "Z"
      },
      {
        "value": "370400",
        "title": "枣庄市",
        "children": [
          {
            "value": "370402",
            "title": "市中区",
            "id": 1366,
            "firstLetter": "S"
          },
          {
            "value": "370403",
            "title": "薛城区",
            "id": 1367,
            "firstLetter": "X"
          },
          {
            "value": "370404",
            "title": "峄城区",
            "id": 1368,
            "firstLetter": "Y"
          },
          {
            "value": "370405",
            "title": "台儿庄区",
            "id": 1369,
            "firstLetter": "T"
          },
          {
            "value": "370406",
            "title": "山亭区",
            "id": 1370,
            "firstLetter": "S"
          },
          {
            "value": "370481",
            "title": "滕州市",
            "id": 1371,
            "firstLetter": "T"
          }
        ],
        "id": 140,
        "provinceID": 15,
        "firstLetter": "Z"
      },
      {
        "value": "370500",
        "title": "东营市",
        "children": [
          {
            "value": "370502",
            "title": "东营区",
            "id": 1373,
            "firstLetter": "D"
          },
          {
            "value": "370503",
            "title": "河口区",
            "id": 1374,
            "firstLetter": "H"
          },
          {
            "value": "370522",
            "title": "利津县",
            "id": 1376,
            "firstLetter": "L"
          },
          {
            "value": "370523",
            "title": "广饶县",
            "id": 1377,
            "firstLetter": "G"
          },
          {
            "value": "370505",
            "title": "垦利区",
            "id": 4173,
            "firstLetter": "K"
          }
        ],
        "id": 141,
        "provinceID": 15,
        "firstLetter": "D"
      },
      {
        "value": "370600",
        "title": "烟台市",
        "children": [
          {
            "value": "370602",
            "title": "芝罘区",
            "id": 1379,
            "firstLetter": "Z"
          },
          {
            "value": "370611",
            "title": "福山区",
            "id": 1380,
            "firstLetter": "F"
          },
          {
            "value": "370612",
            "title": "牟平区",
            "id": 1381,
            "firstLetter": "M"
          },
          {
            "value": "370613",
            "title": "莱山区",
            "id": 1382,
            "firstLetter": "L"
          },
          {
            "value": "370634",
            "title": "长岛县",
            "id": 1383,
            "firstLetter": "Z"
          },
          {
            "value": "370681",
            "title": "龙口市",
            "id": 1384,
            "firstLetter": "L"
          },
          {
            "value": "370682",
            "title": "莱阳市",
            "id": 1385,
            "firstLetter": "L"
          },
          {
            "value": "370683",
            "title": "莱州市",
            "id": 1386,
            "firstLetter": "L"
          },
          {
            "value": "370684",
            "title": "蓬莱市",
            "id": 1387,
            "firstLetter": "P"
          },
          {
            "value": "370685",
            "title": "招远市",
            "id": 1388,
            "firstLetter": "Z"
          },
          {
            "value": "370686",
            "title": "栖霞市",
            "id": 1389,
            "firstLetter": "Q"
          },
          {
            "value": "370687",
            "title": "海阳市",
            "id": 1390,
            "firstLetter": "H"
          }
        ],
        "id": 142,
        "provinceID": 15,
        "firstLetter": "Y"
      },
      {
        "value": "370700",
        "title": "潍坊市",
        "children": [
          {
            "value": "370702",
            "title": "潍城区",
            "id": 1392,
            "firstLetter": "W"
          },
          {
            "value": "370703",
            "title": "寒亭区",
            "id": 1393,
            "firstLetter": "H"
          },
          {
            "value": "370704",
            "title": "坊子区",
            "id": 1394,
            "firstLetter": "F"
          },
          {
            "value": "370705",
            "title": "奎文区",
            "id": 1395,
            "firstLetter": "K"
          },
          {
            "value": "370724",
            "title": "临朐县",
            "id": 1396,
            "firstLetter": "L"
          },
          {
            "value": "370725",
            "title": "昌乐县",
            "id": 1397,
            "firstLetter": "C"
          },
          {
            "value": "370781",
            "title": "青州市",
            "id": 1398,
            "firstLetter": "Q"
          },
          {
            "value": "370782",
            "title": "诸城市",
            "id": 1399,
            "firstLetter": "Z"
          },
          {
            "value": "370783",
            "title": "寿光市",
            "id": 1400,
            "firstLetter": "S"
          },
          {
            "value": "370784",
            "title": "安丘市",
            "id": 1401,
            "firstLetter": "A"
          },
          {
            "value": "370785",
            "title": "高密市",
            "id": 1402,
            "firstLetter": "G"
          },
          {
            "value": "370786",
            "title": "昌邑市",
            "id": 1403,
            "firstLetter": "C"
          }
        ],
        "id": 143,
        "provinceID": 15,
        "firstLetter": "W"
      },
      {
        "value": "370800",
        "title": "济宁市",
        "children": [
          {
            "value": "370811",
            "title": "任城区",
            "id": 1405,
            "firstLetter": "R"
          },
          {
            "value": "370812",
            "title": "兖州区",
            "id": 1406,
            "firstLetter": "Y"
          },
          {
            "value": "370826",
            "title": "微山县",
            "id": 1407,
            "firstLetter": "W"
          },
          {
            "value": "370827",
            "title": "鱼台县",
            "id": 1408,
            "firstLetter": "Y"
          },
          {
            "value": "370828",
            "title": "金乡县",
            "id": 1409,
            "firstLetter": "J"
          },
          {
            "value": "370829",
            "title": "嘉祥县",
            "id": 1410,
            "firstLetter": "J"
          },
          {
            "value": "370830",
            "title": "汶上县",
            "id": 1411,
            "firstLetter": "W"
          },
          {
            "value": "370831",
            "title": "泗水县",
            "id": 1412,
            "firstLetter": "S"
          },
          {
            "value": "370832",
            "title": "梁山县",
            "id": 1413,
            "firstLetter": "L"
          },
          {
            "value": "370881",
            "title": "曲阜市",
            "id": 1414,
            "firstLetter": "Q"
          },
          {
            "value": "370883",
            "title": "邹城市",
            "id": 1415,
            "firstLetter": "Z"
          }
        ],
        "id": 144,
        "provinceID": 15,
        "firstLetter": "J"
      },
      {
        "value": "370900",
        "title": "泰安市",
        "children": [
          {
            "value": "370902",
            "title": "泰山区",
            "id": 1417,
            "firstLetter": "T"
          },
          {
            "value": "370911",
            "title": "岱岳区",
            "id": 1418,
            "firstLetter": "D"
          },
          {
            "value": "370921",
            "title": "宁阳县",
            "id": 1419,
            "firstLetter": "N"
          },
          {
            "value": "370923",
            "title": "东平县",
            "id": 1420,
            "firstLetter": "D"
          },
          {
            "value": "370982",
            "title": "新泰市",
            "id": 1421,
            "firstLetter": "X"
          },
          {
            "value": "370983",
            "title": "肥城市",
            "id": 1422,
            "firstLetter": "F"
          }
        ],
        "id": 145,
        "provinceID": 15,
        "firstLetter": "T"
      },
      {
        "value": "371000",
        "title": "威海市",
        "children": [
          {
            "value": "371002",
            "title": "环翠区",
            "id": 1424,
            "firstLetter": "H"
          },
          {
            "value": "371003",
            "title": "文登区",
            "id": 1425,
            "firstLetter": "W"
          },
          {
            "value": "371082",
            "title": "荣成市",
            "id": 1426,
            "firstLetter": "R"
          },
          {
            "value": "371083",
            "title": "乳山市",
            "id": 1427,
            "firstLetter": "R"
          }
        ],
        "id": 146,
        "provinceID": 15,
        "firstLetter": "W"
      },
      {
        "value": "371100",
        "title": "日照市",
        "children": [
          {
            "value": "371102",
            "title": "东港区",
            "id": 1429,
            "firstLetter": "D"
          },
          {
            "value": "371103",
            "title": "岚山区",
            "id": 1430,
            "firstLetter": "L"
          },
          {
            "value": "371121",
            "title": "五莲县",
            "id": 1431,
            "firstLetter": "W"
          },
          {
            "value": "371122",
            "title": "莒县",
            "id": 1432,
            "firstLetter": "J"
          }
        ],
        "id": 147,
        "provinceID": 15,
        "firstLetter": "R"
      },
      {
        "value": "371200",
        "title": "莱芜市",
        "children": [
          {
            "value": "371202",
            "title": "莱城区",
            "id": 1434,
            "firstLetter": "L"
          },
          {
            "value": "371203",
            "title": "钢城区",
            "id": 1435,
            "firstLetter": "G"
          }
        ],
        "id": 148,
        "provinceID": 15,
        "firstLetter": "L"
      },
      {
        "value": "371300",
        "title": "临沂市",
        "children": [
          {
            "value": "371302",
            "title": "兰山区",
            "id": 1437,
            "firstLetter": "L"
          },
          {
            "value": "371311",
            "title": "罗庄区",
            "id": 1438,
            "firstLetter": "L"
          },
          {
            "value": "371312",
            "title": "河东区",
            "id": 1439,
            "firstLetter": "H"
          },
          {
            "value": "371321",
            "title": "沂南县",
            "id": 1440,
            "firstLetter": "Y"
          },
          {
            "value": "371322",
            "title": "郯城县",
            "id": 1441,
            "firstLetter": "T"
          },
          {
            "value": "371323",
            "title": "沂水县",
            "id": 1442,
            "firstLetter": "Y"
          },
          {
            "value": "371324",
            "title": "兰陵县",
            "id": 1443,
            "firstLetter": "L"
          },
          {
            "value": "371325",
            "title": "费县",
            "id": 1444,
            "firstLetter": "F"
          },
          {
            "value": "371326",
            "title": "平邑县",
            "id": 1445,
            "firstLetter": "P"
          },
          {
            "value": "371327",
            "title": "莒南县",
            "id": 1446,
            "firstLetter": "J"
          },
          {
            "value": "371328",
            "title": "蒙阴县",
            "id": 1447,
            "firstLetter": "M"
          },
          {
            "value": "371329",
            "title": "临沭县",
            "id": 1448,
            "firstLetter": "L"
          }
        ],
        "id": 149,
        "provinceID": 15,
        "firstLetter": "L"
      },
      {
        "value": "371400",
        "title": "德州市",
        "children": [
          {
            "value": "371402",
            "title": "德城区",
            "id": 1450,
            "firstLetter": "D"
          },
          {
            "value": "371403",
            "title": "陵城区",
            "id": 1451,
            "firstLetter": "L"
          },
          {
            "value": "371422",
            "title": "宁津县",
            "id": 1452,
            "firstLetter": "N"
          },
          {
            "value": "371423",
            "title": "庆云县",
            "id": 1453,
            "firstLetter": "Q"
          },
          {
            "value": "371424",
            "title": "临邑县",
            "id": 1454,
            "firstLetter": "L"
          },
          {
            "value": "371425",
            "title": "齐河县",
            "id": 1455,
            "firstLetter": "Q"
          },
          {
            "value": "371426",
            "title": "平原县",
            "id": 1456,
            "firstLetter": "P"
          },
          {
            "value": "371427",
            "title": "夏津县",
            "id": 1457,
            "firstLetter": "X"
          },
          {
            "value": "371428",
            "title": "武城县",
            "id": 1458,
            "firstLetter": "W"
          },
          {
            "value": "371481",
            "title": "乐陵市",
            "id": 1459,
            "firstLetter": "L"
          },
          {
            "value": "371482",
            "title": "禹城市",
            "id": 1460,
            "firstLetter": "Y"
          }
        ],
        "id": 150,
        "provinceID": 15,
        "firstLetter": "D"
      },
      {
        "value": "371500",
        "title": "聊城市",
        "children": [
          {
            "value": "371502",
            "title": "东昌府区",
            "id": 1462,
            "firstLetter": "D"
          },
          {
            "value": "371521",
            "title": "阳谷县",
            "id": 1463,
            "firstLetter": "Y"
          },
          {
            "value": "371522",
            "title": "莘县",
            "id": 1464,
            "firstLetter": "X"
          },
          {
            "value": "371523",
            "title": "茌平县",
            "id": 1465,
            "firstLetter": "C"
          },
          {
            "value": "371524",
            "title": "东阿县",
            "id": 1466,
            "firstLetter": "D"
          },
          {
            "value": "371525",
            "title": "冠县",
            "id": 1467,
            "firstLetter": "G"
          },
          {
            "value": "371526",
            "title": "高唐县",
            "id": 1468,
            "firstLetter": "G"
          },
          {
            "value": "371581",
            "title": "临清市",
            "id": 1469,
            "firstLetter": "L"
          }
        ],
        "id": 151,
        "provinceID": 15,
        "firstLetter": "L"
      },
      {
        "value": "371600",
        "title": "滨州市",
        "children": [
          {
            "value": "371602",
            "title": "滨城区",
            "id": 1471,
            "firstLetter": "B"
          },
          {
            "value": "371603",
            "title": "沾化区",
            "id": 1472,
            "firstLetter": "Z"
          },
          {
            "value": "371621",
            "title": "惠民县",
            "id": 1473,
            "firstLetter": "H"
          },
          {
            "value": "371622",
            "title": "阳信县",
            "id": 1474,
            "firstLetter": "Y"
          },
          {
            "value": "371623",
            "title": "无棣县",
            "id": 1475,
            "firstLetter": "W"
          },
          {
            "value": "371625",
            "title": "博兴县",
            "id": 1476,
            "firstLetter": "B"
          },
          {
            "value": "371681",
            "title": "邹平市",
            "id": 4174,
            "firstLetter": "Z"
          }
        ],
        "id": 152,
        "provinceID": 15,
        "firstLetter": "B"
      },
      {
        "value": "371700",
        "title": "菏泽市",
        "children": [
          {
            "value": "371702",
            "title": "牡丹区",
            "id": 1479,
            "firstLetter": "M"
          },
          {
            "value": "371721",
            "title": "曹县",
            "id": 1480,
            "firstLetter": "C"
          },
          {
            "value": "371722",
            "title": "单县",
            "id": 1481,
            "firstLetter": "D"
          },
          {
            "value": "371723",
            "title": "成武县",
            "id": 1482,
            "firstLetter": "C"
          },
          {
            "value": "371724",
            "title": "巨野县",
            "id": 1483,
            "firstLetter": "J"
          },
          {
            "value": "371725",
            "title": "郓城县",
            "id": 1484,
            "firstLetter": "Y"
          },
          {
            "value": "371726",
            "title": "鄄城县",
            "id": 1485,
            "firstLetter": "J"
          },
          {
            "value": "371728",
            "title": "东明县",
            "id": 1487,
            "firstLetter": "D"
          },
          {
            "value": "371703",
            "title": "定陶区",
            "id": 4175,
            "firstLetter": "D"
          }
        ],
        "id": 153,
        "provinceID": 15,
        "firstLetter": "H"
      }
    ],
    "title": "山东省",
    "id": 15,
    "firstLetter": "S"
  },
  {
    "value": "410000",
    "children": [
      {
        "value": "410100",
        "title": "郑州市",
        "children": [
          {
            "value": "410102",
            "title": "中原区",
            "id": 1489,
            "firstLetter": "Z"
          },
          {
            "value": "410103",
            "title": "二七区",
            "id": 1490,
            "firstLetter": "E"
          },
          {
            "value": "410104",
            "title": "管城回族区",
            "id": 1491,
            "firstLetter": "G"
          },
          {
            "value": "410105",
            "title": "金水区",
            "id": 1492,
            "firstLetter": "J"
          },
          {
            "value": "410106",
            "title": "上街区",
            "id": 1493,
            "firstLetter": "S"
          },
          {
            "value": "410108",
            "title": "惠济区",
            "id": 1494,
            "firstLetter": "H"
          },
          {
            "value": "410122",
            "title": "中牟县",
            "id": 1495,
            "firstLetter": "Z"
          },
          {
            "value": "410181",
            "title": "巩义市",
            "id": 1496,
            "firstLetter": "G"
          },
          {
            "value": "410183",
            "title": "新密市",
            "id": 1497,
            "firstLetter": "X"
          },
          {
            "value": "410184",
            "title": "新郑市",
            "id": 1498,
            "firstLetter": "X"
          },
          {
            "value": "410185",
            "title": "登封市",
            "id": 1499,
            "firstLetter": "D"
          },
          {
            "value": "410182",
            "title": "荥阳市",
            "id": 4176,
            "firstLetter": "Y"
          }
        ],
        "id": 154,
        "provinceID": 16,
        "firstLetter": "Z"
      },
      {
        "value": "410200",
        "title": "开封市",
        "children": [
          {
            "value": "410202",
            "title": "龙亭区",
            "id": 1501,
            "firstLetter": "L"
          },
          {
            "value": "410203",
            "title": "顺河回族区",
            "id": 1502,
            "firstLetter": "S"
          },
          {
            "value": "410204",
            "title": "鼓楼区",
            "id": 1503,
            "firstLetter": "G"
          },
          {
            "value": "410205",
            "title": "禹王台区",
            "id": 1504,
            "firstLetter": "Y"
          },
          {
            "value": "410221",
            "title": "杞县",
            "id": 1506,
            "firstLetter": "Q"
          },
          {
            "value": "410222",
            "title": "通许县",
            "id": 1507,
            "firstLetter": "T"
          },
          {
            "value": "410223",
            "title": "尉氏县",
            "id": 1508,
            "firstLetter": "W"
          },
          {
            "value": "410225",
            "title": "兰考县",
            "id": 1510,
            "firstLetter": "L"
          },
          {
            "value": "410212",
            "title": "祥符区",
            "id": 4177,
            "firstLetter": "X"
          }
        ],
        "id": 155,
        "provinceID": 16,
        "firstLetter": "K"
      },
      {
        "value": "410300",
        "title": "洛阳市",
        "children": [
          {
            "value": "410302",
            "title": "老城区",
            "id": 1512,
            "firstLetter": "L"
          },
          {
            "value": "410303",
            "title": "西工区",
            "id": 1513,
            "firstLetter": "X"
          },
          {
            "value": "410304",
            "title": "瀍河回族区",
            "id": 1514,
            "firstLetter": "C"
          },
          {
            "value": "410305",
            "title": "涧西区",
            "id": 1515,
            "firstLetter": "J"
          },
          {
            "value": "410306",
            "title": "吉利区",
            "id": 1516,
            "firstLetter": "J"
          },
          {
            "value": "410311",
            "title": "洛龙区",
            "id": 1517,
            "firstLetter": "L"
          },
          {
            "value": "410322",
            "title": "孟津县",
            "id": 1518,
            "firstLetter": "M"
          },
          {
            "value": "410323",
            "title": "新安县",
            "id": 1519,
            "firstLetter": "X"
          },
          {
            "value": "410324",
            "title": "栾川县",
            "id": 1520,
            "firstLetter": "L"
          },
          {
            "value": "410325",
            "title": "嵩县",
            "id": 1521,
            "firstLetter": "S"
          },
          {
            "value": "410326",
            "title": "汝阳县",
            "id": 1522,
            "firstLetter": "R"
          },
          {
            "value": "410327",
            "title": "宜阳县",
            "id": 1523,
            "firstLetter": "Y"
          },
          {
            "value": "410328",
            "title": "洛宁县",
            "id": 1524,
            "firstLetter": "L"
          },
          {
            "value": "410329",
            "title": "伊川县",
            "id": 1525,
            "firstLetter": "Y"
          },
          {
            "value": "410381",
            "title": "偃师市",
            "id": 1526,
            "firstLetter": "Y"
          }
        ],
        "id": 156,
        "provinceID": 16,
        "firstLetter": "L"
      },
      {
        "value": "410400",
        "title": "平顶山市",
        "children": [
          {
            "value": "410402",
            "title": "新华区",
            "id": 1528,
            "firstLetter": "X"
          },
          {
            "value": "410403",
            "title": "卫东区",
            "id": 1529,
            "firstLetter": "W"
          },
          {
            "value": "410404",
            "title": "石龙区",
            "id": 1530,
            "firstLetter": "S"
          },
          {
            "value": "410411",
            "title": "湛河区",
            "id": 1531,
            "firstLetter": "Z"
          },
          {
            "value": "410421",
            "title": "宝丰县",
            "id": 1532,
            "firstLetter": "B"
          },
          {
            "value": "410422",
            "title": "叶县",
            "id": 1533,
            "firstLetter": "Y"
          },
          {
            "value": "410423",
            "title": "鲁山县",
            "id": 1534,
            "firstLetter": "L"
          },
          {
            "value": "410425",
            "title": "郏县",
            "id": 1535,
            "firstLetter": "J"
          },
          {
            "value": "410481",
            "title": "舞钢市",
            "id": 1536,
            "firstLetter": "W"
          },
          {
            "value": "410482",
            "title": "汝州市",
            "id": 1537,
            "firstLetter": "R"
          }
        ],
        "id": 157,
        "provinceID": 16,
        "firstLetter": "P"
      },
      {
        "value": "410500",
        "title": "安阳市",
        "children": [
          {
            "value": "410502",
            "title": "文峰区",
            "id": 1539,
            "firstLetter": "W"
          },
          {
            "value": "410503",
            "title": "北关区",
            "id": 1540,
            "firstLetter": "B"
          },
          {
            "value": "410505",
            "title": "殷都区",
            "id": 1541,
            "firstLetter": "Y"
          },
          {
            "value": "410506",
            "title": "龙安区",
            "id": 1542,
            "firstLetter": "L"
          },
          {
            "value": "410522",
            "title": "安阳县",
            "id": 1543,
            "firstLetter": "A"
          },
          {
            "value": "410523",
            "title": "汤阴县",
            "id": 1544,
            "firstLetter": "T"
          },
          {
            "value": "410526",
            "title": "滑县",
            "id": 1545,
            "firstLetter": "H"
          },
          {
            "value": "410527",
            "title": "内黄县",
            "id": 1546,
            "firstLetter": "N"
          },
          {
            "value": "410581",
            "title": "林州市",
            "id": 1547,
            "firstLetter": "L"
          }
        ],
        "id": 158,
        "provinceID": 16,
        "firstLetter": "A"
      },
      {
        "value": "410600",
        "title": "鹤壁市",
        "children": [
          {
            "value": "410602",
            "title": "鹤山区",
            "id": 1549,
            "firstLetter": "H"
          },
          {
            "value": "410603",
            "title": "山城区",
            "id": 1550,
            "firstLetter": "S"
          },
          {
            "value": "410611",
            "title": "淇滨区",
            "id": 1551,
            "firstLetter": "Q"
          },
          {
            "value": "410621",
            "title": "浚县",
            "id": 1552,
            "firstLetter": "J"
          },
          {
            "value": "410622",
            "title": "淇县",
            "id": 1553,
            "firstLetter": "Q"
          }
        ],
        "id": 159,
        "provinceID": 16,
        "firstLetter": "H"
      },
      {
        "value": "410700",
        "title": "新乡市",
        "children": [
          {
            "value": "410702",
            "title": "红旗区",
            "id": 1555,
            "firstLetter": "H"
          },
          {
            "value": "410703",
            "title": "卫滨区",
            "id": 1556,
            "firstLetter": "W"
          },
          {
            "value": "410704",
            "title": "凤泉区",
            "id": 1557,
            "firstLetter": "F"
          },
          {
            "value": "410711",
            "title": "牧野区",
            "id": 1558,
            "firstLetter": "M"
          },
          {
            "value": "410721",
            "title": "新乡县",
            "id": 1559,
            "firstLetter": "X"
          },
          {
            "value": "410724",
            "title": "获嘉县",
            "id": 1560,
            "firstLetter": "H"
          },
          {
            "value": "410725",
            "title": "原阳县",
            "id": 1561,
            "firstLetter": "Y"
          },
          {
            "value": "410726",
            "title": "延津县",
            "id": 1562,
            "firstLetter": "Y"
          },
          {
            "value": "410727",
            "title": "封丘县",
            "id": 1563,
            "firstLetter": "F"
          },
          {
            "value": "410728",
            "title": "长垣县",
            "id": 1564,
            "firstLetter": "Z"
          },
          {
            "value": "410781",
            "title": "卫辉市",
            "id": 1565,
            "firstLetter": "W"
          },
          {
            "value": "410782",
            "title": "辉县市",
            "id": 1566,
            "firstLetter": "H"
          }
        ],
        "id": 160,
        "provinceID": 16,
        "firstLetter": "X"
      },
      {
        "value": "410800",
        "title": "焦作市",
        "children": [
          {
            "value": "410802",
            "title": "解放区",
            "id": 1568,
            "firstLetter": "J"
          },
          {
            "value": "410803",
            "title": "中站区",
            "id": 1569,
            "firstLetter": "Z"
          },
          {
            "value": "410804",
            "title": "马村区",
            "id": 1570,
            "firstLetter": "M"
          },
          {
            "value": "410811",
            "title": "山阳区",
            "id": 1571,
            "firstLetter": "S"
          },
          {
            "value": "410821",
            "title": "修武县",
            "id": 1572,
            "firstLetter": "X"
          },
          {
            "value": "410822",
            "title": "博爱县",
            "id": 1573,
            "firstLetter": "B"
          },
          {
            "value": "410823",
            "title": "武陟县",
            "id": 1574,
            "firstLetter": "W"
          },
          {
            "value": "410825",
            "title": "温县",
            "id": 1575,
            "firstLetter": "W"
          },
          {
            "value": "410882",
            "title": "沁阳市",
            "id": 1576,
            "firstLetter": "Q"
          },
          {
            "value": "410883",
            "title": "孟州市",
            "id": 1577,
            "firstLetter": "M"
          }
        ],
        "id": 161,
        "provinceID": 16,
        "firstLetter": "J"
      },
      {
        "value": "410900",
        "title": "濮阳市",
        "children": [
          {
            "value": "410902",
            "title": "华龙区",
            "id": 1579,
            "firstLetter": "H"
          },
          {
            "value": "410922",
            "title": "清丰县",
            "id": 1580,
            "firstLetter": "Q"
          },
          {
            "value": "410923",
            "title": "南乐县",
            "id": 1581,
            "firstLetter": "N"
          },
          {
            "value": "410926",
            "title": "范县",
            "id": 1582,
            "firstLetter": "F"
          },
          {
            "value": "410927",
            "title": "台前县",
            "id": 1583,
            "firstLetter": "T"
          },
          {
            "value": "410928",
            "title": "濮阳县",
            "id": 1584,
            "firstLetter": "P"
          }
        ],
        "id": 162,
        "provinceID": 16,
        "firstLetter": "P"
      },
      {
        "value": "411000",
        "title": "许昌市",
        "children": [
          {
            "value": "411002",
            "title": "魏都区",
            "id": 1586,
            "firstLetter": "W"
          },
          {
            "value": "411024",
            "title": "鄢陵县",
            "id": 1588,
            "firstLetter": "Y"
          },
          {
            "value": "411025",
            "title": "襄城县",
            "id": 1589,
            "firstLetter": "X"
          },
          {
            "value": "411081",
            "title": "禹州市",
            "id": 1590,
            "firstLetter": "Y"
          },
          {
            "value": "411082",
            "title": "长葛市",
            "id": 1591,
            "firstLetter": "Z"
          },
          {
            "value": "411003",
            "title": "建安区",
            "id": 4178,
            "firstLetter": "J"
          }
        ],
        "id": 163,
        "provinceID": 16,
        "firstLetter": "X"
      },
      {
        "value": "411100",
        "title": "漯河市",
        "children": [
          {
            "value": "411102",
            "title": "源汇区",
            "id": 1593,
            "firstLetter": "Y"
          },
          {
            "value": "411103",
            "title": "郾城区",
            "id": 1594,
            "firstLetter": "Y"
          },
          {
            "value": "411104",
            "title": "召陵区",
            "id": 1595,
            "firstLetter": "Z"
          },
          {
            "value": "411121",
            "title": "舞阳县",
            "id": 1596,
            "firstLetter": "W"
          },
          {
            "value": "411122",
            "title": "临颍县",
            "id": 1597,
            "firstLetter": "L"
          }
        ],
        "id": 164,
        "provinceID": 16,
        "firstLetter": "L"
      },
      {
        "value": "411200",
        "title": "三门峡市",
        "children": [
          {
            "value": "411202",
            "title": "湖滨区",
            "id": 1599,
            "firstLetter": "H"
          },
          {
            "value": "411221",
            "title": "渑池县",
            "id": 1600,
            "firstLetter": "M"
          },
          {
            "value": "411224",
            "title": "卢氏县",
            "id": 1602,
            "firstLetter": "L"
          },
          {
            "value": "411281",
            "title": "义马市",
            "id": 1603,
            "firstLetter": "Y"
          },
          {
            "value": "411282",
            "title": "灵宝市",
            "id": 1604,
            "firstLetter": "L"
          },
          {
            "value": "411203",
            "title": "陕州区",
            "id": 4179,
            "firstLetter": "S"
          }
        ],
        "id": 165,
        "provinceID": 16,
        "firstLetter": "S"
      },
      {
        "value": "411300",
        "title": "南阳市",
        "children": [
          {
            "value": "411302",
            "title": "宛城区",
            "id": 1606,
            "firstLetter": "W"
          },
          {
            "value": "411303",
            "title": "卧龙区",
            "id": 1607,
            "firstLetter": "W"
          },
          {
            "value": "411321",
            "title": "南召县",
            "id": 1608,
            "firstLetter": "N"
          },
          {
            "value": "411322",
            "title": "方城县",
            "id": 1609,
            "firstLetter": "F"
          },
          {
            "value": "411323",
            "title": "西峡县",
            "id": 1610,
            "firstLetter": "X"
          },
          {
            "value": "411324",
            "title": "镇平县",
            "id": 1611,
            "firstLetter": "Z"
          },
          {
            "value": "411325",
            "title": "内乡县",
            "id": 1612,
            "firstLetter": "N"
          },
          {
            "value": "411326",
            "title": "淅川县",
            "id": 1613,
            "firstLetter": "X"
          },
          {
            "value": "411327",
            "title": "社旗县",
            "id": 1614,
            "firstLetter": "S"
          },
          {
            "value": "411328",
            "title": "唐河县",
            "id": 1615,
            "firstLetter": "T"
          },
          {
            "value": "411329",
            "title": "新野县",
            "id": 1616,
            "firstLetter": "X"
          },
          {
            "value": "411330",
            "title": "桐柏县",
            "id": 1617,
            "firstLetter": "T"
          },
          {
            "value": "411381",
            "title": "邓州市",
            "id": 1618,
            "firstLetter": "D"
          }
        ],
        "id": 166,
        "provinceID": 16,
        "firstLetter": "N"
      },
      {
        "value": "411400",
        "title": "商丘市",
        "children": [
          {
            "value": "411402",
            "title": "梁园区",
            "id": 1620,
            "firstLetter": "L"
          },
          {
            "value": "411403",
            "title": "睢阳区",
            "id": 1621,
            "firstLetter": "S"
          },
          {
            "value": "411421",
            "title": "民权县",
            "id": 1622,
            "firstLetter": "M"
          },
          {
            "value": "411422",
            "title": "睢县",
            "id": 1623,
            "firstLetter": "S"
          },
          {
            "value": "411423",
            "title": "宁陵县",
            "id": 1624,
            "firstLetter": "N"
          },
          {
            "value": "411424",
            "title": "柘城县",
            "id": 1625,
            "firstLetter": "Z"
          },
          {
            "value": "411425",
            "title": "虞城县",
            "id": 1626,
            "firstLetter": "Y"
          },
          {
            "value": "411426",
            "title": "夏邑县",
            "id": 1627,
            "firstLetter": "X"
          },
          {
            "value": "411481",
            "title": "永城市",
            "id": 1628,
            "firstLetter": "Y"
          }
        ],
        "id": 167,
        "provinceID": 16,
        "firstLetter": "S"
      },
      {
        "value": "411500",
        "title": "信阳市",
        "children": [
          {
            "value": "411502",
            "title": "浉河区",
            "id": 1630,
            "firstLetter": "S"
          },
          {
            "value": "411503",
            "title": "平桥区",
            "id": 1631,
            "firstLetter": "P"
          },
          {
            "value": "411521",
            "title": "罗山县",
            "id": 1632,
            "firstLetter": "L"
          },
          {
            "value": "411522",
            "title": "光山县",
            "id": 1633,
            "firstLetter": "G"
          },
          {
            "value": "411523",
            "title": "新县",
            "id": 1634,
            "firstLetter": "X"
          },
          {
            "value": "411524",
            "title": "商城县",
            "id": 1635,
            "firstLetter": "S"
          },
          {
            "value": "411525",
            "title": "固始县",
            "id": 1636,
            "firstLetter": "G"
          },
          {
            "value": "411526",
            "title": "潢川县",
            "id": 1637,
            "firstLetter": "H"
          },
          {
            "value": "411527",
            "title": "淮滨县",
            "id": 1638,
            "firstLetter": "H"
          },
          {
            "value": "411528",
            "title": "息县",
            "id": 1639,
            "firstLetter": "X"
          }
        ],
        "id": 168,
        "provinceID": 16,
        "firstLetter": "X"
      },
      {
        "value": "411600",
        "title": "周口市",
        "children": [
          {
            "value": "411602",
            "title": "川汇区",
            "id": 1641,
            "firstLetter": "C"
          },
          {
            "value": "411621",
            "title": "扶沟县",
            "id": 1642,
            "firstLetter": "F"
          },
          {
            "value": "411622",
            "title": "西华县",
            "id": 1643,
            "firstLetter": "X"
          },
          {
            "value": "411623",
            "title": "商水县",
            "id": 1644,
            "firstLetter": "S"
          },
          {
            "value": "411624",
            "title": "沈丘县",
            "id": 1645,
            "firstLetter": "S"
          },
          {
            "value": "411625",
            "title": "郸城县",
            "id": 1646,
            "firstLetter": "D"
          },
          {
            "value": "411626",
            "title": "淮阳县",
            "id": 1647,
            "firstLetter": "H"
          },
          {
            "value": "411627",
            "title": "太康县",
            "id": 1648,
            "firstLetter": "T"
          },
          {
            "value": "411628",
            "title": "鹿邑县",
            "id": 1649,
            "firstLetter": "L"
          },
          {
            "value": "411681",
            "title": "项城市",
            "id": 1650,
            "firstLetter": "X"
          }
        ],
        "id": 169,
        "provinceID": 16,
        "firstLetter": "Z"
      },
      {
        "value": "411700",
        "title": "驻马店市",
        "children": [
          {
            "value": "411702",
            "title": "驿城区",
            "id": 1652,
            "firstLetter": "Y"
          },
          {
            "value": "411721",
            "title": "西平县",
            "id": 1653,
            "firstLetter": "X"
          },
          {
            "value": "411722",
            "title": "上蔡县",
            "id": 1654,
            "firstLetter": "S"
          },
          {
            "value": "411723",
            "title": "平舆县",
            "id": 1655,
            "firstLetter": "P"
          },
          {
            "value": "411724",
            "title": "正阳县",
            "id": 1656,
            "firstLetter": "Z"
          },
          {
            "value": "411725",
            "title": "确山县",
            "id": 1657,
            "firstLetter": "Q"
          },
          {
            "value": "411726",
            "title": "泌阳县",
            "id": 1658,
            "firstLetter": "M"
          },
          {
            "value": "411727",
            "title": "汝南县",
            "id": 1659,
            "firstLetter": "R"
          },
          {
            "value": "411728",
            "title": "遂平县",
            "id": 1660,
            "firstLetter": "S"
          },
          {
            "value": "411729",
            "title": "新蔡县",
            "id": 1661,
            "firstLetter": "X"
          }
        ],
        "id": 170,
        "provinceID": 16,
        "firstLetter": "Z"
      },
      {
        "value": "419000",
        "title": "省直辖县级行政区划",
        "children": [
          {
            "value": "419001",
            "title": "济源市",
            "id": 1662,
            "firstLetter": "J"
          }
        ],
        "id": 171,
        "provinceID": 16,
        "firstLetter": "S"
      }
    ],
    "title": "河南省",
    "id": 16,
    "firstLetter": "H"
  },
  {
    "value": "420000",
    "children": [
      {
        "value": "420100",
        "title": "武汉市",
        "children": [
          {
            "value": "420102",
            "title": "江岸区",
            "id": 1664,
            "firstLetter": "J"
          },
          {
            "value": "420103",
            "title": "江汉区",
            "id": 1665,
            "firstLetter": "J"
          },
          {
            "value": "420104",
            "title": "硚口区",
            "id": 1666,
            "firstLetter": "Q"
          },
          {
            "value": "420105",
            "title": "汉阳区",
            "id": 1667,
            "firstLetter": "H"
          },
          {
            "value": "420106",
            "title": "武昌区",
            "id": 1668,
            "firstLetter": "W"
          },
          {
            "value": "420107",
            "title": "青山区",
            "id": 1669,
            "firstLetter": "Q"
          },
          {
            "value": "420111",
            "title": "洪山区",
            "id": 1670,
            "firstLetter": "H"
          },
          {
            "value": "420112",
            "title": "东西湖区",
            "id": 1671,
            "firstLetter": "D"
          },
          {
            "value": "420113",
            "title": "汉南区",
            "id": 1672,
            "firstLetter": "H"
          },
          {
            "value": "420114",
            "title": "蔡甸区",
            "id": 1673,
            "firstLetter": "C"
          },
          {
            "value": "420115",
            "title": "江夏区",
            "id": 1674,
            "firstLetter": "J"
          },
          {
            "value": "420116",
            "title": "黄陂区",
            "id": 1675,
            "firstLetter": "H"
          },
          {
            "value": "420117",
            "title": "新洲区",
            "id": 1676,
            "firstLetter": "X"
          }
        ],
        "id": 172,
        "provinceID": 17,
        "firstLetter": "W"
      },
      {
        "value": "420200",
        "title": "黄石市",
        "children": [
          {
            "value": "420202",
            "title": "黄石港区",
            "id": 1678,
            "firstLetter": "H"
          },
          {
            "value": "420203",
            "title": "西塞山区",
            "id": 1679,
            "firstLetter": "X"
          },
          {
            "value": "420204",
            "title": "下陆区",
            "id": 1680,
            "firstLetter": "X"
          },
          {
            "value": "420205",
            "title": "铁山区",
            "id": 1681,
            "firstLetter": "T"
          },
          {
            "value": "420222",
            "title": "阳新县",
            "id": 1682,
            "firstLetter": "Y"
          },
          {
            "value": "420281",
            "title": "大冶市",
            "id": 1683,
            "firstLetter": "D"
          }
        ],
        "id": 173,
        "provinceID": 17,
        "firstLetter": "H"
      },
      {
        "value": "420300",
        "title": "十堰市",
        "children": [
          {
            "value": "420302",
            "title": "茅箭区",
            "id": 1685,
            "firstLetter": "M"
          },
          {
            "value": "420303",
            "title": "张湾区",
            "id": 1686,
            "firstLetter": "Z"
          },
          {
            "value": "420304",
            "title": "郧阳区",
            "id": 1687,
            "firstLetter": "Y"
          },
          {
            "value": "420322",
            "title": "郧西县",
            "id": 1688,
            "firstLetter": "Y"
          },
          {
            "value": "420323",
            "title": "竹山县",
            "id": 1689,
            "firstLetter": "Z"
          },
          {
            "value": "420324",
            "title": "竹溪县",
            "id": 1690,
            "firstLetter": "Z"
          },
          {
            "value": "420325",
            "title": "房县",
            "id": 1691,
            "firstLetter": "F"
          },
          {
            "value": "420381",
            "title": "丹江口市",
            "id": 1692,
            "firstLetter": "D"
          }
        ],
        "id": 174,
        "provinceID": 17,
        "firstLetter": "S"
      },
      {
        "value": "420500",
        "title": "宜昌市",
        "children": [
          {
            "value": "420502",
            "title": "西陵区",
            "id": 1694,
            "firstLetter": "X"
          },
          {
            "value": "420503",
            "title": "伍家岗区",
            "id": 1695,
            "firstLetter": "W"
          },
          {
            "value": "420504",
            "title": "点军区",
            "id": 1696,
            "firstLetter": "D"
          },
          {
            "value": "420505",
            "title": "猇亭区",
            "id": 1697,
            "firstLetter": "Y"
          },
          {
            "value": "420506",
            "title": "夷陵区",
            "id": 1698,
            "firstLetter": "Y"
          },
          {
            "value": "420525",
            "title": "远安县",
            "id": 1699,
            "firstLetter": "Y"
          },
          {
            "value": "420526",
            "title": "兴山县",
            "id": 1700,
            "firstLetter": "X"
          },
          {
            "value": "420527",
            "title": "秭归县",
            "id": 1701,
            "firstLetter": "Z"
          },
          {
            "value": "420528",
            "title": "长阳土家族自治县",
            "id": 1702,
            "firstLetter": "Z"
          },
          {
            "value": "420529",
            "title": "五峰土家族自治县",
            "id": 1703,
            "firstLetter": "W"
          },
          {
            "value": "420581",
            "title": "宜都市",
            "id": 1704,
            "firstLetter": "Y"
          },
          {
            "value": "420582",
            "title": "当阳市",
            "id": 1705,
            "firstLetter": "D"
          },
          {
            "value": "420583",
            "title": "枝江市",
            "id": 1706,
            "firstLetter": "Z"
          }
        ],
        "id": 175,
        "provinceID": 17,
        "firstLetter": "Y"
      },
      {
        "value": "420600",
        "title": "襄阳市",
        "children": [
          {
            "value": "420602",
            "title": "襄城区",
            "id": 1708,
            "firstLetter": "X"
          },
          {
            "value": "420606",
            "title": "樊城区",
            "id": 1709,
            "firstLetter": "F"
          },
          {
            "value": "420607",
            "title": "襄州区",
            "id": 1710,
            "firstLetter": "X"
          },
          {
            "value": "420624",
            "title": "南漳县",
            "id": 1711,
            "firstLetter": "N"
          },
          {
            "value": "420625",
            "title": "谷城县",
            "id": 1712,
            "firstLetter": "G"
          },
          {
            "value": "420626",
            "title": "保康县",
            "id": 1713,
            "firstLetter": "B"
          },
          {
            "value": "420682",
            "title": "老河口市",
            "id": 1714,
            "firstLetter": "L"
          },
          {
            "value": "420683",
            "title": "枣阳市",
            "id": 1715,
            "firstLetter": "Z"
          },
          {
            "value": "420684",
            "title": "宜城市",
            "id": 1716,
            "firstLetter": "Y"
          }
        ],
        "id": 176,
        "provinceID": 17,
        "firstLetter": "X"
      },
      {
        "value": "420700",
        "title": "鄂州市",
        "children": [
          {
            "value": "420702",
            "title": "梁子湖区",
            "id": 1718,
            "firstLetter": "L"
          },
          {
            "value": "420703",
            "title": "华容区",
            "id": 1719,
            "firstLetter": "H"
          },
          {
            "value": "420704",
            "title": "鄂城区",
            "id": 1720,
            "firstLetter": "E"
          }
        ],
        "id": 177,
        "provinceID": 17,
        "firstLetter": "E"
      },
      {
        "value": "420800",
        "title": "荆门市",
        "children": [
          {
            "value": "420802",
            "title": "东宝区",
            "id": 1722,
            "firstLetter": "D"
          },
          {
            "value": "420804",
            "title": "掇刀区",
            "id": 1723,
            "firstLetter": "D"
          },
          {
            "value": "420822",
            "title": "沙洋县",
            "id": 1725,
            "firstLetter": "S"
          },
          {
            "value": "420881",
            "title": "钟祥市",
            "id": 1726,
            "firstLetter": "Z"
          },
          {
            "value": "420882",
            "title": "京山市",
            "id": 4180,
            "firstLetter": "J"
          }
        ],
        "id": 178,
        "provinceID": 17,
        "firstLetter": "J"
      },
      {
        "value": "420900",
        "title": "孝感市",
        "children": [
          {
            "value": "420902",
            "title": "孝南区",
            "id": 1728,
            "firstLetter": "X"
          },
          {
            "value": "420921",
            "title": "孝昌县",
            "id": 1729,
            "firstLetter": "X"
          },
          {
            "value": "420922",
            "title": "大悟县",
            "id": 1730,
            "firstLetter": "D"
          },
          {
            "value": "420923",
            "title": "云梦县",
            "id": 1731,
            "firstLetter": "Y"
          },
          {
            "value": "420981",
            "title": "应城市",
            "id": 1732,
            "firstLetter": "Y"
          },
          {
            "value": "420982",
            "title": "安陆市",
            "id": 1733,
            "firstLetter": "A"
          },
          {
            "value": "420984",
            "title": "汉川市",
            "id": 1734,
            "firstLetter": "H"
          }
        ],
        "id": 179,
        "provinceID": 17,
        "firstLetter": "X"
      },
      {
        "value": "421000",
        "title": "荆州市",
        "children": [
          {
            "value": "421002",
            "title": "沙市区",
            "id": 1736,
            "firstLetter": "S"
          },
          {
            "value": "421003",
            "title": "荆州区",
            "id": 1737,
            "firstLetter": "J"
          },
          {
            "value": "421022",
            "title": "公安县",
            "id": 1738,
            "firstLetter": "G"
          },
          {
            "value": "421023",
            "title": "监利县",
            "id": 1739,
            "firstLetter": "J"
          },
          {
            "value": "421024",
            "title": "江陵县",
            "id": 1740,
            "firstLetter": "J"
          },
          {
            "value": "421081",
            "title": "石首市",
            "id": 1741,
            "firstLetter": "S"
          },
          {
            "value": "421083",
            "title": "洪湖市",
            "id": 1742,
            "firstLetter": "H"
          },
          {
            "value": "421087",
            "title": "松滋市",
            "id": 1743,
            "firstLetter": "S"
          }
        ],
        "id": 180,
        "provinceID": 17,
        "firstLetter": "J"
      },
      {
        "value": "421100",
        "title": "黄冈市",
        "children": [
          {
            "value": "421102",
            "title": "黄州区",
            "id": 1745,
            "firstLetter": "H"
          },
          {
            "value": "421121",
            "title": "团风县",
            "id": 1746,
            "firstLetter": "T"
          },
          {
            "value": "421122",
            "title": "红安县",
            "id": 1747,
            "firstLetter": "H"
          },
          {
            "value": "421123",
            "title": "罗田县",
            "id": 1748,
            "firstLetter": "L"
          },
          {
            "value": "421124",
            "title": "英山县",
            "id": 1749,
            "firstLetter": "Y"
          },
          {
            "value": "421125",
            "title": "浠水县",
            "id": 1750,
            "firstLetter": "X"
          },
          {
            "value": "421126",
            "title": "蕲春县",
            "id": 1751,
            "firstLetter": "Q"
          },
          {
            "value": "421127",
            "title": "黄梅县",
            "id": 1752,
            "firstLetter": "H"
          },
          {
            "value": "421181",
            "title": "麻城市",
            "id": 1753,
            "firstLetter": "M"
          },
          {
            "value": "421182",
            "title": "武穴市",
            "id": 1754,
            "firstLetter": "W"
          }
        ],
        "id": 181,
        "provinceID": 17,
        "firstLetter": "H"
      },
      {
        "value": "421200",
        "title": "咸宁市",
        "children": [
          {
            "value": "421202",
            "title": "咸安区",
            "id": 1756,
            "firstLetter": "X"
          },
          {
            "value": "421221",
            "title": "嘉鱼县",
            "id": 1757,
            "firstLetter": "J"
          },
          {
            "value": "421222",
            "title": "通城县",
            "id": 1758,
            "firstLetter": "T"
          },
          {
            "value": "421223",
            "title": "崇阳县",
            "id": 1759,
            "firstLetter": "C"
          },
          {
            "value": "421224",
            "title": "通山县",
            "id": 1760,
            "firstLetter": "T"
          },
          {
            "value": "421281",
            "title": "赤壁市",
            "id": 1761,
            "firstLetter": "C"
          }
        ],
        "id": 182,
        "provinceID": 17,
        "firstLetter": "X"
      },
      {
        "value": "421300",
        "title": "随州市",
        "children": [
          {
            "value": "421303",
            "title": "曾都区",
            "id": 1763,
            "firstLetter": "C"
          },
          {
            "value": "421321",
            "title": "随县",
            "id": 1764,
            "firstLetter": "S"
          },
          {
            "value": "421381",
            "title": "广水市",
            "id": 1765,
            "firstLetter": "G"
          }
        ],
        "id": 183,
        "provinceID": 17,
        "firstLetter": "S"
      },
      {
        "value": "422800",
        "title": "恩施土家族苗族自治州",
        "children": [
          {
            "value": "422801",
            "title": "恩施市",
            "id": 1766,
            "firstLetter": "E"
          },
          {
            "value": "422802",
            "title": "利川市",
            "id": 1767,
            "firstLetter": "L"
          },
          {
            "value": "422822",
            "title": "建始县",
            "id": 1768,
            "firstLetter": "J"
          },
          {
            "value": "422823",
            "title": "巴东县",
            "id": 1769,
            "firstLetter": "B"
          },
          {
            "value": "422825",
            "title": "宣恩县",
            "id": 1770,
            "firstLetter": "X"
          },
          {
            "value": "422826",
            "title": "咸丰县",
            "id": 1771,
            "firstLetter": "X"
          },
          {
            "value": "422827",
            "title": "来凤县",
            "id": 1772,
            "firstLetter": "L"
          },
          {
            "value": "422828",
            "title": "鹤峰县",
            "id": 1773,
            "firstLetter": "H"
          }
        ],
        "id": 184,
        "provinceID": 17,
        "firstLetter": "E"
      },
      {
        "value": "429000",
        "title": "省直辖县级行政区划",
        "children": [
          {
            "value": "429004",
            "title": "仙桃市",
            "id": 1774,
            "firstLetter": "X"
          },
          {
            "value": "429005",
            "title": "潜江市",
            "id": 1775,
            "firstLetter": "Q"
          },
          {
            "value": "429006",
            "title": "天门市",
            "id": 1776,
            "firstLetter": "T"
          },
          {
            "value": "429021",
            "title": "神农架林区",
            "id": 1777,
            "firstLetter": "S"
          }
        ],
        "id": 185,
        "provinceID": 17,
        "firstLetter": "S"
      }
    ],
    "title": "湖北省",
    "id": 17,
    "firstLetter": "H"
  },
  {
    "value": "430000",
    "children": [
      {
        "value": "430100",
        "title": "长沙市",
        "children": [
          {
            "value": "430102",
            "title": "芙蓉区",
            "id": 1779,
            "firstLetter": "F"
          },
          {
            "value": "430103",
            "title": "天心区",
            "id": 1780,
            "firstLetter": "T"
          },
          {
            "value": "430104",
            "title": "岳麓区",
            "id": 1781,
            "firstLetter": "Y"
          },
          {
            "value": "430105",
            "title": "开福区",
            "id": 1782,
            "firstLetter": "K"
          },
          {
            "value": "430111",
            "title": "雨花区",
            "id": 1783,
            "firstLetter": "Y"
          },
          {
            "value": "430112",
            "title": "望城区",
            "id": 1784,
            "firstLetter": "W"
          },
          {
            "value": "430121",
            "title": "长沙县",
            "id": 1785,
            "firstLetter": "Z"
          },
          {
            "value": "430181",
            "title": "浏阳市",
            "id": 1787,
            "firstLetter": "L"
          },
          {
            "value": "430182",
            "title": "宁乡市",
            "id": 4181,
            "firstLetter": "N"
          }
        ],
        "id": 186,
        "provinceID": 18,
        "firstLetter": "Z"
      },
      {
        "value": "430200",
        "title": "株洲市",
        "children": [
          {
            "value": "430202",
            "title": "荷塘区",
            "id": 1789,
            "firstLetter": "H"
          },
          {
            "value": "430203",
            "title": "芦淞区",
            "id": 1790,
            "firstLetter": "L"
          },
          {
            "value": "430204",
            "title": "石峰区",
            "id": 1791,
            "firstLetter": "S"
          },
          {
            "value": "430211",
            "title": "天元区",
            "id": 1792,
            "firstLetter": "T"
          },
          {
            "value": "430223",
            "title": "攸县",
            "id": 1794,
            "firstLetter": "Y"
          },
          {
            "value": "430224",
            "title": "茶陵县",
            "id": 1795,
            "firstLetter": "C"
          },
          {
            "value": "430225",
            "title": "炎陵县",
            "id": 1796,
            "firstLetter": "Y"
          },
          {
            "value": "430281",
            "title": "醴陵市",
            "id": 1797,
            "firstLetter": "L"
          },
          {
            "value": "430212",
            "title": "渌口区",
            "id": 4182,
            "firstLetter": "L"
          }
        ],
        "id": 187,
        "provinceID": 18,
        "firstLetter": "Z"
      },
      {
        "value": "430300",
        "title": "湘潭市",
        "children": [
          {
            "value": "430302",
            "title": "雨湖区",
            "id": 1799,
            "firstLetter": "Y"
          },
          {
            "value": "430304",
            "title": "岳塘区",
            "id": 1800,
            "firstLetter": "Y"
          },
          {
            "value": "430321",
            "title": "湘潭县",
            "id": 1801,
            "firstLetter": "X"
          },
          {
            "value": "430381",
            "title": "湘乡市",
            "id": 1802,
            "firstLetter": "X"
          },
          {
            "value": "430382",
            "title": "韶山市",
            "id": 1803,
            "firstLetter": "S"
          }
        ],
        "id": 188,
        "provinceID": 18,
        "firstLetter": "X"
      },
      {
        "value": "430400",
        "title": "衡阳市",
        "children": [
          {
            "value": "430405",
            "title": "珠晖区",
            "id": 1805,
            "firstLetter": "Z"
          },
          {
            "value": "430406",
            "title": "雁峰区",
            "id": 1806,
            "firstLetter": "Y"
          },
          {
            "value": "430407",
            "title": "石鼓区",
            "id": 1807,
            "firstLetter": "S"
          },
          {
            "value": "430408",
            "title": "蒸湘区",
            "id": 1808,
            "firstLetter": "Z"
          },
          {
            "value": "430412",
            "title": "南岳区",
            "id": 1809,
            "firstLetter": "N"
          },
          {
            "value": "430421",
            "title": "衡阳县",
            "id": 1810,
            "firstLetter": "H"
          },
          {
            "value": "430422",
            "title": "衡南县",
            "id": 1811,
            "firstLetter": "H"
          },
          {
            "value": "430423",
            "title": "衡山县",
            "id": 1812,
            "firstLetter": "H"
          },
          {
            "value": "430424",
            "title": "衡东县",
            "id": 1813,
            "firstLetter": "H"
          },
          {
            "value": "430426",
            "title": "祁东县",
            "id": 1814,
            "firstLetter": "Q"
          },
          {
            "value": "430481",
            "title": "耒阳市",
            "id": 1815,
            "firstLetter": "L"
          },
          {
            "value": "430482",
            "title": "常宁市",
            "id": 1816,
            "firstLetter": "C"
          }
        ],
        "id": 189,
        "provinceID": 18,
        "firstLetter": "H"
      },
      {
        "value": "430500",
        "title": "邵阳市",
        "children": [
          {
            "value": "430502",
            "title": "双清区",
            "id": 1818,
            "firstLetter": "S"
          },
          {
            "value": "430503",
            "title": "大祥区",
            "id": 1819,
            "firstLetter": "D"
          },
          {
            "value": "430511",
            "title": "北塔区",
            "id": 1820,
            "firstLetter": "B"
          },
          {
            "value": "430521",
            "title": "邵东县",
            "id": 1821,
            "firstLetter": "S"
          },
          {
            "value": "430522",
            "title": "新邵县",
            "id": 1822,
            "firstLetter": "X"
          },
          {
            "value": "430523",
            "title": "邵阳县",
            "id": 1823,
            "firstLetter": "S"
          },
          {
            "value": "430524",
            "title": "隆回县",
            "id": 1824,
            "firstLetter": "L"
          },
          {
            "value": "430525",
            "title": "洞口县",
            "id": 1825,
            "firstLetter": "D"
          },
          {
            "value": "430527",
            "title": "绥宁县",
            "id": 1826,
            "firstLetter": "S"
          },
          {
            "value": "430528",
            "title": "新宁县",
            "id": 1827,
            "firstLetter": "X"
          },
          {
            "value": "430529",
            "title": "城步苗族自治县",
            "id": 1828,
            "firstLetter": "C"
          },
          {
            "value": "430581",
            "title": "武冈市",
            "id": 1829,
            "firstLetter": "W"
          }
        ],
        "id": 190,
        "provinceID": 18,
        "firstLetter": "S"
      },
      {
        "value": "430600",
        "title": "岳阳市",
        "children": [
          {
            "value": "430602",
            "title": "岳阳楼区",
            "id": 1831,
            "firstLetter": "Y"
          },
          {
            "value": "430603",
            "title": "云溪区",
            "id": 1832,
            "firstLetter": "Y"
          },
          {
            "value": "430611",
            "title": "君山区",
            "id": 1833,
            "firstLetter": "J"
          },
          {
            "value": "430621",
            "title": "岳阳县",
            "id": 1834,
            "firstLetter": "Y"
          },
          {
            "value": "430623",
            "title": "华容县",
            "id": 1835,
            "firstLetter": "H"
          },
          {
            "value": "430624",
            "title": "湘阴县",
            "id": 1836,
            "firstLetter": "X"
          },
          {
            "value": "430626",
            "title": "平江县",
            "id": 1837,
            "firstLetter": "P"
          },
          {
            "value": "430681",
            "title": "汨罗市",
            "id": 1838,
            "firstLetter": "M"
          },
          {
            "value": "430682",
            "title": "临湘市",
            "id": 1839,
            "firstLetter": "L"
          }
        ],
        "id": 191,
        "provinceID": 18,
        "firstLetter": "Y"
      },
      {
        "value": "430700",
        "title": "常德市",
        "children": [
          {
            "value": "430702",
            "title": "武陵区",
            "id": 1841,
            "firstLetter": "W"
          },
          {
            "value": "430703",
            "title": "鼎城区",
            "id": 1842,
            "firstLetter": "D"
          },
          {
            "value": "430721",
            "title": "安乡县",
            "id": 1843,
            "firstLetter": "A"
          },
          {
            "value": "430722",
            "title": "汉寿县",
            "id": 1844,
            "firstLetter": "H"
          },
          {
            "value": "430723",
            "title": "澧县",
            "id": 1845,
            "firstLetter": "L"
          },
          {
            "value": "430724",
            "title": "临澧县",
            "id": 1846,
            "firstLetter": "L"
          },
          {
            "value": "430725",
            "title": "桃源县",
            "id": 1847,
            "firstLetter": "T"
          },
          {
            "value": "430726",
            "title": "石门县",
            "id": 1848,
            "firstLetter": "S"
          },
          {
            "value": "430781",
            "title": "津市市",
            "id": 1849,
            "firstLetter": "J"
          }
        ],
        "id": 192,
        "provinceID": 18,
        "firstLetter": "C"
      },
      {
        "value": "430800",
        "title": "张家界市",
        "children": [
          {
            "value": "430802",
            "title": "永定区",
            "id": 1851,
            "firstLetter": "Y"
          },
          {
            "value": "430811",
            "title": "武陵源区",
            "id": 1852,
            "firstLetter": "W"
          },
          {
            "value": "430821",
            "title": "慈利县",
            "id": 1853,
            "firstLetter": "C"
          },
          {
            "value": "430822",
            "title": "桑植县",
            "id": 1854,
            "firstLetter": "S"
          }
        ],
        "id": 193,
        "provinceID": 18,
        "firstLetter": "Z"
      },
      {
        "value": "430900",
        "title": "益阳市",
        "children": [
          {
            "value": "430902",
            "title": "资阳区",
            "id": 1856,
            "firstLetter": "Z"
          },
          {
            "value": "430903",
            "title": "赫山区",
            "id": 1857,
            "firstLetter": "H"
          },
          {
            "value": "430921",
            "title": "南县",
            "id": 1858,
            "firstLetter": "N"
          },
          {
            "value": "430922",
            "title": "桃江县",
            "id": 1859,
            "firstLetter": "T"
          },
          {
            "value": "430923",
            "title": "安化县",
            "id": 1860,
            "firstLetter": "A"
          },
          {
            "value": "430981",
            "title": "沅江市",
            "id": 1861,
            "firstLetter": "Y"
          }
        ],
        "id": 194,
        "provinceID": 18,
        "firstLetter": "Y"
      },
      {
        "value": "431000",
        "title": "郴州市",
        "children": [
          {
            "value": "431002",
            "title": "北湖区",
            "id": 1863,
            "firstLetter": "B"
          },
          {
            "value": "431003",
            "title": "苏仙区",
            "id": 1864,
            "firstLetter": "S"
          },
          {
            "value": "431021",
            "title": "桂阳县",
            "id": 1865,
            "firstLetter": "G"
          },
          {
            "value": "431022",
            "title": "宜章县",
            "id": 1866,
            "firstLetter": "Y"
          },
          {
            "value": "431023",
            "title": "永兴县",
            "id": 1867,
            "firstLetter": "Y"
          },
          {
            "value": "431024",
            "title": "嘉禾县",
            "id": 1868,
            "firstLetter": "J"
          },
          {
            "value": "431025",
            "title": "临武县",
            "id": 1869,
            "firstLetter": "L"
          },
          {
            "value": "431026",
            "title": "汝城县",
            "id": 1870,
            "firstLetter": "R"
          },
          {
            "value": "431027",
            "title": "桂东县",
            "id": 1871,
            "firstLetter": "G"
          },
          {
            "value": "431028",
            "title": "安仁县",
            "id": 1872,
            "firstLetter": "A"
          },
          {
            "value": "431081",
            "title": "资兴市",
            "id": 1873,
            "firstLetter": "Z"
          }
        ],
        "id": 195,
        "provinceID": 18,
        "firstLetter": "C"
      },
      {
        "value": "431100",
        "title": "永州市",
        "children": [
          {
            "value": "431102",
            "title": "零陵区",
            "id": 1875,
            "firstLetter": "L"
          },
          {
            "value": "431103",
            "title": "冷水滩区",
            "id": 1876,
            "firstLetter": "L"
          },
          {
            "value": "431121",
            "title": "祁阳县",
            "id": 1877,
            "firstLetter": "Q"
          },
          {
            "value": "431122",
            "title": "东安县",
            "id": 1878,
            "firstLetter": "D"
          },
          {
            "value": "431123",
            "title": "双牌县",
            "id": 1879,
            "firstLetter": "S"
          },
          {
            "value": "431124",
            "title": "道县",
            "id": 1880,
            "firstLetter": "D"
          },
          {
            "value": "431125",
            "title": "江永县",
            "id": 1881,
            "firstLetter": "J"
          },
          {
            "value": "431126",
            "title": "宁远县",
            "id": 1882,
            "firstLetter": "N"
          },
          {
            "value": "431127",
            "title": "蓝山县",
            "id": 1883,
            "firstLetter": "L"
          },
          {
            "value": "431128",
            "title": "新田县",
            "id": 1884,
            "firstLetter": "X"
          },
          {
            "value": "431129",
            "title": "江华瑶族自治县",
            "id": 1885,
            "firstLetter": "J"
          }
        ],
        "id": 196,
        "provinceID": 18,
        "firstLetter": "Y"
      },
      {
        "value": "431200",
        "title": "怀化市",
        "children": [
          {
            "value": "431202",
            "title": "鹤城区",
            "id": 1887,
            "firstLetter": "H"
          },
          {
            "value": "431221",
            "title": "中方县",
            "id": 1888,
            "firstLetter": "Z"
          },
          {
            "value": "431222",
            "title": "沅陵县",
            "id": 1889,
            "firstLetter": "Y"
          },
          {
            "value": "431223",
            "title": "辰溪县",
            "id": 1890,
            "firstLetter": "C"
          },
          {
            "value": "431224",
            "title": "溆浦县",
            "id": 1891,
            "firstLetter": "X"
          },
          {
            "value": "431225",
            "title": "会同县",
            "id": 1892,
            "firstLetter": "H"
          },
          {
            "value": "431226",
            "title": "麻阳苗族自治县",
            "id": 1893,
            "firstLetter": "M"
          },
          {
            "value": "431227",
            "title": "新晃侗族自治县",
            "id": 1894,
            "firstLetter": "X"
          },
          {
            "value": "431228",
            "title": "芷江侗族自治县",
            "id": 1895,
            "firstLetter": "Z"
          },
          {
            "value": "431229",
            "title": "靖州苗族侗族自治县",
            "id": 1896,
            "firstLetter": "J"
          },
          {
            "value": "431230",
            "title": "通道侗族自治县",
            "id": 1897,
            "firstLetter": "T"
          },
          {
            "value": "431281",
            "title": "洪江市",
            "id": 1898,
            "firstLetter": "H"
          }
        ],
        "id": 197,
        "provinceID": 18,
        "firstLetter": "H"
      },
      {
        "value": "431300",
        "title": "娄底市",
        "children": [
          {
            "value": "431302",
            "title": "娄星区",
            "id": 1900,
            "firstLetter": "L"
          },
          {
            "value": "431321",
            "title": "双峰县",
            "id": 1901,
            "firstLetter": "S"
          },
          {
            "value": "431322",
            "title": "新化县",
            "id": 1902,
            "firstLetter": "X"
          },
          {
            "value": "431381",
            "title": "冷水江市",
            "id": 1903,
            "firstLetter": "L"
          },
          {
            "value": "431382",
            "title": "涟源市",
            "id": 1904,
            "firstLetter": "L"
          }
        ],
        "id": 198,
        "provinceID": 18,
        "firstLetter": "L"
      },
      {
        "value": "433100",
        "title": "湘西土家族苗族自治州",
        "children": [
          {
            "value": "433101",
            "title": "吉首市",
            "id": 1905,
            "firstLetter": "J"
          },
          {
            "value": "433122",
            "title": "泸溪县",
            "id": 1906,
            "firstLetter": "L"
          },
          {
            "value": "433123",
            "title": "凤凰县",
            "id": 1907,
            "firstLetter": "F"
          },
          {
            "value": "433124",
            "title": "花垣县",
            "id": 1908,
            "firstLetter": "H"
          },
          {
            "value": "433125",
            "title": "保靖县",
            "id": 1909,
            "firstLetter": "B"
          },
          {
            "value": "433126",
            "title": "古丈县",
            "id": 1910,
            "firstLetter": "G"
          },
          {
            "value": "433127",
            "title": "永顺县",
            "id": 1911,
            "firstLetter": "Y"
          },
          {
            "value": "433130",
            "title": "龙山县",
            "id": 1912,
            "firstLetter": "L"
          }
        ],
        "id": 199,
        "provinceID": 18,
        "firstLetter": "X"
      }
    ],
    "title": "湖南省",
    "id": 18,
    "firstLetter": "H"
  },
  {
    "value": "440000",
    "children": [
      {
        "value": "440100",
        "title": "广州市",
        "children": [
          {
            "value": "440103",
            "title": "荔湾区",
            "id": 1914,
            "firstLetter": "L"
          },
          {
            "value": "440104",
            "title": "越秀区",
            "id": 1915,
            "firstLetter": "Y"
          },
          {
            "value": "440105",
            "title": "海珠区",
            "id": 1916,
            "firstLetter": "H"
          },
          {
            "value": "440106",
            "title": "天河区",
            "id": 1917,
            "firstLetter": "T"
          },
          {
            "value": "440111",
            "title": "白云区",
            "id": 1918,
            "firstLetter": "B"
          },
          {
            "value": "440112",
            "title": "黄埔区",
            "id": 1919,
            "firstLetter": "H"
          },
          {
            "value": "440113",
            "title": "番禺区",
            "id": 1920,
            "firstLetter": "F"
          },
          {
            "value": "440114",
            "title": "花都区",
            "id": 1921,
            "firstLetter": "H"
          },
          {
            "value": "440115",
            "title": "南沙区",
            "id": 1922,
            "firstLetter": "N"
          },
          {
            "value": "440117",
            "title": "从化区",
            "id": 1924,
            "firstLetter": "C"
          },
          {
            "value": "440118",
            "title": "增城区",
            "id": 1925,
            "firstLetter": "Z"
          }
        ],
        "id": 200,
        "provinceID": 19,
        "firstLetter": "G"
      },
      {
        "value": "440200",
        "title": "韶关市",
        "children": [
          {
            "value": "440203",
            "title": "武江区",
            "id": 1927,
            "firstLetter": "W"
          },
          {
            "value": "440204",
            "title": "浈江区",
            "id": 1928,
            "firstLetter": "Z"
          },
          {
            "value": "440205",
            "title": "曲江区",
            "id": 1929,
            "firstLetter": "Q"
          },
          {
            "value": "440222",
            "title": "始兴县",
            "id": 1930,
            "firstLetter": "S"
          },
          {
            "value": "440224",
            "title": "仁化县",
            "id": 1931,
            "firstLetter": "R"
          },
          {
            "value": "440229",
            "title": "翁源县",
            "id": 1932,
            "firstLetter": "W"
          },
          {
            "value": "440232",
            "title": "乳源瑶族自治县",
            "id": 1933,
            "firstLetter": "R"
          },
          {
            "value": "440233",
            "title": "新丰县",
            "id": 1934,
            "firstLetter": "X"
          },
          {
            "value": "440281",
            "title": "乐昌市",
            "id": 1935,
            "firstLetter": "L"
          },
          {
            "value": "440282",
            "title": "南雄市",
            "id": 1936,
            "firstLetter": "N"
          }
        ],
        "id": 201,
        "provinceID": 19,
        "firstLetter": "S"
      },
      {
        "value": "440300",
        "title": "深圳市",
        "children": [
          {
            "value": "440303",
            "title": "罗湖区",
            "id": 1938,
            "firstLetter": "L"
          },
          {
            "value": "440304",
            "title": "福田区",
            "id": 1939,
            "firstLetter": "F"
          },
          {
            "value": "440305",
            "title": "南山区",
            "id": 1940,
            "firstLetter": "N"
          },
          {
            "value": "440306",
            "title": "宝安区",
            "id": 1941,
            "firstLetter": "B"
          },
          {
            "value": "440307",
            "title": "龙岗区",
            "id": 1942,
            "firstLetter": "L"
          },
          {
            "value": "440308",
            "title": "盐田区",
            "id": 1943,
            "firstLetter": "Y"
          },
          {
            "value": "440309",
            "title": "龙华区",
            "id": 4183,
            "firstLetter": "L"
          },
          {
            "value": "440310",
            "title": "坪山区",
            "id": 4184,
            "firstLetter": "P"
          },
          {
            "value": "440311",
            "title": "光明区",
            "id": 4185,
            "firstLetter": "G"
          }
        ],
        "id": 202,
        "provinceID": 19,
        "firstLetter": "S"
      },
      {
        "value": "440400",
        "title": "珠海市",
        "children": [
          {
            "value": "440402",
            "title": "香洲区",
            "id": 1945,
            "firstLetter": "X"
          },
          {
            "value": "440403",
            "title": "斗门区",
            "id": 1946,
            "firstLetter": "D"
          },
          {
            "value": "440404",
            "title": "金湾区",
            "id": 1947,
            "firstLetter": "J"
          }
        ],
        "id": 203,
        "provinceID": 19,
        "firstLetter": "Z"
      },
      {
        "value": "440500",
        "title": "汕头市",
        "children": [
          {
            "value": "440507",
            "title": "龙湖区",
            "id": 1949,
            "firstLetter": "L"
          },
          {
            "value": "440511",
            "title": "金平区",
            "id": 1950,
            "firstLetter": "J"
          },
          {
            "value": "440512",
            "title": "濠江区",
            "id": 1951,
            "firstLetter": "H"
          },
          {
            "value": "440513",
            "title": "潮阳区",
            "id": 1952,
            "firstLetter": "C"
          },
          {
            "value": "440514",
            "title": "潮南区",
            "id": 1953,
            "firstLetter": "C"
          },
          {
            "value": "440515",
            "title": "澄海区",
            "id": 1954,
            "firstLetter": "C"
          },
          {
            "value": "440523",
            "title": "南澳县",
            "id": 1955,
            "firstLetter": "N"
          }
        ],
        "id": 204,
        "provinceID": 19,
        "firstLetter": "S"
      },
      {
        "value": "440600",
        "title": "佛山市",
        "children": [
          {
            "value": "440604",
            "title": "禅城区",
            "id": 1957,
            "firstLetter": "S"
          },
          {
            "value": "440605",
            "title": "南海区",
            "id": 1958,
            "firstLetter": "N"
          },
          {
            "value": "440606",
            "title": "顺德区",
            "id": 1959,
            "firstLetter": "S"
          },
          {
            "value": "440607",
            "title": "三水区",
            "id": 1960,
            "firstLetter": "S"
          },
          {
            "value": "440608",
            "title": "高明区",
            "id": 1961,
            "firstLetter": "G"
          }
        ],
        "id": 205,
        "provinceID": 19,
        "firstLetter": "F"
      },
      {
        "value": "440700",
        "title": "江门市",
        "children": [
          {
            "value": "440703",
            "title": "蓬江区",
            "id": 1963,
            "firstLetter": "P"
          },
          {
            "value": "440704",
            "title": "江海区",
            "id": 1964,
            "firstLetter": "J"
          },
          {
            "value": "440705",
            "title": "新会区",
            "id": 1965,
            "firstLetter": "X"
          },
          {
            "value": "440781",
            "title": "台山市",
            "id": 1966,
            "firstLetter": "T"
          },
          {
            "value": "440783",
            "title": "开平市",
            "id": 1967,
            "firstLetter": "K"
          },
          {
            "value": "440784",
            "title": "鹤山市",
            "id": 1968,
            "firstLetter": "H"
          },
          {
            "value": "440785",
            "title": "恩平市",
            "id": 1969,
            "firstLetter": "E"
          }
        ],
        "id": 206,
        "provinceID": 19,
        "firstLetter": "J"
      },
      {
        "value": "440800",
        "title": "湛江市",
        "children": [
          {
            "value": "440802",
            "title": "赤坎区",
            "id": 1971,
            "firstLetter": "C"
          },
          {
            "value": "440803",
            "title": "霞山区",
            "id": 1972,
            "firstLetter": "X"
          },
          {
            "value": "440804",
            "title": "坡头区",
            "id": 1973,
            "firstLetter": "P"
          },
          {
            "value": "440811",
            "title": "麻章区",
            "id": 1974,
            "firstLetter": "M"
          },
          {
            "value": "440823",
            "title": "遂溪县",
            "id": 1975,
            "firstLetter": "S"
          },
          {
            "value": "440825",
            "title": "徐闻县",
            "id": 1976,
            "firstLetter": "X"
          },
          {
            "value": "440881",
            "title": "廉江市",
            "id": 1977,
            "firstLetter": "L"
          },
          {
            "value": "440882",
            "title": "雷州市",
            "id": 1978,
            "firstLetter": "L"
          },
          {
            "value": "440883",
            "title": "吴川市",
            "id": 1979,
            "firstLetter": "W"
          }
        ],
        "id": 207,
        "provinceID": 19,
        "firstLetter": "Z"
      },
      {
        "value": "440900",
        "title": "茂名市",
        "children": [
          {
            "value": "440902",
            "title": "茂南区",
            "id": 1981,
            "firstLetter": "M"
          },
          {
            "value": "440904",
            "title": "电白区",
            "id": 1982,
            "firstLetter": "D"
          },
          {
            "value": "440981",
            "title": "高州市",
            "id": 1983,
            "firstLetter": "G"
          },
          {
            "value": "440982",
            "title": "化州市",
            "id": 1984,
            "firstLetter": "H"
          },
          {
            "value": "440983",
            "title": "信宜市",
            "id": 1985,
            "firstLetter": "X"
          }
        ],
        "id": 208,
        "provinceID": 19,
        "firstLetter": "M"
      },
      {
        "value": "441200",
        "title": "肇庆市",
        "children": [
          {
            "value": "441202",
            "title": "端州区",
            "id": 1987,
            "firstLetter": "D"
          },
          {
            "value": "441203",
            "title": "鼎湖区",
            "id": 1988,
            "firstLetter": "D"
          },
          {
            "value": "441223",
            "title": "广宁县",
            "id": 1989,
            "firstLetter": "G"
          },
          {
            "value": "441224",
            "title": "怀集县",
            "id": 1990,
            "firstLetter": "H"
          },
          {
            "value": "441225",
            "title": "封开县",
            "id": 1991,
            "firstLetter": "F"
          },
          {
            "value": "441226",
            "title": "德庆县",
            "id": 1992,
            "firstLetter": "D"
          },
          {
            "value": "441284",
            "title": "四会市",
            "id": 1994,
            "firstLetter": "S"
          },
          {
            "value": "441204",
            "title": "高要区",
            "id": 4186,
            "firstLetter": "G"
          }
        ],
        "id": 209,
        "provinceID": 19,
        "firstLetter": "Z"
      },
      {
        "value": "441300",
        "title": "惠州市",
        "children": [
          {
            "value": "441302",
            "title": "惠城区",
            "id": 1996,
            "firstLetter": "H"
          },
          {
            "value": "441303",
            "title": "惠阳区",
            "id": 1997,
            "firstLetter": "H"
          },
          {
            "value": "441322",
            "title": "博罗县",
            "id": 1998,
            "firstLetter": "B"
          },
          {
            "value": "441323",
            "title": "惠东县",
            "id": 1999,
            "firstLetter": "H"
          },
          {
            "value": "441324",
            "title": "龙门县",
            "id": 2000,
            "firstLetter": "L"
          }
        ],
        "id": 210,
        "provinceID": 19,
        "firstLetter": "H"
      },
      {
        "value": "441400",
        "title": "梅州市",
        "children": [
          {
            "value": "441402",
            "title": "梅江区",
            "id": 2002,
            "firstLetter": "M"
          },
          {
            "value": "441403",
            "title": "梅县区",
            "id": 2003,
            "firstLetter": "M"
          },
          {
            "value": "441422",
            "title": "大埔县",
            "id": 2004,
            "firstLetter": "D"
          },
          {
            "value": "441423",
            "title": "丰顺县",
            "id": 2005,
            "firstLetter": "F"
          },
          {
            "value": "441424",
            "title": "五华县",
            "id": 2006,
            "firstLetter": "W"
          },
          {
            "value": "441426",
            "title": "平远县",
            "id": 2007,
            "firstLetter": "P"
          },
          {
            "value": "441427",
            "title": "蕉岭县",
            "id": 2008,
            "firstLetter": "J"
          },
          {
            "value": "441481",
            "title": "兴宁市",
            "id": 2009,
            "firstLetter": "X"
          }
        ],
        "id": 211,
        "provinceID": 19,
        "firstLetter": "M"
      },
      {
        "value": "441500",
        "title": "汕尾市",
        "children": [
          {
            "value": "441502",
            "title": "城区",
            "id": 2011,
            "firstLetter": "C"
          },
          {
            "value": "441521",
            "title": "海丰县",
            "id": 2012,
            "firstLetter": "H"
          },
          {
            "value": "441523",
            "title": "陆河县",
            "id": 2013,
            "firstLetter": "L"
          },
          {
            "value": "441581",
            "title": "陆丰市",
            "id": 2014,
            "firstLetter": "L"
          }
        ],
        "id": 212,
        "provinceID": 19,
        "firstLetter": "S"
      },
      {
        "value": "441600",
        "title": "河源市",
        "children": [
          {
            "value": "441602",
            "title": "源城区",
            "id": 2016,
            "firstLetter": "Y"
          },
          {
            "value": "441621",
            "title": "紫金县",
            "id": 2017,
            "firstLetter": "Z"
          },
          {
            "value": "441622",
            "title": "龙川县",
            "id": 2018,
            "firstLetter": "L"
          },
          {
            "value": "441623",
            "title": "连平县",
            "id": 2019,
            "firstLetter": "L"
          },
          {
            "value": "441624",
            "title": "和平县",
            "id": 2020,
            "firstLetter": "H"
          },
          {
            "value": "441625",
            "title": "东源县",
            "id": 2021,
            "firstLetter": "D"
          }
        ],
        "id": 213,
        "provinceID": 19,
        "firstLetter": "H"
      },
      {
        "value": "441700",
        "title": "阳江市",
        "children": [
          {
            "value": "441702",
            "title": "江城区",
            "id": 2023,
            "firstLetter": "J"
          },
          {
            "value": "441721",
            "title": "阳西县",
            "id": 2024,
            "firstLetter": "Y"
          },
          {
            "value": "441781",
            "title": "阳春市",
            "id": 2026,
            "firstLetter": "Y"
          },
          {
            "value": "441704",
            "title": "阳东区",
            "id": 4187,
            "firstLetter": "Y"
          }
        ],
        "id": 214,
        "provinceID": 19,
        "firstLetter": "Y"
      },
      {
        "value": "441800",
        "title": "清远市",
        "children": [
          {
            "value": "441802",
            "title": "清城区",
            "id": 2028,
            "firstLetter": "Q"
          },
          {
            "value": "441803",
            "title": "清新区",
            "id": 2029,
            "firstLetter": "Q"
          },
          {
            "value": "441821",
            "title": "佛冈县",
            "id": 2030,
            "firstLetter": "F"
          },
          {
            "value": "441823",
            "title": "阳山县",
            "id": 2031,
            "firstLetter": "Y"
          },
          {
            "value": "441825",
            "title": "连山壮族瑶族自治县",
            "id": 2032,
            "firstLetter": "L"
          },
          {
            "value": "441826",
            "title": "连南瑶族自治县",
            "id": 2033,
            "firstLetter": "L"
          },
          {
            "value": "441881",
            "title": "英德市",
            "id": 2034,
            "firstLetter": "Y"
          },
          {
            "value": "441882",
            "title": "连州市",
            "id": 2035,
            "firstLetter": "L"
          }
        ],
        "id": 215,
        "provinceID": 19,
        "firstLetter": "Q"
      },
      {
        "value": "441900",
        "title": "东莞市",
        "children": [
          {
            "value": "441900",
            "title": "东莞市",
            "id": 4101,
            "firstLetter": "D"
          }
        ],
        "id": 216,
        "provinceID": 19,
        "firstLetter": "D"
      },
      {
        "value": "442000",
        "title": "中山市",
        "children": [
          {
            "value": "442000",
            "title": "中山市",
            "id": 4102,
            "firstLetter": "Z"
          }
        ],
        "id": 217,
        "provinceID": 19,
        "firstLetter": "Z"
      },
      {
        "value": "445100",
        "title": "潮州市",
        "children": [
          {
            "value": "445102",
            "title": "湘桥区",
            "id": 2037,
            "firstLetter": "X"
          },
          {
            "value": "445103",
            "title": "潮安区",
            "id": 2038,
            "firstLetter": "C"
          },
          {
            "value": "445122",
            "title": "饶平县",
            "id": 2039,
            "firstLetter": "R"
          }
        ],
        "id": 218,
        "provinceID": 19,
        "firstLetter": "C"
      },
      {
        "value": "445200",
        "title": "揭阳市",
        "children": [
          {
            "value": "445202",
            "title": "榕城区",
            "id": 2041,
            "firstLetter": "R"
          },
          {
            "value": "445203",
            "title": "揭东区",
            "id": 2042,
            "firstLetter": "J"
          },
          {
            "value": "445222",
            "title": "揭西县",
            "id": 2043,
            "firstLetter": "J"
          },
          {
            "value": "445224",
            "title": "惠来县",
            "id": 2044,
            "firstLetter": "H"
          },
          {
            "value": "445281",
            "title": "普宁市",
            "id": 2045,
            "firstLetter": "P"
          }
        ],
        "id": 219,
        "provinceID": 19,
        "firstLetter": "J"
      },
      {
        "value": "445300",
        "title": "云浮市",
        "children": [
          {
            "value": "445302",
            "title": "云城区",
            "id": 2047,
            "firstLetter": "Y"
          },
          {
            "value": "445303",
            "title": "云安区",
            "id": 2048,
            "firstLetter": "Y"
          },
          {
            "value": "445321",
            "title": "新兴县",
            "id": 2049,
            "firstLetter": "X"
          },
          {
            "value": "445322",
            "title": "郁南县",
            "id": 2050,
            "firstLetter": "Y"
          },
          {
            "value": "445381",
            "title": "罗定市",
            "id": 2051,
            "firstLetter": "L"
          }
        ],
        "id": 220,
        "provinceID": 19,
        "firstLetter": "Y"
      }
    ],
    "title": "广东省",
    "id": 19,
    "firstLetter": "G"
  },
  {
    "value": "450000",
    "children": [
      {
        "value": "450100",
        "title": "南宁市",
        "children": [
          {
            "value": "450102",
            "title": "兴宁区",
            "id": 2053,
            "firstLetter": "X"
          },
          {
            "value": "450103",
            "title": "青秀区",
            "id": 2054,
            "firstLetter": "Q"
          },
          {
            "value": "450105",
            "title": "江南区",
            "id": 2055,
            "firstLetter": "J"
          },
          {
            "value": "450107",
            "title": "西乡塘区",
            "id": 2056,
            "firstLetter": "X"
          },
          {
            "value": "450108",
            "title": "良庆区",
            "id": 2057,
            "firstLetter": "L"
          },
          {
            "value": "450109",
            "title": "邕宁区",
            "id": 2058,
            "firstLetter": "Y"
          },
          {
            "value": "450123",
            "title": "隆安县",
            "id": 2060,
            "firstLetter": "L"
          },
          {
            "value": "450124",
            "title": "马山县",
            "id": 2061,
            "firstLetter": "M"
          },
          {
            "value": "450125",
            "title": "上林县",
            "id": 2062,
            "firstLetter": "S"
          },
          {
            "value": "450126",
            "title": "宾阳县",
            "id": 2063,
            "firstLetter": "B"
          },
          {
            "value": "450127",
            "title": "横县",
            "id": 2064,
            "firstLetter": "H"
          },
          {
            "value": "450110",
            "title": "武鸣区",
            "id": 4188,
            "firstLetter": "W"
          }
        ],
        "id": 221,
        "provinceID": 20,
        "firstLetter": "N"
      },
      {
        "value": "450200",
        "title": "柳州市",
        "children": [
          {
            "value": "450202",
            "title": "城中区",
            "id": 2066,
            "firstLetter": "C"
          },
          {
            "value": "450203",
            "title": "鱼峰区",
            "id": 2067,
            "firstLetter": "Y"
          },
          {
            "value": "450204",
            "title": "柳南区",
            "id": 2068,
            "firstLetter": "L"
          },
          {
            "value": "450205",
            "title": "柳北区",
            "id": 2069,
            "firstLetter": "L"
          },
          {
            "value": "450222",
            "title": "柳城县",
            "id": 2071,
            "firstLetter": "L"
          },
          {
            "value": "450223",
            "title": "鹿寨县",
            "id": 2072,
            "firstLetter": "L"
          },
          {
            "value": "450224",
            "title": "融安县",
            "id": 2073,
            "firstLetter": "R"
          },
          {
            "value": "450225",
            "title": "融水苗族自治县",
            "id": 2074,
            "firstLetter": "R"
          },
          {
            "value": "450226",
            "title": "三江侗族自治县",
            "id": 2075,
            "firstLetter": "S"
          },
          {
            "value": "450206",
            "title": "柳江区",
            "id": 4189,
            "firstLetter": "L"
          }
        ],
        "id": 222,
        "provinceID": 20,
        "firstLetter": "L"
      },
      {
        "value": "450300",
        "title": "桂林市",
        "children": [
          {
            "value": "450302",
            "title": "秀峰区",
            "id": 2077,
            "firstLetter": "X"
          },
          {
            "value": "450303",
            "title": "叠彩区",
            "id": 2078,
            "firstLetter": "D"
          },
          {
            "value": "450304",
            "title": "象山区",
            "id": 2079,
            "firstLetter": "X"
          },
          {
            "value": "450305",
            "title": "七星区",
            "id": 2080,
            "firstLetter": "Q"
          },
          {
            "value": "450311",
            "title": "雁山区",
            "id": 2081,
            "firstLetter": "Y"
          },
          {
            "value": "450312",
            "title": "临桂区",
            "id": 2082,
            "firstLetter": "L"
          },
          {
            "value": "450321",
            "title": "阳朔县",
            "id": 2083,
            "firstLetter": "Y"
          },
          {
            "value": "450323",
            "title": "灵川县",
            "id": 2084,
            "firstLetter": "L"
          },
          {
            "value": "450324",
            "title": "全州县",
            "id": 2085,
            "firstLetter": "Q"
          },
          {
            "value": "450325",
            "title": "兴安县",
            "id": 2086,
            "firstLetter": "X"
          },
          {
            "value": "450326",
            "title": "永福县",
            "id": 2087,
            "firstLetter": "Y"
          },
          {
            "value": "450327",
            "title": "灌阳县",
            "id": 2088,
            "firstLetter": "G"
          },
          {
            "value": "450328",
            "title": "龙胜各族自治县",
            "id": 2089,
            "firstLetter": "L"
          },
          {
            "value": "450329",
            "title": "资源县",
            "id": 2090,
            "firstLetter": "Z"
          },
          {
            "value": "450330",
            "title": "平乐县",
            "id": 2091,
            "firstLetter": "P"
          },
          {
            "value": "450332",
            "title": "恭城瑶族自治县",
            "id": 2093,
            "firstLetter": "G"
          },
          {
            "value": "450381",
            "title": "荔浦市",
            "id": 4190,
            "firstLetter": "L"
          }
        ],
        "id": 223,
        "provinceID": 20,
        "firstLetter": "G"
      },
      {
        "value": "450400",
        "title": "梧州市",
        "children": [
          {
            "value": "450403",
            "title": "万秀区",
            "id": 2095,
            "firstLetter": "W"
          },
          {
            "value": "450405",
            "title": "长洲区",
            "id": 2096,
            "firstLetter": "Z"
          },
          {
            "value": "450406",
            "title": "龙圩区",
            "id": 2097,
            "firstLetter": "L"
          },
          {
            "value": "450421",
            "title": "苍梧县",
            "id": 2098,
            "firstLetter": "C"
          },
          {
            "value": "450422",
            "title": "藤县",
            "id": 2099,
            "firstLetter": "T"
          },
          {
            "value": "450423",
            "title": "蒙山县",
            "id": 2100,
            "firstLetter": "M"
          },
          {
            "value": "450481",
            "title": "岑溪市",
            "id": 2101,
            "firstLetter": "C"
          }
        ],
        "id": 224,
        "provinceID": 20,
        "firstLetter": "W"
      },
      {
        "value": "450500",
        "title": "北海市",
        "children": [
          {
            "value": "450502",
            "title": "海城区",
            "id": 2103,
            "firstLetter": "H"
          },
          {
            "value": "450503",
            "title": "银海区",
            "id": 2104,
            "firstLetter": "Y"
          },
          {
            "value": "450512",
            "title": "铁山港区",
            "id": 2105,
            "firstLetter": "T"
          },
          {
            "value": "450521",
            "title": "合浦县",
            "id": 2106,
            "firstLetter": "H"
          }
        ],
        "id": 225,
        "provinceID": 20,
        "firstLetter": "B"
      },
      {
        "value": "450600",
        "title": "防城港市",
        "children": [
          {
            "value": "450602",
            "title": "港口区",
            "id": 2108,
            "firstLetter": "G"
          },
          {
            "value": "450603",
            "title": "防城区",
            "id": 2109,
            "firstLetter": "F"
          },
          {
            "value": "450621",
            "title": "上思县",
            "id": 2110,
            "firstLetter": "S"
          },
          {
            "value": "450681",
            "title": "东兴市",
            "id": 2111,
            "firstLetter": "D"
          }
        ],
        "id": 226,
        "provinceID": 20,
        "firstLetter": "F"
      },
      {
        "value": "450700",
        "title": "钦州市",
        "children": [
          {
            "value": "450702",
            "title": "钦南区",
            "id": 2113,
            "firstLetter": "Q"
          },
          {
            "value": "450703",
            "title": "钦北区",
            "id": 2114,
            "firstLetter": "Q"
          },
          {
            "value": "450721",
            "title": "灵山县",
            "id": 2115,
            "firstLetter": "L"
          },
          {
            "value": "450722",
            "title": "浦北县",
            "id": 2116,
            "firstLetter": "P"
          }
        ],
        "id": 227,
        "provinceID": 20,
        "firstLetter": "Q"
      },
      {
        "value": "450800",
        "title": "贵港市",
        "children": [
          {
            "value": "450802",
            "title": "港北区",
            "id": 2118,
            "firstLetter": "G"
          },
          {
            "value": "450803",
            "title": "港南区",
            "id": 2119,
            "firstLetter": "G"
          },
          {
            "value": "450804",
            "title": "覃塘区",
            "id": 2120,
            "firstLetter": "T"
          },
          {
            "value": "450821",
            "title": "平南县",
            "id": 2121,
            "firstLetter": "P"
          },
          {
            "value": "450881",
            "title": "桂平市",
            "id": 2122,
            "firstLetter": "G"
          }
        ],
        "id": 228,
        "provinceID": 20,
        "firstLetter": "G"
      },
      {
        "value": "450900",
        "title": "玉林市",
        "children": [
          {
            "value": "450902",
            "title": "玉州区",
            "id": 2124,
            "firstLetter": "Y"
          },
          {
            "value": "450903",
            "title": "福绵区",
            "id": 2125,
            "firstLetter": "F"
          },
          {
            "value": "450921",
            "title": "容县",
            "id": 2126,
            "firstLetter": "R"
          },
          {
            "value": "450922",
            "title": "陆川县",
            "id": 2127,
            "firstLetter": "L"
          },
          {
            "value": "450923",
            "title": "博白县",
            "id": 2128,
            "firstLetter": "B"
          },
          {
            "value": "450924",
            "title": "兴业县",
            "id": 2129,
            "firstLetter": "X"
          },
          {
            "value": "450981",
            "title": "北流市",
            "id": 2130,
            "firstLetter": "B"
          }
        ],
        "id": 229,
        "provinceID": 20,
        "firstLetter": "Y"
      },
      {
        "value": "451000",
        "title": "百色市",
        "children": [
          {
            "value": "451002",
            "title": "右江区",
            "id": 2132,
            "firstLetter": "Y"
          },
          {
            "value": "451021",
            "title": "田阳县",
            "id": 2133,
            "firstLetter": "T"
          },
          {
            "value": "451022",
            "title": "田东县",
            "id": 2134,
            "firstLetter": "T"
          },
          {
            "value": "451023",
            "title": "平果县",
            "id": 2135,
            "firstLetter": "P"
          },
          {
            "value": "451024",
            "title": "德保县",
            "id": 2136,
            "firstLetter": "D"
          },
          {
            "value": "451026",
            "title": "那坡县",
            "id": 2138,
            "firstLetter": "N"
          },
          {
            "value": "451027",
            "title": "凌云县",
            "id": 2139,
            "firstLetter": "L"
          },
          {
            "value": "451028",
            "title": "乐业县",
            "id": 2140,
            "firstLetter": "L"
          },
          {
            "value": "451029",
            "title": "田林县",
            "id": 2141,
            "firstLetter": "T"
          },
          {
            "value": "451030",
            "title": "西林县",
            "id": 2142,
            "firstLetter": "X"
          },
          {
            "value": "451031",
            "title": "隆林各族自治县",
            "id": 2143,
            "firstLetter": "L"
          },
          {
            "value": "451081",
            "title": "靖西市",
            "id": 4191,
            "firstLetter": "J"
          }
        ],
        "id": 230,
        "provinceID": 20,
        "firstLetter": "B"
      },
      {
        "value": "451100",
        "title": "贺州市",
        "children": [
          {
            "value": "451102",
            "title": "八步区",
            "id": 2145,
            "firstLetter": "B"
          },
          {
            "value": "451121",
            "title": "昭平县",
            "id": 2146,
            "firstLetter": "Z"
          },
          {
            "value": "451122",
            "title": "钟山县",
            "id": 2147,
            "firstLetter": "Z"
          },
          {
            "value": "451123",
            "title": "富川瑶族自治县",
            "id": 2148,
            "firstLetter": "F"
          },
          {
            "value": "451103",
            "title": "平桂区",
            "id": 4192,
            "firstLetter": "P"
          }
        ],
        "id": 231,
        "provinceID": 20,
        "firstLetter": "H"
      },
      {
        "value": "451200",
        "title": "河池市",
        "children": [
          {
            "value": "451202",
            "title": "金城江区",
            "id": 2150,
            "firstLetter": "J"
          },
          {
            "value": "451221",
            "title": "南丹县",
            "id": 2151,
            "firstLetter": "N"
          },
          {
            "value": "451222",
            "title": "天峨县",
            "id": 2152,
            "firstLetter": "T"
          },
          {
            "value": "451223",
            "title": "凤山县",
            "id": 2153,
            "firstLetter": "F"
          },
          {
            "value": "451224",
            "title": "东兰县",
            "id": 2154,
            "firstLetter": "D"
          },
          {
            "value": "451225",
            "title": "罗城仫佬族自治县",
            "id": 2155,
            "firstLetter": "L"
          },
          {
            "value": "451226",
            "title": "环江毛南族自治县",
            "id": 2156,
            "firstLetter": "H"
          },
          {
            "value": "451227",
            "title": "巴马瑶族自治县",
            "id": 2157,
            "firstLetter": "B"
          },
          {
            "value": "451228",
            "title": "都安瑶族自治县",
            "id": 2158,
            "firstLetter": "D"
          },
          {
            "value": "451229",
            "title": "大化瑶族自治县",
            "id": 2159,
            "firstLetter": "D"
          },
          {
            "value": "451203",
            "title": "宜州区",
            "id": 4193,
            "firstLetter": "Y"
          }
        ],
        "id": 232,
        "provinceID": 20,
        "firstLetter": "H"
      },
      {
        "value": "451300",
        "title": "来宾市",
        "children": [
          {
            "value": "451302",
            "title": "兴宾区",
            "id": 2162,
            "firstLetter": "X"
          },
          {
            "value": "451321",
            "title": "忻城县",
            "id": 2163,
            "firstLetter": "X"
          },
          {
            "value": "451322",
            "title": "象州县",
            "id": 2164,
            "firstLetter": "X"
          },
          {
            "value": "451323",
            "title": "武宣县",
            "id": 2165,
            "firstLetter": "W"
          },
          {
            "value": "451324",
            "title": "金秀瑶族自治县",
            "id": 2166,
            "firstLetter": "J"
          },
          {
            "value": "451381",
            "title": "合山市",
            "id": 2167,
            "firstLetter": "H"
          }
        ],
        "id": 233,
        "provinceID": 20,
        "firstLetter": "L"
      },
      {
        "value": "451400",
        "title": "崇左市",
        "children": [
          {
            "value": "451402",
            "title": "江州区",
            "id": 2169,
            "firstLetter": "J"
          },
          {
            "value": "451421",
            "title": "扶绥县",
            "id": 2170,
            "firstLetter": "F"
          },
          {
            "value": "451422",
            "title": "宁明县",
            "id": 2171,
            "firstLetter": "N"
          },
          {
            "value": "451423",
            "title": "龙州县",
            "id": 2172,
            "firstLetter": "L"
          },
          {
            "value": "451424",
            "title": "大新县",
            "id": 2173,
            "firstLetter": "D"
          },
          {
            "value": "451425",
            "title": "天等县",
            "id": 2174,
            "firstLetter": "T"
          },
          {
            "value": "451481",
            "title": "凭祥市",
            "id": 2175,
            "firstLetter": "P"
          }
        ],
        "id": 234,
        "provinceID": 20,
        "firstLetter": "C"
      }
    ],
    "title": "广西省",
    "id": 20,
    "firstLetter": "G"
  },
  {
    "value": "460000",
    "children": [
      {
        "value": "460100",
        "title": "海口市",
        "children": [
          {
            "value": "460105",
            "title": "秀英区",
            "id": 2177,
            "firstLetter": "X"
          },
          {
            "value": "460106",
            "title": "龙华区",
            "id": 2178,
            "firstLetter": "L"
          },
          {
            "value": "460107",
            "title": "琼山区",
            "id": 2179,
            "firstLetter": "Q"
          },
          {
            "value": "460108",
            "title": "美兰区",
            "id": 2180,
            "firstLetter": "M"
          }
        ],
        "id": 235,
        "provinceID": 21,
        "firstLetter": "H"
      },
      {
        "value": "460200",
        "title": "三亚市",
        "children": [
          {
            "value": "460202",
            "title": "海棠区",
            "id": 2182,
            "firstLetter": "H"
          },
          {
            "value": "460203",
            "title": "吉阳区",
            "id": 2183,
            "firstLetter": "J"
          },
          {
            "value": "460204",
            "title": "天涯区",
            "id": 2184,
            "firstLetter": "T"
          },
          {
            "value": "460205",
            "title": "崖州区",
            "id": 2185,
            "firstLetter": "Y"
          }
        ],
        "id": 236,
        "provinceID": 21,
        "firstLetter": "S"
      },
      {
        "value": "460300",
        "title": "三沙市",
        "children": [
          {
            "value": "460321",
            "title": "西沙群岛",
            "id": 4194,
            "firstLetter": "X"
          },
          {
            "value": "460322",
            "title": "南沙群岛",
            "id": 4195,
            "firstLetter": "N"
          },
          {
            "value": "460323",
            "title": "中沙群岛的岛礁及其海域",
            "id": 4196,
            "firstLetter": "Z"
          }
        ],
        "id": 237,
        "provinceID": 21,
        "firstLetter": "S"
      },
      {
        "value": "469000",
        "title": "省直辖县级行政区划",
        "children": [
          {
            "value": "469001",
            "title": "五指山市",
            "id": 2186,
            "firstLetter": "W"
          },
          {
            "value": "469002",
            "title": "琼海市",
            "id": 2187,
            "firstLetter": "Q"
          },
          {
            "value": "469005",
            "title": "文昌市",
            "id": 2189,
            "firstLetter": "W"
          },
          {
            "value": "469006",
            "title": "万宁市",
            "id": 2190,
            "firstLetter": "W"
          },
          {
            "value": "469007",
            "title": "东方市",
            "id": 2191,
            "firstLetter": "D"
          },
          {
            "value": "469021",
            "title": "定安县",
            "id": 2192,
            "firstLetter": "D"
          },
          {
            "value": "469022",
            "title": "屯昌县",
            "id": 2193,
            "firstLetter": "T"
          },
          {
            "value": "469023",
            "title": "澄迈县",
            "id": 2194,
            "firstLetter": "C"
          },
          {
            "value": "469024",
            "title": "临高县",
            "id": 2195,
            "firstLetter": "L"
          },
          {
            "value": "469025",
            "title": "白沙黎族自治县",
            "id": 2196,
            "firstLetter": "B"
          },
          {
            "value": "469026",
            "title": "昌江黎族自治县",
            "id": 2197,
            "firstLetter": "C"
          },
          {
            "value": "469027",
            "title": "乐东黎族自治县",
            "id": 2198,
            "firstLetter": "L"
          },
          {
            "value": "469028",
            "title": "陵水黎族自治县",
            "id": 2199,
            "firstLetter": "L"
          },
          {
            "value": "469029",
            "title": "保亭黎族苗族自治县",
            "id": 2200,
            "firstLetter": "B"
          },
          {
            "value": "469030",
            "title": "琼中黎族苗族自治县",
            "id": 2201,
            "firstLetter": "Q"
          }
        ],
        "id": 238,
        "provinceID": 21,
        "firstLetter": "S"
      },
      {
        "value": "460400",
        "title": "儋州市",
        "children": [
          {
            "value": "460400",
            "title": "儋州市",
            "id": 4197,
            "firstLetter": "D"
          }
        ],
        "id": 348,
        "provinceID": 21,
        "firstLetter": "D"
      }
    ],
    "title": "海南省",
    "id": 21,
    "firstLetter": "H"
  },
  {
    "value": "500000",
    "children": [
      {
        "value": "500100",
        "title": "重庆市",
        "children": [
          {
            "value": "500101",
            "title": "万州区",
            "id": 2202,
            "firstLetter": "W"
          },
          {
            "value": "500102",
            "title": "涪陵区",
            "id": 2203,
            "firstLetter": "F"
          },
          {
            "value": "500103",
            "title": "渝中区",
            "id": 2204,
            "firstLetter": "Y"
          },
          {
            "value": "500104",
            "title": "大渡口区",
            "id": 2205,
            "firstLetter": "D"
          },
          {
            "value": "500105",
            "title": "江北区",
            "id": 2206,
            "firstLetter": "J"
          },
          {
            "value": "500106",
            "title": "沙坪坝区",
            "id": 2207,
            "firstLetter": "S"
          },
          {
            "value": "500107",
            "title": "九龙坡区",
            "id": 2208,
            "firstLetter": "J"
          },
          {
            "value": "500108",
            "title": "南岸区",
            "id": 2209,
            "firstLetter": "N"
          },
          {
            "value": "500109",
            "title": "北碚区",
            "id": 2210,
            "firstLetter": "B"
          },
          {
            "value": "500110",
            "title": "綦江区",
            "id": 2211,
            "firstLetter": "Q"
          },
          {
            "value": "500111",
            "title": "大足区",
            "id": 2212,
            "firstLetter": "D"
          },
          {
            "value": "500112",
            "title": "渝北区",
            "id": 2213,
            "firstLetter": "Y"
          },
          {
            "value": "500113",
            "title": "巴南区",
            "id": 2214,
            "firstLetter": "B"
          },
          {
            "value": "500114",
            "title": "黔江区",
            "id": 2215,
            "firstLetter": "Q"
          },
          {
            "value": "500115",
            "title": "长寿区",
            "id": 2216,
            "firstLetter": "Z"
          },
          {
            "value": "500116",
            "title": "江津区",
            "id": 2217,
            "firstLetter": "J"
          },
          {
            "value": "500117",
            "title": "合川区",
            "id": 2218,
            "firstLetter": "H"
          },
          {
            "value": "500118",
            "title": "永川区",
            "id": 2219,
            "firstLetter": "Y"
          },
          {
            "value": "500119",
            "title": "南川区",
            "id": 2220,
            "firstLetter": "N"
          },
          {
            "value": "500120",
            "title": "璧山区",
            "id": 2221,
            "firstLetter": "B"
          },
          {
            "value": "500151",
            "title": "铜梁区",
            "id": 2222,
            "firstLetter": "T"
          },
          {
            "value": "500152",
            "title": "潼南区",
            "id": 4198,
            "firstLetter": "T"
          },
          {
            "value": "500153",
            "title": "荣昌区",
            "id": 4199,
            "firstLetter": "R"
          },
          {
            "value": "500154",
            "title": "开州区",
            "id": 4200,
            "firstLetter": "K"
          },
          {
            "value": "500155",
            "title": "梁平区",
            "id": 4201,
            "firstLetter": "L"
          },
          {
            "value": "500156",
            "title": "武隆区",
            "id": 4202,
            "firstLetter": "W"
          }
        ],
        "id": 239,
        "provinceID": 22,
        "firstLetter": "Z"
      }
    ],
    "title": "重庆市",
    "id": 22,
    "firstLetter": "Z"
  },
  {
    "value": "510000",
    "children": [
      {
        "value": "510100",
        "title": "成都市",
        "children": [
          {
            "value": "510104",
            "title": "锦江区",
            "id": 2241,
            "firstLetter": "J"
          },
          {
            "value": "510105",
            "title": "青羊区",
            "id": 2242,
            "firstLetter": "Q"
          },
          {
            "value": "510106",
            "title": "金牛区",
            "id": 2243,
            "firstLetter": "J"
          },
          {
            "value": "510107",
            "title": "武侯区",
            "id": 2244,
            "firstLetter": "W"
          },
          {
            "value": "510108",
            "title": "成华区",
            "id": 2245,
            "firstLetter": "C"
          },
          {
            "value": "510112",
            "title": "龙泉驿区",
            "id": 2246,
            "firstLetter": "L"
          },
          {
            "value": "510113",
            "title": "青白江区",
            "id": 2247,
            "firstLetter": "Q"
          },
          {
            "value": "510114",
            "title": "新都区",
            "id": 2248,
            "firstLetter": "X"
          },
          {
            "value": "510115",
            "title": "温江区",
            "id": 2249,
            "firstLetter": "W"
          },
          {
            "value": "510121",
            "title": "金堂县",
            "id": 2250,
            "firstLetter": "J"
          },
          {
            "value": "510129",
            "title": "大邑县",
            "id": 2253,
            "firstLetter": "D"
          },
          {
            "value": "510131",
            "title": "蒲江县",
            "id": 2254,
            "firstLetter": "P"
          },
          {
            "value": "510132",
            "title": "新津县",
            "id": 2255,
            "firstLetter": "X"
          },
          {
            "value": "510181",
            "title": "都江堰市",
            "id": 2256,
            "firstLetter": "D"
          },
          {
            "value": "510182",
            "title": "彭州市",
            "id": 2257,
            "firstLetter": "P"
          },
          {
            "value": "510183",
            "title": "邛崃市",
            "id": 2258,
            "firstLetter": "Q"
          },
          {
            "value": "510184",
            "title": "崇州市",
            "id": 2259,
            "firstLetter": "C"
          },
          {
            "value": "510116",
            "title": "双流区",
            "id": 4203,
            "firstLetter": "S"
          },
          {
            "value": "510117",
            "title": "郫都区",
            "id": 4204,
            "firstLetter": "P"
          },
          {
            "value": "510185",
            "title": "简阳市",
            "id": 4205,
            "firstLetter": "J"
          }
        ],
        "id": 241,
        "provinceID": 23,
        "firstLetter": "C"
      },
      {
        "value": "510300",
        "title": "自贡市",
        "children": [
          {
            "value": "510302",
            "title": "自流井区",
            "id": 2261,
            "firstLetter": "Z"
          },
          {
            "value": "510303",
            "title": "贡井区",
            "id": 2262,
            "firstLetter": "G"
          },
          {
            "value": "510304",
            "title": "大安区",
            "id": 2263,
            "firstLetter": "D"
          },
          {
            "value": "510311",
            "title": "沿滩区",
            "id": 2264,
            "firstLetter": "Y"
          },
          {
            "value": "510321",
            "title": "荣县",
            "id": 2265,
            "firstLetter": "R"
          },
          {
            "value": "510322",
            "title": "富顺县",
            "id": 2266,
            "firstLetter": "F"
          }
        ],
        "id": 242,
        "provinceID": 23,
        "firstLetter": "Z"
      },
      {
        "value": "510400",
        "title": "攀枝花市",
        "children": [
          {
            "value": "510402",
            "title": "东区",
            "id": 2268,
            "firstLetter": "D"
          },
          {
            "value": "510403",
            "title": "西区",
            "id": 2269,
            "firstLetter": "X"
          },
          {
            "value": "510411",
            "title": "仁和区",
            "id": 2270,
            "firstLetter": "R"
          },
          {
            "value": "510421",
            "title": "米易县",
            "id": 2271,
            "firstLetter": "M"
          },
          {
            "value": "510422",
            "title": "盐边县",
            "id": 2272,
            "firstLetter": "Y"
          }
        ],
        "id": 243,
        "provinceID": 23,
        "firstLetter": "P"
      },
      {
        "value": "510500",
        "title": "泸州市",
        "children": [
          {
            "value": "510502",
            "title": "江阳区",
            "id": 2274,
            "firstLetter": "J"
          },
          {
            "value": "510503",
            "title": "纳溪区",
            "id": 2275,
            "firstLetter": "N"
          },
          {
            "value": "510504",
            "title": "龙马潭区",
            "id": 2276,
            "firstLetter": "L"
          },
          {
            "value": "510521",
            "title": "泸县",
            "id": 2277,
            "firstLetter": "L"
          },
          {
            "value": "510522",
            "title": "合江县",
            "id": 2278,
            "firstLetter": "H"
          },
          {
            "value": "510524",
            "title": "叙永县",
            "id": 2279,
            "firstLetter": "X"
          },
          {
            "value": "510525",
            "title": "古蔺县",
            "id": 2280,
            "firstLetter": "G"
          }
        ],
        "id": 244,
        "provinceID": 23,
        "firstLetter": "L"
      },
      {
        "value": "510600",
        "title": "德阳市",
        "children": [
          {
            "value": "510603",
            "title": "旌阳区",
            "id": 2282,
            "firstLetter": "J"
          },
          {
            "value": "510623",
            "title": "中江县",
            "id": 2283,
            "firstLetter": "Z"
          },
          {
            "value": "510681",
            "title": "广汉市",
            "id": 2285,
            "firstLetter": "G"
          },
          {
            "value": "510682",
            "title": "什邡市",
            "id": 2286,
            "firstLetter": "S"
          },
          {
            "value": "510683",
            "title": "绵竹市",
            "id": 2287,
            "firstLetter": "M"
          },
          {
            "value": "510604",
            "title": "罗江区",
            "id": 4206,
            "firstLetter": "L"
          }
        ],
        "id": 245,
        "provinceID": 23,
        "firstLetter": "D"
      },
      {
        "value": "510700",
        "title": "绵阳市",
        "children": [
          {
            "value": "510703",
            "title": "涪城区",
            "id": 2289,
            "firstLetter": "F"
          },
          {
            "value": "510704",
            "title": "游仙区",
            "id": 2290,
            "firstLetter": "Y"
          },
          {
            "value": "510722",
            "title": "三台县",
            "id": 2291,
            "firstLetter": "S"
          },
          {
            "value": "510723",
            "title": "盐亭县",
            "id": 2292,
            "firstLetter": "Y"
          },
          {
            "value": "510725",
            "title": "梓潼县",
            "id": 2294,
            "firstLetter": "Z"
          },
          {
            "value": "510726",
            "title": "北川羌族自治县",
            "id": 2295,
            "firstLetter": "B"
          },
          {
            "value": "510727",
            "title": "平武县",
            "id": 2296,
            "firstLetter": "P"
          },
          {
            "value": "510781",
            "title": "江油市",
            "id": 2297,
            "firstLetter": "J"
          },
          {
            "value": "510705",
            "title": "安州区",
            "id": 4207,
            "firstLetter": "A"
          }
        ],
        "id": 246,
        "provinceID": 23,
        "firstLetter": "M"
      },
      {
        "value": "510800",
        "title": "广元市",
        "children": [
          {
            "value": "510802",
            "title": "利州区",
            "id": 2299,
            "firstLetter": "L"
          },
          {
            "value": "510811",
            "title": "昭化区",
            "id": 2300,
            "firstLetter": "Z"
          },
          {
            "value": "510812",
            "title": "朝天区",
            "id": 2301,
            "firstLetter": "C"
          },
          {
            "value": "510821",
            "title": "旺苍县",
            "id": 2302,
            "firstLetter": "W"
          },
          {
            "value": "510822",
            "title": "青川县",
            "id": 2303,
            "firstLetter": "Q"
          },
          {
            "value": "510823",
            "title": "剑阁县",
            "id": 2304,
            "firstLetter": "J"
          },
          {
            "value": "510824",
            "title": "苍溪县",
            "id": 2305,
            "firstLetter": "C"
          }
        ],
        "id": 247,
        "provinceID": 23,
        "firstLetter": "G"
      },
      {
        "value": "510900",
        "title": "遂宁市",
        "children": [
          {
            "value": "510903",
            "title": "船山区",
            "id": 2307,
            "firstLetter": "C"
          },
          {
            "value": "510904",
            "title": "安居区",
            "id": 2308,
            "firstLetter": "A"
          },
          {
            "value": "510921",
            "title": "蓬溪县",
            "id": 2309,
            "firstLetter": "P"
          },
          {
            "value": "510922",
            "title": "射洪县",
            "id": 2310,
            "firstLetter": "S"
          },
          {
            "value": "510923",
            "title": "大英县",
            "id": 2311,
            "firstLetter": "D"
          }
        ],
        "id": 248,
        "provinceID": 23,
        "firstLetter": "S"
      },
      {
        "value": "511000",
        "title": "内江市",
        "children": [
          {
            "value": "511002",
            "title": "市中区",
            "id": 2313,
            "firstLetter": "S"
          },
          {
            "value": "511011",
            "title": "东兴区",
            "id": 2314,
            "firstLetter": "D"
          },
          {
            "value": "511024",
            "title": "威远县",
            "id": 2315,
            "firstLetter": "W"
          },
          {
            "value": "511025",
            "title": "资中县",
            "id": 2316,
            "firstLetter": "Z"
          },
          {
            "value": "511083",
            "title": "隆昌市",
            "id": 4208,
            "firstLetter": "L"
          }
        ],
        "id": 249,
        "provinceID": 23,
        "firstLetter": "N"
      },
      {
        "value": "511100",
        "title": "乐山市",
        "children": [
          {
            "value": "511102",
            "title": "市中区",
            "id": 2319,
            "firstLetter": "S"
          },
          {
            "value": "511111",
            "title": "沙湾区",
            "id": 2320,
            "firstLetter": "S"
          },
          {
            "value": "511112",
            "title": "五通桥区",
            "id": 2321,
            "firstLetter": "W"
          },
          {
            "value": "511113",
            "title": "金口河区",
            "id": 2322,
            "firstLetter": "J"
          },
          {
            "value": "511123",
            "title": "犍为县",
            "id": 2323,
            "firstLetter": "J"
          },
          {
            "value": "511124",
            "title": "井研县",
            "id": 2324,
            "firstLetter": "J"
          },
          {
            "value": "511126",
            "title": "夹江县",
            "id": 2325,
            "firstLetter": "J"
          },
          {
            "value": "511129",
            "title": "沐川县",
            "id": 2326,
            "firstLetter": "M"
          },
          {
            "value": "511132",
            "title": "峨边彝族自治县",
            "id": 2327,
            "firstLetter": "E"
          },
          {
            "value": "511133",
            "title": "马边彝族自治县",
            "id": 2328,
            "firstLetter": "M"
          },
          {
            "value": "511181",
            "title": "峨眉山市",
            "id": 2329,
            "firstLetter": "E"
          }
        ],
        "id": 250,
        "provinceID": 23,
        "firstLetter": "L"
      },
      {
        "value": "511300",
        "title": "南充市",
        "children": [
          {
            "value": "511302",
            "title": "顺庆区",
            "id": 2331,
            "firstLetter": "S"
          },
          {
            "value": "511303",
            "title": "高坪区",
            "id": 2332,
            "firstLetter": "G"
          },
          {
            "value": "511304",
            "title": "嘉陵区",
            "id": 2333,
            "firstLetter": "J"
          },
          {
            "value": "511321",
            "title": "南部县",
            "id": 2334,
            "firstLetter": "N"
          },
          {
            "value": "511322",
            "title": "营山县",
            "id": 2335,
            "firstLetter": "Y"
          },
          {
            "value": "511323",
            "title": "蓬安县",
            "id": 2336,
            "firstLetter": "P"
          },
          {
            "value": "511324",
            "title": "仪陇县",
            "id": 2337,
            "firstLetter": "Y"
          },
          {
            "value": "511325",
            "title": "西充县",
            "id": 2338,
            "firstLetter": "X"
          },
          {
            "value": "511381",
            "title": "阆中市",
            "id": 2339,
            "firstLetter": "L"
          }
        ],
        "id": 251,
        "provinceID": 23,
        "firstLetter": "N"
      },
      {
        "value": "511400",
        "title": "眉山市",
        "children": [
          {
            "value": "511402",
            "title": "东坡区",
            "id": 2341,
            "firstLetter": "D"
          },
          {
            "value": "511421",
            "title": "仁寿县",
            "id": 2342,
            "firstLetter": "R"
          },
          {
            "value": "511423",
            "title": "洪雅县",
            "id": 2344,
            "firstLetter": "H"
          },
          {
            "value": "511424",
            "title": "丹棱县",
            "id": 2345,
            "firstLetter": "D"
          },
          {
            "value": "511425",
            "title": "青神县",
            "id": 2346,
            "firstLetter": "Q"
          },
          {
            "value": "511403",
            "title": "彭山区",
            "id": 4209,
            "firstLetter": "P"
          }
        ],
        "id": 252,
        "provinceID": 23,
        "firstLetter": "M"
      },
      {
        "value": "511500",
        "title": "宜宾市",
        "children": [
          {
            "value": "511502",
            "title": "翠屏区",
            "id": 2348,
            "firstLetter": "C"
          },
          {
            "value": "511503",
            "title": "南溪区",
            "id": 2349,
            "firstLetter": "N"
          },
          {
            "value": "511523",
            "title": "江安县",
            "id": 2351,
            "firstLetter": "J"
          },
          {
            "value": "511524",
            "title": "长宁县",
            "id": 2352,
            "firstLetter": "Z"
          },
          {
            "value": "511525",
            "title": "高县",
            "id": 2353,
            "firstLetter": "G"
          },
          {
            "value": "511526",
            "title": "珙县",
            "id": 2354,
            "firstLetter": "G"
          },
          {
            "value": "511527",
            "title": "筠连县",
            "id": 2355,
            "firstLetter": "Y"
          },
          {
            "value": "511528",
            "title": "兴文县",
            "id": 2356,
            "firstLetter": "X"
          },
          {
            "value": "511529",
            "title": "屏山县",
            "id": 2357,
            "firstLetter": "P"
          },
          {
            "value": "511504",
            "title": "叙州区",
            "id": 4210,
            "firstLetter": "X"
          }
        ],
        "id": 253,
        "provinceID": 23,
        "firstLetter": "Y"
      },
      {
        "value": "511600",
        "title": "广安市",
        "children": [
          {
            "value": "511602",
            "title": "广安区",
            "id": 2359,
            "firstLetter": "G"
          },
          {
            "value": "511603",
            "title": "前锋区",
            "id": 2360,
            "firstLetter": "Q"
          },
          {
            "value": "511621",
            "title": "岳池县",
            "id": 2361,
            "firstLetter": "Y"
          },
          {
            "value": "511622",
            "title": "武胜县",
            "id": 2362,
            "firstLetter": "W"
          },
          {
            "value": "511623",
            "title": "邻水县",
            "id": 2363,
            "firstLetter": "L"
          },
          {
            "value": "511681",
            "title": "华蓥市",
            "id": 2364,
            "firstLetter": "H"
          }
        ],
        "id": 254,
        "provinceID": 23,
        "firstLetter": "G"
      },
      {
        "value": "511700",
        "title": "达州市",
        "children": [
          {
            "value": "511702",
            "title": "通川区",
            "id": 2366,
            "firstLetter": "T"
          },
          {
            "value": "511703",
            "title": "达川区",
            "id": 2367,
            "firstLetter": "D"
          },
          {
            "value": "511722",
            "title": "宣汉县",
            "id": 2368,
            "firstLetter": "X"
          },
          {
            "value": "511723",
            "title": "开江县",
            "id": 2369,
            "firstLetter": "K"
          },
          {
            "value": "511724",
            "title": "大竹县",
            "id": 2370,
            "firstLetter": "D"
          },
          {
            "value": "511725",
            "title": "渠县",
            "id": 2371,
            "firstLetter": "Q"
          },
          {
            "value": "511781",
            "title": "万源市",
            "id": 2372,
            "firstLetter": "W"
          }
        ],
        "id": 255,
        "provinceID": 23,
        "firstLetter": "D"
      },
      {
        "value": "511800",
        "title": "雅安市",
        "children": [
          {
            "value": "511802",
            "title": "雨城区",
            "id": 2374,
            "firstLetter": "Y"
          },
          {
            "value": "511803",
            "title": "名山区",
            "id": 2375,
            "firstLetter": "M"
          },
          {
            "value": "511822",
            "title": "荥经县",
            "id": 2376,
            "firstLetter": "Y"
          },
          {
            "value": "511823",
            "title": "汉源县",
            "id": 2377,
            "firstLetter": "H"
          },
          {
            "value": "511824",
            "title": "石棉县",
            "id": 2378,
            "firstLetter": "S"
          },
          {
            "value": "511825",
            "title": "天全县",
            "id": 2379,
            "firstLetter": "T"
          },
          {
            "value": "511826",
            "title": "芦山县",
            "id": 2380,
            "firstLetter": "L"
          },
          {
            "value": "511827",
            "title": "宝兴县",
            "id": 2381,
            "firstLetter": "B"
          }
        ],
        "id": 256,
        "provinceID": 23,
        "firstLetter": "Y"
      },
      {
        "value": "511900",
        "title": "巴中市",
        "children": [
          {
            "value": "511902",
            "title": "巴州区",
            "id": 2383,
            "firstLetter": "B"
          },
          {
            "value": "511903",
            "title": "恩阳区",
            "id": 2384,
            "firstLetter": "E"
          },
          {
            "value": "511921",
            "title": "通江县",
            "id": 2385,
            "firstLetter": "T"
          },
          {
            "value": "511922",
            "title": "南江县",
            "id": 2386,
            "firstLetter": "N"
          },
          {
            "value": "511923",
            "title": "平昌县",
            "id": 2387,
            "firstLetter": "P"
          }
        ],
        "id": 257,
        "provinceID": 23,
        "firstLetter": "B"
      },
      {
        "value": "512000",
        "title": "资阳市",
        "children": [
          {
            "value": "512002",
            "title": "雁江区",
            "id": 2389,
            "firstLetter": "Y"
          },
          {
            "value": "512021",
            "title": "安岳县",
            "id": 2390,
            "firstLetter": "A"
          },
          {
            "value": "512022",
            "title": "乐至县",
            "id": 2391,
            "firstLetter": "L"
          }
        ],
        "id": 258,
        "provinceID": 23,
        "firstLetter": "Z"
      },
      {
        "value": "513200",
        "title": "阿坝藏族羌族自治州",
        "children": [
          {
            "value": "513221",
            "title": "汶川县",
            "id": 2393,
            "firstLetter": "W"
          },
          {
            "value": "513222",
            "title": "理县",
            "id": 2394,
            "firstLetter": "L"
          },
          {
            "value": "513223",
            "title": "茂县",
            "id": 2395,
            "firstLetter": "M"
          },
          {
            "value": "513224",
            "title": "松潘县",
            "id": 2396,
            "firstLetter": "S"
          },
          {
            "value": "513225",
            "title": "九寨沟县",
            "id": 2397,
            "firstLetter": "J"
          },
          {
            "value": "513226",
            "title": "金川县",
            "id": 2398,
            "firstLetter": "J"
          },
          {
            "value": "513227",
            "title": "小金县",
            "id": 2399,
            "firstLetter": "X"
          },
          {
            "value": "513228",
            "title": "黑水县",
            "id": 2400,
            "firstLetter": "H"
          },
          {
            "value": "513230",
            "title": "壤塘县",
            "id": 2402,
            "firstLetter": "R"
          },
          {
            "value": "513231",
            "title": "阿坝县",
            "id": 2403,
            "firstLetter": "A"
          },
          {
            "value": "513232",
            "title": "若尔盖县",
            "id": 2404,
            "firstLetter": "R"
          },
          {
            "value": "513233",
            "title": "红原县",
            "id": 2405,
            "firstLetter": "H"
          },
          {
            "value": "513201",
            "title": "马尔康市",
            "id": 4211,
            "firstLetter": "M"
          }
        ],
        "id": 259,
        "provinceID": 23,
        "firstLetter": "A"
      },
      {
        "value": "513300",
        "title": "甘孜藏族自治州",
        "children": [
          {
            "value": "513322",
            "title": "泸定县",
            "id": 2407,
            "firstLetter": "L"
          },
          {
            "value": "513323",
            "title": "丹巴县",
            "id": 2408,
            "firstLetter": "D"
          },
          {
            "value": "513324",
            "title": "九龙县",
            "id": 2409,
            "firstLetter": "J"
          },
          {
            "value": "513325",
            "title": "雅江县",
            "id": 2410,
            "firstLetter": "Y"
          },
          {
            "value": "513326",
            "title": "道孚县",
            "id": 2411,
            "firstLetter": "D"
          },
          {
            "value": "513327",
            "title": "炉霍县",
            "id": 2412,
            "firstLetter": "L"
          },
          {
            "value": "513328",
            "title": "甘孜县",
            "id": 2413,
            "firstLetter": "G"
          },
          {
            "value": "513329",
            "title": "新龙县",
            "id": 2414,
            "firstLetter": "X"
          },
          {
            "value": "513330",
            "title": "德格县",
            "id": 2415,
            "firstLetter": "D"
          },
          {
            "value": "513331",
            "title": "白玉县",
            "id": 2416,
            "firstLetter": "B"
          },
          {
            "value": "513332",
            "title": "石渠县",
            "id": 2417,
            "firstLetter": "S"
          },
          {
            "value": "513333",
            "title": "色达县",
            "id": 2418,
            "firstLetter": "S"
          },
          {
            "value": "513334",
            "title": "理塘县",
            "id": 2419,
            "firstLetter": "L"
          },
          {
            "value": "513335",
            "title": "巴塘县",
            "id": 2420,
            "firstLetter": "B"
          },
          {
            "value": "513336",
            "title": "乡城县",
            "id": 2421,
            "firstLetter": "X"
          },
          {
            "value": "513337",
            "title": "稻城县",
            "id": 2422,
            "firstLetter": "D"
          },
          {
            "value": "513338",
            "title": "得荣县",
            "id": 2423,
            "firstLetter": "D"
          },
          {
            "value": "513301",
            "title": "康定市",
            "id": 4212,
            "firstLetter": "K"
          }
        ],
        "id": 260,
        "provinceID": 23,
        "firstLetter": "G"
      },
      {
        "value": "513400",
        "title": "凉山彝族自治州",
        "children": [
          {
            "value": "513401",
            "title": "西昌市",
            "id": 2424,
            "firstLetter": "X"
          },
          {
            "value": "513422",
            "title": "木里藏族自治县",
            "id": 2425,
            "firstLetter": "M"
          },
          {
            "value": "513423",
            "title": "盐源县",
            "id": 2426,
            "firstLetter": "Y"
          },
          {
            "value": "513424",
            "title": "德昌县",
            "id": 2427,
            "firstLetter": "D"
          },
          {
            "value": "513425",
            "title": "会理县",
            "id": 2428,
            "firstLetter": "H"
          },
          {
            "value": "513426",
            "title": "会东县",
            "id": 2429,
            "firstLetter": "H"
          },
          {
            "value": "513427",
            "title": "宁南县",
            "id": 2430,
            "firstLetter": "N"
          },
          {
            "value": "513428",
            "title": "普格县",
            "id": 2431,
            "firstLetter": "P"
          },
          {
            "value": "513429",
            "title": "布拖县",
            "id": 2432,
            "firstLetter": "B"
          },
          {
            "value": "513430",
            "title": "金阳县",
            "id": 2433,
            "firstLetter": "J"
          },
          {
            "value": "513431",
            "title": "昭觉县",
            "id": 2434,
            "firstLetter": "Z"
          },
          {
            "value": "513432",
            "title": "喜德县",
            "id": 2435,
            "firstLetter": "X"
          },
          {
            "value": "513433",
            "title": "冕宁县",
            "id": 2436,
            "firstLetter": "M"
          },
          {
            "value": "513434",
            "title": "越西县",
            "id": 2437,
            "firstLetter": "Y"
          },
          {
            "value": "513435",
            "title": "甘洛县",
            "id": 2438,
            "firstLetter": "G"
          },
          {
            "value": "513436",
            "title": "美姑县",
            "id": 2439,
            "firstLetter": "M"
          },
          {
            "value": "513437",
            "title": "雷波县",
            "id": 2440,
            "firstLetter": "L"
          }
        ],
        "id": 261,
        "provinceID": 23,
        "firstLetter": "L"
      }
    ],
    "title": "四川省",
    "id": 23,
    "firstLetter": "S"
  },
  {
    "value": "520000",
    "children": [
      {
        "value": "520100",
        "title": "贵阳市",
        "children": [
          {
            "value": "520102",
            "title": "南明区",
            "id": 2442,
            "firstLetter": "N"
          },
          {
            "value": "520103",
            "title": "云岩区",
            "id": 2443,
            "firstLetter": "Y"
          },
          {
            "value": "520111",
            "title": "花溪区",
            "id": 2444,
            "firstLetter": "H"
          },
          {
            "value": "520112",
            "title": "乌当区",
            "id": 2445,
            "firstLetter": "W"
          },
          {
            "value": "520113",
            "title": "白云区",
            "id": 2446,
            "firstLetter": "B"
          },
          {
            "value": "520115",
            "title": "观山湖区",
            "id": 2447,
            "firstLetter": "G"
          },
          {
            "value": "520121",
            "title": "开阳县",
            "id": 2448,
            "firstLetter": "K"
          },
          {
            "value": "520122",
            "title": "息烽县",
            "id": 2449,
            "firstLetter": "X"
          },
          {
            "value": "520123",
            "title": "修文县",
            "id": 2450,
            "firstLetter": "X"
          },
          {
            "value": "520181",
            "title": "清镇市",
            "id": 2451,
            "firstLetter": "Q"
          }
        ],
        "id": 262,
        "provinceID": 24,
        "firstLetter": "G"
      },
      {
        "value": "520200",
        "title": "六盘水市",
        "children": [
          {
            "value": "520201",
            "title": "钟山区",
            "id": 2452,
            "firstLetter": "Z"
          },
          {
            "value": "520203",
            "title": "六枝特区",
            "id": 2453,
            "firstLetter": "L"
          },
          {
            "value": "520221",
            "title": "水城县",
            "id": 2454,
            "firstLetter": "S"
          },
          {
            "value": "520281",
            "title": "盘州市",
            "id": 4213,
            "firstLetter": "P"
          }
        ],
        "id": 263,
        "provinceID": 24,
        "firstLetter": "L"
      },
      {
        "value": "520300",
        "title": "遵义市",
        "children": [
          {
            "value": "520302",
            "title": "红花岗区",
            "id": 2457,
            "firstLetter": "H"
          },
          {
            "value": "520303",
            "title": "汇川区",
            "id": 2458,
            "firstLetter": "H"
          },
          {
            "value": "520322",
            "title": "桐梓县",
            "id": 2460,
            "firstLetter": "T"
          },
          {
            "value": "520323",
            "title": "绥阳县",
            "id": 2461,
            "firstLetter": "S"
          },
          {
            "value": "520324",
            "title": "正安县",
            "id": 2462,
            "firstLetter": "Z"
          },
          {
            "value": "520325",
            "title": "道真仡佬族苗族自治县",
            "id": 2463,
            "firstLetter": "D"
          },
          {
            "value": "520326",
            "title": "务川仡佬族苗族自治县",
            "id": 2464,
            "firstLetter": "W"
          },
          {
            "value": "520327",
            "title": "凤冈县",
            "id": 2465,
            "firstLetter": "F"
          },
          {
            "value": "520328",
            "title": "湄潭县",
            "id": 2466,
            "firstLetter": "M"
          },
          {
            "value": "520329",
            "title": "余庆县",
            "id": 2467,
            "firstLetter": "Y"
          },
          {
            "value": "520330",
            "title": "习水县",
            "id": 2468,
            "firstLetter": "X"
          },
          {
            "value": "520381",
            "title": "赤水市",
            "id": 2469,
            "firstLetter": "C"
          },
          {
            "value": "520382",
            "title": "仁怀市",
            "id": 2470,
            "firstLetter": "R"
          },
          {
            "value": "520304",
            "title": "播州区",
            "id": 4214,
            "firstLetter": "B"
          }
        ],
        "id": 264,
        "provinceID": 24,
        "firstLetter": "Z"
      },
      {
        "value": "520400",
        "title": "安顺市",
        "children": [
          {
            "value": "520402",
            "title": "西秀区",
            "id": 2472,
            "firstLetter": "X"
          },
          {
            "value": "520422",
            "title": "普定县",
            "id": 2474,
            "firstLetter": "P"
          },
          {
            "value": "520423",
            "title": "镇宁布依族苗族自治县",
            "id": 2475,
            "firstLetter": "Z"
          },
          {
            "value": "520424",
            "title": "关岭布依族苗族自治县",
            "id": 2476,
            "firstLetter": "G"
          },
          {
            "value": "520425",
            "title": "紫云苗族布依族自治县",
            "id": 2477,
            "firstLetter": "Z"
          },
          {
            "value": "520403",
            "title": "平坝区",
            "id": 4215,
            "firstLetter": "P"
          }
        ],
        "id": 265,
        "provinceID": 24,
        "firstLetter": "A"
      },
      {
        "value": "520500",
        "title": "毕节市",
        "children": [
          {
            "value": "520502",
            "title": "七星关区",
            "id": 2479,
            "firstLetter": "Q"
          },
          {
            "value": "520521",
            "title": "大方县",
            "id": 2480,
            "firstLetter": "D"
          },
          {
            "value": "520522",
            "title": "黔西县",
            "id": 2481,
            "firstLetter": "Q"
          },
          {
            "value": "520523",
            "title": "金沙县",
            "id": 2482,
            "firstLetter": "J"
          },
          {
            "value": "520524",
            "title": "织金县",
            "id": 2483,
            "firstLetter": "Z"
          },
          {
            "value": "520525",
            "title": "纳雍县",
            "id": 2484,
            "firstLetter": "N"
          },
          {
            "value": "520526",
            "title": "威宁彝族回族苗族自治县",
            "id": 2485,
            "firstLetter": "W"
          },
          {
            "value": "520527",
            "title": "赫章县",
            "id": 2486,
            "firstLetter": "H"
          }
        ],
        "id": 266,
        "provinceID": 24,
        "firstLetter": "B"
      },
      {
        "value": "520600",
        "title": "铜仁市",
        "children": [
          {
            "value": "520602",
            "title": "碧江区",
            "id": 2488,
            "firstLetter": "B"
          },
          {
            "value": "520603",
            "title": "万山区",
            "id": 2489,
            "firstLetter": "W"
          },
          {
            "value": "520621",
            "title": "江口县",
            "id": 2490,
            "firstLetter": "J"
          },
          {
            "value": "520622",
            "title": "玉屏侗族自治县",
            "id": 2491,
            "firstLetter": "Y"
          },
          {
            "value": "520623",
            "title": "石阡县",
            "id": 2492,
            "firstLetter": "S"
          },
          {
            "value": "520624",
            "title": "思南县",
            "id": 2493,
            "firstLetter": "S"
          },
          {
            "value": "520625",
            "title": "印江土家族苗族自治县",
            "id": 2494,
            "firstLetter": "Y"
          },
          {
            "value": "520626",
            "title": "德江县",
            "id": 2495,
            "firstLetter": "D"
          },
          {
            "value": "520627",
            "title": "沿河土家族自治县",
            "id": 2496,
            "firstLetter": "Y"
          },
          {
            "value": "520628",
            "title": "松桃苗族自治县",
            "id": 2497,
            "firstLetter": "S"
          }
        ],
        "id": 267,
        "provinceID": 24,
        "firstLetter": "T"
      },
      {
        "value": "522300",
        "title": "黔西南布依族苗族自治州",
        "children": [
          {
            "value": "522301",
            "title": "兴义市",
            "id": 2498,
            "firstLetter": "X"
          },
          {
            "value": "522323",
            "title": "普安县",
            "id": 2500,
            "firstLetter": "P"
          },
          {
            "value": "522324",
            "title": "晴隆县",
            "id": 2501,
            "firstLetter": "Q"
          },
          {
            "value": "522325",
            "title": "贞丰县",
            "id": 2502,
            "firstLetter": "Z"
          },
          {
            "value": "522326",
            "title": "望谟县",
            "id": 2503,
            "firstLetter": "W"
          },
          {
            "value": "522327",
            "title": "册亨县",
            "id": 2504,
            "firstLetter": "C"
          },
          {
            "value": "522328",
            "title": "安龙县",
            "id": 2505,
            "firstLetter": "A"
          },
          {
            "value": "522302",
            "title": "兴仁市",
            "id": 4216,
            "firstLetter": "X"
          }
        ],
        "id": 268,
        "provinceID": 24,
        "firstLetter": "Q"
      },
      {
        "value": "522600",
        "title": "黔东南苗族侗族自治州",
        "children": [
          {
            "value": "522601",
            "title": "凯里市",
            "id": 2506,
            "firstLetter": "K"
          },
          {
            "value": "522622",
            "title": "黄平县",
            "id": 2507,
            "firstLetter": "H"
          },
          {
            "value": "522623",
            "title": "施秉县",
            "id": 2508,
            "firstLetter": "S"
          },
          {
            "value": "522624",
            "title": "三穗县",
            "id": 2509,
            "firstLetter": "S"
          },
          {
            "value": "522625",
            "title": "镇远县",
            "id": 2510,
            "firstLetter": "Z"
          },
          {
            "value": "522626",
            "title": "岑巩县",
            "id": 2511,
            "firstLetter": "C"
          },
          {
            "value": "522627",
            "title": "天柱县",
            "id": 2512,
            "firstLetter": "T"
          },
          {
            "value": "522628",
            "title": "锦屏县",
            "id": 2513,
            "firstLetter": "J"
          },
          {
            "value": "522629",
            "title": "剑河县",
            "id": 2514,
            "firstLetter": "J"
          },
          {
            "value": "522630",
            "title": "台江县",
            "id": 2515,
            "firstLetter": "T"
          },
          {
            "value": "522631",
            "title": "黎平县",
            "id": 2516,
            "firstLetter": "L"
          },
          {
            "value": "522632",
            "title": "榕江县",
            "id": 2517,
            "firstLetter": "R"
          },
          {
            "value": "522633",
            "title": "从江县",
            "id": 2518,
            "firstLetter": "C"
          },
          {
            "value": "522634",
            "title": "雷山县",
            "id": 2519,
            "firstLetter": "L"
          },
          {
            "value": "522635",
            "title": "麻江县",
            "id": 2520,
            "firstLetter": "M"
          },
          {
            "value": "522636",
            "title": "丹寨县",
            "id": 2521,
            "firstLetter": "D"
          }
        ],
        "id": 269,
        "provinceID": 24,
        "firstLetter": "Q"
      },
      {
        "value": "522700",
        "title": "黔南州",
        "children": [
          {
            "value": "522701",
            "title": "都匀市",
            "id": 2522,
            "firstLetter": "D"
          },
          {
            "value": "522702",
            "title": "福泉市",
            "id": 2523,
            "firstLetter": "F"
          },
          {
            "value": "522722",
            "title": "荔波县",
            "id": 2524,
            "firstLetter": "L"
          },
          {
            "value": "522723",
            "title": "贵定县",
            "id": 2525,
            "firstLetter": "G"
          },
          {
            "value": "522725",
            "title": "瓮安县",
            "id": 2526,
            "firstLetter": "W"
          },
          {
            "value": "522726",
            "title": "独山县",
            "id": 2527,
            "firstLetter": "D"
          },
          {
            "value": "522727",
            "title": "平塘县",
            "id": 2528,
            "firstLetter": "P"
          },
          {
            "value": "522728",
            "title": "罗甸县",
            "id": 2529,
            "firstLetter": "L"
          },
          {
            "value": "522729",
            "title": "长顺县",
            "id": 2530,
            "firstLetter": "Z"
          },
          {
            "value": "522730",
            "title": "龙里县",
            "id": 2531,
            "firstLetter": "L"
          },
          {
            "value": "522731",
            "title": "惠水县",
            "id": 2532,
            "firstLetter": "H"
          },
          {
            "value": "522732",
            "title": "三都水族自治县",
            "id": 2533,
            "firstLetter": "S"
          }
        ],
        "id": 270,
        "provinceID": 24,
        "firstLetter": "Q"
      }
    ],
    "title": "贵州省",
    "id": 24,
    "firstLetter": "G"
  },
  {
    "value": "530000",
    "children": [
      {
        "value": "530100",
        "title": "昆明市",
        "children": [
          {
            "value": "530102",
            "title": "五华区",
            "id": 2535,
            "firstLetter": "W"
          },
          {
            "value": "530103",
            "title": "盘龙区",
            "id": 2536,
            "firstLetter": "P"
          },
          {
            "value": "530111",
            "title": "官渡区",
            "id": 2537,
            "firstLetter": "G"
          },
          {
            "value": "530112",
            "title": "西山区",
            "id": 2538,
            "firstLetter": "X"
          },
          {
            "value": "530113",
            "title": "东川区",
            "id": 2539,
            "firstLetter": "D"
          },
          {
            "value": "530114",
            "title": "呈贡区",
            "id": 2540,
            "firstLetter": "C"
          },
          {
            "value": "530124",
            "title": "富民县",
            "id": 2542,
            "firstLetter": "F"
          },
          {
            "value": "530125",
            "title": "宜良县",
            "id": 2543,
            "firstLetter": "Y"
          },
          {
            "value": "530126",
            "title": "石林彝族自治县",
            "id": 2544,
            "firstLetter": "S"
          },
          {
            "value": "530127",
            "title": "嵩明县",
            "id": 2545,
            "firstLetter": "S"
          },
          {
            "value": "530128",
            "title": "禄劝彝族苗族自治县",
            "id": 2546,
            "firstLetter": "L"
          },
          {
            "value": "530129",
            "title": "寻甸回族彝族自治县",
            "id": 2547,
            "firstLetter": "X"
          },
          {
            "value": "530181",
            "title": "安宁市",
            "id": 2548,
            "firstLetter": "A"
          },
          {
            "value": "530115",
            "title": "晋宁区",
            "id": 4217,
            "firstLetter": "J"
          }
        ],
        "id": 271,
        "provinceID": 25,
        "firstLetter": "K"
      },
      {
        "value": "530300",
        "title": "曲靖市",
        "children": [
          {
            "value": "530302",
            "title": "麒麟区",
            "id": 2550,
            "firstLetter": "Q"
          },
          {
            "value": "530322",
            "title": "陆良县",
            "id": 2552,
            "firstLetter": "L"
          },
          {
            "value": "530323",
            "title": "师宗县",
            "id": 2553,
            "firstLetter": "S"
          },
          {
            "value": "530324",
            "title": "罗平县",
            "id": 2554,
            "firstLetter": "L"
          },
          {
            "value": "530325",
            "title": "富源县",
            "id": 2555,
            "firstLetter": "F"
          },
          {
            "value": "530326",
            "title": "会泽县",
            "id": 2556,
            "firstLetter": "H"
          },
          {
            "value": "530381",
            "title": "宣威市",
            "id": 2558,
            "firstLetter": "X"
          },
          {
            "value": "530303",
            "title": "沾益区",
            "id": 4218,
            "firstLetter": "Z"
          },
          {
            "value": "530304",
            "title": "马龙区",
            "id": 4219,
            "firstLetter": "M"
          }
        ],
        "id": 272,
        "provinceID": 25,
        "firstLetter": "Q"
      },
      {
        "value": "530400",
        "title": "玉溪市",
        "children": [
          {
            "value": "530402",
            "title": "红塔区",
            "id": 2560,
            "firstLetter": "H"
          },
          {
            "value": "530422",
            "title": "澄江县",
            "id": 2562,
            "firstLetter": "C"
          },
          {
            "value": "530423",
            "title": "通海县",
            "id": 2563,
            "firstLetter": "T"
          },
          {
            "value": "530424",
            "title": "华宁县",
            "id": 2564,
            "firstLetter": "H"
          },
          {
            "value": "530425",
            "title": "易门县",
            "id": 2565,
            "firstLetter": "Y"
          },
          {
            "value": "530426",
            "title": "峨山彝族自治县",
            "id": 2566,
            "firstLetter": "E"
          },
          {
            "value": "530427",
            "title": "新平彝族傣族自治县",
            "id": 2567,
            "firstLetter": "X"
          },
          {
            "value": "530428",
            "title": "元江哈尼族彝族傣族自治县",
            "id": 2568,
            "firstLetter": "Y"
          },
          {
            "value": "530403",
            "title": "江川区",
            "id": 4220,
            "firstLetter": "J"
          }
        ],
        "id": 273,
        "provinceID": 25,
        "firstLetter": "Y"
      },
      {
        "value": "530500",
        "title": "保山市",
        "children": [
          {
            "value": "530502",
            "title": "隆阳区",
            "id": 2570,
            "firstLetter": "L"
          },
          {
            "value": "530521",
            "title": "施甸县",
            "id": 2571,
            "firstLetter": "S"
          },
          {
            "value": "530523",
            "title": "龙陵县",
            "id": 2573,
            "firstLetter": "L"
          },
          {
            "value": "530524",
            "title": "昌宁县",
            "id": 2574,
            "firstLetter": "C"
          },
          {
            "value": "530581",
            "title": "腾冲市",
            "id": 4221,
            "firstLetter": "T"
          }
        ],
        "id": 274,
        "provinceID": 25,
        "firstLetter": "B"
      },
      {
        "value": "530600",
        "title": "昭通市",
        "children": [
          {
            "value": "530602",
            "title": "昭阳区",
            "id": 2576,
            "firstLetter": "Z"
          },
          {
            "value": "530621",
            "title": "鲁甸县",
            "id": 2577,
            "firstLetter": "L"
          },
          {
            "value": "530622",
            "title": "巧家县",
            "id": 2578,
            "firstLetter": "Q"
          },
          {
            "value": "530623",
            "title": "盐津县",
            "id": 2579,
            "firstLetter": "Y"
          },
          {
            "value": "530624",
            "title": "大关县",
            "id": 2580,
            "firstLetter": "D"
          },
          {
            "value": "530625",
            "title": "永善县",
            "id": 2581,
            "firstLetter": "Y"
          },
          {
            "value": "530626",
            "title": "绥江县",
            "id": 2582,
            "firstLetter": "S"
          },
          {
            "value": "530627",
            "title": "镇雄县",
            "id": 2583,
            "firstLetter": "Z"
          },
          {
            "value": "530628",
            "title": "彝良县",
            "id": 2584,
            "firstLetter": "Y"
          },
          {
            "value": "530629",
            "title": "威信县",
            "id": 2585,
            "firstLetter": "W"
          },
          {
            "value": "530681",
            "title": "水富市",
            "id": 4222,
            "firstLetter": "S"
          }
        ],
        "id": 275,
        "provinceID": 25,
        "firstLetter": "Z"
      },
      {
        "value": "530700",
        "title": "丽江市",
        "children": [
          {
            "value": "530702",
            "title": "古城区",
            "id": 2588,
            "firstLetter": "G"
          },
          {
            "value": "530721",
            "title": "玉龙纳西族自治县",
            "id": 2589,
            "firstLetter": "Y"
          },
          {
            "value": "530722",
            "title": "永胜县",
            "id": 2590,
            "firstLetter": "Y"
          },
          {
            "value": "530723",
            "title": "华坪县",
            "id": 2591,
            "firstLetter": "H"
          },
          {
            "value": "530724",
            "title": "宁蒗彝族自治县",
            "id": 2592,
            "firstLetter": "N"
          }
        ],
        "id": 276,
        "provinceID": 25,
        "firstLetter": "L"
      },
      {
        "value": "530800",
        "title": "普洱市",
        "children": [
          {
            "value": "530802",
            "title": "思茅区",
            "id": 2594,
            "firstLetter": "S"
          },
          {
            "value": "530821",
            "title": "宁洱哈尼族彝族自治县",
            "id": 2595,
            "firstLetter": "N"
          },
          {
            "value": "530822",
            "title": "墨江哈尼族自治县",
            "id": 2596,
            "firstLetter": "M"
          },
          {
            "value": "530823",
            "title": "景东彝族自治县",
            "id": 2597,
            "firstLetter": "J"
          },
          {
            "value": "530824",
            "title": "景谷傣族彝族自治县",
            "id": 2598,
            "firstLetter": "J"
          },
          {
            "value": "530825",
            "title": "镇沅彝族哈尼族拉祜族自治县",
            "id": 2599,
            "firstLetter": "Z"
          },
          {
            "value": "530826",
            "title": "江城哈尼族彝族自治县",
            "id": 2600,
            "firstLetter": "J"
          },
          {
            "value": "530827",
            "title": "孟连傣族拉祜族佤族自治县",
            "id": 2601,
            "firstLetter": "M"
          },
          {
            "value": "530828",
            "title": "澜沧拉祜族自治县",
            "id": 2602,
            "firstLetter": "L"
          },
          {
            "value": "530829",
            "title": "西盟佤族自治县",
            "id": 2603,
            "firstLetter": "X"
          }
        ],
        "id": 277,
        "provinceID": 25,
        "firstLetter": "P"
      },
      {
        "value": "530900",
        "title": "临沧市",
        "children": [
          {
            "value": "530902",
            "title": "临翔区",
            "id": 2605,
            "firstLetter": "L"
          },
          {
            "value": "530921",
            "title": "凤庆县",
            "id": 2606,
            "firstLetter": "F"
          },
          {
            "value": "530922",
            "title": "云县",
            "id": 2607,
            "firstLetter": "Y"
          },
          {
            "value": "530923",
            "title": "永德县",
            "id": 2608,
            "firstLetter": "Y"
          },
          {
            "value": "530924",
            "title": "镇康县",
            "id": 2609,
            "firstLetter": "Z"
          },
          {
            "value": "530925",
            "title": "双江拉祜族佤族布朗族傣族自治县",
            "id": 2610,
            "firstLetter": "S"
          },
          {
            "value": "530926",
            "title": "耿马傣族佤族自治县",
            "id": 2611,
            "firstLetter": "G"
          },
          {
            "value": "530927",
            "title": "沧源佤族自治县",
            "id": 2612,
            "firstLetter": "C"
          }
        ],
        "id": 278,
        "provinceID": 25,
        "firstLetter": "L"
      },
      {
        "value": "532300",
        "title": "楚雄彝族自治州",
        "children": [
          {
            "value": "532301",
            "title": "楚雄市",
            "id": 2613,
            "firstLetter": "C"
          },
          {
            "value": "532322",
            "title": "双柏县",
            "id": 2614,
            "firstLetter": "S"
          },
          {
            "value": "532323",
            "title": "牟定县",
            "id": 2615,
            "firstLetter": "M"
          },
          {
            "value": "532324",
            "title": "南华县",
            "id": 2616,
            "firstLetter": "N"
          },
          {
            "value": "532325",
            "title": "姚安县",
            "id": 2617,
            "firstLetter": "Y"
          },
          {
            "value": "532326",
            "title": "大姚县",
            "id": 2618,
            "firstLetter": "D"
          },
          {
            "value": "532327",
            "title": "永仁县",
            "id": 2619,
            "firstLetter": "Y"
          },
          {
            "value": "532328",
            "title": "元谋县",
            "id": 2620,
            "firstLetter": "Y"
          },
          {
            "value": "532329",
            "title": "武定县",
            "id": 2621,
            "firstLetter": "W"
          },
          {
            "value": "532331",
            "title": "禄丰县",
            "id": 2622,
            "firstLetter": "L"
          }
        ],
        "id": 279,
        "provinceID": 25,
        "firstLetter": "C"
      },
      {
        "value": "532500",
        "title": "红河哈尼族彝族自治州",
        "children": [
          {
            "value": "532501",
            "title": "个旧市",
            "id": 2623,
            "firstLetter": "G"
          },
          {
            "value": "532502",
            "title": "开远市",
            "id": 2624,
            "firstLetter": "K"
          },
          {
            "value": "532503",
            "title": "蒙自市",
            "id": 2625,
            "firstLetter": "M"
          },
          {
            "value": "532504",
            "title": "弥勒市",
            "id": 2626,
            "firstLetter": "M"
          },
          {
            "value": "532523",
            "title": "屏边苗族自治县",
            "id": 2627,
            "firstLetter": "P"
          },
          {
            "value": "532524",
            "title": "建水县",
            "id": 2628,
            "firstLetter": "J"
          },
          {
            "value": "532525",
            "title": "石屏县",
            "id": 2629,
            "firstLetter": "S"
          },
          {
            "value": "532527",
            "title": "泸西县",
            "id": 2630,
            "firstLetter": "L"
          },
          {
            "value": "532528",
            "title": "元阳县",
            "id": 2631,
            "firstLetter": "Y"
          },
          {
            "value": "532529",
            "title": "红河县",
            "id": 2632,
            "firstLetter": "H"
          },
          {
            "value": "532530",
            "title": "金平苗族瑶族傣族自治县",
            "id": 2633,
            "firstLetter": "J"
          },
          {
            "value": "532531",
            "title": "绿春县",
            "id": 2634,
            "firstLetter": "L"
          },
          {
            "value": "532532",
            "title": "河口瑶族自治县",
            "id": 2635,
            "firstLetter": "H"
          }
        ],
        "id": 280,
        "provinceID": 25,
        "firstLetter": "H"
      },
      {
        "value": "532600",
        "title": "文山壮族苗族自治州",
        "children": [
          {
            "value": "532601",
            "title": "文山市",
            "id": 2636,
            "firstLetter": "W"
          },
          {
            "value": "532622",
            "title": "砚山县",
            "id": 2637,
            "firstLetter": "Y"
          },
          {
            "value": "532623",
            "title": "西畴县",
            "id": 2638,
            "firstLetter": "X"
          },
          {
            "value": "532624",
            "title": "麻栗坡县",
            "id": 2639,
            "firstLetter": "M"
          },
          {
            "value": "532625",
            "title": "马关县",
            "id": 2640,
            "firstLetter": "M"
          },
          {
            "value": "532626",
            "title": "丘北县",
            "id": 2641,
            "firstLetter": "Q"
          },
          {
            "value": "532627",
            "title": "广南县",
            "id": 2642,
            "firstLetter": "G"
          },
          {
            "value": "532628",
            "title": "富宁县",
            "id": 2643,
            "firstLetter": "F"
          }
        ],
        "id": 281,
        "provinceID": 25,
        "firstLetter": "W"
      },
      {
        "value": "532800",
        "title": "西双版纳傣族自治州",
        "children": [
          {
            "value": "532801",
            "title": "景洪市",
            "id": 2644,
            "firstLetter": "J"
          },
          {
            "value": "532822",
            "title": "勐海县",
            "id": 2645,
            "firstLetter": "M"
          },
          {
            "value": "532823",
            "title": "勐腊县",
            "id": 2646,
            "firstLetter": "M"
          }
        ],
        "id": 282,
        "provinceID": 25,
        "firstLetter": "X"
      },
      {
        "value": "532900",
        "title": "大理白族自治州",
        "children": [
          {
            "value": "532901",
            "title": "大理市",
            "id": 2647,
            "firstLetter": "D"
          },
          {
            "value": "532922",
            "title": "漾濞彝族自治县",
            "id": 2648,
            "firstLetter": "Y"
          },
          {
            "value": "532923",
            "title": "祥云县",
            "id": 2649,
            "firstLetter": "X"
          },
          {
            "value": "532924",
            "title": "宾川县",
            "id": 2650,
            "firstLetter": "B"
          },
          {
            "value": "532925",
            "title": "弥渡县",
            "id": 2651,
            "firstLetter": "M"
          },
          {
            "value": "532926",
            "title": "南涧彝族自治县",
            "id": 2652,
            "firstLetter": "N"
          },
          {
            "value": "532927",
            "title": "巍山彝族回族自治县",
            "id": 2653,
            "firstLetter": "W"
          },
          {
            "value": "532928",
            "title": "永平县",
            "id": 2654,
            "firstLetter": "Y"
          },
          {
            "value": "532929",
            "title": "云龙县",
            "id": 2655,
            "firstLetter": "Y"
          },
          {
            "value": "532930",
            "title": "洱源县",
            "id": 2656,
            "firstLetter": "E"
          },
          {
            "value": "532931",
            "title": "剑川县",
            "id": 2657,
            "firstLetter": "J"
          },
          {
            "value": "532932",
            "title": "鹤庆县",
            "id": 2658,
            "firstLetter": "H"
          }
        ],
        "id": 283,
        "provinceID": 25,
        "firstLetter": "D"
      },
      {
        "value": "533100",
        "title": "德宏傣族景颇族自治州",
        "children": [
          {
            "value": "533102",
            "title": "瑞丽市",
            "id": 2659,
            "firstLetter": "R"
          },
          {
            "value": "533103",
            "title": "芒市",
            "id": 2660,
            "firstLetter": "M"
          },
          {
            "value": "533122",
            "title": "梁河县",
            "id": 2661,
            "firstLetter": "L"
          },
          {
            "value": "533123",
            "title": "盈江县",
            "id": 2662,
            "firstLetter": "Y"
          },
          {
            "value": "533124",
            "title": "陇川县",
            "id": 2663,
            "firstLetter": "L"
          }
        ],
        "id": 284,
        "provinceID": 25,
        "firstLetter": "D"
      },
      {
        "value": "533300",
        "title": "怒江傈僳族自治州",
        "children": [
          {
            "value": "533323",
            "title": "福贡县",
            "id": 2665,
            "firstLetter": "F"
          },
          {
            "value": "533324",
            "title": "贡山独龙族怒族自治县",
            "id": 2666,
            "firstLetter": "G"
          },
          {
            "value": "533325",
            "title": "兰坪白族普米族自治县",
            "id": 2667,
            "firstLetter": "L"
          },
          {
            "value": "533301",
            "title": "泸水市",
            "id": 4223,
            "firstLetter": "L"
          }
        ],
        "id": 285,
        "provinceID": 25,
        "firstLetter": "N"
      },
      {
        "value": "533400",
        "title": "迪庆藏族自治州",
        "children": [
          {
            "value": "533422",
            "title": "德钦县",
            "id": 2669,
            "firstLetter": "D"
          },
          {
            "value": "533423",
            "title": "维西傈僳族自治县",
            "id": 2670,
            "firstLetter": "W"
          },
          {
            "value": "533401",
            "title": "香格里拉市",
            "id": 4224,
            "firstLetter": "X"
          }
        ],
        "id": 286,
        "provinceID": 25,
        "firstLetter": "D"
      }
    ],
    "title": "云南省",
    "id": 25,
    "firstLetter": "Y"
  },
  {
    "value": "540000",
    "children": [
      {
        "value": "540100",
        "title": "拉萨市",
        "children": [
          {
            "value": "540102",
            "title": "城关区",
            "id": 2672,
            "firstLetter": "C"
          },
          {
            "value": "540121",
            "title": "林周县",
            "id": 2673,
            "firstLetter": "L"
          },
          {
            "value": "540122",
            "title": "当雄县",
            "id": 2674,
            "firstLetter": "D"
          },
          {
            "value": "540123",
            "title": "尼木县",
            "id": 2675,
            "firstLetter": "N"
          },
          {
            "value": "540124",
            "title": "曲水县",
            "id": 2676,
            "firstLetter": "Q"
          },
          {
            "value": "540127",
            "title": "墨竹工卡县",
            "id": 2679,
            "firstLetter": "M"
          },
          {
            "value": "540103",
            "title": "堆龙德庆区",
            "id": 4225,
            "firstLetter": "D"
          },
          {
            "value": "540104",
            "title": "达孜区",
            "id": 4226,
            "firstLetter": "D"
          }
        ],
        "id": 287,
        "provinceID": 26,
        "firstLetter": "L"
      },
      {
        "value": "540200",
        "title": "日喀则市",
        "children": [
          {
            "value": "540202",
            "title": "桑珠孜区",
            "id": 2680,
            "firstLetter": "S"
          },
          {
            "value": "540221",
            "title": "南木林县",
            "id": 2681,
            "firstLetter": "N"
          },
          {
            "value": "540222",
            "title": "江孜县",
            "id": 2682,
            "firstLetter": "J"
          },
          {
            "value": "540223",
            "title": "定日县",
            "id": 2683,
            "firstLetter": "D"
          },
          {
            "value": "540224",
            "title": "萨迦县",
            "id": 2684,
            "firstLetter": "S"
          },
          {
            "value": "540225",
            "title": "拉孜县",
            "id": 2685,
            "firstLetter": "L"
          },
          {
            "value": "540226",
            "title": "昂仁县",
            "id": 2686,
            "firstLetter": "A"
          },
          {
            "value": "540227",
            "title": "谢通门县",
            "id": 2687,
            "firstLetter": "X"
          },
          {
            "value": "540228",
            "title": "白朗县",
            "id": 2688,
            "firstLetter": "B"
          },
          {
            "value": "540229",
            "title": "仁布县",
            "id": 2689,
            "firstLetter": "R"
          },
          {
            "value": "540230",
            "title": "康马县",
            "id": 2690,
            "firstLetter": "K"
          },
          {
            "value": "540231",
            "title": "定结县",
            "id": 2691,
            "firstLetter": "D"
          },
          {
            "value": "540232",
            "title": "仲巴县",
            "id": 2692,
            "firstLetter": "Z"
          },
          {
            "value": "540233",
            "title": "亚东县",
            "id": 2693,
            "firstLetter": "Y"
          },
          {
            "value": "540234",
            "title": "吉隆县",
            "id": 2694,
            "firstLetter": "J"
          },
          {
            "value": "540235",
            "title": "聂拉木县",
            "id": 2695,
            "firstLetter": "N"
          },
          {
            "value": "540236",
            "title": "萨嘎县",
            "id": 2696,
            "firstLetter": "S"
          },
          {
            "value": "540237",
            "title": "岗巴县",
            "id": 2697,
            "firstLetter": "G"
          }
        ],
        "id": 288,
        "provinceID": 26,
        "firstLetter": "R"
      },
      {
        "value": "542500",
        "title": "阿里地区",
        "children": [
          {
            "value": "542521",
            "title": "普兰县",
            "id": 2731,
            "firstLetter": "P"
          },
          {
            "value": "542522",
            "title": "札达县",
            "id": 2732,
            "firstLetter": "Z"
          },
          {
            "value": "542523",
            "title": "噶尔县",
            "id": 2733,
            "firstLetter": "G"
          },
          {
            "value": "542524",
            "title": "日土县",
            "id": 2734,
            "firstLetter": "R"
          },
          {
            "value": "542525",
            "title": "革吉县",
            "id": 2735,
            "firstLetter": "G"
          },
          {
            "value": "542526",
            "title": "改则县",
            "id": 2736,
            "firstLetter": "G"
          },
          {
            "value": "542527",
            "title": "措勤县",
            "id": 2737,
            "firstLetter": "C"
          }
        ],
        "id": 292,
        "provinceID": 26,
        "firstLetter": "A"
      },
      {
        "value": "540300",
        "title": "昌都市",
        "children": [
          {
            "value": "540302",
            "title": "卡若区",
            "id": 4227,
            "firstLetter": "K"
          },
          {
            "value": "540321",
            "title": "江达县",
            "id": 4228,
            "firstLetter": "J"
          },
          {
            "value": "540322",
            "title": "贡觉县",
            "id": 4229,
            "firstLetter": "G"
          },
          {
            "value": "540323",
            "title": "类乌齐县",
            "id": 4230,
            "firstLetter": "L"
          },
          {
            "value": "540324",
            "title": "丁青县",
            "id": 4231,
            "firstLetter": "D"
          },
          {
            "value": "540325",
            "title": "察雅县",
            "id": 4232,
            "firstLetter": "C"
          },
          {
            "value": "540326",
            "title": "八宿县",
            "id": 4233,
            "firstLetter": "B"
          },
          {
            "value": "540327",
            "title": "左贡县",
            "id": 4234,
            "firstLetter": "Z"
          },
          {
            "value": "540328",
            "title": "芒康县",
            "id": 4235,
            "firstLetter": "M"
          },
          {
            "value": "540329",
            "title": "洛隆县",
            "id": 4236,
            "firstLetter": "L"
          },
          {
            "value": "540330",
            "title": "边坝县",
            "id": 4237,
            "firstLetter": "B"
          }
        ],
        "id": 349,
        "provinceID": 26,
        "firstLetter": "C"
      },
      {
        "value": "540400",
        "title": "林芝市",
        "children": [
          {
            "value": "540402",
            "title": "巴宜区",
            "id": 4238,
            "firstLetter": "B"
          },
          {
            "value": "540421",
            "title": "工布江达县",
            "id": 4239,
            "firstLetter": "G"
          },
          {
            "value": "540422",
            "title": "米林县",
            "id": 4240,
            "firstLetter": "M"
          },
          {
            "value": "540423",
            "title": "墨脱县",
            "id": 4241,
            "firstLetter": "M"
          },
          {
            "value": "540424",
            "title": "波密县",
            "id": 4242,
            "firstLetter": "B"
          },
          {
            "value": "540425",
            "title": "察隅县",
            "id": 4243,
            "firstLetter": "C"
          },
          {
            "value": "540426",
            "title": "朗县",
            "id": 4244,
            "firstLetter": "L"
          }
        ],
        "id": 350,
        "provinceID": 26,
        "firstLetter": "L"
      },
      {
        "value": "540500",
        "title": "山南市",
        "children": [
          {
            "value": "540502",
            "title": "乃东区",
            "id": 4246,
            "firstLetter": "N"
          },
          {
            "value": "540521",
            "title": "扎囊县",
            "id": 4247,
            "firstLetter": "Z"
          },
          {
            "value": "540522",
            "title": "贡嘎县",
            "id": 4248,
            "firstLetter": "G"
          },
          {
            "value": "540523",
            "title": "桑日县",
            "id": 4249,
            "firstLetter": "S"
          },
          {
            "value": "540524",
            "title": "琼结县",
            "id": 4250,
            "firstLetter": "Q"
          },
          {
            "value": "540525",
            "title": "曲松县",
            "id": 4251,
            "firstLetter": "Q"
          },
          {
            "value": "540526",
            "title": "措美县",
            "id": 4252,
            "firstLetter": "C"
          },
          {
            "value": "540527",
            "title": "洛扎县",
            "id": 4253,
            "firstLetter": "L"
          },
          {
            "value": "540528",
            "title": "加查县",
            "id": 4254,
            "firstLetter": "J"
          },
          {
            "value": "540529",
            "title": "隆子县",
            "id": 4255,
            "firstLetter": "L"
          },
          {
            "value": "540530",
            "title": "错那县",
            "id": 4256,
            "firstLetter": "C"
          },
          {
            "value": "540531",
            "title": "浪卡子县",
            "id": 4257,
            "firstLetter": "L"
          }
        ],
        "id": 351,
        "provinceID": 26,
        "firstLetter": "S"
      },
      {
        "value": "540600",
        "title": "那曲市",
        "children": [
          {
            "value": "540602",
            "title": "色尼区",
            "id": 4258,
            "firstLetter": "S"
          },
          {
            "value": "540621",
            "title": "嘉黎县",
            "id": 4259,
            "firstLetter": "J"
          },
          {
            "value": "540622",
            "title": "比如县",
            "id": 4260,
            "firstLetter": "B"
          },
          {
            "value": "540623",
            "title": "聂荣县",
            "id": 4261,
            "firstLetter": "N"
          },
          {
            "value": "540624",
            "title": "安多县",
            "id": 4262,
            "firstLetter": "A"
          },
          {
            "value": "540625",
            "title": "申扎县",
            "id": 4263,
            "firstLetter": "S"
          },
          {
            "value": "540626",
            "title": "索县",
            "id": 4264,
            "firstLetter": "S"
          },
          {
            "value": "540627",
            "title": "班戈县",
            "id": 4265,
            "firstLetter": "B"
          },
          {
            "value": "540628",
            "title": "巴青县",
            "id": 4266,
            "firstLetter": "B"
          },
          {
            "value": "540629",
            "title": "尼玛县",
            "id": 4267,
            "firstLetter": "N"
          },
          {
            "value": "540630",
            "title": "双湖县",
            "id": 4268,
            "firstLetter": "S"
          }
        ],
        "id": 352,
        "provinceID": 26,
        "firstLetter": "N"
      }
    ],
    "title": "西藏自治区",
    "id": 26,
    "firstLetter": "X"
  },
  {
    "value": "610000",
    "children": [
      {
        "value": "610100",
        "title": "西安市",
        "children": [
          {
            "value": "610102",
            "title": "新城区",
            "id": 2746,
            "firstLetter": "X"
          },
          {
            "value": "610103",
            "title": "碑林区",
            "id": 2747,
            "firstLetter": "B"
          },
          {
            "value": "610104",
            "title": "莲湖区",
            "id": 2748,
            "firstLetter": "L"
          },
          {
            "value": "610111",
            "title": "灞桥区",
            "id": 2749,
            "firstLetter": "B"
          },
          {
            "value": "610112",
            "title": "未央区",
            "id": 2750,
            "firstLetter": "W"
          },
          {
            "value": "610113",
            "title": "雁塔区",
            "id": 2751,
            "firstLetter": "Y"
          },
          {
            "value": "610114",
            "title": "阎良区",
            "id": 2752,
            "firstLetter": "Y"
          },
          {
            "value": "610115",
            "title": "临潼区",
            "id": 2753,
            "firstLetter": "L"
          },
          {
            "value": "610116",
            "title": "长安区",
            "id": 2754,
            "firstLetter": "Z"
          },
          {
            "value": "610122",
            "title": "蓝田县",
            "id": 2755,
            "firstLetter": "L"
          },
          {
            "value": "610124",
            "title": "周至县",
            "id": 2756,
            "firstLetter": "Z"
          },
          {
            "value": "610117",
            "title": "高陵区",
            "id": 4269,
            "firstLetter": "G"
          },
          {
            "value": "610118",
            "title": "鄠邑区",
            "id": 4270,
            "firstLetter": "H"
          }
        ],
        "id": 294,
        "provinceID": 27,
        "firstLetter": "X"
      },
      {
        "value": "610200",
        "title": "铜川市",
        "children": [
          {
            "value": "610202",
            "title": "王益区",
            "id": 2760,
            "firstLetter": "W"
          },
          {
            "value": "610203",
            "title": "印台区",
            "id": 2761,
            "firstLetter": "Y"
          },
          {
            "value": "610204",
            "title": "耀州区",
            "id": 2762,
            "firstLetter": "Y"
          },
          {
            "value": "610222",
            "title": "宜君县",
            "id": 2763,
            "firstLetter": "Y"
          }
        ],
        "id": 295,
        "provinceID": 27,
        "firstLetter": "T"
      },
      {
        "value": "610300",
        "title": "宝鸡市",
        "children": [
          {
            "value": "610302",
            "title": "渭滨区",
            "id": 2765,
            "firstLetter": "W"
          },
          {
            "value": "610303",
            "title": "金台区",
            "id": 2766,
            "firstLetter": "J"
          },
          {
            "value": "610304",
            "title": "陈仓区",
            "id": 2767,
            "firstLetter": "C"
          },
          {
            "value": "610322",
            "title": "凤翔县",
            "id": 2768,
            "firstLetter": "F"
          },
          {
            "value": "610323",
            "title": "岐山县",
            "id": 2769,
            "firstLetter": "Q"
          },
          {
            "value": "610324",
            "title": "扶风县",
            "id": 2770,
            "firstLetter": "F"
          },
          {
            "value": "610326",
            "title": "眉县",
            "id": 2771,
            "firstLetter": "M"
          },
          {
            "value": "610327",
            "title": "陇县",
            "id": 2772,
            "firstLetter": "L"
          },
          {
            "value": "610328",
            "title": "千阳县",
            "id": 2773,
            "firstLetter": "Q"
          },
          {
            "value": "610329",
            "title": "麟游县",
            "id": 2774,
            "firstLetter": "L"
          },
          {
            "value": "610330",
            "title": "凤县",
            "id": 2775,
            "firstLetter": "F"
          },
          {
            "value": "610331",
            "title": "太白县",
            "id": 2776,
            "firstLetter": "T"
          }
        ],
        "id": 296,
        "provinceID": 27,
        "firstLetter": "B"
      },
      {
        "value": "610400",
        "title": "咸阳市",
        "children": [
          {
            "value": "610402",
            "title": "秦都区",
            "id": 2778,
            "firstLetter": "Q"
          },
          {
            "value": "610403",
            "title": "杨陵区",
            "id": 2779,
            "firstLetter": "Y"
          },
          {
            "value": "610404",
            "title": "渭城区",
            "id": 2780,
            "firstLetter": "W"
          },
          {
            "value": "610422",
            "title": "三原县",
            "id": 2781,
            "firstLetter": "S"
          },
          {
            "value": "610423",
            "title": "泾阳县",
            "id": 2782,
            "firstLetter": "J"
          },
          {
            "value": "610424",
            "title": "乾县",
            "id": 2783,
            "firstLetter": "Q"
          },
          {
            "value": "610425",
            "title": "礼泉县",
            "id": 2784,
            "firstLetter": "L"
          },
          {
            "value": "610426",
            "title": "永寿县",
            "id": 2785,
            "firstLetter": "Y"
          },
          {
            "value": "610428",
            "title": "长武县",
            "id": 2787,
            "firstLetter": "Z"
          },
          {
            "value": "610429",
            "title": "旬邑县",
            "id": 2788,
            "firstLetter": "X"
          },
          {
            "value": "610430",
            "title": "淳化县",
            "id": 2789,
            "firstLetter": "C"
          },
          {
            "value": "610431",
            "title": "武功县",
            "id": 2790,
            "firstLetter": "W"
          },
          {
            "value": "610481",
            "title": "兴平市",
            "id": 2791,
            "firstLetter": "X"
          },
          {
            "value": "610482",
            "title": "彬州市",
            "id": 4271,
            "firstLetter": "B"
          }
        ],
        "id": 297,
        "provinceID": 27,
        "firstLetter": "X"
      },
      {
        "value": "610500",
        "title": "渭南市",
        "children": [
          {
            "value": "610502",
            "title": "临渭区",
            "id": 2793,
            "firstLetter": "L"
          },
          {
            "value": "610522",
            "title": "潼关县",
            "id": 2795,
            "firstLetter": "T"
          },
          {
            "value": "610523",
            "title": "大荔县",
            "id": 2796,
            "firstLetter": "D"
          },
          {
            "value": "610524",
            "title": "合阳县",
            "id": 2797,
            "firstLetter": "H"
          },
          {
            "value": "610525",
            "title": "澄城县",
            "id": 2798,
            "firstLetter": "C"
          },
          {
            "value": "610526",
            "title": "蒲城县",
            "id": 2799,
            "firstLetter": "P"
          },
          {
            "value": "610527",
            "title": "白水县",
            "id": 2800,
            "firstLetter": "B"
          },
          {
            "value": "610528",
            "title": "富平县",
            "id": 2801,
            "firstLetter": "F"
          },
          {
            "value": "610581",
            "title": "韩城市",
            "id": 2802,
            "firstLetter": "H"
          },
          {
            "value": "610582",
            "title": "华阴市",
            "id": 2803,
            "firstLetter": "H"
          },
          {
            "value": "610503",
            "title": "华州区",
            "id": 4272,
            "firstLetter": "H"
          }
        ],
        "id": 298,
        "provinceID": 27,
        "firstLetter": "W"
      },
      {
        "value": "610600",
        "title": "延安市",
        "children": [
          {
            "value": "610602",
            "title": "宝塔区",
            "id": 2805,
            "firstLetter": "B"
          },
          {
            "value": "610621",
            "title": "延长县",
            "id": 2806,
            "firstLetter": "Y"
          },
          {
            "value": "610622",
            "title": "延川县",
            "id": 2807,
            "firstLetter": "Y"
          },
          {
            "value": "610623",
            "title": "子长县",
            "id": 2808,
            "firstLetter": "Z"
          },
          {
            "value": "610625",
            "title": "志丹县",
            "id": 2810,
            "firstLetter": "Z"
          },
          {
            "value": "610626",
            "title": "吴起县",
            "id": 2811,
            "firstLetter": "W"
          },
          {
            "value": "610627",
            "title": "甘泉县",
            "id": 2812,
            "firstLetter": "G"
          },
          {
            "value": "610628",
            "title": "富县",
            "id": 2813,
            "firstLetter": "F"
          },
          {
            "value": "610629",
            "title": "洛川县",
            "id": 2814,
            "firstLetter": "L"
          },
          {
            "value": "610630",
            "title": "宜川县",
            "id": 2815,
            "firstLetter": "Y"
          },
          {
            "value": "610631",
            "title": "黄龙县",
            "id": 2816,
            "firstLetter": "H"
          },
          {
            "value": "610632",
            "title": "黄陵县",
            "id": 2817,
            "firstLetter": "H"
          },
          {
            "value": "610603",
            "title": "安塞区",
            "id": 4273,
            "firstLetter": "A"
          }
        ],
        "id": 299,
        "provinceID": 27,
        "firstLetter": "Y"
      },
      {
        "value": "610700",
        "title": "汉中市",
        "children": [
          {
            "value": "610702",
            "title": "汉台区",
            "id": 2819,
            "firstLetter": "H"
          },
          {
            "value": "610722",
            "title": "城固县",
            "id": 2821,
            "firstLetter": "C"
          },
          {
            "value": "610723",
            "title": "洋县",
            "id": 2822,
            "firstLetter": "Y"
          },
          {
            "value": "610724",
            "title": "西乡县",
            "id": 2823,
            "firstLetter": "X"
          },
          {
            "value": "610725",
            "title": "勉县",
            "id": 2824,
            "firstLetter": "M"
          },
          {
            "value": "610726",
            "title": "宁强县",
            "id": 2825,
            "firstLetter": "N"
          },
          {
            "value": "610727",
            "title": "略阳县",
            "id": 2826,
            "firstLetter": "L"
          },
          {
            "value": "610728",
            "title": "镇巴县",
            "id": 2827,
            "firstLetter": "Z"
          },
          {
            "value": "610729",
            "title": "留坝县",
            "id": 2828,
            "firstLetter": "L"
          },
          {
            "value": "610730",
            "title": "佛坪县",
            "id": 2829,
            "firstLetter": "F"
          },
          {
            "value": "610703",
            "title": "南郑区",
            "id": 4274,
            "firstLetter": "N"
          }
        ],
        "id": 300,
        "provinceID": 27,
        "firstLetter": "H"
      },
      {
        "value": "610800",
        "title": "榆林市",
        "children": [
          {
            "value": "610802",
            "title": "榆阳区",
            "id": 2831,
            "firstLetter": "Y"
          },
          {
            "value": "610822",
            "title": "府谷县",
            "id": 2833,
            "firstLetter": "F"
          },
          {
            "value": "610824",
            "title": "靖边县",
            "id": 2835,
            "firstLetter": "J"
          },
          {
            "value": "610825",
            "title": "定边县",
            "id": 2836,
            "firstLetter": "D"
          },
          {
            "value": "610826",
            "title": "绥德县",
            "id": 2837,
            "firstLetter": "S"
          },
          {
            "value": "610827",
            "title": "米脂县",
            "id": 2838,
            "firstLetter": "M"
          },
          {
            "value": "610828",
            "title": "佳县",
            "id": 2839,
            "firstLetter": "J"
          },
          {
            "value": "610829",
            "title": "吴堡县",
            "id": 2840,
            "firstLetter": "W"
          },
          {
            "value": "610830",
            "title": "清涧县",
            "id": 2841,
            "firstLetter": "Q"
          },
          {
            "value": "610831",
            "title": "子洲县",
            "id": 2842,
            "firstLetter": "Z"
          },
          {
            "value": "610803",
            "title": "横山区",
            "id": 4275,
            "firstLetter": "H"
          },
          {
            "value": "610881",
            "title": "神木市",
            "id": 4276,
            "firstLetter": "S"
          }
        ],
        "id": 301,
        "provinceID": 27,
        "firstLetter": "Y"
      },
      {
        "value": "610900",
        "title": "安康市",
        "children": [
          {
            "value": "610902",
            "title": "汉滨区",
            "id": 2844,
            "firstLetter": "H"
          },
          {
            "value": "610921",
            "title": "汉阴县",
            "id": 2845,
            "firstLetter": "H"
          },
          {
            "value": "610922",
            "title": "石泉县",
            "id": 2846,
            "firstLetter": "S"
          },
          {
            "value": "610923",
            "title": "宁陕县",
            "id": 2847,
            "firstLetter": "N"
          },
          {
            "value": "610924",
            "title": "紫阳县",
            "id": 2848,
            "firstLetter": "Z"
          },
          {
            "value": "610925",
            "title": "岚皋县",
            "id": 2849,
            "firstLetter": "L"
          },
          {
            "value": "610926",
            "title": "平利县",
            "id": 2850,
            "firstLetter": "P"
          },
          {
            "value": "610927",
            "title": "镇坪县",
            "id": 2851,
            "firstLetter": "Z"
          },
          {
            "value": "610928",
            "title": "旬阳县",
            "id": 2852,
            "firstLetter": "X"
          },
          {
            "value": "610929",
            "title": "白河县",
            "id": 2853,
            "firstLetter": "B"
          }
        ],
        "id": 302,
        "provinceID": 27,
        "firstLetter": "A"
      },
      {
        "value": "611000",
        "title": "商洛市",
        "children": [
          {
            "value": "611002",
            "title": "商州区",
            "id": 2855,
            "firstLetter": "S"
          },
          {
            "value": "611021",
            "title": "洛南县",
            "id": 2856,
            "firstLetter": "L"
          },
          {
            "value": "611022",
            "title": "丹凤县",
            "id": 2857,
            "firstLetter": "D"
          },
          {
            "value": "611023",
            "title": "商南县",
            "id": 2858,
            "firstLetter": "S"
          },
          {
            "value": "611024",
            "title": "山阳县",
            "id": 2859,
            "firstLetter": "S"
          },
          {
            "value": "611025",
            "title": "镇安县",
            "id": 2860,
            "firstLetter": "Z"
          },
          {
            "value": "611026",
            "title": "柞水县",
            "id": 2861,
            "firstLetter": "Z"
          }
        ],
        "id": 303,
        "provinceID": 27,
        "firstLetter": "S"
      }
    ],
    "title": "陕西省",
    "id": 27,
    "firstLetter": "S"
  },
  {
    "value": "620000",
    "children": [
      {
        "value": "620100",
        "title": "兰州市",
        "children": [
          {
            "value": "620102",
            "title": "城关区",
            "id": 2863,
            "firstLetter": "C"
          },
          {
            "value": "620103",
            "title": "七里河区",
            "id": 2864,
            "firstLetter": "Q"
          },
          {
            "value": "620104",
            "title": "西固区",
            "id": 2865,
            "firstLetter": "X"
          },
          {
            "value": "620105",
            "title": "安宁区",
            "id": 2866,
            "firstLetter": "A"
          },
          {
            "value": "620111",
            "title": "红古区",
            "id": 2867,
            "firstLetter": "H"
          },
          {
            "value": "620121",
            "title": "永登县",
            "id": 2868,
            "firstLetter": "Y"
          },
          {
            "value": "620122",
            "title": "皋兰县",
            "id": 2869,
            "firstLetter": "G"
          },
          {
            "value": "620123",
            "title": "榆中县",
            "id": 2870,
            "firstLetter": "Y"
          }
        ],
        "id": 304,
        "provinceID": 28,
        "firstLetter": "L"
      },
      {
        "value": "620200",
        "title": "嘉峪关市",
        "children": [
          {
            "value": "620201",
            "title": "嘉峪关市",
            "id": 2871,
            "firstLetter": "J"
          }
        ],
        "id": 305,
        "provinceID": 28,
        "firstLetter": "J"
      },
      {
        "value": "620300",
        "title": "金昌市",
        "children": [
          {
            "value": "620302",
            "title": "金川区",
            "id": 2873,
            "firstLetter": "J"
          },
          {
            "value": "620321",
            "title": "永昌县",
            "id": 2874,
            "firstLetter": "Y"
          }
        ],
        "id": 306,
        "provinceID": 28,
        "firstLetter": "J"
      },
      {
        "value": "620400",
        "title": "白银市",
        "children": [
          {
            "value": "620402",
            "title": "白银区",
            "id": 2876,
            "firstLetter": "B"
          },
          {
            "value": "620403",
            "title": "平川区",
            "id": 2877,
            "firstLetter": "P"
          },
          {
            "value": "620421",
            "title": "靖远县",
            "id": 2878,
            "firstLetter": "J"
          },
          {
            "value": "620422",
            "title": "会宁县",
            "id": 2879,
            "firstLetter": "H"
          },
          {
            "value": "620423",
            "title": "景泰县",
            "id": 2880,
            "firstLetter": "J"
          }
        ],
        "id": 307,
        "provinceID": 28,
        "firstLetter": "B"
      },
      {
        "value": "620500",
        "title": "天水市",
        "children": [
          {
            "value": "620502",
            "title": "秦州区",
            "id": 2882,
            "firstLetter": "Q"
          },
          {
            "value": "620503",
            "title": "麦积区",
            "id": 2883,
            "firstLetter": "M"
          },
          {
            "value": "620521",
            "title": "清水县",
            "id": 2884,
            "firstLetter": "Q"
          },
          {
            "value": "620522",
            "title": "秦安县",
            "id": 2885,
            "firstLetter": "Q"
          },
          {
            "value": "620523",
            "title": "甘谷县",
            "id": 2886,
            "firstLetter": "G"
          },
          {
            "value": "620524",
            "title": "武山县",
            "id": 2887,
            "firstLetter": "W"
          },
          {
            "value": "620525",
            "title": "张家川回族自治县",
            "id": 2888,
            "firstLetter": "Z"
          }
        ],
        "id": 308,
        "provinceID": 28,
        "firstLetter": "T"
      },
      {
        "value": "620600",
        "title": "武威市",
        "children": [
          {
            "value": "620602",
            "title": "凉州区",
            "id": 2890,
            "firstLetter": "L"
          },
          {
            "value": "620621",
            "title": "民勤县",
            "id": 2891,
            "firstLetter": "M"
          },
          {
            "value": "620622",
            "title": "古浪县",
            "id": 2892,
            "firstLetter": "G"
          },
          {
            "value": "620623",
            "title": "天祝藏族自治县",
            "id": 2893,
            "firstLetter": "T"
          }
        ],
        "id": 309,
        "provinceID": 28,
        "firstLetter": "W"
      },
      {
        "value": "620700",
        "title": "张掖市",
        "children": [
          {
            "value": "620702",
            "title": "甘州区",
            "id": 2895,
            "firstLetter": "G"
          },
          {
            "value": "620721",
            "title": "肃南裕固族自治县",
            "id": 2896,
            "firstLetter": "S"
          },
          {
            "value": "620722",
            "title": "民乐县",
            "id": 2897,
            "firstLetter": "M"
          },
          {
            "value": "620723",
            "title": "临泽县",
            "id": 2898,
            "firstLetter": "L"
          },
          {
            "value": "620724",
            "title": "高台县",
            "id": 2899,
            "firstLetter": "G"
          },
          {
            "value": "620725",
            "title": "山丹县",
            "id": 2900,
            "firstLetter": "S"
          }
        ],
        "id": 310,
        "provinceID": 28,
        "firstLetter": "Z"
      },
      {
        "value": "620800",
        "title": "平凉市",
        "children": [
          {
            "value": "620802",
            "title": "崆峒区",
            "id": 2902,
            "firstLetter": "K"
          },
          {
            "value": "620821",
            "title": "泾川县",
            "id": 2903,
            "firstLetter": "J"
          },
          {
            "value": "620822",
            "title": "灵台县",
            "id": 2904,
            "firstLetter": "L"
          },
          {
            "value": "620823",
            "title": "崇信县",
            "id": 2905,
            "firstLetter": "C"
          },
          {
            "value": "620825",
            "title": "庄浪县",
            "id": 2907,
            "firstLetter": "Z"
          },
          {
            "value": "620826",
            "title": "静宁县",
            "id": 2908,
            "firstLetter": "J"
          },
          {
            "value": "620881",
            "title": "华亭市",
            "id": 4277,
            "firstLetter": "H"
          }
        ],
        "id": 311,
        "provinceID": 28,
        "firstLetter": "P"
      },
      {
        "value": "620900",
        "title": "酒泉市",
        "children": [
          {
            "value": "620902",
            "title": "肃州区",
            "id": 2910,
            "firstLetter": "S"
          },
          {
            "value": "620921",
            "title": "金塔县",
            "id": 2911,
            "firstLetter": "J"
          },
          {
            "value": "620922",
            "title": "瓜州县",
            "id": 2912,
            "firstLetter": "G"
          },
          {
            "value": "620923",
            "title": "肃北蒙古族自治县",
            "id": 2913,
            "firstLetter": "S"
          },
          {
            "value": "620924",
            "title": "阿克塞哈萨克族自治县",
            "id": 2914,
            "firstLetter": "A"
          },
          {
            "value": "620981",
            "title": "玉门市",
            "id": 2915,
            "firstLetter": "Y"
          },
          {
            "value": "620982",
            "title": "敦煌市",
            "id": 2916,
            "firstLetter": "D"
          }
        ],
        "id": 312,
        "provinceID": 28,
        "firstLetter": "J"
      },
      {
        "value": "621000",
        "title": "庆阳市",
        "children": [
          {
            "value": "621002",
            "title": "西峰区",
            "id": 2918,
            "firstLetter": "X"
          },
          {
            "value": "621021",
            "title": "庆城县",
            "id": 2919,
            "firstLetter": "Q"
          },
          {
            "value": "621022",
            "title": "环县",
            "id": 2920,
            "firstLetter": "H"
          },
          {
            "value": "621023",
            "title": "华池县",
            "id": 2921,
            "firstLetter": "H"
          },
          {
            "value": "621024",
            "title": "合水县",
            "id": 2922,
            "firstLetter": "H"
          },
          {
            "value": "621025",
            "title": "正宁县",
            "id": 2923,
            "firstLetter": "Z"
          },
          {
            "value": "621026",
            "title": "宁县",
            "id": 2924,
            "firstLetter": "N"
          },
          {
            "value": "621027",
            "title": "镇原县",
            "id": 2925,
            "firstLetter": "Z"
          }
        ],
        "id": 313,
        "provinceID": 28,
        "firstLetter": "Q"
      },
      {
        "value": "621100",
        "title": "定西市",
        "children": [
          {
            "value": "621102",
            "title": "安定区",
            "id": 2927,
            "firstLetter": "A"
          },
          {
            "value": "621121",
            "title": "通渭县",
            "id": 2928,
            "firstLetter": "T"
          },
          {
            "value": "621122",
            "title": "陇西县",
            "id": 2929,
            "firstLetter": "L"
          },
          {
            "value": "621123",
            "title": "渭源县",
            "id": 2930,
            "firstLetter": "W"
          },
          {
            "value": "621124",
            "title": "临洮县",
            "id": 2931,
            "firstLetter": "L"
          },
          {
            "value": "621125",
            "title": "漳县",
            "id": 2932,
            "firstLetter": "Z"
          },
          {
            "value": "621126",
            "title": "岷县",
            "id": 2933,
            "firstLetter": "M"
          }
        ],
        "id": 314,
        "provinceID": 28,
        "firstLetter": "D"
      },
      {
        "value": "621200",
        "title": "陇南市",
        "children": [
          {
            "value": "621202",
            "title": "武都区",
            "id": 2935,
            "firstLetter": "W"
          },
          {
            "value": "621221",
            "title": "成县",
            "id": 2936,
            "firstLetter": "C"
          },
          {
            "value": "621222",
            "title": "文县",
            "id": 2937,
            "firstLetter": "W"
          },
          {
            "value": "621223",
            "title": "宕昌县",
            "id": 2938,
            "firstLetter": "D"
          },
          {
            "value": "621224",
            "title": "康县",
            "id": 2939,
            "firstLetter": "K"
          },
          {
            "value": "621225",
            "title": "西和县",
            "id": 2940,
            "firstLetter": "X"
          },
          {
            "value": "621226",
            "title": "礼县",
            "id": 2941,
            "firstLetter": "L"
          },
          {
            "value": "621227",
            "title": "徽县",
            "id": 2942,
            "firstLetter": "H"
          },
          {
            "value": "621228",
            "title": "两当县",
            "id": 2943,
            "firstLetter": "L"
          }
        ],
        "id": 315,
        "provinceID": 28,
        "firstLetter": "L"
      },
      {
        "value": "622900",
        "title": "临夏回族自治州",
        "children": [
          {
            "value": "622901",
            "title": "临夏市",
            "id": 2944,
            "firstLetter": "L"
          },
          {
            "value": "622921",
            "title": "临夏县",
            "id": 2945,
            "firstLetter": "L"
          },
          {
            "value": "622922",
            "title": "康乐县",
            "id": 2946,
            "firstLetter": "K"
          },
          {
            "value": "622923",
            "title": "永靖县",
            "id": 2947,
            "firstLetter": "Y"
          },
          {
            "value": "622924",
            "title": "广河县",
            "id": 2948,
            "firstLetter": "G"
          },
          {
            "value": "622925",
            "title": "和政县",
            "id": 2949,
            "firstLetter": "H"
          },
          {
            "value": "622926",
            "title": "东乡族自治县",
            "id": 2950,
            "firstLetter": "D"
          },
          {
            "value": "622927",
            "title": "积石山保安族东乡族撒拉族自治县",
            "id": 2951,
            "firstLetter": "J"
          }
        ],
        "id": 316,
        "provinceID": 28,
        "firstLetter": "L"
      },
      {
        "value": "623000",
        "title": "甘南藏族自治州",
        "children": [
          {
            "value": "623001",
            "title": "合作市",
            "id": 2952,
            "firstLetter": "H"
          },
          {
            "value": "623021",
            "title": "临潭县",
            "id": 2953,
            "firstLetter": "L"
          },
          {
            "value": "623022",
            "title": "卓尼县",
            "id": 2954,
            "firstLetter": "Z"
          },
          {
            "value": "623023",
            "title": "舟曲县",
            "id": 2955,
            "firstLetter": "Z"
          },
          {
            "value": "623024",
            "title": "迭部县",
            "id": 2956,
            "firstLetter": "D"
          },
          {
            "value": "623025",
            "title": "玛曲县",
            "id": 2957,
            "firstLetter": "M"
          },
          {
            "value": "623026",
            "title": "碌曲县",
            "id": 2958,
            "firstLetter": "L"
          },
          {
            "value": "623027",
            "title": "夏河县",
            "id": 2959,
            "firstLetter": "X"
          }
        ],
        "id": 317,
        "provinceID": 28,
        "firstLetter": "G"
      }
    ],
    "title": "甘肃省",
    "id": 28,
    "firstLetter": "G"
  },
  {
    "value": "630000",
    "children": [
      {
        "value": "630100",
        "title": "西宁市",
        "children": [
          {
            "value": "630102",
            "title": "城东区",
            "id": 2961,
            "firstLetter": "C"
          },
          {
            "value": "630103",
            "title": "城中区",
            "id": 2962,
            "firstLetter": "C"
          },
          {
            "value": "630104",
            "title": "城西区",
            "id": 2963,
            "firstLetter": "C"
          },
          {
            "value": "630105",
            "title": "城北区",
            "id": 2964,
            "firstLetter": "C"
          },
          {
            "value": "630121",
            "title": "大通回族土族自治县",
            "id": 2965,
            "firstLetter": "D"
          },
          {
            "value": "630122",
            "title": "湟中县",
            "id": 2966,
            "firstLetter": "H"
          },
          {
            "value": "630123",
            "title": "湟源县",
            "id": 2967,
            "firstLetter": "H"
          }
        ],
        "id": 318,
        "provinceID": 29,
        "firstLetter": "X"
      },
      {
        "value": "630200",
        "title": "海东市",
        "children": [
          {
            "value": "630202",
            "title": "乐都区",
            "id": 2968,
            "firstLetter": "L"
          },
          {
            "value": "630222",
            "title": "民和回族土族自治县",
            "id": 2970,
            "firstLetter": "M"
          },
          {
            "value": "630223",
            "title": "互助土族自治县",
            "id": 2971,
            "firstLetter": "H"
          },
          {
            "value": "630224",
            "title": "化隆回族自治县",
            "id": 2972,
            "firstLetter": "H"
          },
          {
            "value": "630225",
            "title": "循化撒拉族自治县",
            "id": 2973,
            "firstLetter": "X"
          },
          {
            "value": "630203",
            "title": "平安区",
            "id": 4278,
            "firstLetter": "P"
          }
        ],
        "id": 319,
        "provinceID": 29,
        "firstLetter": "H"
      },
      {
        "value": "632200",
        "title": "海北藏族自治州",
        "children": [
          {
            "value": "632221",
            "title": "门源回族自治县",
            "id": 2974,
            "firstLetter": "M"
          },
          {
            "value": "632222",
            "title": "祁连县",
            "id": 2975,
            "firstLetter": "Q"
          },
          {
            "value": "632223",
            "title": "海晏县",
            "id": 2976,
            "firstLetter": "H"
          },
          {
            "value": "632224",
            "title": "刚察县",
            "id": 2977,
            "firstLetter": "G"
          }
        ],
        "id": 320,
        "provinceID": 29,
        "firstLetter": "H"
      },
      {
        "value": "632300",
        "title": "黄南藏族自治州",
        "children": [
          {
            "value": "632321",
            "title": "同仁县",
            "id": 2978,
            "firstLetter": "T"
          },
          {
            "value": "632322",
            "title": "尖扎县",
            "id": 2979,
            "firstLetter": "J"
          },
          {
            "value": "632323",
            "title": "泽库县",
            "id": 2980,
            "firstLetter": "Z"
          },
          {
            "value": "632324",
            "title": "河南蒙古族自治县",
            "id": 2981,
            "firstLetter": "H"
          }
        ],
        "id": 321,
        "provinceID": 29,
        "firstLetter": "H"
      },
      {
        "value": "632500",
        "title": "海南藏族自治州",
        "children": [
          {
            "value": "632521",
            "title": "共和县",
            "id": 2982,
            "firstLetter": "G"
          },
          {
            "value": "632522",
            "title": "同德县",
            "id": 2983,
            "firstLetter": "T"
          },
          {
            "value": "632523",
            "title": "贵德县",
            "id": 2984,
            "firstLetter": "G"
          },
          {
            "value": "632524",
            "title": "兴海县",
            "id": 2985,
            "firstLetter": "X"
          },
          {
            "value": "632525",
            "title": "贵南县",
            "id": 2986,
            "firstLetter": "G"
          }
        ],
        "id": 322,
        "provinceID": 29,
        "firstLetter": "H"
      },
      {
        "value": "632600",
        "title": "果洛藏族自治州",
        "children": [
          {
            "value": "632621",
            "title": "玛沁县",
            "id": 2987,
            "firstLetter": "M"
          },
          {
            "value": "632622",
            "title": "班玛县",
            "id": 2988,
            "firstLetter": "B"
          },
          {
            "value": "632623",
            "title": "甘德县",
            "id": 2989,
            "firstLetter": "G"
          },
          {
            "value": "632624",
            "title": "达日县",
            "id": 2990,
            "firstLetter": "D"
          },
          {
            "value": "632625",
            "title": "久治县",
            "id": 2991,
            "firstLetter": "J"
          },
          {
            "value": "632626",
            "title": "玛多县",
            "id": 2992,
            "firstLetter": "M"
          }
        ],
        "id": 323,
        "provinceID": 29,
        "firstLetter": "G"
      },
      {
        "value": "632700",
        "title": "玉树藏族自治州",
        "children": [
          {
            "value": "632701",
            "title": "玉树市",
            "id": 2993,
            "firstLetter": "Y"
          },
          {
            "value": "632722",
            "title": "杂多县",
            "id": 2994,
            "firstLetter": "Z"
          },
          {
            "value": "632723",
            "title": "称多县",
            "id": 2995,
            "firstLetter": "C"
          },
          {
            "value": "632724",
            "title": "治多县",
            "id": 2996,
            "firstLetter": "Z"
          },
          {
            "value": "632725",
            "title": "囊谦县",
            "id": 2997,
            "firstLetter": "N"
          },
          {
            "value": "632726",
            "title": "曲麻莱县",
            "id": 2998,
            "firstLetter": "Q"
          }
        ],
        "id": 324,
        "provinceID": 29,
        "firstLetter": "Y"
      },
      {
        "value": "632800",
        "title": "海西蒙古族藏族自治州",
        "children": [
          {
            "value": "632801",
            "title": "格尔木市",
            "id": 2999,
            "firstLetter": "G"
          },
          {
            "value": "632802",
            "title": "德令哈市",
            "id": 3000,
            "firstLetter": "D"
          },
          {
            "value": "632821",
            "title": "乌兰县",
            "id": 3001,
            "firstLetter": "W"
          },
          {
            "value": "632822",
            "title": "都兰县",
            "id": 3002,
            "firstLetter": "D"
          },
          {
            "value": "632823",
            "title": "天峻县",
            "id": 3003,
            "firstLetter": "T"
          },
          {
            "value": "632803",
            "title": "茫崖市",
            "id": 4279,
            "firstLetter": "M"
          },
          {
            "value": "632857",
            "title": "大柴旦行政委员会",
            "id": 4280,
            "firstLetter": "D"
          }
        ],
        "id": 325,
        "provinceID": 29,
        "firstLetter": "H"
      }
    ],
    "title": "青海省",
    "id": 29,
    "firstLetter": "Q"
  },
  {
    "value": "640000",
    "children": [
      {
        "value": "640100",
        "title": "银川市",
        "children": [
          {
            "value": "640104",
            "title": "兴庆区",
            "id": 3005,
            "firstLetter": "X"
          },
          {
            "value": "640105",
            "title": "西夏区",
            "id": 3006,
            "firstLetter": "X"
          },
          {
            "value": "640106",
            "title": "金凤区",
            "id": 3007,
            "firstLetter": "J"
          },
          {
            "value": "640121",
            "title": "永宁县",
            "id": 3008,
            "firstLetter": "Y"
          },
          {
            "value": "640122",
            "title": "贺兰县",
            "id": 3009,
            "firstLetter": "H"
          },
          {
            "value": "640181",
            "title": "灵武市",
            "id": 3010,
            "firstLetter": "L"
          }
        ],
        "id": 326,
        "provinceID": 30,
        "firstLetter": "Y"
      },
      {
        "value": "640200",
        "title": "石嘴山市",
        "children": [
          {
            "value": "640202",
            "title": "大武口区",
            "id": 3012,
            "firstLetter": "D"
          },
          {
            "value": "640205",
            "title": "惠农区",
            "id": 3013,
            "firstLetter": "H"
          },
          {
            "value": "640221",
            "title": "平罗县",
            "id": 3014,
            "firstLetter": "P"
          }
        ],
        "id": 327,
        "provinceID": 30,
        "firstLetter": "S"
      },
      {
        "value": "640300",
        "title": "吴忠市",
        "children": [
          {
            "value": "640302",
            "title": "利通区",
            "id": 3016,
            "firstLetter": "L"
          },
          {
            "value": "640303",
            "title": "红寺堡区",
            "id": 3017,
            "firstLetter": "H"
          },
          {
            "value": "640323",
            "title": "盐池县",
            "id": 3018,
            "firstLetter": "Y"
          },
          {
            "value": "640324",
            "title": "同心县",
            "id": 3019,
            "firstLetter": "T"
          },
          {
            "value": "640381",
            "title": "青铜峡市",
            "id": 3020,
            "firstLetter": "Q"
          }
        ],
        "id": 328,
        "provinceID": 30,
        "firstLetter": "W"
      },
      {
        "value": "640400",
        "title": "固原市",
        "children": [
          {
            "value": "640402",
            "title": "原州区",
            "id": 3022,
            "firstLetter": "Y"
          },
          {
            "value": "640422",
            "title": "西吉县",
            "id": 3023,
            "firstLetter": "X"
          },
          {
            "value": "640423",
            "title": "隆德县",
            "id": 3024,
            "firstLetter": "L"
          },
          {
            "value": "640424",
            "title": "泾源县",
            "id": 3025,
            "firstLetter": "J"
          },
          {
            "value": "640425",
            "title": "彭阳县",
            "id": 3026,
            "firstLetter": "P"
          }
        ],
        "id": 329,
        "provinceID": 30,
        "firstLetter": "G"
      },
      {
        "value": "640500",
        "title": "中卫市",
        "children": [
          {
            "value": "640502",
            "title": "沙坡头区",
            "id": 3028,
            "firstLetter": "S"
          },
          {
            "value": "640521",
            "title": "中宁县",
            "id": 3029,
            "firstLetter": "Z"
          },
          {
            "value": "640522",
            "title": "海原县",
            "id": 3030,
            "firstLetter": "H"
          }
        ],
        "id": 330,
        "provinceID": 30,
        "firstLetter": "Z"
      }
    ],
    "title": "宁夏回族自治区",
    "id": 30,
    "firstLetter": "N"
  },
  {
    "value": "650000",
    "children": [
      {
        "value": "650100",
        "title": "乌鲁木齐市",
        "children": [
          {
            "value": "650102",
            "title": "天山区",
            "id": 3032,
            "firstLetter": "T"
          },
          {
            "value": "650103",
            "title": "沙依巴克区",
            "id": 3033,
            "firstLetter": "S"
          },
          {
            "value": "650104",
            "title": "新市区",
            "id": 3034,
            "firstLetter": "X"
          },
          {
            "value": "650105",
            "title": "水磨沟区",
            "id": 3035,
            "firstLetter": "S"
          },
          {
            "value": "650106",
            "title": "头屯河区",
            "id": 3036,
            "firstLetter": "T"
          },
          {
            "value": "650107",
            "title": "达坂城区",
            "id": 3037,
            "firstLetter": "D"
          },
          {
            "value": "650109",
            "title": "米东区",
            "id": 3038,
            "firstLetter": "M"
          },
          {
            "value": "650121",
            "title": "乌鲁木齐县",
            "id": 3039,
            "firstLetter": "W"
          }
        ],
        "id": 331,
        "provinceID": 31,
        "firstLetter": "W"
      },
      {
        "value": "650200",
        "title": "克拉玛依市",
        "children": [
          {
            "value": "650202",
            "title": "独山子区",
            "id": 3041,
            "firstLetter": "D"
          },
          {
            "value": "650203",
            "title": "克拉玛依区",
            "id": 3042,
            "firstLetter": "K"
          },
          {
            "value": "650204",
            "title": "白碱滩区",
            "id": 3043,
            "firstLetter": "B"
          },
          {
            "value": "650205",
            "title": "乌尔禾区",
            "id": 3044,
            "firstLetter": "W"
          }
        ],
        "id": 332,
        "provinceID": 31,
        "firstLetter": "K"
      },
      {
        "value": "652300",
        "title": "昌吉回族自治州",
        "children": [
          {
            "value": "652301",
            "title": "昌吉市",
            "id": 3051,
            "firstLetter": "C"
          },
          {
            "value": "652302",
            "title": "阜康市",
            "id": 3052,
            "firstLetter": "F"
          },
          {
            "value": "652323",
            "title": "呼图壁县",
            "id": 3053,
            "firstLetter": "H"
          },
          {
            "value": "652324",
            "title": "玛纳斯县",
            "id": 3054,
            "firstLetter": "M"
          },
          {
            "value": "652325",
            "title": "奇台县",
            "id": 3055,
            "firstLetter": "Q"
          },
          {
            "value": "652327",
            "title": "吉木萨尔县",
            "id": 3056,
            "firstLetter": "J"
          },
          {
            "value": "652328",
            "title": "木垒哈萨克自治县",
            "id": 3057,
            "firstLetter": "M"
          }
        ],
        "id": 335,
        "provinceID": 31,
        "firstLetter": "C"
      },
      {
        "value": "652700",
        "title": "博尔塔拉蒙古自治州",
        "children": [
          {
            "value": "652701",
            "title": "博乐市",
            "id": 3058,
            "firstLetter": "B"
          },
          {
            "value": "652702",
            "title": "阿拉山口市",
            "id": 3059,
            "firstLetter": "A"
          },
          {
            "value": "652722",
            "title": "精河县",
            "id": 3060,
            "firstLetter": "J"
          },
          {
            "value": "652723",
            "title": "温泉县",
            "id": 3061,
            "firstLetter": "W"
          }
        ],
        "id": 336,
        "provinceID": 31,
        "firstLetter": "B"
      },
      {
        "value": "652800",
        "title": "巴音郭楞蒙古自治州",
        "children": [
          {
            "value": "652801",
            "title": "库尔勒市",
            "id": 3062,
            "firstLetter": "K"
          },
          {
            "value": "652822",
            "title": "轮台县",
            "id": 3063,
            "firstLetter": "L"
          },
          {
            "value": "652823",
            "title": "尉犁县",
            "id": 3064,
            "firstLetter": "W"
          },
          {
            "value": "652824",
            "title": "若羌县",
            "id": 3065,
            "firstLetter": "R"
          },
          {
            "value": "652825",
            "title": "且末县",
            "id": 3066,
            "firstLetter": "Q"
          },
          {
            "value": "652826",
            "title": "焉耆回族自治县",
            "id": 3067,
            "firstLetter": "Y"
          },
          {
            "value": "652827",
            "title": "和静县",
            "id": 3068,
            "firstLetter": "H"
          },
          {
            "value": "652828",
            "title": "和硕县",
            "id": 3069,
            "firstLetter": "H"
          },
          {
            "value": "652829",
            "title": "博湖县",
            "id": 3070,
            "firstLetter": "B"
          }
        ],
        "id": 337,
        "provinceID": 31,
        "firstLetter": "B"
      },
      {
        "value": "652900",
        "title": "阿克苏地区",
        "children": [
          {
            "value": "652901",
            "title": "阿克苏市",
            "id": 3071,
            "firstLetter": "A"
          },
          {
            "value": "652922",
            "title": "温宿县",
            "id": 3072,
            "firstLetter": "W"
          },
          {
            "value": "652923",
            "title": "库车县",
            "id": 3073,
            "firstLetter": "K"
          },
          {
            "value": "652924",
            "title": "沙雅县",
            "id": 3074,
            "firstLetter": "S"
          },
          {
            "value": "652925",
            "title": "新和县",
            "id": 3075,
            "firstLetter": "X"
          },
          {
            "value": "652926",
            "title": "拜城县",
            "id": 3076,
            "firstLetter": "B"
          },
          {
            "value": "652927",
            "title": "乌什县",
            "id": 3077,
            "firstLetter": "W"
          },
          {
            "value": "652928",
            "title": "阿瓦提县",
            "id": 3078,
            "firstLetter": "A"
          },
          {
            "value": "652929",
            "title": "柯坪县",
            "id": 3079,
            "firstLetter": "K"
          }
        ],
        "id": 338,
        "provinceID": 31,
        "firstLetter": "A"
      },
      {
        "value": "653000",
        "title": "克孜勒苏柯尔克孜自治州",
        "children": [
          {
            "value": "653001",
            "title": "阿图什市",
            "id": 3080,
            "firstLetter": "A"
          },
          {
            "value": "653022",
            "title": "阿克陶县",
            "id": 3081,
            "firstLetter": "A"
          },
          {
            "value": "653023",
            "title": "阿合奇县",
            "id": 3082,
            "firstLetter": "A"
          },
          {
            "value": "653024",
            "title": "乌恰县",
            "id": 3083,
            "firstLetter": "W"
          }
        ],
        "id": 339,
        "provinceID": 31,
        "firstLetter": "K"
      },
      {
        "value": "653100",
        "title": "喀什地区",
        "children": [
          {
            "value": "653101",
            "title": "喀什市",
            "id": 3084,
            "firstLetter": "K"
          },
          {
            "value": "653121",
            "title": "疏附县",
            "id": 3085,
            "firstLetter": "S"
          },
          {
            "value": "653122",
            "title": "疏勒县",
            "id": 3086,
            "firstLetter": "S"
          },
          {
            "value": "653123",
            "title": "英吉沙县",
            "id": 3087,
            "firstLetter": "Y"
          },
          {
            "value": "653124",
            "title": "泽普县",
            "id": 3088,
            "firstLetter": "Z"
          },
          {
            "value": "653125",
            "title": "莎车县",
            "id": 3089,
            "firstLetter": "S"
          },
          {
            "value": "653126",
            "title": "叶城县",
            "id": 3090,
            "firstLetter": "Y"
          },
          {
            "value": "653127",
            "title": "麦盖提县",
            "id": 3091,
            "firstLetter": "M"
          },
          {
            "value": "653128",
            "title": "岳普湖县",
            "id": 3092,
            "firstLetter": "Y"
          },
          {
            "value": "653129",
            "title": "伽师县",
            "id": 3093,
            "firstLetter": "J"
          },
          {
            "value": "653130",
            "title": "巴楚县",
            "id": 3094,
            "firstLetter": "B"
          },
          {
            "value": "653131",
            "title": "塔什库尔干塔吉克自治县",
            "id": 3095,
            "firstLetter": "T"
          }
        ],
        "id": 340,
        "provinceID": 31,
        "firstLetter": "K"
      },
      {
        "value": "653200",
        "title": "和田地区",
        "children": [
          {
            "value": "653201",
            "title": "和田市",
            "id": 3096,
            "firstLetter": "H"
          },
          {
            "value": "653221",
            "title": "和田县",
            "id": 3097,
            "firstLetter": "H"
          },
          {
            "value": "653222",
            "title": "墨玉县",
            "id": 3098,
            "firstLetter": "M"
          },
          {
            "value": "653223",
            "title": "皮山县",
            "id": 3099,
            "firstLetter": "P"
          },
          {
            "value": "653224",
            "title": "洛浦县",
            "id": 3100,
            "firstLetter": "L"
          },
          {
            "value": "653225",
            "title": "策勒县",
            "id": 3101,
            "firstLetter": "C"
          },
          {
            "value": "653226",
            "title": "于田县",
            "id": 3102,
            "firstLetter": "Y"
          },
          {
            "value": "653227",
            "title": "民丰县",
            "id": 3103,
            "firstLetter": "M"
          }
        ],
        "id": 341,
        "provinceID": 31,
        "firstLetter": "H"
      },
      {
        "value": "654000",
        "title": "伊犁哈萨克自治州",
        "children": [
          {
            "value": "654002",
            "title": "伊宁市",
            "id": 3104,
            "firstLetter": "Y"
          },
          {
            "value": "654003",
            "title": "奎屯市",
            "id": 3105,
            "firstLetter": "K"
          },
          {
            "value": "654021",
            "title": "伊宁县",
            "id": 3106,
            "firstLetter": "Y"
          },
          {
            "value": "654022",
            "title": "察布查尔锡伯自治县",
            "id": 3107,
            "firstLetter": "C"
          },
          {
            "value": "654023",
            "title": "霍城县",
            "id": 3108,
            "firstLetter": "H"
          },
          {
            "value": "654024",
            "title": "巩留县",
            "id": 3109,
            "firstLetter": "G"
          },
          {
            "value": "654025",
            "title": "新源县",
            "id": 3110,
            "firstLetter": "X"
          },
          {
            "value": "654026",
            "title": "昭苏县",
            "id": 3111,
            "firstLetter": "Z"
          },
          {
            "value": "654027",
            "title": "特克斯县",
            "id": 3112,
            "firstLetter": "T"
          },
          {
            "value": "654028",
            "title": "尼勒克县",
            "id": 3113,
            "firstLetter": "N"
          },
          {
            "value": "654004",
            "title": "霍尔果斯市",
            "id": 4287,
            "firstLetter": "H"
          }
        ],
        "id": 342,
        "provinceID": 31,
        "firstLetter": "Y"
      },
      {
        "value": "654200",
        "title": "塔城地区",
        "children": [
          {
            "value": "654201",
            "title": "塔城市",
            "id": 3114,
            "firstLetter": "T"
          },
          {
            "value": "654202",
            "title": "乌苏市",
            "id": 3115,
            "firstLetter": "W"
          },
          {
            "value": "654221",
            "title": "额敏县",
            "id": 3116,
            "firstLetter": "E"
          },
          {
            "value": "654223",
            "title": "沙湾县",
            "id": 3117,
            "firstLetter": "S"
          },
          {
            "value": "654224",
            "title": "托里县",
            "id": 3118,
            "firstLetter": "T"
          },
          {
            "value": "654225",
            "title": "裕民县",
            "id": 3119,
            "firstLetter": "Y"
          },
          {
            "value": "654226",
            "title": "和布克赛尔蒙古自治县",
            "id": 3120,
            "firstLetter": "H"
          }
        ],
        "id": 343,
        "provinceID": 31,
        "firstLetter": "T"
      },
      {
        "value": "654300",
        "title": "阿勒泰地区",
        "children": [
          {
            "value": "654301",
            "title": "阿勒泰市",
            "id": 3121,
            "firstLetter": "A"
          },
          {
            "value": "654321",
            "title": "布尔津县",
            "id": 3122,
            "firstLetter": "B"
          },
          {
            "value": "654322",
            "title": "富蕴县",
            "id": 3123,
            "firstLetter": "F"
          },
          {
            "value": "654323",
            "title": "福海县",
            "id": 3124,
            "firstLetter": "F"
          },
          {
            "value": "654324",
            "title": "哈巴河县",
            "id": 3125,
            "firstLetter": "H"
          },
          {
            "value": "654325",
            "title": "青河县",
            "id": 3126,
            "firstLetter": "Q"
          },
          {
            "value": "654326",
            "title": "吉木乃县",
            "id": 3127,
            "firstLetter": "J"
          }
        ],
        "id": 344,
        "provinceID": 31,
        "firstLetter": "A"
      },
      {
        "value": "659000",
        "title": "自治区直辖县级行政区划",
        "children": [
          {
            "value": "659001",
            "title": "石河子市",
            "id": 3128,
            "firstLetter": "S"
          },
          {
            "value": "659002",
            "title": "阿拉尔市",
            "id": 3129,
            "firstLetter": "A"
          },
          {
            "value": "659003",
            "title": "图木舒克市",
            "id": 3130,
            "firstLetter": "T"
          },
          {
            "value": "659004",
            "title": "五家渠市",
            "id": 3131,
            "firstLetter": "W"
          },
          {
            "value": "659006",
            "title": "铁门关市",
            "id": 4288,
            "firstLetter": "T"
          }
        ],
        "id": 345,
        "provinceID": 31,
        "firstLetter": "Z"
      },
      {
        "value": "650400",
        "title": "吐鲁番市",
        "children": [
          {
            "value": "650402",
            "title": "高昌区",
            "id": 4281,
            "firstLetter": "G"
          },
          {
            "value": "650421",
            "title": "鄯善县",
            "id": 4282,
            "firstLetter": "S"
          },
          {
            "value": "650422",
            "title": "托克逊县",
            "id": 4283,
            "firstLetter": "T"
          }
        ],
        "id": 353,
        "provinceID": 31,
        "firstLetter": "T"
      },
      {
        "value": "650500",
        "title": "哈密市",
        "children": [
          {
            "value": "650502",
            "title": "伊州区",
            "id": 4284,
            "firstLetter": "Y"
          },
          {
            "value": "650521",
            "title": "巴里坤哈萨克自治县",
            "id": 4285,
            "firstLetter": "B"
          },
          {
            "value": "650522",
            "title": "伊吾县",
            "id": 4286,
            "firstLetter": "Y"
          }
        ],
        "id": 354,
        "provinceID": 31,
        "firstLetter": "H"
      }
    ],
    "title": "新疆维吾尔自治区",
    "id": 31,
    "firstLetter": "X"
  }
];
export default address;
function getByCode (tree, code) {
  if (_.isEmpty(tree)) return null;
  let target = null;
  for (let i = 0; i < tree.length; i++) {
    var each = tree[i];
    if (each.value === code) {
      target = each;
      break;
    }
    if (each.children && ((target = getByCode(each.children, code)), target)) break;
  }
  return target;
}
export { getByCode };



