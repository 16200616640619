import React, { Component } from 'react';
import { message, Icon } from 'antd';
import { inject, observer } from 'mobx-react';
import { getPageContainer, toGoodsDetail, navToApp, track, openMini, getShopCity } from '@/util/h5Helper';
import { browserTypeEnum } from '@/util/systemEnum';
import systemEvent from '@/util/systemEvent';
import district, { specialCitys } from '@/util/district';
import Service from '@/service/goods';
import SelectType from './Edit/SelectType';
import SelectBg from './Edit/SelectBg';
import SelectGoods from './Edit/SelectGoods';
import './index.scss';

@inject((store) => {
  return {
    activityStore: store.activityStore,
    queryLoading: false,
  };
})
@observer
class Goods extends Component {
  constructor(props) {
    super(props);
    const isEditor = Boolean(window.location.pathname.indexOf('/activity/detail') !== -1);
    const browserType = getPageContainer(false);
    this.state = {
      goodsList: [],
      browserType,
      isEditor,
      searchKeyword: '',
    };
  }

  componentDidMount() {
    const { goodsData } = this.props;
    if (!goodsData?.t) return;
    this.queryGoodsList();
    systemEvent.on('bnq_search_event', this.handleSearch);
    systemEvent.on('bnq_shopcity_change', this.queryGoodsList);
  }

  componentDidUpdate(prevProps) {
    const { goodsData } = this.props;
    if (!goodsData?.t) return;
    let t1 = prevProps.goodsData?.t;
    let t2 = this.props.goodsData?.t;
    if (t1 !== t2) {
      this.queryGoodsList();
    }
  }

  componentWillUnmount() {
    systemEvent.off('bnq_search_event', this.handleSearch);
    systemEvent.off('bnq_shopcity_change', this.queryGoodsList);
  }
// eslint-disable-next-line
  handleSearch = (data) => {
    this.setState({
      searchKeyword: data?.keyword || '',
    });
  };

  // eslint-disable-next-line
  queryGoodsList = async () => {
    let {
      soldoutGoods,
      activityId,
      componentUuid,
      id: componentCode,
      activityStore: { setCurrentGoods, editComUuid },
      
      __extraInfo,
    } = this.props;
    const { browserType } = this.state;
    let xShopCity = await getShopCity();
    if (!activityId) {
      let params = new URL(window.location.href).searchParams;
      activityId = params.get('id') || params.get('activityId');
    }
    let res = {};
    this.setState({ queryLoading: true });
    if (browserType === browserTypeEnum.APP || browserType === browserTypeEnum.MINI) {
      let code = xShopCity?.cityCode || '';
      let options = {
        default: 0,
        bottom: 1,
        hidden: 2,
      };
      let params = {
        activityId,
        componentCode,
        componentUuid,
        noStockShowType: options[soldoutGoods] || 0,
      };
      let changeAble = __extraInfo?.pageTitle?.$props?.shopCityChangeAble;

      if (changeAble == '2') {
        params.shopCode = __extraInfo?.shopCode || xShopCity?.shopCode || '1004';
      } else {
        params.cityCode = code || '310100';
      }
      res = await Service.queryGoodsByCityCode(params);
    } else {
      res = await Service.queryComponentGoods({ activityId, componentCode, componentUuid });
    }
    this.setState({ queryLoading: false });
    if (res?.code === 0 && res?.success) {
      let list = [];
      if (Array.isArray(res.data)) {
        list = res.data;
      }
      this.setState({ goodsList: list });
      // 如果当前组件为编辑中的组件，则保存商品数据到store
      if (editComUuid === componentUuid) {
        setCurrentGoods(list);
      }
    } else {
      console.log('查询商品失败', activityId, componentCode, componentUuid);
    }
  }

  getCityName = (cityCode) => {
    let name = ''
    district.some((v) => {
      if (!v.children) return false;
      return v.children.some((e) => {
        if (e.value == cityCode) {
          name = e.label
          return true
        }
        return false
      });
    });
    return name
  };

  async toDetail(item, isSoldOut) {
    const { componentType, id } = this.props;

    if (isSoldOut || window.location.pathname.indexOf('/activity/detail') !== -1) return;

    const { browserType } = this.state;
    let params = new URL(window.location.href).searchParams;
    let activityId = params.get('activityId');
    let channel = params.get('channel') || '';
    let giochannel = params.get('giochannel') || '';

    if (browserType === browserTypeEnum.BROWSER || browserType == browserTypeEnum.WX_BROWSER) {
      openMini(`unpack/pages/web/index?pageId=${activityId || ''}&channel=${channel}&giochannel=${giochannel}`);
      return;
    }

    let {
      activityStore: { getCityCode },
    } = this.props;
    let code = await getCityCode();
    let queryParams = { cityCode: code || '310100', sapSku: item.sapCode };
    // 直辖市 省code 转换为 市code
    let specialItem = specialCitys.find((e) => e.provinceCode == queryParams.cityCode);
    if (specialItem) {
      queryParams.cityCode = specialItem.cityCode;
    }
    
    let cityProduct = JSON.parse(item.cityProduct || '[]');
    if (cityProduct.length > 0) {
      let productItem = cityProduct.find((e) => e.cityCodeList && e.cityCodeList.indexOf(queryParams.cityCode) != -1);
      if (!productItem) {
        let name = this.getCityName(queryParams.cityCode)
        message.error(`未找到该商品在 ${name} 对应的商品编码`);
        return;
      }
      queryParams.sapSku = productItem.sapCode;
    }
    track({ code: 'click', componentType, componentCode: id }, { itemId: queryParams.sapSku });

    const res = await Service.queryRedirectParams(queryParams);

    if (res?.code === 0 && res?.success && res?.data) {
      // return toGoodsDetail({ itemId: 5003531, skuId: 14455 }); // test
      // if (browserType === browserTypeEnum.BROWSER || browserType == browserTypeEnum.WX_BROWSER) {
      //   // 跳转商品详情页;
      //   // navToApp();
      //   // openMini(`unpack/pages/goodsdetails/goodsdetails?itemId=${res.data.itemId}&skuId=${res.data.skuId}`);
      //   // openMini(`unpack/pages/web/index?pageId=${activityId || ''}&channel=${channel}&giochannel=${giochannel}`);
      // } else {
      // }
      toGoodsDetail({ itemId: res.data.itemId, skuId: res.data.skuId }, { componentType, componentCode: id });
    } else {
      message.error('查询跳转信息失败');
    }
  }

  getData() {
    const { activityStore, comList, ...data } = this.props;
    const { labelPosition, goodsBg } = data;
    let bgStyle = {};
    if (goodsBg?.type === 1) {
      bgStyle = { backgroundImage: `url(${goodsBg.data})`, backgroundSize: 'cover' };
      if (goodsBg?.style === 1) {
        bgStyle.backgroundSize = '100% 100%';
      } else if (goodsBg?.style === 2) {
        bgStyle.backgroundSize = 'contain';
      }
    } else if (goodsBg.type === 0) {
      bgStyle = { backgroundColor: goodsBg?.data ?? '#f5f5f5' };
    } else {
      bgStyle = { backgroundColor: 'inherit' };
      const pageTitle = comList.find((v) => v.$component == 'pageTitle');
      if (pageTitle && pageTitle?.$props?.bgType == 1) {
        bgStyle.backgroundColor = pageTitle.$props?.bgColor || 'inherit';
      }
    }
    let labelStyle = { left: 0, top: 0 };
    if (labelPosition === 'rightTop') {
      labelStyle = { right: 0, top: 0 };
    } else if (labelPosition === 'leftBottom') {
      labelStyle = { left: 0, bottom: 0 };
    } else if (labelPosition === 'rightBottom') {
      labelStyle = { right: 0, bottom: 0 };
    }
    return { ...data, bgStyle, labelStyle };
  }

  loadImage = (src) => {
    return new Promise((resolve) => {
      let img = null;
      if (!src) {
        img = document.createElement('div');
        resolve(img);
        return;
      }
      img = new Image();
      img.onload = (e) => {
        resolve(e.target);
      };
      img.src = src;
    });
  };

  imgLoaded = async (imgEle) => {
    return new Promise((resolve) => {
      imgEle.onload = () => {
        resolve();
      };

      imgEle.onerror = () => {
        resolve();
      };
    });
  };

  renderImg = async ({ list, labelPosition, browserType, goodsTitleColor, hideItemBg, showPrice, showName, isEditor }) => {
    let cols = document.querySelectorAll('.goods-column-content');
    if (cols.length < 1) return;
    for (let i = 0; i < list.length; i++) {
      // let isSoldOut = Boolean(
      //   (list[i].itemId === undefined || list[i].itemId === null) &&
      //     (browserType === browserTypeEnum.APP || browserType === browserTypeEnum.MINI),
      // );

      let isSoldOut = false;
      let heightArr = Array.from(cols).map((v) => v.clientHeight);
      let itemEl = document.createElement('div');
      let nameEl = document.createElement('div');
      let descEl = document.createElement('div');
      let priceEl = document.createElement('div');
      itemEl.classList.add('goods-item');
      itemEl.setAttribute('data-editor', isEditor);
      nameEl.classList.add('goods-item-name');
      descEl.classList.add('goods-item-desc');
      priceEl.classList.add('goods-item-price');
      nameEl.innerText = list[i].productName;
      descEl.innerText = list[i].sellingPoints;
      if (goodsTitleColor) {
        nameEl.style.color = goodsTitleColor;
        descEl.style.color = goodsTitleColor;
      }
      if (list[i].price === null || list[i].price === undefined) {
        priceEl.classList.add('no-price');
      } else if (showPrice) {
        priceEl.innerText = list[i].price;
      } else {
        priceEl.classList.add('no-price');
      }
      let imgWrapEl = document.createElement('div');
      imgWrapEl.classList.add('goods-item-img-wrap');
      imgWrapEl.setAttribute('data-column', cols.length);
      if (!hideItemBg) {
        imgWrapEl.style.backgroundColor = '#efefef';
      }

      let imgEl = null;
      if (list[i].imgUrl) {
        imgEl = document.createElement('img');
        imgEl.src = list[i].imgUrl;
        imgEl.classList.add('goods-item-img');
        imgWrapEl.style.height = 'auto';
      } else {
        imgEl = document.createElement('div');
        imgEl.style.backgroundImage = `url(${list[i].imgUrl})`;
        imgEl.classList.add('goods-item-img');
      }

      if (list[i].tagUrl) {
        let tagEl = document.createElement('img');
        tagEl.src = list[i].tagUrl;
        tagEl.classList.add('goods-item-tag-img', `goods-item-tag-${labelPosition}`);
        imgWrapEl.append(tagEl);
      }
      if (isSoldOut) {
        imgEl = document.createElement('img');
        imgEl.src = 'https://dhstatic.bthome.com/img/activity/h5editor/icon/soldout@3x.png';
        imgEl.classList.add('soldout-img');
        imgWrapEl.append(imgEl);
      } else {
        imgWrapEl.append(imgEl);
      }

      await this.imgLoaded(imgEl);

      itemEl.append(imgWrapEl);
      if (showName !== 0) {
        itemEl.append(nameEl);
        itemEl.append(descEl);
      }
      itemEl.append(priceEl);
      itemEl.onclick = () => {
        this.toDetail(list[i], isSoldOut);
      };
      let minVal = Math.min(...heightArr);
      let colIndex = heightArr.indexOf(minVal);
      cols[colIndex].append(itemEl);
    }
  };

  renderColumn = (displayType, componentUuid) => {
    let colNum = parseInt(displayType) || 2;
    if (colNum < 1) {
      colNum = 2;
    } else if (colNum > 3) {
      colNum = 3;
    }
    const comEl = document.getElementById(componentUuid);
    if (!comEl) {
      return colNum;
    }
    let innerContent = comEl.querySelector('.content-wrap');
    if (innerContent) comEl.removeChild(innerContent);
    innerContent = document.createElement('div');
    innerContent.classList.add('content-wrap');
    comEl.append(innerContent);

    let xWidth = '100%';
    if (colNum == 2) {
      xWidth = `calc(50% - 5px)`;
    } else if (colNum == 3) {
      xWidth = `calc(33.3% - 7px)`;
    }
    for (let i = 0; i < colNum; i++) {
      let col = document.createElement('div');
      let colContent = document.createElement('div');
      col.classList.add('goods-column');
      col.style = `width: ${xWidth}`;
      colContent.classList.add('goods-column-content');
      col.append(colContent);
      innerContent.append(col);
    }
    return colNum;
  };

  renderWaterFall = ({ displayType, componentUuid, ...rest }) => {
    this.renderColumn(displayType, componentUuid);
    setTimeout(() => {
      this.renderImg(rest);
    }, 10);
  };

  renderItem = (data, rest) => {
    const { goodsTitleColor, labelStyle, hideItemBg } = this.getData();
    // let isSoldOut = Boolean(
    //   (data.itemId === undefined || data.itemId === null) &&
    //     (browserType === browserTypeEnum.APP || browserType === browserTypeEnum.MINI),
    // );
    const isSoldOut = false;
    let xImgWrapStyle = {
    };

    if (!hideItemBg) {
      xImgWrapStyle.backgroundColor = '#efefef';
    }
    const titleStyle = {
      color: goodsTitleColor,
      display: rest.showName === 0 ? 'none' : 'inherit'
    }
    return (
      <div key={data.sapCode} className="goods-item-wrap" onClick={() => this.toDetail(data, isSoldOut)}>
        <div className="goods-item">
          {isSoldOut ? (
            <div className="goods-item-img-wrap soldout" style={xImgWrapStyle}>
              <img src="https://dhstatic.bthome.com/img/activity/h5editor/icon/soldout@3x.png" alt="" />
            </div>
          ) : (
            <div className="goods-item-img-wrap" style={xImgWrapStyle}>
              <img src={data.imgUrl} alt="" />
              <img src={data.tagUrl} className="goods-item-tag-img" style={labelStyle} alt="" title="" />
            </div>
          )}
          <div className="goods-item-title" style={titleStyle}>
            {data.productName}
          </div>
          <div className="goods-item-desc" style={titleStyle}>
            {data.sellingPoints}
          </div>
          <div className="goods-item-price" data-price={Boolean(data.price && rest.showPrice)}>
            {data.price}
          </div>
        </div>
      </div>
    );
  };

  renderXX = ({ goodsList, alignType, ...rest }) => {
    const { queryLoading, isEditor } = this.state;
    if (isEditor && !queryLoading && !goodsList.length) {
      return (
        <div className="goods-tip">
          <div>商品</div>
          <div>请设置商品</div>
        </div>
      );
    }

    // console.log('goodsList', alignType, goodsList);

    rest.list = goodsList;
    rest.isEditor = isEditor;
    if (alignType === 'waterfall') {
      return this.renderWaterFall(rest);
    }
    if (alignType === 'horizontal' || !alignType) {
      const comEl = document.getElementById(rest.componentUuid);
      if (comEl) {
        let innerContent = comEl.querySelector('.content-wrap');
        innerContent?.remove();
      }
      return goodsList.map((v) => this.renderItem(v, rest));
    }
  };

  render() {
    const { bgStyle, componentUuid, alignType, displayType, ...rest } = this.getData();
    let { goodsList, queryLoading, browserType, searchKeyword } = this.state;
    if (searchKeyword && goodsList) {
      goodsList = goodsList.filter((item) => (item.productName || '').indexOf(searchKeyword) >= 0);
    }
    // console.log('goods render props:', this.props);
    return (
      <div className="goods" id={componentUuid} style={bgStyle} data-align={alignType} data-display={displayType}>
        {this.renderXX({ ...rest, goodsList, componentUuid, browserType, alignType, displayType })}
        {queryLoading ? (
          <div className="goods-spin">
            <Icon type="loading" className="goods-spin-icon" />
            <div>加载中</div>
          </div>
        ) : null}
      </div>
    );
  }
}

Goods.getDefaultValue = () => {
  return {
    displayType: '2col',
    goodsTitleColor: '#333333',
    goodsBg: {
      type: 0,
      data: '#f5f5f5',
      style: 0,
    },
    goodsData: {
      num: 0, // 商品数量
      t: null, // 最近变更时间
    },
    labelPosition: 'leftTop',
    soldoutGoods: 'default',
  };
};

Goods.getEditData = () => ({
  data: {
    displayType: {
      control: SelectType,
    },
    goodsTitleColor: {
      label: '商品标题颜色',
      control: 'colorPicker',
    },
    goodsBg: {
      control: SelectBg,
    },
    hideItemBg: {
      label: '是否隐藏商品单项背景',
      control: 'radioGroup',
      getInitialValue: 0,
      options: () => [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ],
    },
    goodsData: {
      control: SelectGoods,
    },
    labelPosition: {
      label: '标签位置',
      control: 'radioGroup',
      getInitialValue: 'leftTop',
      options: () => [
        { label: '左上', value: 'leftTop' },
        { label: '右上', value: 'rightTop' },
        { label: '左下', value: 'leftBottom' },
        { label: '右下', value: 'rightBottom' },
      ],
    },
    alignType: {
      label: '坑位展示',
      control: 'radioGroup',
      getInitialValue: 'horizontal',
      options: () => [
        { label: '水平对齐', value: 'horizontal' },
        { label: '瀑布流', value: 'waterfall' },
      ],
    },
    showName: {
      label: '商品名称展示',
      control: 'radioGroup',
      getInitialValue: 1,
      options: () => [
        { label: '展示', value: 1 },
        { label: '不展示', value: 0 },
      ],
    },
    showPrice: {
      label: '价格展示',
      control: 'radioGroup',
      getInitialValue: 1,
      options: () => [
        { label: '展示', value: 1 },
        { label: '不展示', value: 0 },
      ],
    },
    $label1: '页面控制',
    soldoutGoods: {
      label: '抢光商品展示',
      control: 'radioGroup',
      getInitialValue: 'default',
      options: () => [
        { label: '默认排序展示', value: 'default' },
        { label: '沉底展示', value: 'bottom' },
        { label: '不展示', value: 'hidden' },
      ],
    },
  },
});

Goods.validate = (props) => {
  // console.log('Goods validate', props);
  const { goodsBg, goodsData, goodsTitleColor } = props;
  if (!goodsTitleColor) return '请设置商品标题颜色';
  if (!goodsBg?.data) return '请设置商品背景';
  if (!goodsData?.num) return '请添加活动商品';
};

export default Goods;
