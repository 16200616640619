import axios from 'axios';
import { Remote } from '@/util';

class GoodsService {
  queryBrandOptions(params = {}) {
    return Remote.get('/product/brandList.do', { pageNum: 1, pageSize: 10, ...params });
  }
  queryCategoryOptions(params = {}) {
    return Remote.get('/product/categoryList.do', { pageNum: 1, pageSize: 10, ...params });
  }
  queryShopGoods(data) {
    return Remote.post('/product/pageList.do', data);
  }
  queryComponentGoods(params) {
    if (!params.activityId) delete params.activityId;
    return Remote.get('/client/productDetail/queryProductList.do', params);
  }
  queryGoodsByCityCode(params) {
    if (!params.activityId) delete params.activityId;
    return Remote.post('/client/productDetail/getProductPageInfo', params);
  }
  saveComponentGoods(params) {
    return new Promise((resolve) => {
      Remote.post('/productdetail/saveProductList.do', params)
        .then((data) => resolve(data))
        .catch((err) => resolve(err));
    });
  }
  importGoods(data, source) {
    const accessToken = localStorage.getItem('accessToken');
    const host = Remote.genDomainForEnv('default');
    const url = `${host}/product/batchImportProduct`;
    return axios.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        token: accessToken,
      },
      cancelToken: source?.token,
    });
  }
  /**
   * 查询商品详情页跳转参数
   * @param {*} data { sapCode, cityCode }
   * @returns
   */
  queryRedirectParams(data) {
    return Remote.post('/client/productDetail/getProductJumpInfo', data);
  }
  queryActivityId(ztId) {
    return Remote.get(`/client/activityInfo/getActivityInfoByCode?activityCode=${ztId}`);
  }

  receiveCoupon(data) {
    return Remote.post(`/client/activityComponent/coupon/receiveCoupon.do`, data);
  }

  validateSapCode(data) {
    return Remote.get(`/product/check/sapSkuNo`, data);
  }
}

const Service = new GoodsService();
export default Service;
