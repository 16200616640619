import { Table } from 'antd';
import React, { useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Icon, Input, Popconfirm, Button, Checkbox } from 'antd';
import Upload from '@/components/Upload';
import district from '@/util/district';
import './index.less';

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });

  const [, drag] = useDrag({
    type,
    item: {
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{
        cursor: 'move',
        ...style,
      }}
      {...restProps}
    />
  );
};

const columns = ({ handleDelete, handleUpdateItem, handleConfigItem, handleCheckAll }) => [
  {
    title: '序号',
    dataIndex: 'number',
    width: 90,
    render: (text, record, index) => {
      return (
        <div>
          <Icon type="menu" />
          <span style={{ color: 'rgba(0,0,0,0.65)', marginLeft: 10 }}>{index + 1}</span>
        </div>
      );
    },
  },
  {
    title: '商品名称',
    dataIndex: 'productName',
  },
  {
    title: 'SAP商品编码',
    dataIndex: 'sapCode',
    width: 260,
    render: (text, record, index) => {
      let cityProduct = JSON.parse(record.cityProduct || '[]');
      if (cityProduct.length > 0) {
        cityProduct = cityProduct.map(({ cityCodeList, sapCode }) => {
          let list = cityCodeList.map((cityCode) => {
            let provinceName = '';
            let cityName = '';
            district.some((e) => {
              if (e.children) {
                e.children.some((e1) => {
                  if (e1.value == cityCode) {
                    provinceName = e.label;
                    cityName = e1.label;
                    return true;
                  }
                  return false;
                });
              }
              return false;
            });
            // return `${provinceName || '未知'} / ${cityName || '未知'}`;
            return cityName || '未知';
          });
          return { cityList: list, sapCode };
        });
        console.log('data', cityProduct)
        return (
          <ul className="tableConfigColumn">
            {cityProduct.map((item, itemIndex) => (
              <li key={itemIndex}>
                <span className="sapCode">{item.sapCode}</span>
                <span className="cityList">
                  {item.cityList && item.cityList.map((city) => <span key={city}>{city}</span>)}
                </span>
              </li>
            ))}
          </ul>
        );
      }
      return text;
    },
  },
  {
    title: () => {
      return <div>
        <div>是否爆款商品</div>
        <div><Checkbox onChange={handleCheckAll} />全部选是</div>
      </div>
    },
    width: 160,
    dataIndex: 'recommendGoods',
    render: (text, record, index) => {
      return (
        <Checkbox
          value={text}
          checked={text === 1 ? true : false}
          onChange={(evt) => {
            console.log(evt);
            let newItem = { ...record, recommendGoods: evt.target.checked ? 1 : 0 };
            handleUpdateItem(index, newItem);
          }}
        >是</Checkbox>
      );
    },
  },
  {
    title: '商品图片',
    dataIndex: 'imgUrl',
    render: (value, record, index) => {
      return (
        <Upload
          accept=".jpg,.jpeg,.png"
          maxSize="1M"
          value={value}
          onChange={(evt) => {
            let newItem = { ...record, imgUrl: evt };
            handleUpdateItem(index, newItem);
          }}
        />
      );
    },
  },
  {
    title: '商品卖点（非必填）',
    dataIndex: 'sellingPoints',
    render: (text, record, index) => {
      return (
        <Input
          placeholder='商品卖点，不超过20字'
          maxLength={20}
          value={text}
          onChange={(evt) => {
            let newItem = { ...record, sellingPoints: evt.target.value };
            handleUpdateItem(index, newItem);
          }}
        />
      );
    },
  },
  {
    title: '标签图片',
    dataIndex: 'tagUrl',
    render: (value, record, index) => {
      return (
        <Upload
          accept=".jpg,.jpeg,.png"
          maxSize="1M"
          value={value}
          onChange={(evt) => {
            let newItem = { ...record, tagUrl: evt };
            handleUpdateItem(index, newItem);
          }}
        />
      );
    },
  },
  {
    title: '操作',
    align: 'center',
    width: 160,
    render: (text, record, index) => {
      return (
        <>
          <Button type="link" onClick={() => handleConfigItem(index, record)}>
            城市设置
          </Button>
          <Popconfirm
            placement="topLeft"
            title="确定删除?"
            onConfirm={() => handleDelete(index)}
            okText="确定"
            cancelText="取消"
          >
            <a href="#">删除</a>
          </Popconfirm>
        </>
      );
    },
  },
];

const SortTable = (props) => {
  const { data, handleDelete, handleUpdateItem, handleMoveRow, handleConfigItem, handleCheckAll, ...rest } = props;

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        className="goodsTable"
        style={{ marginTop: 20 }}
        rowKey="sapCode"
        dataSource={data}
        columns={columns({ handleDelete, handleUpdateItem, handleConfigItem, handleCheckAll })}
        pagination={false}
        {...rest}
        components={components}
        onRow={(_, index) => {
          const attr = {
            index,
            moveRow: handleMoveRow,
          };
          return attr;
        }}
      />
    </DndProvider>
  );
};

export default SortTable;
