import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, message, Upload, Button, Icon, Table } from 'antd';
import Service from '@/service/goods';
import './index.less';

const toNumber = (size) => {
  let unit = size.slice(-1);
  let val = size.slice(0, -1);

  if (unit == 'K') {
    return val * 1024;
  }

  if (unit == 'M') {
    return val * 1024 * 1024;
  }

  if (unit == 'G') {
    return val * 1024 * 1024 * 1024;
  }

  return +size || 0;
};

const UploadModal = (props) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [status, setStatus] = useState(null); // 上传状态， success, fail
  const [source, setSource] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [isShowErrModal, setIsShowErrModal] = useState(false);
  const columns = [
    {
      title: 'SAP商品编码(必填)',
      dataIndex: 'sapCode',
      key: 'sapCode',
    },
    {
      title: '是否爆款商品(必填)',
      dataIndex: 'recommendGoods',
      key: 'recommendGoods',
      render: (text) => {
        return text === 1 ? '是' : '否';
      }
    },
    {
      title: '商品卖点(非必填)',
      dataIndex: 'sellingPoints',
      key: 'sellingPoints',
    },
    {
      title: '排序',
      dataIndex: 'sortNum',
      key: 'sortNum',
    },
    {
      title: '失败原因',
      dataIndex: 'reason',
      key: 'reason',
    },
  ];

  const { visible, onOk, ...rest } = props;

  const uploadProps = {
    accept: '.xlsx, .xls',
    fileList,
    showUploadList: false,
    beforeUpload: () => false,
    onChange: async ({ fileList: fileArr }) => {
      if (loading) return;
      if (fileArr.length > 0) {
        if (fileArr[0].size > toNumber('10M')) {
          message.warning(`上传的文件大小不能超过10M`);
          return;
        }
        setFileList(fileArr);
        setLoading(true);
        setStatus(null);
        let { CancelToken } = axios;
        let s = CancelToken.source();
        setSource(s);
        let formData = new FormData();
        formData.append('file', fileArr[0].originFileObj);
        const { data: res } = await Service.importGoods(formData, s);
        setLoading(false);
        if (res?.code === 0 && res?.success && Array.isArray(res?.data)) {
          message.success('文件解析成功');
          let errData = res.data.filter((item) => item.reason !== null);
          if (errData.length !== 0) {
            setList(res.data);
            setDataSource(errData);
            setIsShowErrModal(true);
          } else {
            setList(res.data);
            setStatus('success');
          }

        } else {
          setStatus('fail');
          setList([]);
          message.error(`err: ${res.message}` || '文件解析失败');
        }
      } else {
        setFileList([]);
      }
    },
  };

  const handleConfirm = () => {
    if (fileList.length < 1 || status === 'fail') {
      message.warn('请上传文件');
      return;
    }
    onOk?.(list);
  };

  const removeFile = () => {
    setFileList([]);
    setList([]);
    setStatus(null);
    if (loading) {
      source?.cancel('取消上传');
      setLoading(false);
    }
  };

  const downloadTemplate = () => {
    window.location.href = 'https://dhstatic.bthome.com/img/activity/h5editor/excel_template.xls';
  };

  useEffect(() => {
    if (!visible) return;
    // reset
    setFileList([]);
    setList([]);
    setStatus(null);
  }, [visible]);

  let nameStyle = { color: '#0073c9' };
  if (status === 'success') {
    nameStyle.color = '#52C41A';
  } else if (status === 'fail') {
    nameStyle.color = 'red';
  }

  return (
    <Modal {...rest} maskClosable={false} title="批量导入商品" visible={visible} width={460} onOk={handleConfirm}>
      <div className="goods-upload-box">
        <div className="goods-upload-content">
          <div className="goods-upload-content-top">
            <Upload {...uploadProps}>
              <Button icon="upload" loading={loading}>
                上传商品文件
              </Button>
              <div className="goods-upload-content-tip">支持扩展名.xlsx, .xls</div>
            </Upload>
            <Button type="link" className="goods-upload-content-download" onClick={downloadTemplate}>
              下载导入模板
            </Button>
          </div>
          {fileList.length > 0 ? (
            <div className="goods-upload-content-fileName" style={nameStyle}>
              {fileList[0]?.name} <Icon type="delete" onClick={removeFile} />
            </div>
          ) : null}
        </div>
        <Modal title="以下信息录入失败，请更改信息后，再尝试上传" visible={isShowErrModal} onCancel={() => { setIsShowErrModal(false) }} width={900} footer={null} >
          <div>
            <Table dataSource={dataSource} columns={columns} />
          </div>
        </Modal>
      </div>
    </Modal>
  );
};

export default UploadModal;
