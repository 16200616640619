import { observable, action, runInAction } from 'mobx';
import _ from 'lodash';
import service from '@/service/global';

class GlobalStore {
  @observable collapse = false;
  @observable locale = '';
  @observable globalEnum = {};

  @action toggle = () => {
    this.collapse = !this.collapse;
  };

  @action fetchGlobalEnmu = async () => {
    if (!_.isEmpty(this.globalEnum)) {
      return this.globalEnum;
    }

    let res = await service.fetchGlobalEnmu(['appointmentChannel']);

    runInAction(() => {
      this.globalEnum = res?.data || {};
    });

    return res;
  };

  @action fetchAreaData = async () => {
    let res = await service.fetchAreaData();
    return res;
  };
}

export default new GlobalStore();
