import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HashRouter, BrowserRouter, MemoryRouter, StaticRouter, Switch, Route } from 'react-router-dom';
import { Layout, Icon, message, Spin } from 'antd';
import './index.less';
import Config from '../../../config/config';
import SliderMenu from '../../components/SliderMenu';
import Contents from './Content';
import CommonService from '../../service/CommonService';
import { getQueryString } from '@/util/common';
import ActiviyH5 from '@/app/Activity/H5';

const { Header } = Layout;
const { routeType, menus } = Config;

class Home extends Component {
  static propTypes = {
    logOut: PropTypes.func,
  };

  static defaultProps = {
    logOut: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false, // 当前侧边栏收起状态
      isShowUserOption: false,
      fullName: localStorage.getItem('fullName'),
    };
  }

  componentDidMount() {
    let xAccessToken = getQueryString('accessToken');
    if (xAccessToken) {
      localStorage.setItem('accessToken', xAccessToken);
      document.cookie = `sessionToken=${xAccessToken}`;
    }

    // CommonService.getAuthInfo().then((res) => {
    //     const { code, data: { realname } = {} } = (res || {}).data || {};
    //     if (code === 0) {
    //         // if (username) {
    //         //     document.cookie = `sessionToken=${sessionToken}`;
    //         //     localStorage.setItem('accessToken', sessionToken);
    //         // }
    //         localStorage.setItem('fullName', realname);
    //     }
    // });
  }

  /**
   * 渲染左侧菜单
   */
  genMenu = () => {
    const menu = menus;
    const { collapsed } = this.state;
    return menu ? (
      <SliderMenu
        menu={menu}
        collapsed={collapsed}
        setMenuCollapsed={() => {
          this.setMenuCollapsed();
        }}
        {...this.props}
      />
    ) : null;
  };

  /**
   * 设置菜单收缩状态
   */
  setMenuCollapsed = (iscollapsed) => {
    const collapsed = iscollapsed || !this.state.collapsed;
    this.setState({ collapsed });
  };

  _logout = async () => {
    this.setState({ isShowUserOption: false });
    await CommonService.logout();
    localStorage.removeItem('accessToken');
    document.cookie = `sessionToken='';expires=-1`;
    message.success('退出成功');
    setTimeout(() => {
      // 重定向
      CommonService.getAuthInfo();
    }, 1500);
  };

  /**
   * 判断使用的路由方式
   */
  _getRouteType() {
    switch (routeType) {
      case 'browser':
        return BrowserRouter;
      case 'memory':
        return MemoryRouter;
      case 'static':
        return StaticRouter;
      default:
        return HashRouter;
    }
  }

  render() {
    // 获取用户名
    const username = this.state.fullName || localStorage.getItem('fullName');
    const RouterType = this._getRouteType();
    const { isShowUserOption } = this.state;
    return (
      <RouterType basename={window.__POWERED_BY_QIANKUN__ ? '/activity_h5' : '/'}>
        <Switch>
          <Route path="/activity/h5" exact component={ActiviyH5} />
          <Route
            render={() => {
              return (
                <Layout className="container">
                  {!window.__POWERED_BY_QIANKUN__ && this.genMenu()}
                  <Layout className="ant-layout-ie9">
                    {!window.__POWERED_BY_QIANKUN__ && <Header className="header">
                      <Icon
                        className="trigger"
                        type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                        onClick={() => {
                          this.setMenuCollapsed();
                        }}
                      />
                      <div
                        className="userContainer"
                        onMouseLeave={() => {
                          return this.setState({ isShowUserOption: false });
                        }}
                      >
                        <div
                          className="userInfo"
                          onMouseEnter={() => {
                            return this.setState({ isShowUserOption: true });
                          }}
                        >
                          <Icon type="user" className="usernameIcon" />
                          <span>
                            {username || ''}
                            <Icon type="caret-down" />
                          </span>
                        </div>
                        <div
                          className="userOption"
                          style={{
                            display: isShowUserOption ? 'block' : 'none',
                          }}
                        >
                          <div className="logout" onClick={this._logout}>
                            退出登录
                          </div>
                        </div>
                      </div>
                    </Header>}
                    <Layout style={{ background: '#eee' }}>{this.state.loading ?  <Spin size="large" className="contentSpin" /> : <Contents />}</Layout>
                  </Layout>
                </Layout>
              );
            }}
          />
        </Switch>
      </RouterType>
    );
  }
}

export default Home;
