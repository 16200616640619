/****** create file by codeGen ******/
import { action, observable, runInAction } from 'mobx';
import service from '@/service/activity';
import goodsService from '@/service/goods';
import { getShopCityCode } from '@/util/h5Helper';
import BaseStore from '../magicPackages/BaseStore';

class ActivityStore extends BaseStore {
  constructor() {
    super(service, {});
  }

  // 商城城市code
  @observable cityCode = null;

  // 活动id
  @observable activityId = null;

  // 当前编辑的组件id
  @observable editComId = null;

  // 当前编辑的组件uuid
  @observable editComUuid = null;

  // 当前编辑的组件类型
  @observable editComType = null;

  // 商品组件编辑时绑定的商品列表
  @observable currentGoods = [];

  @action setEditId = ({ activityId, id, uuid, type }) => {
    // console.log('set EditId', activityId, id, uuid, type, this.editComId, this.editComType);
    if (
      (this.editComType == type && type == 'goods' && this.editComId !== id) ||
      (type == 'goods' && this.editComId !== id)
    ) {
      // 存在多个商品组件时，切换选中商品组件时重新查询列表
      // 切换编辑组件，并且当前编辑组件为商品组件时，重新查询商品列表
      this.queryCurrentGoods({ activityId, componentCode: id, componentUuid: uuid });
    }
    if (activityId) this.activityId = activityId;
    if (id) this.editComId = id;
    if (uuid) this.editComUuid = uuid;
    if (type) this.editComType = type;
  };

  @action queryCurrentGoods = async (data = {}) => {
    let params = {
      activityId: this.activityId,
      componentCode: this.editComId,
      componentUuid: this.editComUuid,
      ...data,
    };
    const res = await goodsService.queryComponentGoods(params);
    let arr = [];
    if (res.code === 0 && res.success && Array.isArray(res.data)) {
      arr = res.data;
    } else {
      arr = [];
    }
    runInAction(() => {
      this.currentGoods = arr;
    });
    return res;
  };

  @action setCurrentGoods = (list) => {
    this.currentGoods = list;
  };

  @action getCityCode = async () => {
    if (this.cityCode) return this.cityCode;
    let code = await getShopCityCode();
    runInAction(() => {
      if (code) {
        this.cityCode = code;
      }
    });
    return code;
  };

  @action resetDetail = (detail) => {
    this.$detail = detail || null;
  };

  @action getCopyDetail = this.flowWithLoading('getCopyDetail', function* genx(...args) {
    const res = yield service.getCopyDetail(...args);
    this.$detail = res?.data || {};

    return res;
  });
}

export default new ActivityStore();
