import ApplyForm from '../components/ApplyForm';
import ShopWindow from '../components/ShopWindow';
import PageTitle from '../components/PageTitle';
import Banner from '../components/Banner';
import Goods from '../components/Goods';
import Anchor from '../components/Anchor';
import Appointment from '../components/Appointment';
import Coupon from '../components/Coupon';
import Quotation from '../components/Quotation';
import Timer from '../components/Timer';
import Search from '../components/Search';
import Zone from '../components/Zone';

const modulesList = new Map([
  ['applyForm', ApplyForm],
  ['shopWindow', ShopWindow],
  ['pageTitle', PageTitle],
  ['banner', Banner],
  ['goods', Goods],
  ['anchor', Anchor],
  ['appointment', Appointment],
  ['quotation', Quotation],
  ['coupon', Coupon],
  ['timer', Timer],
  ['search', Search],
  ['zone', Zone],
]);

export default (type) => {
  return modulesList.get(type);
};
