import React from 'react';
import './index.less';

const Tip = (props) => {
  const {
    title = '切换城市门店',
    des = '定位与活动城市门店不符，是否需要切换城市门店',
    cancalText = '取消',
    okText = '立即切换',
    onOk = () => {},
    onCancel = () => {},
  } = props;

  return (
    <div className="tip-page">
      <div className="content">
        <div className="box">
          <div className="title">{title}</div>
          <div className="descript">{des}</div>
        </div>
        <div className="footer">
          <div className="btn" onClick={onCancel}>
            {cancalText}
          </div>
          <div className="btn" onClick={onOk}>
            {okText}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Tip;
