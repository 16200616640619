/****** create file by codeGen ******/
import BaseService, { postFn } from '../magicPackages/BaseService';
import remote from '@/util/Remote';

class Service extends BaseService {
  constructor() {
    super('/activityCommonData', {
      copy: '/activityCommonData/copy.do',
      changeStatus: '/activityCommonData/changeStatus.do',
      $getCreaterList: '/activityDic/getCreaterList.do',
      $getDetail: (params) => {
        let { id, isPreview, ...restParams } = params || {};
        if (isPreview) {
          // 活动预览
          return remote.get(`/client/activityCommonData/get/${id}.do`, restParams);
        }
        return remote.get(`/client/activityCommonData/getOnline/${id}.do`, restParams);
      },
    });
  }

  getCopyDetail (params) {
    let { id, ...restParams } = params || {};
    return remote.get(`/activityCommonData/getCopyById/${id}.do`, restParams);
  }
}

export default new Service();
