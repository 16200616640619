import React, { useState, useEffect } from 'react';
import { browserTypeEnum } from '@/util/systemEnum';
import './index.scss';

const Player = (props) => {
  const { url, type, visible } = props;
  let isAndroid = window.navigator.userAgent.match(/android/gi);
  // 浏览器和app内支持视频自动播放
  let enableAutoPlay = Boolean(
    type == browserTypeEnum.BROWSER || type == browserTypeEnum.APP_BNQ || type == browserTypeEnum.APP_JU,
  );
  // ios 微信环境显示 controls
  let showControls = Boolean((type == browserTypeEnum.WX_BROWSER || type == browserTypeEnum.MINI) && !isAndroid);
  const [isPlaying, setPlayStatus] = useState(false);
  const [target, setTarget] = useState(null);
  const [status, setStatus] = useState(enableAutoPlay);

  // console.log('Player: ', isAndroid, type);
  // console.log('enable: ', enableAutoPlay, showControls);
  const onLoadedData = (e) => {
    setTarget(e.target);
  };

  const clickMaskEl = () => {
    setStatus(true);
    if (!isPlaying) {
      target?.play();
      setPlayStatus(true);
    }
  };

  useEffect(() => {
    if (url && visible && status && target) {
      target.current = 0;
      target.play();
      return;
    }
    target?.pause();
  }, [url, visible]);

  // 浏览器和app内，视频自动播放，不显示 controls
  // 微信环境，视频不支持自动播放，ios内显示controls，android 隐藏controls，自定义播放按钮
  return (
    <div
      className="video-mask"
      onClick={clickMaskEl}
      data-show={isAndroid && (type == browserTypeEnum.WX_BROWSER || type == browserTypeEnum.MINI) && !isPlaying}
    >
      <video
        preload="metadata"
        controls={showControls}
        playsInline
        // playsinline
        // webkit-playsinline
        // x5-playsinline
        // x5-video-player-type="h5-page"
        // x5-video-orientation="portrait"
        // x5-video-player-fullscreen="false"
        // x-webkit-airplay="allow"
        muted
        loop
        src={url}
        autoPlay={enableAutoPlay}
        className="banner-slider-item-video"
        onLoadedData={onLoadedData}
      ></video>
    </div>
  );
};

export default Player;
