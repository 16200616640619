import axios from 'axios';
import Config from '../../config';

class QuotationService {
  getHouseType(params) {
    let env = process.env.CURRENT_ENV == 'production' ? 'production' : 'test';
    const host = Config[env].ds;
    const url = `${host}/dsService/fastQuote/getHouseType.do`;
    return new Promise((resolve) => {
      axios({
        url,
        method: 'get',
        params,
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((data) => resolve(data.data))
        .catch((err) => resolve(null));
    });
  }

  isSelfCity(params) {
    let env = process.env.CURRENT_ENV == 'production' ? 'production' : 'test';
    const host = Config[env].himiko;
    const url = `${host}/xl-contentApi/selfSupportCityRecognition.do`;
    return new Promise((resolve) => {
      axios({
        url,
        method: 'get',
        params,
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((data) => resolve(data.data))
        .catch((err) => resolve(err));
    });
  }
}

const Service = new QuotationService();
export default Service;
