import React, { Component } from 'react';
import { message } from 'antd';
import { getPageContainer, reqLogin, navToApp, track } from '@/util/h5Helper';
import { browserTypeEnum } from '@/util/systemEnum';
import quotationService from '@/service/quotation';
import CusForm from './CusForm';
import SubmitBtn from './SubmitBtn';
import './index.scss';

class Quotation extends Component {
  constructor(props) {
    super(props);
    let isEditor = true;
    if (window.location.pathname.indexOf('/activity/detail') === -1) {
      isEditor = false;
    }
    const browserType = getPageContainer();
    this.formRef = React.createRef();
    this.areaRange = null;
    this.state = {
      browserType,
      isEditor,
      submitLoading: false,
    };
  }

  componentDidMount() {}

  getHouseType = async (area) => {
    return await quotationService.getHouseType({ area });
  };

  isSelfCity = async (cityCode) => {
    const res = await quotationService.isSelfCity({ cityCode });
    return res?.isSelfSupportCity;
  };

  getUserPhone = () => {
    return new Promise((resolve) => {
      reqLogin(true, function (data) {
        resolve(data);
      });
      setTimeout(() => {
        resolve(null);
      }, 2000);
    });
  };

  onChangeArea = async (size) => {
    const res = await this.getHouseType(size);
    if (res?.code === 0 && res?.result?.data) {
      this.areaRange = res.result.data;
    }
  };

  submit = async () => {
    const { isEditor, browserType } = this.state;
    if (isEditor) {
      message.warn('请在手机端操作');
      return;
    }
    if (browserType === browserTypeEnum.BROWSER) {
      navToApp();
      return;
    }
    const userInfo = await this.getUserPhone();

    const box = document.querySelector('.quotation-form-wrap');
    console.log('submit data:', this.formRef.current);
    const { areaSize, address, houseConfig } = this.formRef.current?.values || {};
    let v = parseInt(areaSize);
    if (!v || v < 10 || v > 4999) {
      box?.scrollIntoView();
      message.warn('房屋面积需要在10～4999之间');
      return;
    }
    // uni.showLoading({
    //   title: '加载中',
    //   mask: true,
    // });

    if (!this.areaRange) {
      await this.getHouseType(areaSize);
    }
    let params = {
      ...this.areaRange,
      ...address,
      ...houseConfig,
      bathroom: houseConfig.toiletNum,
      parlour: houseConfig.hallNum,
      area: areaSize,
      entrancePage: '',
      shopCode: '',
      shopName: '',
      phone: userInfo?.phone,
      token: userInfo?.apptoken,
    };
    delete params.id;
    delete params.updateTime;
    delete params.isOldHouse;
    delete params.updaterId;
    delete params.isDeleted;
    delete params.creatorId;
    console.log('__info__', params);
    sessionStorage.setItem('__info__', JSON.stringify(params));
    const isSelfSupport = await this.isSelfCity(address.cityCode);
    // uni.hideLoading();

    let env = process.env.CURRENT_ENV == 'production' ? 'production' : 'test';
    console.log('env', env);
    const prefix = `https://dhstatic.bthome.com/${env == 'production' ? 'prod' : 'test'}/web/quotationn/index.html#`;
    let redirectUrl = prefix + '/ordering?channelCode=PChannel1036&environment=test&newapp=1&toolId=F027';
    if (isSelfSupport) {
      redirectUrl = prefix + '/detail?channelCode=PChannel1036&environment=test&newapp=1&toolId=F027';
    }
    console.log('redirectUrl', redirectUrl);
    // window.location.href = redirectUrl;
  };

  render() {
    const { isEditor, submitLoading } = this.state;
    const { componentUuid, btnTxt, btnColor, btnBg, bgImage, position } = this.props;

    let btnStyle = {};
    if (btnColor) {
      btnStyle.color = btnColor;
    }
    if (btnBg) {
      btnStyle.backgroundColor = btnBg;
    }
    // console.log('quotation render props:', this.props);
    return (
      <div
        className="quotation-com"
        id={componentUuid}
        style={{ backgroundImage: `url(${bgImage})` }}
        data-type={position}
      >
        {isEditor && !btnTxt ? (
          <div className="quotation-tip">
            <div>报价器</div>
            <div>请上传图片</div>
          </div>
        ) : (
          <>
            <CusForm ref={this.formRef} position={position} onChangeArea={this.onChangeArea} />
            <SubmitBtn
              isEditor={isEditor}
              type={position}
              btnStyle={btnStyle}
              btnTxt={btnTxt}
              onSubmit={this.submit}
              loading={submitLoading}
            />
          </>
        )}
      </div>
    );
  }
}

Quotation.getDefaultValue = () => {
  return {
    bgImage: '',
    btnTxt: '',
    btnColor: '',
    btnBg: '',
    position: 'float',
  };
};

Quotation.getEditData = () => ({
  data: {
    bgImage: {
      label: '报价器背景图片',
      control: 'upload',
      controlProps: {
        accept: '.jpg,.jpeg,.png',
        maxSize: '1M',
      },
      renderAfter: () => {
        return <span style={{ color: ' rgba(0,0,0,0.45)' }}>支持拓展名：.png .jpg .jpeg，建议宽度750px(小于1M)</span>;
      },
    },
    btnTxt: {
      label: '提交按钮文案',
      controlProps: {
        maxLength: 20,
      },
    },
    btnColor: {
      label: '按钮文字颜色',
      control: 'colorPicker',
    },
    btnBg: {
      label: '按钮颜色',
      control: 'colorPicker',
    },
    position: {
      label: '按钮位置',
      control: 'radioGroup',
      getInitialValue: 'float',
      options: () => [
        { label: '浮动', value: 'float' },
        { label: '固定', value: 'fixed' },
      ],
    },
  },
});

Quotation.validate = (props) => {
  // console.log('Quotation validate', props);
  const { bgImage, btnTxt, btnColor, btnBg, position } = props;
  if (!bgImage) return '请设置报价器背景图片';
  if (!btnTxt) return '请设置提交按钮文案';
  if (!btnColor) return '请设置按钮文字颜色';
  if (!btnBg) return '请设置按钮颜色';
  if (!position) return '请设置按钮位置';
  return '';
};

export default Quotation;
