import React from 'react';
import { Redirect } from 'react-router-dom';

import { getQueryString } from '@/util/common';

export default () => {
    let accessToken = getQueryString('accessToken');
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        // 兼容内容平台的登录登录
        document.cookie = `sessionToken=${accessToken}`;
    }

    return (
        <Redirect
            from="/sesameLogin"
            to={{
                pathname: '/',
                // search: `accessToken=${accessToken}`,
            }}
        />
    );
};
