import React, { Component } from 'react';
import Slider from 'react-slick';
import Edit from './Edit';
import './index.scss';

class Anchor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      selectedId: null,
    };
  }

  componentDidMount() {}

  handleClickItem(item) {
    const { position } = this.props;
    this.setState({ selectedId: item.id });
    let { origin, pathname, search } = window.location;
    if (pathname.indexOf('/activity/detail') !== -1) return;
    // window.location.href = origin + pathname + search + '#' + item.comId;
    let ele = document.getElementById(item.comId);
    if (!ele) {
      return;
    }

    ele.scrollIntoView();

    if (position === 'fixedTop') {
      setTimeout(() => {
        let currentTop = document.documentElement.scrollTop;
        let xWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        document.documentElement.scrollTop = currentTop - (48 * xWidth / 375);
      }, 5);
    }
    // history.pushState(null, null, origin + pathname + search + '#' + item.comId);
  }

  render() {
    const { selectedId } = this.state;
    // console.log('anchor render props:', this.props);
    const { componentUuid, defaultBg, selectedBg, defaultColor, selectedColor, anchorList, position } = this.props;
    let showNum = anchorList.length > 4 ? 4 : anchorList.length;
    const settings = {
      initialSlide: 0,
      dots: false,
      arrows: false,
      infinite: false,
      speed: 0.5,
      slidesToShow: showNum,
      slidesToScroll: 1,
      autoplay: false,
    };
    const selectedStyle = {
      backgroundColor: selectedBg,
      color: selectedColor,
    };
    let classes = 'anchor';
    if (position === 'fixedTop') {
      classes = 'anchor fixed-anchor';
    }

    return (
      <div className={classes} id={componentUuid} style={anchorList.length > 0 ? { backgroundColor: defaultBg } : {}}>
        {anchorList.length > 0 ? (
          <>
            <Slider {...settings} className="anchor-slider">
              {anchorList.map((item) => (
                <div key={item.id}>
                  <div
                    className={item.id === selectedId ? 'anchor-slider-item selected' : 'anchor-slider-item'}
                    style={item.id === selectedId ? selectedStyle : { color: defaultColor }}
                    onClick={() => this.handleClickItem(item)}
                  >
                    {item.name}
                  </div>
                </div>
              ))}
            </Slider>
          </>
        ) : (
          <div className="tip">请设置锚点</div>
        )}
      </div>
    );
  }
}

Anchor.getDefaultValue = () => {
  return {
    defaultBg: '',
    selectedBg: '',
    defaultColor: '',
    selectedColor: '',
    position: 'default',
    anchorList: [],
  };
};

Anchor.getEditData = () => ({
  data: {
    defaultBg: {
      label: '锚点背景颜色',
      control: 'colorPicker',
    },
    selectedBg: {
      label: '锚点选中背景颜色',
      control: 'colorPicker',
    },
    defaultColor: {
      label: '锚点文字颜色',
      control: 'colorPicker',
    },
    selectedColor: {
      label: '锚点选中文字颜色',
      control: 'colorPicker',
    },
    position: {
      label: '位置设置',
      control: 'radioGroup',
      options: () => [
        { label: '固定位置', value: 'default' },
        { label: '保持页面顶端', value: 'fixedTop' },
      ],
    },
    anchorList: {
      label: '',
      control: Edit,
    },
  },
  methods: {},
});

export const NUM_OPTIONS = {
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  7: '七',
  8: '八',
  9: '九',
};

Anchor.validate = (props) => {
  // console.log('Anchor validate: ', props);
  const { anchorList, defaultBg, selectedBg, defaultColor, selectedColor } = props;
  let errMsg = '';
  if (!defaultBg) {
    return '请设置锚点背景颜色';
  }
  if (!selectedBg) {
    return '请设置锚点选中背景颜色';
  }
  if (!defaultColor) {
    return '请设置锚点文字颜色';
  }
  if (!selectedColor) {
    return '请设置锚点选中文字颜色';
  }
  if (anchorList.length < 2) {
    return '请设置锚点，至少两个';
  }
  for (let i = 0; i < anchorList.length; i++) {
    let item = anchorList[i];
    if (!item.name) {
      errMsg = `请输入第${NUM_OPTIONS[i + 1]}个锚点名称`;
      break;
    }
    if (!item.comId) {
      errMsg = `请设置第${NUM_OPTIONS[i + 1]}个锚点链接模块`;
      break;
    }
  }
  return errMsg;
};

export default Anchor;
