import React, { useEffect, useMemo, useState, Component } from 'react';
import { TreeSelect } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import Service from './Service';

export default class ShopSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tree: [],
    };
  }

  componentDidMount() {
    this.getShopList();
  }

  selectOnChane = (v) => {
    const { onChange } = this.props;
    onChange?.(v);
  };

  getShopList = async () => {
    const result = await Service.getMcmShopList();
    const { code, data } = result;
    if (code === 0) {
      let tree = (data || []).map((item) => {
        let { children, esShopDto, ...rest } = item;

        return {
          ...rest,
          children,
        };
      });
      this.setState({
        tree,
      });
    }
  };

  render() {
    const { value, isHideAllStore } = this.props;
    const treeData = isHideAllStore
      ? this.state.tree
      : [
          {
            title: '全部门店',
            key: '0',
            value: '0',
          },
          ...this.state.tree,
        ];
    const tProps = {
      placeholder: '请选择门店',
      treeData,
      value,
      getPopupContainer: (node) => node.parentNode,
      dropdownStyle: { maxHeight: 300, overflow: 'auto', maxWidth: 400 },
      onChange: this.selectOnChane,
      treeCheckable: true,
      treeCheckStrictly: true,
      maxTagCount: 10,
      treeDataSimpleMode: false,
      treeNodeFilterProp: 'title',
      style: {
        width: '100%',
      },
    };

    return <TreeSelect {...tProps} />;
  }
}
