import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

const columns = ({ pageSize, current }) => {
  let arr = [
    {
      title: '序号',
      dataIndex: 'number',
      render: (text, record, index) => {
        return (current - 1) * pageSize + index + 1;
      },
    },
    { title: 'SAP商品编码', dataIndex: 'sapCode' },
    { title: '商品名称', dataIndex: 'productName' },
    {
      title: '商品图片',
      dataIndex: 'imgUrl',
      render: (value) => {
        if (!value) '暂无图片';
        return <img src={value} style={{ maxWidth: 70, maxHeight: 34 }} alt="" />;
      },
    },
    { title: '基础类目', dataIndex: 'baseCategory' },
    { title: '品牌中文名称', dataIndex: 'brandNameZh' },
  ];
  return arr;
};

const setPaginationOptions = (pagination = {}) => {
  const { current = 1, pageSize = 10 } = pagination;
  return {
    ...pagination,
    showQuickJumper: true,
    showSizeChanger: true,
    showTotal: (total) => {
      return (
        <div>
          共 {total} 条记录，第 {current} / {Math.ceil(total / pageSize)} 页
        </div>
      );
    },
    pageSizeOptions: ['10', '25', '50', '100'],
  };
};

const MultiSelectTable = (props) => {
  const { data, pagination, ...rest } = props;
  const { current, pageSize } = pagination;
  return (
    <Table
      style={{ marginTop: 20 }}
      rowKey="sapCode"
      dataSource={data}
      columns={columns({
        pageSize,
        current,
      })}
      pagination={setPaginationOptions(pagination)}
      {...rest}
    />
  );
};

MultiSelectTable.propTypes = {
  data: PropTypes.array,
  pagination: PropTypes.object,
};

MultiSelectTable.defaultProps = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
};

export default MultiSelectTable;
