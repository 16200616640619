import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Icon, Select, Input } from 'antd';
import EditContext from '@/util/EditContext';
import './index.scss';

const { Option } = Select;

const NUM_OPTIONS = {
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  7: '七',
  8: '八',
  9: '九',
};

class AnchorList extends Component {
  constructor(props) {
    super(props);
  }

  handleChange = (list, item, itemIndex, key, evt) => {
    const { onChange } = this.props;

    if (key === 'name') {
      item[key] = evt.target.value;
    } else if (key === 'comId') {
      item[key] = evt;
    }
    list[itemIndex] = item;
    onChange?.(list);
  };

  colorReverse = (oColor = '') => {
    let oldColor = '0x' + oColor.replace(/#/g, '');
    let str = '000000' + (0xffffff - oldColor).toString(16);
    return '#' + str.substring(str.length - 6, str.length);
  };

  removeSchedule = (list, index) => {
    const { onChange } = this.props;
    list.splice(index, 1);
    onChange?.(list);
  };

  addSchedule = (curList) => {
    const { onChange } = this.props;
    curList.push({
      id: uuidv4(),
      name: null,
      comId: null,
    });
    onChange?.(curList);
  };

  render() {
    let { value } = this.props;
    let list = [];
    if (Array.isArray(value) && value.length > 0) {
      list = value;
    }
    if (list.length < 1) {
      list = [
        {
          id: uuidv4(),
          name: '',
          comId: null,
        },
        {
          id: uuidv4(),
          name: null,
          comId: null,
        },
      ];
    }
    let { comList } = this.context;
    if (!Array.isArray(comList)) {
      comList = [];
    }
    return (
      <div className="anchorListCom">
        {list.map((schedule, index) => (
          <div className="anchorListCom-block" key={schedule.id}>
            <div className="anchorListCom-block-head">
              锚点{NUM_OPTIONS[index + 1]}
              {list.length > 2 ? (
                <div className="anchorListCom-block-head-delete" onClick={() => this.removeSchedule(list, index)}>
                  <Icon type="delete" />
                </div>
              ) : null}
            </div>
            <Input
              maxLength={10}
              placeholder="请输入锚点名称，不超过10个字"
              value={schedule.name}
              onChange={(evt) => this.handleChange(list, schedule, index, 'name', evt)}
            />
            <Select
              placeholder="请选择模块"
              style={{ marginTop: 8 }}
              value={schedule.comId || undefined}
              onChange={(evt) => this.handleChange(list, schedule, index, 'comId', evt)}
            >
              {comList
                .filter((v) => v.componentUuid)
                .map((v) => (
                  <Option key={v.$id} value={v.componentUuid}>
                    {v.$id} {v.$name}
                  </Option>
                ))}
            </Select>
          </div>
        ))}
        {list.length < 9 ? (
          <Button type="dashed" icon="plus" className="anchorListCom-add" onClick={() => this.addSchedule(list)}>
            新增锚点
          </Button>
        ) : null}
      </div>
    );
  }
}

AnchorList.contextType = EditContext;

export default AnchorList;
