import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import SelectTypeModal from '../SelectTypeModal';
import SelectCityModal from '../SelectCityModal';
import './index.scss';
import { message } from 'antd';

const initAddress = () => ({
  provinceCode: '',
  provinceName: '',
  cityCode: '310100',
  cityName: '上海',
  countyName: '',
  district: '',
  districtName: '',
});

const initHouseConfig = () => ({
  roomNum: 2,
  hallNum: 1,
  kitchenNum: 1,
  balconyNum: 1,
  toiletNum: 2,
});

const CusForm = (props, ref) => {
  const [areaSize, setAreaSize] = useState(90);
  const [address, setAddress] = useState(() => initAddress());
  const [houseConfig, setHouseConfig] = useState(() => initHouseConfig());
  const [typeModalVisible, setTypeModalVisible] = useState(false);
  const [cityModalVisible, setCityModalVisible] = useState(false);
  const { position, onChangeArea } = props;

  const togglePageScroll = (enable) => {
    const container = document.querySelector('.preview-canvas');
    if (!container) return;
    if (enable) {
      container.style.overflow = 'hidden auto';
    } else {
      container.style.overflow = 'hidden';
    }
  };

  const clickHouseType = () => {
    setTypeModalVisible(true);
    togglePageScroll(false);
  };

  const clickHouseCity = () => {
    setCityModalVisible(true);
    togglePageScroll(false);
  };

  const getHouseType = () => {
    const { roomNum, hallNum, kitchenNum, balconyNum, toiletNum } = houseConfig;
    let str = '';
    if (Number(roomNum) > 0) str += roomNum + '室 ';
    if (Number(hallNum) > 0) str += hallNum + '厅 ';
    if (Number(kitchenNum) > 0) str += kitchenNum + '厨 ';
    if (Number(toiletNum) > 0) str += toiletNum + '卫 ';
    if (Number(balconyNum) > 0) str += balconyNum + '阳台';
    return str;
  };

  useImperativeHandle(ref, () => ({
    values: {
      areaSize,
      address,
      houseConfig,
    },
  }));

  return (
    <>
      <div className="quotation-form-wrap" ref={ref} data-type={position}>
        <div className="form-item">
          <span className="label">房屋面积：</span>
          <input
            type="number"
            placeholder="请输入房屋面积"
            value={areaSize}
            onChange={(e) => setAreaSize(e.target.value)}
            onBlur={(e) => {
              setTimeout(() => {
                console.log('blur', areaSize);
                let v = parseInt(areaSize);
                if (!v || v < 10 || v > 4999) {
                  message.warn('请输入纯数字，面积需要在10～4999之间');
                  setAreaSize(90);
                  onChangeArea?.(90);
                } else {
                  setAreaSize(v);
                  onChangeArea?.(v);
                }
              }, 500);
            }}
          />
        </div>
        <div className="form-item" onClick={clickHouseType}>
          <span className="label">房屋类型：</span>
          <input placeholder="请选择房屋类型" value={getHouseType()} disabled />
        </div>
        <div className="form-item" onClick={clickHouseCity}>
          <span className="label">房屋城市：</span>
          <input placeholder="请选择房屋城市" value={address.provinceName + ' ' + address.cityName} disabled />
        </div>
      </div>
      <SelectTypeModal
        visible={typeModalVisible}
        houseConfig={houseConfig}
        onConfirm={(data) => setHouseConfig(data)}
        onClose={() => {
          setTypeModalVisible(false);
          togglePageScroll(true);
        }}
      />
      <SelectCityModal
        visible={cityModalVisible}
        cityCode={address.cityCode}
        onChange={(data) => setAddress(data)}
        onClose={() => {
          setCityModalVisible(false);
          togglePageScroll(true);
        }}
      />
    </>
  );
};

export default forwardRef(CusForm);
