import React, { useEffect, Component } from 'react';
import { Button, Input, message } from 'antd';
import tryOpen from '@/util/tryOpen';
import './index.scss';

class ApplyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleOrder = () => {
    const { __extraInfo, onReqLogin } = this.props;
    if (tryOpen()) {
      return;
    }

    if (__extraInfo?.apptoken) {
      message.success('领取成功');
    } else {
      onReqLogin && onReqLogin(true);
    }
  };

  render() {
    const { __extraInfo, componentUuid } = this.props;
    return (
      <div className="applyFromCom" id={componentUuid}>
        <div className="applyFromCom-item">
          <span className="applyFromCom-item__label">名称</span>
          <Input className="applyFromCom-item__control" />
        </div>
        <div className="applyFromCom-item">
          <span className="applyFromCom-item__label">手机号</span>
          <Input className="applyFromCom-item__control" value={__extraInfo?.phone} />
        </div>
        <div className="btn-submit applyFromCom-item" onClick={this.handleOrder}>
          {__extraInfo?.apptoken ? '领取' : '登录'}
        </div>
      </div>
    );
  }
}

ApplyForm.getEditData = () => ({
  data: {},
  methods: {},
});

ApplyForm.getDefaultValue = () => {
  return {};
};

export default ApplyForm;
