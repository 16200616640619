const config = {
  routeType: 'browser',
  menuPosition: 'left',
  openPaths: ['/', '/403', '/404', '/500', '/welcome'],
  hasPermission: false,
  hasBreadcrumb: true,
  hasLogin: true,
  projectName: '管理后台',
  menus: [
    {
      name: '活动管理',
      key: 'activityManage',
      icon: 'windows',
      children: [
        {
          name: '活动数据看板',
          key: 'acitvityMap',
          path: '/acitvityMap/list',
          icon: 'home',
        },
        {
          name: '专题管理',
          key: 'specialMangage',
          path: '/acitvityManage/specialMangage',
          icon: 'home',
        },
        {
          name: '留资活动',
          key: 'consultConfig',
          path: '/consult',
          icon: 'setting',
        },
        {
          name: '家装活动配置',
          key: 'acitvityConfig',
          path: '/acitvityconfig',
          icon: 'setting',
        },
        {
          name: '家装活动参与记录',
          key: 'activityRecord',
          path: '/activityRecord',
          icon: 'setting',
        },
        {
          name: '优惠券设定',
          key: 'couponConfig',
          path: '/couponConfig',
          icon: 'setting',
        },
        {
          name: '优惠券订单',
          key: 'couponOrder',
          path: '/couponOrder',
          icon: 'setting',
        },
        {
          name: '实用工具',
          key: 'toolSet',
          icon: 'setting',
          children: [
            {
              name: '二维码生成工具',
              key: 'qrcodeTool',
              path: '/qrcodeTool',
              icon: 'qrcode'
            },
            {
              name: '视频链接工具',
              key: 'ossTool',
              path: '/ossTool',
              icon: 'link'
            },
            {
              name: '抽奖概率设置',
              key: 'probabilitySetting',
              path: '/probabilitySetting',
              icon: 'percentage',
            },
          ],
        },
      ],
    },
  ],
};

module.exports = config;
