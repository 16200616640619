import { parse, stringify } from 'qs';
import moment from 'moment';

/**
 * 将api返回结果中的分页和数据提取出来
 *
 * @export
 * @param {object} [response={}]
 * @returns {object} {list, pagination}
 */
export function getFormatRes(response = {}) {
    const { code, data, meta, success } = response;

    // 请求失败或返回data为null时，返回默认空值
    if (code >= 400 || !data || !success) {
        return {
            list: [],
            pagination: {},
            // meta: [],
        };
    }

    const { list, pageNum, pageSize, total } = data;

    const res = {
        list,
        pagination: {
            current: pageNum,
            pageSize,
            total,
        },
    };

    if (meta) {
        res.meta = meta;
    }

    return res;
}

/**
 * 发票模块数据特殊处理
 *
 * @export
 * @param {object} [response={}]
 * @returns {object} {list, pagination}
 */
export function getFormatInvoce(response = {}) {
    const { code, result, msg } = response;

    if (code >= 400 || !msg) {
        return {
            list: [],
            page: {},
            meta: [],
        };
    }

    const { data, page } = result;

    const { curPage, pageSize, totalNum } = page;

    return {
        list: data,
        page: {
            current: curPage,
            pageSize,
            total: totalNum,
        },
    };
}

/**
 * 返回图片文件的宽高
 *
 * @export
 * @param {file} file
 * @returns {promise} {widht, height}
 */
export function getImgInfo(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            const image = new Image();
            image.onload = () => {
                resolve({
                    widht: image.width,
                    height: image.height,
                });
            };

            image.src = e.target.result;
        };
    });
}

/**
 * 解析出url的请求参数
 *
 * @export
 * @param {string} search
 * @returns {object}
 */
export function getPageQuery(search) {
    const str = search || window.location.href;

    return parse(str.split('?')[1]);
}

/**
 * 根据请求参数生成url
 *
 * @export
 * @param {string} [path='']
 * @param {object} [query={}]
 * @returns {string}
 */
export function getQueryPath(path = '', query = {}) {
    const search = stringify(query);
    if (search.length) {
        return `${path}?${search}`;
    }
    return path;
}

export function formatFilelist(urls) {
    let res;

    if (urls && typeof urls === 'string') {
        res = [
            {
                uid: -1,
                name: 'image.png',
                status: 'done',
                url: urls,
            },
        ];
    } else if (Array.isArray(urls) && urls.length) {
        let uid = 0;
        res = urls.map((itme) => {
            uid -= 1;

            return {
                uid,
                name: 'image.png',
                status: 'done',
                url: itme,
            };
        });
    }

    // console.log('formatFilelist res: ', res);
    return res;
}

/**
 *  UploadImage组件的fileList格式转换方法
 *  将Upload组件结果fileList转换成url字符串或数组
 *
 * @export
 * @param {array} imgArray
 * @param {string} type 转换结果的类型，可选string和array
 * @returns
 */
export function revFormatFilelist(imgArray, type) {
    if (!Array.isArray(imgArray) || imgArray.length === 0) {
        return null;
    }

    const res = imgArray.map((item) => {
        return item.url;
    });

    if (type === 'string') {
        return res.join(',');
    }

    return res;
}

/**
 * 格式化输入的数字, 保留两位小数的正数
 *
 * @export
 * @param {string} value
 * @returns {string}
 */
export function foramtNumber(value) {
    let res = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
    res = res.replace(/^\./g, ''); // 必须保证第一个为数字而不是.
    res = res.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
    res = res.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
    res = res.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入两个小数
    if (res.indexOf('.') < 0 && res !== '' && res !== '0') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        res = parseFloat(res);
    }

    return res;
}

/**
 * 计算两个时间差
 *
 * @export
 * @param {string} a, b, type
 * @returns {string}
 */
export function timeDiff(a, b, type = 'days', decimal = true) {
    if (!a || !b) {
        return undefined;
    }

    const A = moment(a);
    const B = moment(b);
    const diff = A.diff(B, type, decimal);

    return Math.abs(diff);
}

export function getQueryString(param) {
    const currentUrl = window.location.href; // 获取当前链接
    let arr = currentUrl.split('?'); // 分割域名和参数界限
    if (arr.length > 1) {
        arr = arr[1].split('&'); // 分割参数
        for (let i = 0; i < arr.length; i++) {
            const tem = arr[i].split('='); // 分割参数名和参数内容
            if (tem[0] === param) {
                return tem[1];
            }
        }
        return null;
    }
    return null;
}
