import React, { Component } from 'react';
import { Button } from 'antd';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import SelectGoodsDrawer from '../SelectGoodsDrawer';
import './index.scss';

@inject((store) => {
  return {
    currentGoods: store.activityStore.currentGoods,
  };
})
@observer
class SelectGoods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  handleEdit = () => {
    this.setState({ visible: true });
  };

  closeDrawer = () => {
    this.setState({ visible: false });
  };

  onConfirmGoods = (goodsNum) => {
    this.setState({ visible: false });
    this.props.onChange?.({ num: goodsNum, t: Date.now() });
  };

  render() {
    const { visible } = this.state;
    const { currentGoods } = this.props;
    const list = toJS(currentGoods);
    return (
      <div>
        <div style={{ marginBottom: 8, color: 'rgba(0, 0, 0, 0.85)' }}>展示商品选择</div>
        {list.length > 0 ? (
          <Button icon="edit" style={{ width: '100%' }} onClick={this.handleEdit}>
            已添加 {list.length} 件活动商品
          </Button>
        ) : (
          <Button type="dashed" icon="plus" style={{ width: '100%' }} onClick={this.handleEdit}>
            添加活动商品
          </Button>
        )}
        <SelectGoodsDrawer
          visible={visible}
          onCancel={this.closeDrawer}
          onClose={this.closeDrawer}
          onOk={this.onConfirmGoods}
        />
      </div>
    );
  }
}

export default SelectGoods;
