import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Layout, Breadcrumb } from 'antd';
import _ from 'lodash';
import Router from '../../router/RouterGenerator';
import RouterConfig from '../../router/RouterConfig';
import Config from '../../../config/config';

const { Content } = Layout;

class Contents extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  static defaultProps = {
    location: {},
  };

  constructor(props) {
    super(props);
    if (Config.hasBreadcrumb) {
      this.breadcrumbNameMap = {};
      RouterConfig.forEach((item) => {
        if (item.text && item.path) {
          this.breadcrumbNameMap[item.path] = item.text;
          if (item.breadParent) {
            this.breadcrumbNameMap[item.path] = [
              ...item.breadParent,
              {
                url: item.path,
                name: item.text,
              },
            ];
          }
        }
      });
    }
  }

  render() {
    const {
      location = {
        pathname: '',
      },
    } = this.props;
    let contentPadding = '20px';
    let contentMargin = '0';
    let pathSnippets = [];
    let extraBreadcrumbItems = [];
    if (location.pathname && ['/welcome', '/permission', '/login'].indexOf(location.pathname) > -1) {
      contentPadding = 0;
      contentMargin = 0;
    }
    if (Config.hasBreadcrumb) {
      pathSnippets = location.pathname.split('/').filter((i) => {
        return i;
      });
      extraBreadcrumbItems = pathSnippets.map((__, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        let xbread = this.breadcrumbNameMap[url];
        if (!xbread) {
          return '';
        }
        if (_.isArray(xbread)) {
          return xbread.map((item) => (
            <Breadcrumb.Item key={item.url + item.name}>
              {item.url ? <Link to={item.url}>{item.name}</Link> : <span>{item.name}</span>}
            </Breadcrumb.Item>
          ));
        }

        return (
          <Breadcrumb.Item key={url}>
            <Link to={url}>{xbread}</Link>
          </Breadcrumb.Item>
        );
      });
    }
    return (
      <Fragment>
        {Config.hasBreadcrumb && !window.__POWERED_BY_QIANKUN__ ? (
          <Breadcrumb style={{ padding: '20px 10px', background: '#fff' }}>{extraBreadcrumbItems}</Breadcrumb>
        ) : null}
        <Content
          style={{
            margin: contentMargin,
            padding: contentPadding,
            background: '#eee',
            minHeight: 'auto',
            position: 'relative',
          }}
        >
          {Router.genRouter()}
        </Content>
      </Fragment>
    );
  }
}

export default withRouter(Contents);
