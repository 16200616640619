export default [
  {
    $name: '标题', // 左侧组件列表显示的名称
    $icon: 'title',
    $component: 'pageTitle', // 组件
    designWidth: 375,
    designHeight: 40,
    $props: {},
  },
  {
    $id: '',
    $name: 'banner', // 左侧组件列表显示的名称
    $component: 'banner', // 组件
    $icon: 'banner',
    designWidth: 375,
    designHeight: 230,
  },
  {
    $id: '',
    $name: '锚点', // 左侧组件列表显示的名称
    $component: 'anchor', // 组件
    $icon: 'anchor',
    isOnly: true,
    designWidth: 375,
    designHeight: 47,
  },
  {
    $id: '',
    $name: '商品', // 左侧组件列表显示的名称
    $component: 'goods', // 组件
    $icon: 'goods',
    designWidth: 375,
    designHeight: 170,
  },
  {
    $id: '',
    $name: '留资工具', // 左侧组件列表显示的名称
    $component: 'appointment', // 组件
    $icon: 'order',
    isOnly: true,
    designWidth: 375,
    designHeight: 138,
  },
  {
    $id: '',
    $name: '橱窗',
    $component: 'shopWindow',
    $icon: 'window',
    designWidth: 375,
    designHeight: 138,
  },
  {
    $id: '',
    $name: '优惠券',
    $component: 'coupon',
    $icon: 'coupon',
    designWidth: 375,
    designHeight: 138,
  },
  {
    $id: '',
    $name: '搜索',
    $component: 'search',
    $icon: 'search',
    isOnly: true,
    designWidth: 375,
    designHeight: 56,
  },
  // {
  //   $id: '',
  //   $name: '报价器',
  //   $component: 'quotation',
  //   $icon: 'quotation',
  //   isOnly: true,
  //   designWidth: 375,
  //   designHeight: 138,
  // },
  {
    $id: '',
    $name: '倒计时',
    $component: 'timer',
    $icon: 'timer',
    isOnly: true,
    designWidth: 375,
    designHeight: 48,
  },
  {
    $id: '',
    $name: '容器',
    $component: 'zone',
    $icon: 'zone',
    designWidth: 375,
    designHeight: 48,
  },
];
