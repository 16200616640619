import React, { useEffect, useState } from 'react';
import './index.scss';

const SelectTypeModal = (props) => {
  const [selectedData, setSelectedData] = useState({});

  const config = [
    { num: 10, type: '室', fieldName: 'roomNum' },
    { num: 5, type: '厅', fieldName: 'hallNum' },
    { num: 5, type: '厨', fieldName: 'kitchenNum' },
    { num: 5, type: '卫', fieldName: 'toiletNum' },
    { num: 5, type: '阳台', fieldName: 'balconyNum' },
  ];
  const { visible, houseConfig, onConfirm, onClose } = props;
  let scrollTop = 0;
  let timer = null;

  const init = () => {};

  const handleConfirm = () => {
    onConfirm?.(selectedData);
    onClose?.();
  };

  const adjustPosition = (target, scrollTop) => {
    let type = target.dataset.type;
    let columnItem = target.querySelector('.item');
    let num = Math.round(scrollTop / columnItem.clientHeight);
    let top = num * columnItem.clientHeight;
    let tempData = {
      ...houseConfig,
      ...selectedData,
      [type]: num,
    };
    if (type === 'roomNum') {
      ++tempData[type];
    }
    setSelectedData(tempData);
    setTimeout(() => {
      target.scroll({ top, behavior: 'smooth' });
    }, 50);
  };

  const handleScroll = (e) => {
    scrollTop = e.target.scrollTop;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      adjustPosition(e.target, scrollTop);
    }, 100);
  };

  useEffect(() => {
    init();
    let columns = document.querySelectorAll('.modal-content .column');
    columns.forEach((column) => {
      column.addEventListener('scroll', handleScroll);
    });
    return () => {
      columns.forEach((column) => {
        column?.removeEventListener('scroll', handleScroll);
      });
    };
  }, []);

  useEffect(() => {
    if (!visible || !houseConfig) return;
    let columns = document.querySelectorAll('.modal-content .column');
    let item = document.querySelector('.modal-content .column .item');
    let itemHeight = item.clientHeight;
    columns.forEach((column) => {
      let type = column.dataset.type;
      let top = itemHeight * houseConfig[type];
      if (type === 'roomNum') {
        top = itemHeight * (houseConfig[type] - 1);
      }
      column.scroll({ top, behavior: 'smooth' });
    });
  }, [visible, houseConfig]);

  return (
    <div className="select-type-modal-wrap" onClick={() => onClose?.()} style={{ display: visible ? 'block' : 'none' }}>
      <div className="select-type-modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-top">
          <span className="modal-btn cancel" onClick={() => onClose?.()}>
            取消
          </span>
          <span className="modal-btn confirm" onClick={handleConfirm}>
            确认
          </span>
        </div>
        <div className="modal-content">
          {config.map((item, index) => {
            return (
              <div className="column" key={item.type} data-type={item.fieldName}>
                {Array.from({ length: 2 }).map((v, k) => (
                  <div key={k} className="item"></div>
                ))}
                {Array.from({ length: item.num }).map((v, k) => (
                  <div key={k} className="item">
                    {index == 0 ? k + 1 : k}
                    {item.type}
                  </div>
                ))}
                {Array.from({ length: 3 }).map((v, k) => (
                  <div key={k} className="item"></div>
                ))}
              </div>
            );
          })}
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    </div>
  );
};

export default SelectTypeModal;
