import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { InputNumber, message } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import WindowConfig from './components/WindowConfig';
import { getPageContainer, toGoodsDetail, toDesignerDetail, navToApp, track } from '@/util/h5Helper';
import { browserTypeEnum } from '@/util/systemEnum';
import Service from '@/service/goods';
import tryOpen from '@/util/tryOpen';
import parseLink from '../../libs/parseLink';

import './index.scss';
import { TreeIterator } from '@/util';

@inject(({ activityStore: { getCityCode } }) => {
  return {
    getCityCode,
  };
})
@observer
class ShopWindowCom extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  pageDataList = () => {
    const { colCount, rowCount, windowConfig } = this.props;
    let temp = [];

    if (!_.isEmpty(windowConfig)) {
      temp = windowConfig;
    } else if (colCount && rowCount) {
      temp = new Array(colCount * rowCount).fill('').map((item, index) => ({
        id: index,
      }));
    }
    let xcol = colCount || 1;
    let xrow = rowCount || 1;
    let itemCounts = xcol * xrow;

    let pageCount = Math.ceil(temp.length / itemCounts);
    let ret = [];

    for (let i = 0; i < pageCount; i++) {
      ret.push(temp.slice(i * itemCounts, i * itemCounts + xcol * xrow));
    }

    return ret;
  };

  handleItemClick = async (item) => {
    const { componentType, id } = this.props;
    const trackEvent = { code: 'click', componentType, componentCode: id };
    const { linkType, linkInfo } = item;
    const type = getPageContainer(false);
    if (linkType === 'none') {
      track(trackEvent);
      return;
    }
    if (linkType === 'goods') {
      // 跳转商品详情页
      track(trackEvent, { itemId: linkInfo });
      if (type === browserTypeEnum.BROWSER) {
        navToApp();
        return;
      }
      let code = await this.props.getCityCode();
      let res = await Service.queryRedirectParams({ cityCode: code || '310100', sapSku: item.linkInfo });
      if (res?.code === 0 && res?.success && res?.data) {
        toGoodsDetail({ itemId: res.data.itemId, skuId: res.data.skuId }, { componentType, componentCode: id }); // test
      } else {
        message.error('查询跳转信息失败');
      }

      return;
    }
    if (linkType === 'specialTopic') {
      track(trackEvent, { activityCode: linkInfo });
      let res = await Service.queryActivityId(linkInfo);
      if (res?.code === 0 && res?.success && res?.data) {
        window.location.href = window.origin + '/activity/h5?activityId=' + res.data?.id;
      } else {
        message.error('查询跳转信息失败');
      }
      return;
    }
    if (linkType === 'designer') {
      track(trackEvent);
      toDesignerDetail(linkInfo);
      return;
    }
    if (linkType === 'link') {
      track(trackEvent, { link: linkInfo });
      window.location.href = parseLink(linkInfo || '');
    }

    if (linkType == 'coupon') {
      track(trackEvent, { couponId: linkInfo });
      const { __extraInfo, componentUuid, onReqLogin } = this.props;
      if (tryOpen()) {
        return;
      }
      if (!__extraInfo?.apptoken) {
        // 拉起登录
        onReqLogin(true);
        return;
      }

      let res = await Service.receiveCoupon({
        activityId: __extraInfo?.activityId,
        componentCode: id,
        componentUuid,
        couponCode: linkInfo,
        token: __extraInfo?.apptoken,
      });
      if (res?.code === 0 && res?.success && res?.data) {
        message.success('领券成功');
      } else {
        message.error(res?.message || '领券失败');
      }
    }
  };

  render() {
    const { colCount, bgColor, componentUuid, borderWidth, isAutoHeight, isFixBottom, comList } = this.props;
    let dataList = this.pageDataList();

    let xStyle = {};
    if (bgColor) {
      xStyle.background = bgColor;
    }
    let itemWidth = 100 / colCount + 'vw';
    let curr = TreeIterator.find(comList, (item) => item.componentUuid == componentUuid, '$props.elements');
    if (curr && curr.parentId) {
      let parent = comList.find((item) => item.componentUuid == curr.parentId);
      if (parent?.$props) {
        const { paddingLeft, marginLeft, paddingRight, marginRight } = parent?.$props;
        let xw = 375 - (paddingLeft || 0) - (marginLeft || 0) - (paddingRight || 0) - (marginRight || 0);
        itemWidth = (xw * 100) / (colCount * 375) + 'vw';
      }
    }
    let bWidth = ((borderWidth || 0) * 100) / (colCount * 375) + 'vw';

    return (
      <div
        className={cn('ShopWindowCom', { fixBottom: isFixBottom == '1', empty: _.isEmpty(dataList) })}
        id={componentUuid}
        style={{ ...xStyle }}
      >
        {_.isEmpty(dataList) && (
          <div className="empty-holder">
            <div className="empty-holder-title">橱窗</div>
            <div className="empty-holder-desc">请上传图片</div>
          </div>
        )}
        {!_.isEmpty(dataList) &&
          dataList.map((page, pageIndex) => (
            <div className="ShopWindowCom-page" key={pageIndex}>
              {page.map((item, index) => {
                let lastRow = Math.ceil((index + 1) / colCount) == Math.ceil(page.length / colCount);
                return (
                  <div
                    className={cn('ShopWindowCom-item', { lastCol: (index + 1) % colCount == 0, lastRow })}
                    key={item.id}
                    style={{
                      borderWidth: bWidth,
                      width: itemWidth,
                      height: isAutoHeight == '1' ? 'auto' : colCount > 1 ? itemWidth : '37.3333333vw',
                    }}
                  >
                    {item?.url ? (
                      <img onClick={this.handleItemClick.bind(this, item)} className="xx-img" src={item.url} alt="" />
                    ) : (
                      <div className="empty-holder">
                        <div className="empty-holder-title">橱窗</div>
                        <div className="empty-holder-desc">请上传图片</div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
      </div>
    );
  }
}

ShopWindowCom.getDefaultValue = () => {
  return {
    windowConfig: undefined,
  };
};

ShopWindowCom.getEditData = ({ comList, currCom } = {}) => ({
  data: {
    colCount: {
      label: '每行橱窗个数',
      control: InputNumber,
      controlProps: {
        precision: 0,
      },
      fieldDecorator: {
        normalize: (value, prevValue) => {
          if (value === '' || value === null || value === undefined) {
            return value;
          }

          if (!+value || +value <= 0 || +value > 6) {
            message.warning(`每行橱窗个数取值范围为1~6`);
            return prevValue;
          }

          return value;
        },
      },

      renderAfter: ({ props }) => {
        let xVal = props.form.getFieldValue('colCount');
        return (
          <span style={{ marginTop: 10 }}>总宽度750px{xVal ? `，单个橱窗宽度为${(750 / xVal).toFixed(0)}px` : ''}</span>
        );
      },
    },
    rowCount: {
      label: '橱窗排数',
      control: InputNumber,
      controlProps: {
        precision: 0,
      },
      fieldDecorator: {
        normalize: (value, prevValue) => {
          if (value === '' || value === null || value === undefined) {
            return value;
          }

          if (!+value || +value <= 0) {
            message.warning(`橱窗排数为正整数`);
            return prevValue;
          }
          return value;
        },
      },
    },

    isAutoHeight: {
      label: '高度是否自适应',
      control: 'radioGroup',
      getInitialValue: () => '0',
      options: () => [
        { key: '1', value: '1', label: '是' },
        { key: '0', value: '0', label: '否' },
      ],
    },

    bgColor: {
      label: '背景颜色',
      control: 'colorPicker',
    },
    borderWidth: {
      label: '边框宽度',
      control: 'number',
      controlProps: {
        suffix: 'px',
      },
    },
    isFixBottom: {
      label: '是否吸底',
      control: 'radioGroup',
      getInitialValue: () => '0',
      options: () => [
        { key: '1', value: '1', label: '是' },
        { key: '0', value: '0', label: '否' },
      ],
      fieldDecorator: {
        normalize: (value, prevValue) => {
          if (value != '1') {
            return value;
          }

          let x = TreeIterator.find(
            comList,
            (item) => item.$component == 'shopWindow' && item.$props?.isFixBottom == '1' && item.$id != currCom?.$id,
            '$props.elements',
          );

          if (x) {
            return '0';
          }
          return value;
        },
      },
    },
    windowConfig: {
      label: '橱窗配置',
      control: WindowConfig,
    },
  },
  methods: {},
});

ShopWindowCom.validate = (props) => {
  const { windowConfig, colCount, rowCount } = props;
  if (!colCount) return '请设置每行橱窗个数';
  if (!rowCount) return '请设置橱窗排数';

  if (_.isEmpty(windowConfig)) {
    return '请设置橱窗配置';
  }
  return '';
};

export default ShopWindowCom;
