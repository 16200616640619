import React, { Component } from 'react';
import { Icon, message, Alert, Modal, Button, Select, Input } from 'antd';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import _ from 'lodash';
import { LINK_OPTIONS } from './data';
import Upload from '@/components/Upload';

import './index.less';

const SortableContainer = sortableContainer((props) => {
  return <div {...props}>{props.children}</div>;
});
const SortableItem = sortableElement((props) => {
  return <div {...props}>{props.children}</div>;
});

class TextList extends Component {
  //   static getDerivedStateFromProps(props, state) {
  //     console.log('getDerivedStateFromProps', props.value, state.value);
  //     if (props.value !== state.value) {
  //       if (props.value && props.value.length) {
  //         return {
  //           value: props.value,
  //         };
  //       }
  //       return {
  //         value: [
  //           {
  //             id: String(Math.random()).slice(3, 9),
  //           },
  //         ],
  //       };
  //     }

  //     return null;
  //   }

  constructor(props) {
    super(props);
    this.state = {
      isModalShow: false,
      value: props.value || [
        {
          id: String(Math.random()).slice(3, 9),
        },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.value, this.props.value)) {
      this.setState({
        value: this.props.value || [
          {
            id: String(Math.random()).slice(3, 9),
          },
        ],
      });
    }
  }

  handleChange = (item, key, evt) => {
    const { value = [] } = this.state;

    if (item) {
      item[key] = evt.target ? evt.target.value : evt;
    }

    this.setState({
      value,
    });
  };

  removeRow = (row, index) => {
    let { value = [] } = this.state;
    if (!value) {
      value = [];
    }

    Modal.confirm({
      title: '确定删除该项?',
      onOk: () => {
        value.splice(index, 1);

        this.setState({
          value,
        });
      },
    });
  };

  addRow = () => {
    let { value = [] } = this.state;
    const { maxCount } = this.props;
    if (maxCount && value.length >= maxCount) {
      message.warning(`最多能添加${maxCount}条记录`);
      return;
    }
    if (!value) {
      value = [];
    }

    value.push({
      id: String(Math.random()).slice(3, 9),
    });
    this.setState({
      value,
    });
  };

  onSortEnd = ({ newIndex, oldIndex }) => {
    let { value = [] } = this.state;
    let curr = value[oldIndex];
    value.splice(oldIndex, 1);
    value.splice(newIndex, 0, curr);

    this.setState({
      value,
    });
  };

  checkSort = (evt) => {
    if (evt.target.nodeName == 'path' || evt.target.nodeName == 'svg' || evt.target.nodeName == 'i') {
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    const { onChange } = this.props;
    const { value } = this.state;

    if (_.isEmpty(value)) {
      message.warning('配置不能为空');
      return;
    }

    let configIndex = value.findIndex((item) => !item.linkType);
    console.log('configIndex', value, configIndex);
    if (configIndex >= 0) {
      message.warning(`第${configIndex + 1}行[跳转至]未设置`);
      return;
    }

    let imgIndex = value.findIndex((item) => !item.url);
    if (imgIndex >= 0) {
      message.warning(`第${imgIndex + 1}行[图片]未设置`);
      return;
    }

    let infoIndex = value.findIndex((item) => !item.linkInfo && item.linkType != 'none');
    if (infoIndex >= 0) {
      message.warning(`第${infoIndex + 1}行[内容]未设置`);
      return;
    }

    let linkIndex = value.findIndex((item) => item.linkType == 'link' && item.linkInfo.indexOf('http') != 0);
    if (linkIndex >= 0) {
      message.warning(`第${linkIndex + 1}行[内容]链接格式不正确`);
      return;
    }

    if (onChange) {
      onChange(this.state.value);
    }

    this.setState({ isModalShow: false });
  };

  getPlaceholder = (type) => {
    let curr = LINK_OPTIONS.find((item) => item.value == type);
    return '请输入' + (curr?.placeholder || '');
  };

  render() {
    const { value = [], isModalShow } = this.state;

    return (
      <div className="windowConfigCom">
        {_.isEmpty(this.props.value) ? (
          <Button style={{ width: '100%' }} icon="plus" onClick={() => this.setState({ isModalShow: true })}>
            添加橱窗配置
          </Button>
        ) : (
          <Alert
            message={
              <>
                <span>已配置橱窗</span>
                <span
                  onClick={() => this.setState({ isModalShow: true })}
                  style={{ position: 'absolute', right: 24, top: 8, color: '#1890FF', cursor: 'pointer' }}
                >
                  编辑
                </span>
              </>
            }
            type="info"
            showIcon
            style={{ position: 'relative' }}
          />
        )}

        <Modal
          onCancel={() => {
            this.setState({
              isModalShow: false,
            });
          }}
          footer={null}
          title="橱窗配置"
          centered
          width={1000}
          bodyStyle={{
            maxHeight: 764,
            overflow: 'auto',
          }}
          maskClosable
          className="windowConfigCom-modal"
          visible={isModalShow}
        >
          <Alert message="橱窗序号排序为从左至右从上到下由1递增" type="info" showIcon />

          <div className="config-head">
            <div className="config-cell">橱窗序号</div>
            <div className="config-cell">跳转至</div>
            <div className="config-cell">图片</div>
            <div className="config-cell">内容</div>
            <div className="config-cell">操作</div>
          </div>

          <SortableContainer
            shouldCancelStart={this.checkSort}
            className="config-body"
            onSortEnd={this.onSortEnd}
            distance={1}
            lockAxis="y"
            axis="xy"
          >
            {(value || []).map((row, index) => (
              <SortableItem index={index} className="config-row" key={row.id}>
                <div className="config-cell">
                  <Icon type="menu" className="dragable-box-icon" />
                  {index + 1}
                </div>
                <div className="config-cell">
                  <Select
                    style={{ width: 184 }}
                    placeholder="请选择"
                    value={row.linkType}
                    onChange={this.handleChange.bind(this, row, 'linkType')}
                  >
                    {LINK_OPTIONS.map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="config-cell">
                  <Upload value={row.url} onChange={this.handleChange.bind(this, row, 'url')} />
                </div>
                <div className="config-cell">
                  {row.linkType !== 'none' && (
                    <Input
                      placeholder={this.getPlaceholder(row.linkType)}
                      value={row.linkInfo}
                      onChange={this.handleChange.bind(this, row, 'linkInfo')}
                    />
                  )}
                </div>
                <div className="config-cell">
                  <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={this.removeRow.bind(this, row, index)}>
                    删除
                  </span>
                </div>
              </SortableItem>
            ))}
          </SortableContainer>
          <Button className="btn-add" icon="plus" onClick={this.addRow}>
            添加橱窗
          </Button>
          <div className="config-bottom">
            <Button type="primary" onClick={this.handleSubmit}>
              批量添加
            </Button>
            <Button onClick={() => this.setState({ isModalShow: false })}>取消</Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default TextList;
