import axios from 'axios';
import { Remote } from '@/util';

class AcitvityConfigService {
  queryList (params = {}) {
    return Remote.post('/activityBpmInfo/queryPageList', params);
  }
  queryFlowList (params = {}) {
    return Remote.post('/activityBpmInfo/flow/list', params);
  }
  save (params = {}) {
    return Remote.post('/activityBpmInfo/save', params);
  }
  update (params = {}) {
    return Remote.post('/activityBpmInfo/update', params);
  }
  getDetail (params = {}) {
    return Remote.get('/activityBpmInfo/get/' + params.id);
  }
  changeStatus (params = {}) {
    return Remote.get('/activityBpmInfo/changeStatus', params);
  }
  changeStock (params = {}) {
    return Remote.post('/activityBpmInfo/option/changeStock', params);
  }
  discountGet (params = {}) {
    return Remote.get('/activityBpmInfo/discount/get', params);
  }
  discountSave (params = {}) {
    return Remote.post('/activityBpmInfo/discount/save', params);
  }
  getAreaList (params = {}) {
    return Remote.get('/activityBpmInfo/area/List', params);
  }

  exportToExcelByCondition (data = {}) {
    const accessToken = localStorage.getItem('accessToken');
    const host = Remote.genDomainForEnv('default');
    const url = `${host}/activityBpmInfo/export`;
    return axios.post(url, data, {
      headers: {
        'content-type': 'application/json',
        token: accessToken,
      },
      responseType: 'blob',
    });
  }
}

export default new AcitvityConfigService();
