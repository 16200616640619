import React, { Component } from 'react';
import Slider from 'react-slick';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import { message } from 'antd';
import { getPageContainer, toGoodsDetail, toDesignerDetail, navToApp, track } from '@/util/h5Helper';
import { TreeIterator } from '@/util';
import { browserTypeEnum, LINK_OPTIONS, NUM_OPTIONS } from '@/util/systemEnum';
import Service from '@/service/goods';
import Edit from './Edit';
import EditSpeed from './Edit/Speed';
import Player from './Player';
import parseLink from '../../libs/parseLink';
import './index.scss';

@inject(({ activityStore: { getCityCode } }) => {
  return {
    getCityCode,
  };
})
@observer
class Banner extends Component {
  constructor(props) {
    super(props);
    const isEditor = window.location.pathname.indexOf('/activity/detail') !== -1;
    const type = getPageContainer();
    this.state = {
      current: 0,
      bannerHeight: '60vw',
      isEditor,
      type,
    };
    this.sliderRef = null;
  }

  componentDidMount() {
    const { banner } = this.props;
    if (Array.isArray(banner) && banner.length > 0) {
      this.getFirstSlideSize(banner[0]);
    }
  }

  componentDidUpdate(prevProps) {
    const { banner: oldBanner } = prevProps;
    const { banner } = this.props;
    const oldFirstSlide = oldBanner && oldBanner.length > 0 ? oldBanner[0].url : '';
    const firstSlide = banner && banner.length > 0 ? banner[0].url : '';
    if (oldFirstSlide !== firstSlide) {
      this.getFirstSlideSize(banner[0]);
    }
  }

  getRenderRatio(comList, componentUuid) {
    let ratio = 1;
    let curr = TreeIterator.find(comList, (item) => item.componentUuid == componentUuid, '$props.elements');
    if (curr && curr.parentId) {
      let parent = comList.find((item) => item.componentUuid == curr.parentId);
      if (parent?.$props) {
        const { paddingLeft, marginLeft, paddingRight, marginRight } = parent?.$props;
        let xw = 375 - (paddingLeft || 0) - (marginLeft || 0) - (paddingRight || 0) - (marginRight || 0);
        ratio = xw / 375;
      }
    }
    return ratio;
  }

  getFirstSlideSize = (bannerItem) => {
    if (!bannerItem) return;
    const { comList, componentUuid } = this.props;
    const ratio = this.getRenderRatio(comList, componentUuid);
    const { isVideo, url } = bannerItem;
    if (isVideo) {
      let video = document.createElement('video');
      video.preload = 'metadata';
      video.src = url;
      video.onloadedmetadata = () => {
        let width = video.videoWidth;
        let height = video.videoHeight;
        if (!width || !height) return;
        let displayHeight = (height / width) * 100 * ratio + 'vw';
        this.setState({ bannerHeight: displayHeight });
      };
    } else {
      let img = new Image();
      img.onload = ({ target: { width, height } }) => {
        if (!width || !height) return;
        let displayHeight = (height / width) * 100 * ratio + 'vw';
        this.setState({ bannerHeight: displayHeight });
      };
      img.src = url;
    }
  };

  beforeChange = (oldIndex, newIndex) => {
    this.setState({ current: newIndex });
  };

  slickPause = () => {
    this.sliderRef?.slickPause();
  };

  slickPlay = () => {
    setTimeout(() => {
      this.sliderRef?.slickPlay();
    }, 500);
  };

  handleClickItem = async (item) => {
    const { componentType, id } = this.props;
    const trackEvent = { code: 'click', componentType, componentCode: id };
    if (window.location.pathname.indexOf('/activity/detail') !== -1) {
      this.slickPlay();
      return;
    }
    const { linkType, linkInfo } = item;
    const type = getPageContainer(false);
    if (linkType === 'none') {
      track(trackEvent);
      return;
    }
    if (linkType === 'goods') {
      // 跳转商品详情页
      track(trackEvent, { itemId: item.linkInfo });
      if (type === browserTypeEnum.BROWSER) {
        navToApp();
        return;
      }
      let code = await this.props.getCityCode();
      let res = await Service.queryRedirectParams({ cityCode: code || '310100', sapSku: item.linkInfo });
      if (res?.code === 0 && res?.success && res?.data) {
        toGoodsDetail({ itemId: res.data.itemId, skuId: res.data.skuId }, { componentCode: id, componentType });
        return;
      }
      message.error('查询跳转信息失败');
    }
    if (linkType === 'specialTopic') {
      track(trackEvent, { activityCode: linkInfo });
      let res = await Service.queryActivityId(linkInfo);
      if (res?.code === 0 && res?.success && res?.data) {
        window.location.href = window.origin + '/activity/h5?activityId=' + res.data?.id;
      } else {
        message.error('查询跳转信息失败');
      }
      return;
    }
    if (linkType === 'designer') {
      track(trackEvent);
      toDesignerDetail(linkInfo);
      return;
    }
    if (linkType === 'link') {
      track(trackEvent, { link: linkInfo });
      window.location.href = parseLink(linkInfo || '');
    }
  };

  showRule = async (data) => {
    const { componentType, id } = this.props;
    track({ code: 'rule', componentType, componentCode: id });
    const { ruleLinkType, ruleLinkInfo } = data;
    if (ruleLinkType) {
      // 链接
      window.location.href = ruleLinkInfo;
      return;
    }
    // 专题
    let res = await Service.queryActivityId(ruleLinkInfo);
    if (res?.code === 0 && res?.success && res?.data) {
      window.location.href = window.origin + '/activity/h5?activityId=' + res.data?.id;
    } else {
      message.error('查询跳转信息失败');
    }
  };

  renderCom = () => {
    const { current, isEditor, type } = this.state;
    // console.log('banner render props:', this.props);
    const { speed, banner } = this.props;
    const settings = {
      initialSlide: current,
      dots: Boolean(banner.length > 1),
      arrows: false,
      pauseOnHover: false,
      pauseOnFocus: false,
      draggable: true,
      infinite: true, // 无限环绕内容
      speed: 500, // 滑动动画时间，ms
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      autoplay: isEditor || Boolean(banner.length > 1),
      autoplaySpeed: (speed ?? 2) * 1000, // 自动轮播时间间隔
      useTransform: false,
    };
    let firstSlide = null;
    let ruleBtnStyle = {};

    if (!banner || !banner.length) {
      return <div className="tip">请上传Banner</div>;
    }
    if (banner.length > 0) {
      firstSlide = banner[0];
      ruleBtnStyle = {
        color: banner[0].ruleBtnColor,
        backgroundColor: banner[0].ruleBtnBg,
      };
    }
    if (banner.length == 1 && !firstSlide.isVideo) {
      return (
        <>
          <div
            className="img-box"
            style={{
              backgroundImage: `url(${firstSlide.url})`,
            }}
            key={firstSlide.id}
            onClick={() => this.handleClickItem(firstSlide, 0)}
          />
          {firstSlide?.showRule && current === 0 ? (
            <div className="banner-rule-btn" onClick={() => this.showRule(firstSlide)}>
              <span style={ruleBtnStyle}>活动规则</span>
            </div>
          ) : null}
        </>
      );
    }

    return (
      <>
        <Slider
          {...settings}
          className="banner-slider"
          ref={(slider) => {
            this.sliderRef = slider;
          }}
          beforeChange={this.beforeChange}
        >
          {banner.map((item, key) => (
            <div className="banner-slider-item" key={item.id} onClick={() => this.handleClickItem(item, key)}>
              {isEditor && !item.url ? (
                <div className="tip">请上传视频或图片</div>
              ) : item.isVideo ? (
                <Player type={type} url={item.url} visible={key === current} />
              ) : (
                <img className="banner-slider-item-img" src={item.url} alt="" />
              )}
            </div>
          ))}
        </Slider>
        {firstSlide?.showRule && current === 0 ? (
          <div className="banner-rule-btn" onClick={() => this.showRule(firstSlide)}>
            <span style={ruleBtnStyle}>活动规则</span>
          </div>
        ) : null}
      </>
    );
  };

  render() {
    const { bannerHeight } = this.state;
    // console.log('banner render props:', this.props);
    const { banner, componentUuid } = this.props;

    return (
      <div
        className={cn('banner', { singleItem: banner && banner.length == 1 })}
        style={{ backgroundColor: banner.length > 0 ? (banner.length == 1 ? '' : 'inherit') : '#f5f5f5', height: bannerHeight }}
        id={componentUuid}
      >
        {this.renderCom()}
      </div>
    );
  }
}

Banner.getDefaultValue = () => {
  return {
    banner: [],
    speed: 2, // 图片切换时间间隔，秒
  };
};

Banner.getEditData = () => ({
  data: {
    banner: {
      label: '',
      control: Edit,
    },
    speed: {
      label: '',
      control: EditSpeed,
    },
  },
  methods: {},
});

Banner.validate = (props) => {
  // console.log('banner validate:', props);
  const { banner: list, speed } = props;
  let errMsg = '';
  if (list.length < 1) {
    return '请设置Banner内容';
  }
  if (!speed) {
    return '请设置跳转时间';
  }
  if (speed < 0 || speed > 10) {
    return '请设置跳转时间，范围在 0 ~ 10之间';
  }

  for (let i = 0; i < list.length; i++) {
    let item = list[i];
    if (!item.url) {
      errMsg = `请上传第${NUM_OPTIONS[i + 1]}帧图片/视频`;
      break;
    }
    if (item.linkType !== 'none' && !item.linkInfo) {
      let option = LINK_OPTIONS.find((v) => v.value === item.linkType);
      errMsg = `请输入第${NUM_OPTIONS[i + 1]}帧图片/视频跳转的` + option?.placeholder;
      break;
    }
    if (item.linkType === 'link' && !/(http|https):\/\/([\w.]+\/?)\S*/.test(item.linkInfo)) {
      let option = LINK_OPTIONS.find((v) => v.value === item.linkType);
      errMsg = `第${NUM_OPTIONS[i + 1]}帧跳转链接填写有误，请输入正确的${option?.placeholder}`;
      break;
    }
    if (i === 0 && item.showRule) {
      if (!item.ruleBtnBg) {
        errMsg = `请设置第一帧规则按钮背景颜色`;
        break;
      }
      if (!item.ruleBtnColor) {
        errMsg = `请设置第一帧规则按钮文字颜色`;
        break;
      }
      if (!item.ruleLinkInfo) {
        errMsg = `请输入第${NUM_OPTIONS[i + 1]}帧规则跳转的` + (item.ruleLinkType ? '链接' : '专题ID');
        break;
      }
      if (item.ruleLinkType && !/(http|https):\/\/([\w.]+\/?)\S*/.test(item.ruleLinkInfo)) {
        errMsg = '请输入正确的规则跳转链接';
        break;
      }
    }
  }
  return errMsg;
};

export default Banner;
