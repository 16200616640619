import React, { Component } from 'react';
import { message } from 'antd';
import moment from 'moment';
import cn from 'classnames';
import _ from 'lodash';
import ShopSelect from './modules/ShopSelect';
import { cityEditMixin } from '@/magicPackages/mixins';
import './index.scss';
import EditContext from '@/util/EditContext';

class PageTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    const { activityName, componentUuid, titleBg } = this.props;
    return (
      <div className={cn('pageTitleCom', { dark: titleBg == 'dark' })} id={componentUuid}>
        {activityName}
      </div>
    );
  }
}

PageTitle.contextType = EditContext;

PageTitle.getEditData = ({ detail } = {}) => ({
  data: {
    $label: '标题配置',
    activityName: {
      label: '标题名称',
      required: true,
      controlProps: {
        maxLength: 50,
      },
    },
    time: {
      label: '启用时间',
      required: true,
      control: 'rangePicker',
      startKey: 'startTime',
      endKey: 'endTime',
      checkShowBy: {
        foreverFlag: (val) => !val,
      },
      controlProps: {
        showTime: {
          hideDisabledOptions: true,
          // defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
        },
        // disabled: detail?.status == 2,
        disabledDate (current) {
          return current && current <= moment().subtract(1, 'day').endOf('day');
        },
      },
    },
    foreverFlag: {
      label: '永久生效',
      inline: true,
      control: 'checkbox',
      controlProps: {
        // disabled: detail?.status == 2,
      },
    },
    showInApp: {
      label: '在app/小程序广告位展示',
      inline: true,
      control: 'radioGroup',
      getInitialValue: 0,
      options: () => [
        { key: '1', label: '显示', value: 1 },
        { key: '2', label: '不显示', value: 0 },
      ],
    },
    appBannerPic: {
      label: 'Banner广告-逛商场',
      control: 'upload',
      controlProps: {
        accept: '.gif,.jpg,.jpeg,.png',
      },
      renderAfter: () => {
        return <span style={{ color: ' rgba(0,0,0,0.45)' }}>请上传GIF,JPEG格式,尺寸大小为398*98的图片</span>;
      },
      checkShowBy: {
        showInApp: (val) => val == 1,
      },
    },
    appStreamPic: {
      label: '瀑布流图-首页',
      control: 'upload',
      controlProps: {
        accept: '.gif,.jpg,.jpeg,.png',
      },
      renderAfter: () => {
        return <span style={{ color: ' rgba(0,0,0,0.45)' }}>请上传GIF,JPEG格式,尺寸大小为205*224的图片</span>;
      },
      checkShowBy: {
        showInApp: (val) => val == 1,
      },
    },
    isWecomShow: {
      label: '企微是否显示',
      control: 'radioGroup',
      getInitialValue: 0,
      options: () => [
        { key: '1', label: '显示', value: 1 },
        { key: '2', label: '不显示', value: 0 },
      ],
    },
    platformFlag: {
      label: '活动城市/门店',
      control: 'radioGroup',
      getInitialValue: '1',
      options: () => [
        { key: '1', label: '零售', value: '1' },
        { key: '2', label: '家装', value: '2' },
      ],
    },
    shopJson: {
      label: '',
      control: ShopSelect,
      checkShowBy: {
        platformFlag: (val) => val == 1,
      },
      fieldDecorator: {
        getValueFromEvent: (values) => {
          let last = _.last(values);
          if (last) {
            if (last.value == '0') {
              return [{ label: '全部门店', value: '0' }];
            }
          }
          return values.filter((item) => item.value != 0);
        },
      },
      convertControlToModel: (value, { conditions }) => {
        conditions.shopCodes = _.map(value || [], (item) => item.value).join(',');
        return value;
      },
    },
    cityJson: cityEditMixin({
      label: '',
      checkShowBy: {
        platformFlag: (val) => val == 2,
      },
      // options: () => {
      //   return address;
      // },
      // controlProps: {
      //   placeholder: '请选择城市',
      //   treeDataSimpleMode: false,
      //   treeCheckStrictly: true,
      //   treeCheckable: true,
      //   treeNodeFilterProp: 'title',
      //   maxTagCount: 10,
      // },
      // convertControlToModel: (value, { conditions }) => {
      //   conditions.cityCodes = _.map(value || [], (item) => item.value).join(',');
      //   return value;
      // },
    }),
    targetPerson: {
      label: '目标人群',
      inline: true,
      control: 'radioGroup',
      getInitialValue: true,
      options: () => [
        { key: true, label: '全部', value: true },
        { key: false, label: '非内部人员', value: false },
      ],
    },

    $label1: '页面配置',

    titleBg: {
      label: '标题栏背景色',
      inline: true,
      control: 'radioGroup',
      options: () => [
        { key: 'light', label: '浅色', value: 'light' },
        { key: 'dark', label: '深色', value: 'dark' },
      ],
      getInitialValue: 'light',
    },
    bgType: {
      label: '页面背景',
      control: 'radioGroup',
      getInitialValue: '1',
      options: () => [
        { key: '1', label: '纯色', value: '1' },
        { key: '2', label: '图片', value: '2' },
      ],
    },
    bgColor: {
      label: '页面背景色',
      inline: true,
      control: 'colorPicker',
      checkShowBy: {
        bgType: (val) => val == 1,
      },
    },
    bgImage: {
      label: '页面背景图片',
      control: 'upload',
      controlProps: {
        accept: '.jpg,.jpeg,.png',
      },
      renderAfter: () => {
        return <span style={{ color: ' rgba(0,0,0,0.45)' }}>支持拓展名：.png .jpg .jpeg，建议宽度750px</span>;
      },
      checkShowBy: {
        bgType: (val) => val == 2,
      },
    },

    shareTitle: {
      label: '分享标题',
      controlProps: {
        maxLength: 50,
      },
    },
    shareContent: {
      label: '分享文案',
      control: 'textarea',
      controlProps: {
        maxLength: 200,
      },
    },
    sharePic: {
      // label: '分享图',
      label: '微信分享缩略图',
      control: 'upload',
      controlProps: {
        accept: '.jpg,.jpeg,.png',
        // maxSize: '100k',
        maxSize: '1024k',
      },
      renderAfter: () => {
        // return <span style={{ color: ' rgba(0,0,0,0.45)' }}>支持拓展名：.png .jpg .jpeg，建议宽度750px(小于100K)</span>;
        return <span style={{ color: ' rgba(0,0,0,0.45)' }}>支持拓展名：.png .jpg .jpeg，宽度建议750*750(小于100KB)</span>;
      },
    },
    shareAppPic: {
      // label: 'app分享图',
      label: '活动列表封面图',
      control: 'upload',
      controlProps: {
        accept: '.jpg,.jpeg,.png',
        // maxSize: '100k',
        maxSize: '1024k',
      },
      renderAfter: () => {
        // return <span style={{ color: ' rgba(0,0,0,0.45)' }}>支持拓展名：.png .jpg .jpeg，建议宽度750px(小于100K)</span>;
        return <span style={{ color: ' rgba(0,0,0,0.45)' }}>支持拓展名：.png .jpg .jpeg，建议宽高比为1:0.8(小于1M)</span>;
      },
    },
    previewImage: {
      label: '预览图',
      control: 'upload',
      controlProps: {
        accept: '.jpg,.jpeg,.png',
      },
      // required: true,
      renderAfter: () => {
        return <span style={{ color: ' rgba(0,0,0,0.45)' }}>支持拓展名：.png .jpg .jpeg，建议尺寸为515*795</span>;
      },
    },

    $label2: '页面控制',
    buttonBg: {
      label: '右上角按钮背景颜色',
      control: 'colorPicker',
    },
    buttonColor: {
      label: '右上角按钮字体颜色',
      control: 'colorPicker',
    },

    shopCityChangeAble: {
      // 在小程序中只显示切换门店
      label: '是否切换城市或门店 (小程序只支持切换门店)',
      control: 'radioGroup',
      getInitialValue: '0',
      options: () => [
        { key: '0', label: '否', value: '0' },
        { key: '1', label: '城市', value: '1' },
        { key: '2', label: '门店', value: '2' },
      ],
    },
    isRuleShow: {
      label: '是否显示规则',
      control: 'radioGroup',
      getInitialValue: '0',
      options: () => [
        { key: '0', label: '否', value: '0' },
        { key: '1', label: '是', value: '1' },
      ],
    },

    ruleLinkType: {
      label: '跳转规则',
      control: 'radioGroup',
      getInitialValue: '0',
      checkShowBy: {
        isRuleShow: (val) => val == 1,
      },
      options: () => [
        { key: '0', label: '专题', value: '0' },
        { key: '1', label: '链接', value: '1' },
      ],
    },
    ruleLinkInfo: {
      label: ({ props }) => {
        let ruleLinkType = props.form.getFieldValue('ruleLinkType');
        return ruleLinkType == '0' ? '专题ID' : '链接(http://或https://)';
      },
      checkShowBy: {
        isRuleShow: (val) => val == 1,
      },
    },

    flowButtons: {
      label: '浮动按钮(最多选3个)',
      control: 'select',
      controlProps: {
        mode: 'multiple',
      },
      fieldDecorator: {
        getValueFromEvent: (values, target) => {
          if (values?.length >= 4) {
            message.warning('最多只能选择3项目');
            values = values.slice(0, 3);
          }

          return values;
        },
      },
      options: () => {
        return [
          { key: 1, value: 1, label: '分享' },
          { key: 2, value: 2, label: '购物车' },
          { key: 3, value: 3, label: '回到顶部' },
          { key: 4, value: 4, label: '返回首页' },
          // { key: 5, value: 5, label: '留资' },
        ];
      },
    },
    // activityCode: '专题ID',
  },
  methods: {},
});

PageTitle.getDefaultValue = () => {
  return {
    activityName: '',
  };
};

PageTitle.validate = (props) => {
  let errMap = {
    shareTitle: '分享标题不能为空',
    shareContent: '分享文案不能为空',
    sharePic: '分享图片不能为空',
    shareAppPic: 'app分享图片不能为空',
    // flowButtons: '浮动按钮不能为空',
    targetPerson: '目标人群不能为空',
    previewImage: '请上传预览图'
  };

  let keys = _.keys(errMap);

  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    if (
      !_.has(props, key) ||
      (!props[key]?.length && _.isString(props[key])) ||
      (!props[key]?.length && _.isArray(props[key]))
    ) {
      return errMap[key];
    }
  }

  if (props.platformFlag == 1 && !props.shopJson) {
    return '门店不能为空';
  }

  if (props.platformFlag == 2 && !props.cityJson) {
    return '城市不能为空';
  }

  if (props.bgType == 1 && !props.bgColor) {
    return '页面背景颜色不能为空';
  }

  if (props.bgType == 2 && !props.bgImage) {
    return '页面背景图片不能为空';
  }

  return '';
};

// PageTitle.contextType = EditContext;

export default PageTitle;
