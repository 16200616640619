import React from 'react';
import { createPortal } from 'react-dom';
import { message } from 'antd';
import './index.scss';

export default class SubmitBtn extends React.Component {
  constructor(props) {
    super(props);
    this.dom = document.querySelector('.preview-container');
    this.state = {
      checked: false,
    };
  }
  render () {
    const {
      appointmentFieldArray,
      btnStyle,
      btnTxt,
      onSubmit,
      type,
      loading,
      isEditor,
      protocol,
      protocolColor,
      protocolTitleColor,
      protocolList,
    } = this.props;
    let params = new URL(window.location.href).searchParams;
    let activityId = params.get('id') || params.get('activityId');
    // console.log('consult id:', activityId);
    let content = (
      <div
        className="appointment-btn-wrap"
        data-num={appointmentFieldArray.length}
        data-type={type}
        data-editor={isEditor}
      >
        <div
          className="appointment-btn"
          style={btnStyle}
          onClick={() => {
            if (protocol == 1) {
              if (this.state.checked) {
                onSubmit(btnTxt);
              } else {
                message.warn('请勾选隐私协议');
              }
              return;
            }
            onSubmit(btnTxt);
          }}
        >
          {btnTxt}
        </div>
        {protocol == 1 ? (
          <div className="appointment-protocol" style={{ color: protocolColor }}>
            <span
              className="appointment-check-input-wrap"
              onChange={(e) => this.setState({ checked: !this.state.checked })}
            >
              <input type="checkbox" value={this.state.checked} />
            </span>
            <span>我已阅读并同意</span>
            {protocolList &&
              protocolList.map((item, index) => (
                <span
                  key={item.title + index}
                  onClick={() => {
                    window.open(item.url, '_blank');
                  }}
                  style={{ color: protocolTitleColor }}
                >
                  《{item.title}》
                </span>
              ))}
            <span>百安居承诺：您的私人信息，不会泄露给任何第三方</span>
          </div>
        ) : null}
      </div>
    );
    if (this.dom && type == 'float') {
      return createPortal(content, this.dom);
    }
    return content;
  }
}
