import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react';
import { message } from 'antd';
import SelectAddress from '../SelectAddress';
import SelectRadio from '../SelectRadio';
import SelectModal from '../SelectModal';
import { newTrack } from '@/util/h5Helper';
import sendSmsCodeAPI from '@/util/sendSmsCode';

import './index.scss';

const CusForm = (props, ref) => {
  const [type, setType] = useState(null);
  const [cityName, setCityName] = useState('');
  const [cityValue, setCityValue] = useState(null);
  const [storeName, setStoreName] = useState('');
  const [storeValue, setStoreValue] = useState(null);
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [phoneMsg, setPhoneMsg] = useState('');
  const [address, setAddress] = useState('');
  const [referee, setReferee] = useState('');
  const [visible, setVisible] = useState(false);
  const [isShowRadio, setShowRadio] = useState(false);
  const { position, data, serviceItems, cityCodes } = props;
  const [serviceNames, setServiceNames] = useState([]);
  const [serviceIds, setServiceIds] = useState([]);
  const [msgTime, setMsgTime] = useState(0);
  const timeRef = useRef();

  const handleClick = (type) => {
    const container = document.querySelector('.preview-canvas');
    if (container) {
      container.style.overflow = 'hidden';
    }
    setType(type);
    setVisible(true);
  };

  const selectServiceItems = () => {
    const container = document.querySelector('.preview-canvas');
    if (container) {
      container.style.overflow = 'hidden';
    }
    setShowRadio(true);
  };

  const closeModal = () => {
    setVisible(false);
    const container = document.querySelector('.preview-canvas');
    if (container) {
      container.style.overflow = 'hidden auto';
    }
  };

  const receiveData = ({ nameArr, idArr }) => {
    closeModal();
    const activityID = sessionStorage.getItem('__activityID__');
    const activityName = sessionStorage.getItem('__activityName__');
    if (type === 'city') {
      const city = nameArr.join('-');
      setCityName(city);
      setCityValue(idArr);
      const param = {
        ck: activityID,
        cknm: activityName,
        ccity: city,
      };
      newTrack('click_city', '', param);
    } else {
      setStoreName(nameArr.join('-'));
      setStoreValue(idArr);
    }
  };

  const resetField = () => {
    setCityName('');
    setCityValue(null);
    setName('');
    setMobile('');
    setPhoneMsg('');
    setAddress('');
    setServiceNames([]);
    setServiceIds([]);
    setReferee('');
  };

  useImperativeHandle(ref, () => ({
    reset: () => resetField(),
    values: {
      name,
      mobile,
      phoneMsg,
      cityName,
      cityValue,
      address,
      serviceNames,
      serviceIds,
      referee,
    },
    resetSendMsg () {
      clearInterval(timeRef.current);
      setMsgTime(0)
    }
  }));

  useEffect(() => {
    // 百安居app里键盘弹起时，webview高度没有变化，输入框会被键盘遮挡
    //获取原窗口的高度
    // var originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
    // window.onresize = function () {
    //   console.log('resize');
    //   //键盘弹起与隐藏都会引起窗口的高度发生变化
    //   var resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
    //   console.log('height', resizeHeight, originalHeight);
    //   if (resizeHeight - 0 < originalHeight - 0) {
    //     //当软键盘弹起，在此处操作
    //     console.log('键盘弹出');
    //   } else {
    //     //当软键盘收起，在此处操作
    //     console.log('键盘收起');
    //   }
    // };
    // return () => {};
  }, []);


  useEffect(() => {
    if (msgTime === 0) {
      clearInterval(timeRef.current);
    }
  }, [msgTime])

  const sendMsg = async () => {
    if (!mobile) {
      message.warn('请输入手机号码');
      return;
    }
    if (msgTime === 0) {
      let res = await sendSmsCodeAPI({ mobile: mobile });
      if (res.code === 0) {
        message.success('验证码发送成功!');
      } else {
        message.warn(res.message);
        return;
      }
      let newtime = 60;
      timeRef.current = setInterval(() => {
        if (newtime > 0) newtime--
        setMsgTime(newtime)
      }, 1000)
    }
  }

  return (
    <>
      <div className="appointment-form-wrap" ref={ref} data-type={position}>
        {data.indexOf(6) == -1 ? null : (
          <div className="form-item">
            <span className="label">服务：</span>
            <div onClick={() => selectServiceItems()} className="input-wrap">
              <input placeholder="请选择服务项目" disabled value={serviceNames.join('、')} />
            </div>
          </div>
        )}
        {data.indexOf(1) == -1 ? null : (
          <div className="form-item">
            <span className="label">姓名：</span>
            <input placeholder="请输入姓名" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
        )}
        {data.indexOf(2) == -1 ? null : (
          <div className="form-item">
            <span className="label">手机：</span>
            <input
              placeholder="请输入手机号码"
              maxLength={11}
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
        )}
        {data.indexOf(7) == -1 ? null : (
          <div className="form-item">
            <span className="label">验证码：</span>
            <input maxLength={6} placeholder="请输入验证码" value={phoneMsg} onChange={(e) => setPhoneMsg(e.target.value)} />
            <div className='fixed-btn' onClick={sendMsg}>{msgTime !== 0 ? `${msgTime}s` : '发送'}</div>
          </div>
        )}

        {data.indexOf(3) == -1 ? null : (
          <div className="form-item">
            <span className="label">城市：</span>
            <div onClick={() => handleClick('city')} className="input-wrap">
              <span className='input-span'>{cityName ? cityName : '请选择城市'}</span>
            </div>
          </div>
        )}
        {/* <div className="form-item">
          <span className="label">门店：</span>
          <div onClick={() => handleClick('store')} className="input-wrap">
            <input placeholder="请选择门店" disabled value={storeName} />
          </div>
        </div> */}
        {data.indexOf(5) == -1 ? null : (
          <div className="form-item">
            <span className="label">小区：</span>
            <input placeholder="请输入小区地址" value={address} onChange={(e) => setAddress(e.target.value)} />
          </div>
        )}
        {visible ? (
          <SelectModal onClose={() => setVisible(!visible)}>
            <SelectAddress
              type={type}
              selectedIdArr={type === 'city' ? cityValue : storeValue}
              onReceive={receiveData}
              onCancel={closeModal}
              cityCodes={cityCodes}
            />
          </SelectModal>
        ) : null}
        {data.indexOf(8) == -1 ? null : (
          <div className="form-item">
            <span className="label">推荐人：</span>
            <input placeholder="请输入推荐人" value={referee} onChange={(e) => setReferee(e.target.value)} />
          </div>
        )}
      </div >
      {
        isShowRadio ? (
          <SelectModal onClose={() => setShowRadio(!isShowRadio)}>
            <SelectRadio
              options={serviceItems}
              value={serviceIds}
              title="请选择服务项目"
              onChange={(vals) => {
                setServiceIds(vals);
                setServiceNames(vals);
                // const names = serviceItems
                //   .filter((item) => {
                //     for (let i in vals) {
                //       if (vals[i] === item.value) {
                //         return true;
                //       }
                //     }
                //     return false;
                //   })
                //   .map((item) => {
                //     return item.label;
                //   });
                // setServiceNames(names);
                setShowRadio(false);
              }}
            ></SelectRadio>
          </SelectModal >
        ) : null}

    </>
  );
};

export default forwardRef(CusForm);
