import React, { useState } from 'react';
import { RNTool } from 'web-message-for-rn';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import wx from 'weixin-js-sdk';
import { isMinProgram, isApp, reqLogin } from '@/util/h5Helper';
import tryOpen from '@/util/tryOpen';
import './index.scss';

let imgMap = {
  1: 'https://dhstatic.bthome.com/img/activity/h5editor/icon/share.png',
  2: 'https://dhstatic.bthome.com/img/activity/h5editor/icon/cart.png',
  3: 'https://dhstatic.bthome.com/img/activity/h5editor/icon/top.png',
  4: 'https://dhstatic.bthome.com/img/activity/h5editor/icon/home.png',
  5: 'https://dhstatic.bthome.com/img/activity/h5editor/icon/banner.png',
};

function toCart({ apptoken } = {}) {
  if (tryOpen()) {
    return;
  }

  if (!apptoken) {
    reqLogin(true);
  }

  if (isMinProgram()) {
    wx.miniProgram.switchTab({ url: '/pages/cart/cart' });
  } else if (isApp()) {
    RNTool.pushToRouter('ShoppingCard');
  }
}

function toHome() {
  if (tryOpen()) {
    return;
  }
  if (isMinProgram()) {
    wx.miniProgram.switchTab({ url: '/pages/home/home' });
  } else if (isApp()) {
    // RNTool.pushToRouter('PTHome');
    RNTool.postMessageToNative('postNotification', { msgName: 'backToHome' });
  }
}

function toTop() {
  document.documentElement.scrollTop = 0;
}

export default ({ dataList, style, mode, __extraInfo }) => {
  const [shareVisible, setShareVisible] = useState(false);

  const share = () => {
    if (tryOpen()) {
      return;
    }
    if (isMinProgram()) {
      setShareVisible(true);
    } else if (isApp()) {
      RNTool.postMessageToNative('share', {});
    }
  };
  const handle = (btnId) => {
    if (mode == 'preview') {
      // return;
    }
    let fnMap = {
      1: share,
      2: toCart,
      3: toTop,
      4: toHome,
    };

    if (fnMap[btnId]) {
      console.log('btnId', btnId);
      fnMap[btnId](__extraInfo);
    }
  };
  return (
    <div className="flowButtonCom" style={{ ...style }}>
      {(dataList || []).map((item) => (
        <div className="img-box" key={item}>
          <img onClick={() => handle(item)} className="btn-img" src={imgMap[item]} alt="" />
        </div>
      ))}

      {shareVisible &&
        ReactDOM.createPortal(
          <div className="bnq-share-modal">
            <div className="bnq-share-modal-mask" onClick={() => setShareVisible(false)}></div>
            <img className="share-img" src="https://dhstatic.bthome.com/img/activity/common/guide/share.png" alt="" />
          </div>,
          document.querySelector('body'),
        )}
    </div>
  );
};
