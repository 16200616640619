import React, { Component } from 'react';
import { InputNumber } from 'antd';
import './index.scss';

class EditSpeed extends Component {
  constructor(props) {
    super(props);
  }

  handleChange = (value) => {
    let v = parseFloat(value);
    if (v) {
      this.props.onChange?.(v);
    } else {
      this.props.onChange?.(0.5);
    }
  };

  render() {
    const { value } = this.props;
    return (
      <div className="bannerListCom">
        <div className="bannerListCom-block-item">
          <div className="bannerListCom-block-item-label">轮播时间</div>
          <div className="bannerListCom-block-item-control">
            <InputNumber
              value={value}
              placeholder="请输入"
              min={0.1}
              max={10}
              step={0.1}
              onChange={this.handleChange}
            />
            &nbsp;秒
          </div>
        </div>
      </div>
    );
  }
}

export default EditSpeed;
