/****** create file by codeGen ******/
import { action, observable, runInAction } from 'mobx';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import service from '@/service/coupon';

class CouponStore {
  // 优惠券列表
  @observable detailList = [{ init: true, uuid: uuidv4() }];

  // 限时发放列表
  @observable zoneList = [{ init: true, uuid: uuidv4(), sendCount: 0, stockCount: 0 }];

  // 优惠券可以领取的时间
  @observable validTime = [moment(), undefined];

  // 列表数据
  @observable tableData = {
    list: [],
    total: 0,
    pageNum: 1,
    pageSize: 10,
  };

  /**
   * 新增项
   * @param {*} type detail / zone
   * @param {*} index
   */
  @action addTableItem = (type, index) => {
    const fieldName = type + 'List';
    let newItem = { init: true, uuid: uuidv4() };
    if (type === 'zone') {
      newItem = { init: true, uuid: uuidv4(), sendCount: 0, stockCount: 0 };
    }
    if (index !== undefined && index !== null) {
      this[fieldName] = update(this[fieldName], {
        $splice: [[index, 0, newItem]],
      });
    } else {
      this[fieldName] = update(this[fieldName], {
        $push: [newItem],
      });
    }
  };

  updateEndTime = () => {
    const current = moment();
    let endTime = undefined;
    for (let i = 0; i < this.detailList.length; i++) {
      let item = this.detailList[i];
      let time = item.endTime ? moment(new Date(item.endTime)) : '';
      if (time < current) continue;
      if (!endTime) {
        endTime = time;
      } else if (time < endTime) {
        endTime = time;
      }
    }
    this.validTime = update(this.validTime, {
      $splice: [[1, 1, endTime]],
    });
  };

  @action updateTableItem = (type, newItem, index) => {
    if (type === 'detail') {
      const itemIndex = this.detailList.findIndex((v) => v.promotionActivityNo == newItem.promotionActivityNo);
      if (itemIndex !== -1 && itemIndex !== index) {
        newItem.repeat = true;
      }
    }
    const fieldName = type + 'List';
    this[fieldName] = update(this[fieldName], {
      $splice: [[index, 1, newItem]],
    });
    if (type !== 'detail') return;
    this.updateEndTime(); // 更新结束日期
  };

  @action removeTableItem = (type, index) => {
    const fieldName = type + 'List';
    this[fieldName] = update(this[fieldName], {
      $splice: [[index, 1]],
    });
    if (type !== 'detail') return;
    this.updateEndTime(); // 更新结束日期
  };

  @action resetList = () => {
    this.detailList = [{ init: true, promotionActivityNo: uuidv4() }];
    this.zoneList = [{ init: true, uuid: uuidv4(), sendCount: 0, stockCount: 0 }];
    this.validTime = [moment(), undefined];
  };

  @action queryList = async (params) => {
    let res = {};
    try {
      res = await service.queryList(params);
    } catch (error) {}
    let list = [];
    if (res.code === 0 && res.success && Array.isArray(res.data?.list)) {
      list = res.data.list;
    }
    runInAction(() => {
      this.tableData = {
        list,
        total: res.data?.total || 0,
        pageNum: res.data?.pageNum || 1,
        pageSize: res.data?.pageSize || 10,
      };
    });
    return res;
  };

  @action saveItem = async (params) => {
    return await service.saveItem(params);
  };

  @action updateItem = async (params) => {
    return await service.updateItem(params);
  };

  @action queryById = async (params) => {
    let res = {};
    try {
      res = await service.queryById(params);
    } catch (error) {}
    if (res?.code == 0 && res?.success) {
      runInAction(() => {
        const { startTime, endTime, detailList, zoneList } = res.data || {};
        this.validTime = [startTime ? moment(startTime) : moment(), endTime ? moment(endTime) : undefined];
        if (Array.isArray(detailList)) {
          this.detailList = detailList;
        }
        if (Array.isArray(zoneList)) {
          this.zoneList = zoneList;
        }
      });
    }
    return res;
  };
}

export default new CouponStore();
