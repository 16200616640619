const url = {
    development: {
        apiUrl: '',
        apiUrlFilter: '/api',
        authUrl: 'https://auth-dev.bnq.com.cn',
        authUrlFilter: '/auth',
        zmAuth: 'https://z-dev.bnq.com.cn/user/login',
        website: 'https://ams-dev.bthome.com',

        port: 8010,
        autoOpenBrowser: true,
    },
    prodDev: {
        apiUrl: 'https://ams-dev.bthome.com',
        apiUrlFilter: '/api',
        authUrl: 'https://auth-dev.bnq.com.cn',
        authUrlFilter: '/auth',
        zmAuth: 'https://z-dev.bnq.com.cn/user/login',
        website: 'https://ams-dev.bthome.com',

    },
    test: {
        apiUrl: 'https://ams-test.bthome.com',
        apiUrlFilter: '/api',
        authUrl: 'https://auth-test.bnq.com.cn',
        authUrlFilter: '/auth',
        zmAuth: 'https://z-test.bnq.com.cn/user/login',
        website: 'https://ams-test.bthome.com',
    },
    production: {
        apiUrl: 'https://ams.bthome.com',
        apiUrlFilter: '/api',
        authUrl: 'https://auth.bnq.com.cn',
        authUrlFilter: '/auth',
        zmAuth: 'https://z.bnq.com.cn/user/login',
        website: 'https://ams.bthome.com',
    },
};

module.exports = url;
