import { Remote } from '@/util';

class CouponService {
  queryList(params = {}) {
    return Remote.post('/activityCoupon/queryPageList.do', params);
  }

  saveItem(params = {}) {
    return new Promise((resolve) => {
      Remote.post('/activityCoupon/saveActivityInfo.do', params)
        .then((data) => resolve(data))
        .catch((err) => resolve(err));
    });
  }

  updateItem(params = {}) {
    return new Promise((resolve) => {
      Remote.post('/activityCoupon/updateActivityInfo.do', params)
        .then((data) => resolve(data))
        .catch((err) => resolve(err));
    });
  }

  queryById(id) {
    return Remote.post(`/activityCoupon/do/${id}.do`);
  }

  queryCouponItem(id) {
    return Remote.get(`/activityCoupon/getCouponDetailByPromotionActivityNo.do?activityNo=${id}`);
  }

  queryUserCouponStatus(params) {
    return new Promise((resolve) => {
      Remote.post('/client/activityComponent/coupon/getCustomerCouponStatus.do', params)
        .then((data) => resolve(data))
        .catch((err) => resolve(err));
    });
  }

  receiveCoupon(params) {
    return new Promise((resolve) => {
      Remote.post('/client/activityComponent/coupon/receiveCoupon.do', params)
        .then((data) => resolve(data))
        .catch((err) => resolve(err));
    });
  }
}

export default new CouponService();
