import queryString from 'query-string';
import axios from 'axios';
import { RNTool, rnToolV2 } from 'web-message-for-rn';
import wx from 'weixin-js-sdk';
import callHelper from './callHelper';
import { browserTypeEnum } from './systemEnum';

import dayjs from 'dayjs';

function postMessagePromise (key, opt) {
  return new Promise((resolve, reject) => {
    RNTool.postMessageToNative(key, opt || {}, (res) => {
      resolve(res);
    });
  });
}

function sendMsgToRNPromise (key, opt) {
  return new Promise((resolve, reject) => {
    rnToolV2.sendMsgToRN(key, opt || {}, (res) => {
      resolve(res);
    });
  });
}

export async function reqDeviceInfo () {
  return new Promise((resolve) => {
    let deviceInfo = null;
    let tmpData = window.localStorage.getItem('bnq_device_info');
    try {
      deviceInfo = JSON.parse(tmpData || null);
      // eslint-disable-next-line no-empty
    } catch (error) { }
    if (deviceInfo) {
      resolve(deviceInfo);
      return;
    }
    RNTool.requestDeviceInfo((res) => {
      deviceInfo = res?.data?.response ?? res?.deviceInfo;
      window.localStorage.setItem('bnq_device_info', JSON.stringify(deviceInfo));
      resolve(deviceInfo);
    });
  });
}

export function getBrowserType () {
  let ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('micromessenger') != -1) {
    return 1; // 微信浏览器
  }

  if (ua.indexOf('baianjuapp') != -1) {
    return 2; // 百安居App webView
  }

  return 0; // 其他浏览器
}

export function is_weixin () {
  let ua = navigator.userAgent.toLowerCase();
  let isWeixin = ua.indexOf('micromessenger') != -1;
  if (isWeixin) {
    return true;
  }

  return false;
}

/**
 * 获取页面载体
 * @param {*} detail 尽可能详细
 * @returns
 */
export function getPageContainer (detail = true) {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('micromessenger') != -1) {
    // 微信
    if (window.__wxjs_environment === 'miniprogram') {
      // 小程序
      return browserTypeEnum.MINI;
    }
    if (detail) return browserTypeEnum.WX_BROWSER;
    return browserTypeEnum.BROWSER;
  }

  if (ua.indexOf('baianjuapp') != -1) {
    // 百安居app
    if (detail) return browserTypeEnum.APP_BNQ;
    return browserTypeEnum.APP;
  }

  if (ua.indexOf('baianjuapp_ju') != -1) {
    // 小居app
    if (detail) return browserTypeEnum.APP_JU;
    return browserTypeEnum.APP;
  }

  return browserTypeEnum.BROWSER;
}

export function isMinProgram () {
  return getPageContainer() === browserTypeEnum.MINI;
}

export function isBnqApp () {
  return getPageContainer() == browserTypeEnum.APP_BNQ;
}

export function isXiaojuApp () {
  return getPageContainer() == browserTypeEnum.APP_JU;
}

export function isApp () {
  return isBnqApp() || isXiaojuApp();
}

export function reqLogin (isManualLogin, successCB) {
  // 函数重载
  if (!successCB && Object.prototype.toString.call(isManualLogin) == '[object Function]') {
    successCB = isManualLogin;
    isManualLogin = false;
  }
  // 在小程序打开
  if (isMinProgram()) {
    const params = queryString.parse(location.search);
    console.log('params=========>', params);
    const token = params.token || '';
    const cityCode = params.cityCode || '';
    const cityName = params.cityName || '';
    const shopCode = params.shopCode || '';
    const shopName = params.shopName || '';
    const phone = params.phone || '';
    window.localStorage.setItem('bnq_access_token', token);
    if (token) {
      const newParams = {
        apptoken: token,
        phone,
        cityCode,
        cityName,
        shopCode,
        shopName,
      };
      if (successCB) {
        successCB(newParams);
      }
    } else {
      window.localStorage.setItem('bnq_access_token', '');
      if (isManualLogin) {
        wx.miniProgram.navigateTo({
          url: `/pages/my/login/index?login=need`,
        });
      } else if (successCB) {
        successCB({
          phone,
          cityCode,
          cityName,
          shopCode,
          shopName,
        });
      }
    }
  } else if (isApp()) {
    let opt = {};
    if (isManualLogin) {
      opt = {
        action: 'buyEvent',
        identifity: '909876',
      };
    }
    RNTool.postMessageToNative('reqLoginInfo', opt, async (res) => {
      if (res?.nativeResponse?.code === 0) {
        let xUser = res?.nativeResponse?.data?.user || {};

        let params = {
          apptoken: res?.nativeResponse?.data.token,
          phone: xUser.phone,
        };
        window.localStorage.setItem('bnq_access_token', params.apptoken);

        let cityRes = await postMessagePromise('reqCurrentCity');
        let shopRes = await sendMsgToRNPromise('getShop');

        console.log('ciytsssssss', cityRes);
        console.log('shopRes', shopRes);

        let city = cityRes?.data?.city || {};
        let shop = shopRes || {};

        params.cityCode = city.cityCode;
        params.cityName = city.cityName;
        params.shopCode = shop.shopCode;
        params.shopName = shop.shopName;

        if (successCB) {
          successCB(params);
        }
      } else {
        window.localStorage.setItem('bnq_access_token', '');
        if (successCB) {
          successCB();
        }
      }
    });
  } else if (successCB) {
    const params = queryString.parse(location.search);
    const cityCode = params.cityCode || '';
    const cityName = params.cityName || '';
    const shopCode = params.shopCode || '';
    const shopName = params.shopName || '';
    const phone = params.phone || '';

    successCB({
      cityCode,
      cityName,
      shopCode,
      shopName,
      phone,
    });
  }
}

export async function getShopCity () {
  let params = {};
  if (isApp()) {
    let cityRes = await postMessagePromise('reqCurrentCity');
    let shopRes = await sendMsgToRNPromise('getShop');

    let city = cityRes?.data?.city || {};
    let shop = shopRes || {};

    console.log('shoppppciyyyyyyy', city, shop);

    params.cityCode = city.cityCode;
    params.cityName = city.cityName;
    params.shopCode = shop.shopCode;
    params.shopName = shop.shopName;

    return params;
  }

  const query = queryString.parse(location.search);
  const cityCode = query.cityCode || '';
  const cityName = query.cityName || '';
  const shopCode = query.shopCode || '';
  const shopName = query.shopName || '';

  return {
    cityCode,
    cityName,
    shopCode,
    shopName,
  };
}

export function setAppHeaderTitle (params) {
  let options = {
    url: window.location.href,
    hideNav: false, // 隐藏导航栏
    // navibarStyle: params?.titleBg || 'light', // 目前仅支持两种 'light' 'dark'
    // navOutputColor: '#D0021B',
    // needWhiteContainAlways: '#F8E71C',
    transparent: false, // 导航栏是否支持渐变
    supportShare: true, // 是否支持分享
    title: params?.title || '',
  };

  if (params?.titleBg == 'dark') {
    options.navibarStyle = 'light';
  }
  if (isApp()) {
    setTimeout(() => {
      RNTool.postMessageToNative('h5Option', options);
    }, 500);
  }
}

export function appShare (options) {
  console.log(options, 'appShare');
  let defaultOptions = {
    title: options.title,
    content: options.content,
    url: window.location.href,
    iconUrl: options.sharePic,
    miniProgramId: 'gh_eb10d36d271d', //小程序原始ID 非必填需要分享小程序需要传递
    miniProgramPageUrl: ``, //小程序页面 非必填需要分享小程序需要传递
    isFriendNeedMiniProgram: true, //分享微信是否是需要小程序
    isFriendCircleNeedMinProgram: false, //分享朋友圈是否是需要小程序
    shareChannel: [1, 2, 100, 101], //分享面板的分享渠道 数组可包含 分享的类型 0,新浪 1,微信 2,朋友圈  4,qq  5,Qzone     100,复制链接 101,海报分享
    posterShareJS: {
      title: options.title,
      qrcodeUrl: 'https://dhstatic.bthome.com/img/activity/tigerYear/haibao/qrcode.jpg',
      mainImgUrl: options.sharePic,
      qrcodeWebUrl: 'http://weixin.qq.com/r/_0wRCXDEDi1xrTRq9xnp',
      shareChannel: [1, 2], //数组可包含 分享的类型 0,新浪 1,微信 2,朋友圈  4,qq  5,Qzone
      // 100,复制链接 101,海报分享，1010保存到相册
    },
  };
  const newOptions = Object.assign(defaultOptions, options);

  if (isApp()) {
    setTimeout(() => {
      RNTool.postMessageToNative('shareData', newOptions);
    }, 500);
  }
}

export function getCityByApp (cb) {
  if (isApp()) {
    setTimeout(() => {
      RNTool.postMessageToNative('reqCurrentCity', {}, (res) => {
        const { data } = res || {};
        const { city } = data;
        console.log(city, '获取到app的city');
        cb(city);
      });
    }, 500);
  }
}

export function getMiniPrograParmas () {
  if (isMinProgram()) {
    const params = queryString.parse(location.search);
    return params;
  }
  return {};
}

/**
 * 跳转商品详情页
 * @param {*} item 商品信息
 * @returns
 */
export async function toGoodsDetail (item, { componentCode, componentType }) {
  const browserType = getPageContainer(false);
  const urlParams = new URL(window.location.href).searchParams;
  const giochannel = urlParams.get('giochannel') || urlParams.get('channel') || '';
  const activityCode = sessionStorage.getItem('__activityCode__');
  if (browserType === browserTypeEnum.MINI) {
    wx.miniProgram.navigateTo({
      url: `/unpack/pages/goodsdetails/goodsdetails?itemId=${item.itemId}&skuId=${item.skuId}&source=ams&activityCode=${activityCode}&componentCode=${componentCode}&componentType=${componentType}&giochannel=${giochannel}`,
    });
    return;
  }
  if (browserType === browserTypeEnum.APP) {
    let xRouter = 'FurtureStoreGoodsDetail';
    let deviceInfo = await reqDeviceInfo();
    let xVersion = deviceInfo?.appVersion || deviceInfo?.pVersion || '';
    if (xVersion[0] >= 7 && deviceInfo?.appVersion != '7.0.0') {
      xRouter = 'PTProductDetail';
    }
    RNTool.pushToRouter(xRouter, {
      itemId: item.itemId,
      skuId: item.skuId,
      source: 'ams',
      activityCode, // 活动编码
      componentCode, // 组件code
      componentType, // 组件类型
      giochannel, // 运营计划编码
    });
  }
}

/**
 * 跳转设计师详情页
 * @param {*} id
 */
export function toDesignerDetail (id) {
  let browserType = getPageContainer();
  if (browserType === browserTypeEnum.BROWSER) {
    navToApp();
    return;
  }
  if (browserType === browserTypeEnum.APP_BNQ) {
    RNTool.pushToRouter('DesignerDetails', { id });
    return;
  }
  if (browserType === browserTypeEnum.MINI) {
    wx.miniProgram.navigateTo({ url: `/pages/designers/designersDetail?id=${id}` });
  }
}

/**
 * 跳转优惠券列表页
 */
export function toCouponListPage () {
  let browserType = getPageContainer();
  if (browserType === browserTypeEnum.BROWSER) {
    navToApp();
    return;
  }
  if (browserType === browserTypeEnum.APP_BNQ) {
    RNTool.pushToRouter('MyCouponPage');
    return;
  }
  if (browserType === browserTypeEnum.MINI) {
    wx.miniProgram.navigateTo({ url: '/unpack/pages/coupon/list/index' });
  }
}

/**
 * 跳转到百安居app
 */
export function navToApp () {
  let url = window.location.href;
  callHelper.open({
    screen: 'PTCustomWebPage',
    param: encodeURIComponent(JSON.stringify({ url })),
  });
}

/**
 * 获取app、小程序商城城市code
 */
export async function getShopCityCode () {
  return new Promise((resolve) => {
    let cityCode = null;
    if (isApp()) {
      console.log('******app reqCurrentCity');
      RNTool.postMessageToNative('reqCurrentCity', {}, (res) => {
        console.log('******app reqCurrentCity res:', res);
        cityCode = res?.data?.city?.cityCode;
        resolve(cityCode);
      });
      setTimeout(() => {
        console.log('******app reqCurrentCity timeout');
        resolve(null);
      }, 6000);
    } else if (isMinProgram()) {
      let params = new URL(window.location.href).searchParams;
      cityCode = params.get('cityCode');
      resolve(cityCode);
    } else {
      resolve(null);
    }
  });
}

/**
 * 获取中的用户token
 * @param {*} isApp app 或 小程序内
 * @returns
 */
export async function getTerminalToken (isApp = false) {
  return new Promise((resolve) => {
    if (isApp) {
      RNTool.requestLoginInfo(false, async (data) => {
        resolve(data.data?.loginInfo?.token);
      });
      setTimeout(() => {
        console.log('******app reqToken timeout');
        resolve(null);
      }, 6000);
    } else {
      let params = new URL(window.location.href).searchParams;
      let token = params.get('token');
      console.log('get xcx token: ', window.location.href, token);
      resolve(token);
    }
  });
}

/**
 * 登录以获取用户token
 * @param {*} loginedAppCB app登录成功后回调
 * @returns
 */
export function toLogin (loginedAppCB) {
  if (isMinProgram()) {
    wx.miniProgram.navigateTo({ url: '/pages/my/login/index' });
    return;
  }
  if (isApp()) {
    RNTool.postMessageToNative(
      'reqLoginInfo',
      {
        action: 'buyEvent',
        identifity: '909876',
      },
      (res) => {
        if (res?.nativeResponse?.code === 0 && res?.nativeResponse?.data?.token) {
          loginedAppCB?.(res?.nativeResponse?.data?.token);
        }
      },
    );
  }
}

/**
 * 埋点
 * @param {*} param0 组件信息
 * @param {*} isComponent 类型，false页面, true组件
 * @param {*} params 埋点参数
 * @returns
 */
export function track ({ componentType, componentCode, code } = {}, params = {}, isComponent = true) {
  // eslint-disable-next-line no-useless-return
  if (!window.gio) return;
  let browserType = getPageContainer();
  let urlParams = new URL(window.location.href).searchParams;
  let giochannel = urlParams.get('giochannel');
  let channel = urlParams.get('channel');
  const activityCode = sessionStorage.getItem('__activityCode__');
  const eventData = {
    terminal: browserTypeEnum.getLabel(browserType),
    giochannel: giochannel || channel,
    ...params,
  };
  let eventName = `${activityCode}_${componentType}_${componentCode}_${code}`;
  if (!isComponent) {
    eventName = `${activityCode}_${code}`;
  }
  console.log(`======track:${eventName}`, eventData);
  window.gio('track', eventName, eventData);
}

/**
 * 新埋点
 * @param {*} param0 组件信息
 * @param {*} isComponent 类型，false页面, true组件
 * @param {*} params 埋点参数
 * @returns
 */
export function newTrack (eventName, tid, params = {}) {
  // eslint-disable-next-line no-useless-return
  if (!window.gio) return;
  const time = dayjs().format('YYYY-MM-DD HH:mm:ss');
  const host = window.location.host;
  const dl = window.location.href;
  // const scurl = window.location.href;
  let ds1 = '网页';
  const eventData = {
    time,
    host,
    dl,
    // scurl,
    ds1,
    tid,
    device: 'H5',
    ...params
  };
  console.log(`======track:${eventName}`, eventData);
  window.gio('track', eventName, eventData);
}

export async function getSchemaUrl (url) {
  let host = 'https://wy-test.bthome.com';
  if (process.env.CURRENT_ENV == 'production' || !process.env.CURRENT_ENV) {
    host = 'https://wy.bthome.com';
  }
  let xpath = url.split('?');
  let res = await axios.get(
    `${host}/bnq_owner/apis/common/getUrlScheme.do?path=${encodeURIComponent(xpath[0])}&query=${encodeURIComponent(
      xpath[1],
    )}`,
  );

  // console.log('getSchemaUrl res:', res);

  return res?.data || {};
}

export async function openMini (url) {
  let urlRes = await getSchemaUrl(url);
  if (urlRes?.data?.openlink) {
    window.location.href = urlRes.data.openlink;
  }
}
