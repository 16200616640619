import React, { Component } from 'react';
import { message } from 'antd';
import {
  getPageContainer,
  toLogin,
  getTerminalToken,
  toCouponListPage,
  track,
  openMini,
} from '@/util/h5Helper';
import { browserTypeEnum } from '@/util/systemEnum';
import couponService from '@/service/coupon';
import './index.scss';

class Coupon extends Component {
  constructor(props) {
    super(props);
    const browserType = getPageContainer(false);
    let isEditor = true;
    if (window.location.pathname.indexOf('/activity/detail') === -1) {
      isEditor = false;
    }
    const params = new URL(window.location.href).searchParams;
    const id = params.get('id') || params.get('activityId') || '';
    const channel = params.get('channel') || '';
    const giochannel = params.get('giochannel') || '';
    this.statusOptions = {
      1: 'enable',
      2: 'afterReceive',
      3: 'runOut',
      4: 'outOfTime',
    };
    this.state = {
      channel,
      giochannel,
      activityId: id,
      browserType,
      isEditor,
      token: null,
      // 1: 可领取
      // 2: 已领完
      // 3: 无库存
      // 4: 不在时间区间内
      couponStatus: 0,
      imgSrc: null,
    };
  }

  componentDidMount() {
    this.toggleZone();
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { couponStatus } = this.state;
    if (prevState.couponStatus !== couponStatus) {
      this.getImgSrc();
    }
  }

  toggleZone() {
    const { isEditor, imgSrc } = this.state;
    const { zoneComponentUuid } = this.props;
    let zoneEl = document.getElementById(zoneComponentUuid);
    if (!zoneEl) return;
    if (!imgSrc && !isEditor) {
      zoneEl.style.display = 'none';
    } else {
      zoneEl.style.display = 'block';
    }
  }

  async queryUserToken() {
    const { browserType } = this.state;
    const res = await getTerminalToken(browserType === browserTypeEnum.APP);
    this.setState({ token: res });
    return res;
  }

  async queryUserCouponStatus(token, invoteReceive = false) {
    const { activityId } = this.state;
    const { componentUuid, businessId, id } = this.props;
    const params = {
      activityId,
      componentCode: id,
      componentUuid,
      couponId: businessId,
      token,
    };
    const res = await couponService.queryUserCouponStatus(params);
    if (res?.code == 0 && res?.success) {
      this.setState({ couponStatus: res.data ?? 1 });
      if (res?.data == 1 && invoteReceive) {
        this.receiveCoupon();
      }
    }
  }

  async receiveCoupon(data) {
    const { activityId, token } = this.state;
    const { componentUuid, businessId, id } = this.props;
    const params = {
      activityId,
      componentCode: id,
      componentUuid,
      couponId: businessId,
      token: data || token,
    };
    const res = await couponService.receiveCoupon(params);
    if (res?.code == 0 && res?.success) {
      message.success({ icon: <div></div>, content: '领取成功' });
      this.queryUserCouponStatus(token);
    } else {
      message.error(res?.message || '领取失败');
    }
  }

  init = async () => {
    const { browserType } = this.state;
    if (browserType === browserTypeEnum.APP || browserType === browserTypeEnum.MINI) {
      let token = await this.queryUserToken();
      if (token) {
        this.queryUserCouponStatus(token);
      } else {
        this.setState({ couponStatus: 1 }); // 未登录情况下，显示邀请领取的图片
      }
    } else {
      this.setState({ couponStatus: 1 });
    }
  };

  handleClick = async () => {
    const { componentType, id } = this.props;
    track({ code: 'click', componentType, componentCode: id });
    const { browserType, token, isEditor, couponStatus, activityId, channel, giochannel } = this.state;
    const { afterReceive } = this.props;
    if (isEditor) return;
    if (browserType == browserTypeEnum.APP || browserType === browserTypeEnum.MINI) {
      if (!token) {
        toLogin((res) => {
          this.setState({ token: res });
          this.queryUserCouponStatus(res, true);
        });
      } else if (couponStatus == 1 || couponStatus == 4) {
        this.receiveCoupon();
      } else if (couponStatus == 2 && afterReceive == 'changeStyle') {
        toCouponListPage();
      } else if (couponStatus == 3) {
        message.success({ icon: <div></div>, content: '优惠券已派发完~' });
      }
    } else {
      openMini(`unpack/pages/web/index?pageId=${activityId}&channel=${channel}&giochannel=${giochannel}`);
    }
  };

  getImgSrc = () => {
    const { couponStatus } = this.state;
    const { bgImage, afterReceive, runOut, outOfTime, imgAfterReceive, imgRunOut, imgOutOfTime } = this.props;
    let imgSrc = null;
    if (couponStatus == 1) {
      imgSrc = bgImage;
    } else if (couponStatus == 2 && afterReceive == 'changeStyle') {
      imgSrc = imgAfterReceive;
    } else if (couponStatus == 3 && runOut == 'changeStyle') {
      imgSrc = imgRunOut;
    } else if (couponStatus == 4 && outOfTime == 'changeStyle') {
      imgSrc = imgOutOfTime;
    }
    this.setState({ imgSrc }, () => this.toggleZone());
  };

  render() {
    const { isEditor, imgSrc } = this.state;
    const { componentUuid, bgImage } = this.props;
    // console.log('coupon render props', this.props);
    return (
      <div className="coupon-com" id={componentUuid}>
        {isEditor ? (
          bgImage ? (
            <img className="coupon-img" alt="" src={bgImage} />
          ) : (
            <div className="coupon-tip">
              <div>优惠券</div>
              <div>请上传图片</div>
            </div>
          )
        ) : (
          imgSrc && <img className="coupon-img" alt="" src={imgSrc} onClick={this.handleClick} />
        )}
      </div>
    );
  }
}

Coupon.getDefaultValue = () => {
  return {
    couponId: '',
    bgImage: '',
    afterReceive: 'changeStyle',
    runOut: 'changeStyle',
    outOfTime: 'changeStyle',
    imgAfterReceive: '',
    imgRunOut: '',
    imgOutOfTime: '',
  };
};

Coupon.getEditData = () => ({
  data: {
    couponBindType: {
      label: '优惠券绑定类型',
      control: 'radioGroup',
      getInitialValue: 0,
      options: () => [
        { label: '礼券活动', value: 0 },
        { label: '优惠券限定', value: 1 },
      ],
    },
    activityNoList: {
      label: '礼券活动ID',
      renderAfter: () => {
        return <span style={{ color: ' rgba(0,0,0,0.45)' }}>可输入多个礼券活动ID，使用英文符号 , 分隔</span>;
      },
      checkShow: ({ props }) => {
        let { form } = props || {};
        return form.getFieldValue('couponBindType') == 0;
      },
    },
    couponId: {
      label: '限定ID',
      checkShow: ({ props }) => {
        let { form } = props || {};
        return form.getFieldValue('couponBindType') == 1;
      },
    },
    bgImage: {
      label: '优惠券图片',
      control: 'upload',
      controlProps: {
        accept: '.jpg,.jpeg,.png',
        maxSize: '1M',
      },
      renderAfter: () => {
        return (
          <span style={{ color: ' rgba(0,0,0,0.45)' }}>支持拓展名：.png .jpg .jpeg，建议宽高比375:140(小于1M)</span>
        );
      },
    },
    afterReceive: {
      label: '领取完样式',
      control: 'radioGroup',
      getInitialValue: 'changeStyle',
      options: () => [
        { label: '切换图片', value: 'changeStyle' },
        { label: '不显示', value: 'hidden' },
      ],
    },
    imgAfterReceive: {
      label: '领取后图片',
      control: 'upload',
      controlProps: {
        accept: '.jpg,.jpeg,.png',
        maxSize: '1M',
      },
      renderAfter: () => {
        return (
          <span style={{ color: ' rgba(0,0,0,0.45)' }}>支持拓展名：.png .jpg .jpeg，建议宽高比375:140(小于1M)</span>
        );
      },
      checkShow: ({ props }) => {
        let { form } = props || {};
        return form.getFieldValue('afterReceive') == 'changeStyle';
      },
    },
    runOut: {
      label: '派发完样式',
      control: 'radioGroup',
      getInitialValue: 'changeStyle',
      options: () => [
        { label: '切换图片', value: 'changeStyle' },
        { label: '不显示', value: 'hidden' },
      ],
    },
    imgRunOut: {
      label: '派发完图片',
      control: 'upload',
      controlProps: {
        accept: '.jpg,.jpeg,.png',
        maxSize: '1M',
      },
      renderAfter: () => {
        return (
          <span style={{ color: ' rgba(0,0,0,0.45)' }}>支持拓展名：.png .jpg .jpeg，建议宽高比375:140(小于1M)</span>
        );
      },
      checkShow: ({ props }) => {
        let { form } = props || {};
        return form.getFieldValue('runOut') == 'changeStyle';
      },
    },
    outOfTime: {
      label: '非领取时间样式',
      control: 'radioGroup',
      getInitialValue: 'changeStyle',
      options: () => [
        { label: '切换图片', value: 'changeStyle' },
        { label: '不显示', value: 'hidden' },
      ],
    },
    imgOutOfTime: {
      label: '非领取时间图片',
      control: 'upload',
      controlProps: {
        accept: '.jpg,.jpeg,.png',
        maxSize: '1M',
      },
      renderAfter: () => {
        return (
          <span style={{ color: ' rgba(0,0,0,0.45)' }}>支持拓展名：.png .jpg .jpeg，建议宽高比375:140(小于1M)</span>
        );
      },
      checkShow: ({ props }) => {
        let { form } = props || {};
        return form.getFieldValue('outOfTime') == 'changeStyle';
      },
    },
  },
});

Coupon.validate = (props) => {
  const {
    couponBindType,
    activityNoList,
    couponId,
    bgImage,
    afterReceive,
    runOut,
    outOfTime,
    imgAfterReceive,
    imgRunOut,
    imgOutOfTime,
  } = props;
  if (couponBindType == 0 && !activityNoList) return '请输入礼券活动ID';
  if (couponBindType == 1 && !couponId) return '请输入限定ID';
  if (!bgImage) return '请设置优惠券图片';
  if (afterReceive == 'changeStyle' && !imgAfterReceive) return '请设置领取后图片';
  if (runOut == 'changeStyle' && !imgRunOut) return '请设置派发完图片';
  if (outOfTime == 'changeStyle' && !imgOutOfTime) return '请设置非领取时间图片';
  return '';
};

export default Coupon;
